import React from 'react';

import MenuSection from '../MenuSection';
import ActiveListItems from './items';

function ActiveList() {

  return (
    <>
      {ActiveListItems().map((section) => (
        <MenuSection key={section?.id} heading={section?.heading} items={section?.items}></MenuSection>
      ))}
    </>
  );
}

export default ActiveList;