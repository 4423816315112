import React from "react";

import PropTypes from 'prop-types';
import { Button, Typography, Box, Grid } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { Link } from 'react-router-dom';

import DisplayFilePrefixAndType from 'src/components/DisplayFilePrefixAndType';
import DisplayPgpFilePreference from "src/components/DisplayPgpFilePreference";
import CopyButton from "src/components/CopyButton";
import FileDownloadCard from "src/components/FileDownload";

import * as Constants from 'src/constants';

function CustomerFileSetupSummary(props) {

  const nextLink = '/verified-deaths-file-setup';

  return (
    <>
      <Box sx={{ p: 5 }}>
        <DisplayFilePrefixAndType filePrefix={props?.file?.prefix} fileType={props?.file?.type} transferFreq={props?.transfer?.frequency} />

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 4, mb: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

        <Typography variant="subtitle1" color="text.primary">Transfer Location Method:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{(props?.transfer?.type === 'sftp') ? 'SFTP (Secure File Transfer Protocol)' : 'AWS S3 (Simple Storage Service)'}</Typography>

        {(props.transfer.type === 'sftp') &&
          <>
            <Typography variant="subtitle1" color="text.primary">LENS SFTP Server Hostname:</Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy SFTP Server" textToCopy={props.transfer?.sftp?.sftpServer} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.sftp?.sftpServer}</Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" color="text.primary">Port:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{Constants.lensSftpPort}</Typography>
            <Typography variant="subtitle1" color="text.primary">Subdirectory:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>/customer_files</Typography>
            <Typography variant="subtitle1" color="text.primary">SFTP Username:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props.transfer.sftp.username}</Typography>
            <Typography variant="subtitle1" color="text.primary" >SSH-RSA key to authenticate to access the virtual server instance:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>For security reasons, the public SSH-RSA key provided is hidden.</Typography>
            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Use the credentials to access LENS SFTP. At the transfer frequency identified, place your Customer list in the SFTP subdirectory <strong>(/customer_files)</strong>.</Typography>
            <Typography variant="subtitle1" color="text.primary">File Encryption Preference:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props.transfer.sftp.pgp ? 'Encrypt list with public PGP key created by LENS.' : 'Do not encrypt list with PGP key.'}</Typography>
          </>
        }
        {(props?.transfer?.sftp?.pgp && (props.transfer.type === 'sftp')) && <>
          <DisplayPgpFilePreference showButtons={true} pgpKeyRotationInYears={props.transfer.sftp.pgp.keyRotationInYears} pgpPublicKey={props.transfer.sftp.pgp.publicKey} buttonText="KEY" fileName={'customers-' + props.file.prefix + '.key'} />
          <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Download the public PGP key to encrypt your Customer list.</Typography>
        </>
        }

        {(props.transfer.type === 'awsS3') &&
          <>
            <Typography variant="subtitle1" color="text.primary">AWS Account ID <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 0 }}>Carrier AWS Cloud Formation Template<Typography display="inline" variant="body2"> (Provided by LENS):</Typography></Typography>
            <FileDownloadCard variation="icon" fileName={"lens-carrier-aws-" + props.file.prefix + "-files-option.yml"} file='carrier-aws-customer-files-option.yml' fileType='yml' label="Customer File Template" />
            <Typography sx={{ mb: 2 }} variant="body2" >Downloading and deploying this template with the Lambdas in your AWS environment provides S3 Bucket, KMS Key (optional) and IAM Role ARN output values.</Typography>
            <Typography variant="subtitle1" color="text.primary">Lambda ARN <Typography variant="body2" display="inline">(Provided by LENS):</Typography></Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy ARN" textToCopy={props.transfer.awsS3.evadataLambdaArn} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.evadataLambdaArn}</Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" color="text.primary" >Lambda Role ARN <Typography variant="body2" display="inline">(Provided by LENS):</Typography></Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy ARN" textToCopy={props?.transfer?.awsS3?.evadataLambdaRoleArn} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.evadataLambdaRoleArn}</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Lambda ARNs are used by your organization to set the event trigger for the S3 bucket, and to set role permissions for LENS to access your S3 bucket to retrieve your list.</Typography>
            <Typography variant="subtitle1" color="text.primary">S3 Bucket ARN <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.s3Bucket}</Typography>
            {props?.transfer?.awsS3?.kmsArn && <>
              <Typography variant="subtitle1" color="text.primary">KMS Key ARN <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props.transfer.awsS3.kmsArn}</Typography>
              <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>LENS uses the KMS Key ARN to decrypt your transferred list.</Typography>
            </>}
            <Typography variant="subtitle1" color="text.primary">IAM Role ARN <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.iamRole}</Typography>
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown.</Typography>
          </>
        }

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>NOTIFICATION PREFERENCES</Typography>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Email(s) to receive notifications related to this list:</Typography>
        {props.notificationEmails.map((email) => {
          return <Typography variant='body1' sx={{ mb: 4 }} key={email}>{email}</Typography>
        })}
      </Box>

      <hr />

      <Grid container sx={{ px: 5, pb: 4 }}>
        <Grid item sm={6}>
          <Button LinkComponent={Link} to="/onboarding-welcome" sx={{ mt: 3 }} variant="text" startIcon={<ArrowBack />}>
            Back
          </Button>
        </Grid>
        <Grid item sm={6} sx={{ textAlign: 'right' }}>
          <Button LinkComponent={Link} to={nextLink} sx={{ mt: 3 }} variant="contained" type="submit" endIcon={<ArrowForward />}>
            Next Step
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

CustomerFileSetupSummary.propTypes = {
  file: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  transfer: PropTypes.shape({
    type: PropTypes.string,
    frequency: PropTypes.string,
    sftp: PropTypes.shape({
      username: PropTypes.string,
      frequency: PropTypes.string,
      sshKey: PropTypes.string,
      sftpServer: PropTypes.string,
      pgp: PropTypes.object
    }),
    awsS3: PropTypes.shape({
      s3Bucket: PropTypes.string,
      kmsArn: PropTypes.string,
      iamRole: PropTypes.string,
      evadataLambdaArn: PropTypes.string,
      evadataLambdaRoleArn: PropTypes.string,
      carrierIntegrationAccountId: PropTypes.string
    })
  }),
  pgp: PropTypes.shape({
    keyRotationInYears: PropTypes.number,
    publicKey: PropTypes.string
  }),
  notificationEmails: PropTypes.arrayOf(PropTypes.string),
  sftpServer: PropTypes.string,
  evadataLambdaArn: PropTypes.string,
  evadataLambdaRoleArn: PropTypes.string,
};

export default CustomerFileSetupSummary;


