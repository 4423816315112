import React, { useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import FileDownloadCard from "../FileDownload";
import PropTypes from 'prop-types';
import { ErrorOutline } from "@mui/icons-material";

function AttributeDownloads({ label, variation, fileType, fileName, file }) {

  const [hasError, setHasError] = useState(false);

  return (
    <Grid container sx={{height: '70px'}}>
      <Grid item xs={4}>
        <Typography variant="h5">Attributes</Typography>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ textAlign: 'left', float: 'right' }}>
          <Typography display="inline-block" variant="body2">Download Data Dictionary:</Typography>
          <Box sx={{ display: 'inline-block', ml: 1 }}>
            <FileDownloadCard label={label} variation={variation} fileType={fileType} fileName={fileName} file={file} setHasError={setHasError} disableInlineError={true} disableErrorOutline={true} iconFontSize={13} iconWidth="16px" iconTopPadding={0} />
          </Box>
        </Box>
      </Grid>
        {hasError &&
          <Box sx={{ width: '100%' }}>
            <Box sx={{ textAlign: 'left', float: 'right', }}>
              <ErrorOutline sx={{ color: 'error.main', float: 'left', ml: 3, fontSize: '20px', mt: '4px', mr: '4px' }} /><Typography variant="caption" color="error.main"> Error retrieving file, try again.</Typography>
            </Box>
          </Box>
        }
    </Grid>
  )
}

AttributeDownloads.propTypes = {
  label: PropTypes.string,
  variation: PropTypes.string,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  file: PropTypes.string
}

export default AttributeDownloads;