const rows = [
  {
    id: 1,
    categoryLevel: ['CATEGORY 1', 'Highest confidence match'],
    dataTier: ['CARRIER-TO-CARRIER', 'DMF'],
    ssn: 'M',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M',
    city: '',
    state: ''
  },
  {
    id: 2,
    categoryLevel: ['CATEGORY 1.25'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M or F',
    city: 'M',
    state: 'M'
  },
  {
    id: 3,
    categoryLevel: ['CATEGORY 1.5'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M',
    city: '',
    state: 'M'
  },
  {
    id: 4,
    categoryLevel: ['CATEGORY 1.75'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'F',
    city: '',
    state: 'M'
  },
  {
    id: 5,
    categoryLevel: ['CATEGORY 1.8'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M or F',
    firstName: 'M or F',
    lastName: 'M',
    city: 'M',
    state: 'M'
  },
  {
    id: 6,
    categoryLevel: ['CATEGORY 2'],
    dataTier: ['CARRIER-TO-CARRIER', 'DMF'],
    ssn: 'M',
    dob: 'M or F',
    firstName: 'M or F',
    lastName: 'M or F',
    city: '',
    state: ''
  },
  {
    id: 7,
    categoryLevel: ['CATEGORY 2.1'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M',
    city: '',
    state: ''
  },
  {
    id: 8,
    categoryLevel: ['CATEGORY 3'],
    dataTier: ['CARRIER-TO-CARRIER', 'DMF'],
    ssn: 'M or F',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M of F',
    city: '',
    state: ''
  },
  {
    id: 9,
    categoryLevel: ['CATEGORY 3.1'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'F',
    city: '',
    state: ''
  },
  {
    id: 10,
    categoryLevel: ['CATEGORY 3.2'],
    dataTier: ['CARRIER-TO-CARRIER', 'DMF'],
    ssn: 'N or I',
    dob: 'M',
    firstName: 'M or F',
    lastName: 'M or F',
    city: '',
    state: ''
  },
  {
    id: 11,
    categoryLevel: ['CATEGORY 4'],
    dataTier: ['ALL CARRIERS', 'Obituary'],
    ssn: '',
    dob: '',
    firstName: 'M or F',
    lastName: 'M or F',
    city: 'M or F',
    state: 'M'
  }
];

export default rows;