
import { ListAltRounded, HistoryRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppContext } from "src/AppContext";
import { filterItems } from "../../filterItems";

function ActiveListItems() {

  const { carrierConfig, lensRoles } = useAppContext();
  const [activeListSummaryLink, setActiveListSummaryLink] = useState('/active-list-summary');
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    if (carrierConfig?.status === 'live') {
      setActiveListSummaryLink('/');
    }
  }, [activeListSummaryLink, carrierConfig?.status])

  useEffect(() => {
    const menuItems = [
      {
        name: 'Active List Summary',
        icon: ListAltRounded,
        link: activeListSummaryLink,
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
      {
        name: 'Activity Log',
        icon: HistoryRounded,
        link: '/activity-log',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      }
    ];

    if (lensRoles && carrierConfig?.status) {
      let filteredItems = menuItems?.filter((item) => filterItems(item, lensRoles, carrierConfig?.status));

      const _displayActiveItems = [
        {
          items: filteredItems,
          id: 'active'
        }
      ];

      if (filteredItems.length) {
        setDisplayItems(_displayActiveItems);
      }

    }
  }, [lensRoles, carrierConfig?.status, activeListSummaryLink])

  return displayItems;

}

export default ActiveListItems;
