import { PeopleRounded } from '@mui/icons-material';
import React from 'react';

import FileStatus from 'src/components/FileStatus';

function CustomerFileStatus() {
  return (
    <FileStatus title="Customer Lists" subtitle="All recent and historical Customer List data loads." queryType="customer-files" queryKey="customerFileDataLoads" sortKey={{'field': 'completed', 'sort': 'desc'}} icon={<PeopleRounded />}/>
  )
}

CustomerFileStatus.propTypes = {};

export default CustomerFileStatus;