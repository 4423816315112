import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Typography, Box, Grid, Alert, Container, styled, IconButton, Tooltip, Chip } from '@mui/material';

import { Link } from 'react-router-dom';
import DisplayPgpFilePreference from 'src/components/DisplayPgpFilePreference';
import { ArrowBack, CheckCircleOutline, Close, InfoRounded } from '@mui/icons-material';
import { getCurrentDate } from 'src/utilities/getDate';
import CopyButton from 'src/components/CopyButton';
import FileDownloadCard from 'src/components/FileDownload';
import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';
import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';
import { months } from 'src/utilities/months';
import { AnnualDmfFileSample } from 'src/components/DisplayAnnualDmfFileSample';

function MatchResultsFileSetupSummary(props) {

  const date = getCurrentDate();

  function bothFiles() {
    return (
      <>
        {props?.file.prefix}-{date}.json
        <br />
        {props?.file.prefix}-{date}.xlsx
      </>
    )
  }

  const AlertWrapper = styled(Container)(
    ({ theme }) => `
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 25px !important;
    position: fixed;
    z-index: 0;
    left: 0px;
    top: 70px;
      .completed-alert {
        width: calc(100% - 340px);
        margin-left: 315px;
      }
      @media screen and (max-width: 1279px) {
        .completed-alert {
          width: calc(100% - 50px);
          margin-left: 25px !important;
        }
      }
      `
  );

  const LinkWrapper = styled(Typography)(
    ({ theme }) => `
      a:-webkit-any-link {
        color: ${theme.colors.primary.main}
      }
    `
  );

  const SourcesWrapper = styled(Box)(() => `white-space: pre-wrap;`);

  const [open, setOpen] = useState(true);

  function close() {
    setOpen(false);
  }

  return (
    <>
      {(open && props?.showCompletedAlert) && <AlertWrapper>
        <Alert
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          className="completed-alert"
          severity="success"
          action={
            <IconButton className="alert-close"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={close}
            >
              CLOSE <Close className='close-icon' fontSize="inherit" />
            </IconButton>
          }>Onboarding Complete<br />
          Reach out when you are ready to move to Live. Once Live, you will be able to see list metadata and receive matches. </Alert>

      </AlertWrapper>
      }
      <Box sx={{ p: 5 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>
        <Typography variant="subtitle1" color="text.primary">File Prefix:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.file?.prefix}</Typography>

        <Typography variant="subtitle1" color="text.primary">File Type:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2, textTransform: 'uppercase' }}>{props?.file?.type === 'both' ? 'JSON and XLSX' : props?.file?.type}</Typography>

        <Typography variant="subtitle1" color="text.primary">File Name Example:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}> {props?.file?.type === 'both' ? bothFiles() : props?.file.prefix + '-' + date + '.' + props?.file.type}</Typography>

        <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>Prefix and file type are used by LENS to create your matches.</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 3 }}>
          <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0 }}>Transfer Frequency Schedule: </Typography>
          <Typography variant="body1" color="text.primary" sx={{ textTransform: 'capitalize', whiteSpace: 'pre' }}>{props?.transfer?.frequency === 'daily' ? ' Daily (Monday-Friday)' : ' ' + props?.transfer?.frequency} {(props?.transfer?.frequency === 'weekly' || props?.transfer?.frequency === 'monthly') && '(' + props?.transfer?.frequencyDay + ')'}</Typography>
          <Tooltip placement="right" arrow title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>
        </Box>

        {props?.transfer?.frequency === 'quarterly' &&
          <DisplayQuarterlyDates reportDates={props?.transfer?.reportDates} />
        }

        <Typography variant="body2" color="text.primary" sx={{ mb: 4, mt: 1 }}>At this frequency, LENS delivers match results to your organization’s transfer location.</Typography>

        {props?.transfer?.frequency === 'daily' ?
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2, mt: 3 }}>
              <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0, mr: 1 }}>Observe Company Holidays:</Typography>
              <Typography variant="body1" color="text.primary">{props?.holidayCalendarId ? 'Yes' : 'No'}</Typography>
              <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" /></Tooltip>
            </Box>
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
          </>
          : ''}

        {props?.availableFrequencies?.includes('dmf-annual') &&
          <>
            <Typography variant="subtitle1" color="text.primary">Include annual full DMF scans?</Typography>
            <Typography mb={props?.transfer?.annualDmf ? 2 : 4}>{props?.transfer?.annualDmf ? 'Yes, send annual full DMF scans. One match results file to be delivered once per contract year.' : 'No, do not send annual DMF scans.'}</Typography>
            {props?.transfer?.annualDmf &&
              <>
                <Typography variant="subtitle1" color="text.primary">Delivery Date:</Typography>
                <Typography>{months[Number(props?.transfer?.annualDmf?.split('/')[0])-1]} {Number(props?.transfer?.annualDmf?.split('/')[1])}</Typography>
                <Typography variant="subtitle1" color="text.primary" mt={2}>File Name Example:</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>{AnnualDmfFileSample(props?.transfer?.annualDmf, props?.file?.prefix, props?.file?.type)}</Typography>
                <Box mb={4}>
                  {props?.transfer?.annualDmf &&
                    <>
                      <Typography mt={2}>Within each contract year, only one customer list at a time can undergo an annual DMF scan.</Typography>
                      <Typography mt={2} variant="body2">Note: Annual DMF full scans can be added later once the account has moved to Live stage.</Typography>
                    </>
                  }
                </Box>
              </>
            }
          </>
        }

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>SOURCE SELECTION</Typography>

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Sources your customer records will be matched against:</Typography>
        <SourcesWrapper>
          <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{props?.sources.sort().map((source) => (
            sourceDisplayMap[source])
          ).join('\n')}
          </Typography>
        </SourcesWrapper>

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>MATCH CATEGORIES</Typography>
        <Grid container>
          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 0 }}>These categories will appear in your match results:</Typography>
          <LinkWrapper variant="body2" mt={0.5} ml={3}><Link target="_blank" to="/evadatas-match-categories">View Match Category Definitions</Link></LinkWrapper>
        </Grid>

        <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{props?.categories && props?.categories?.map((cat) => {
          return <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px'}} size="small" label={cat} />
        })}</Typography>

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

        <Typography variant="h6" color="text.primary" sx={{ mb: 1, mt: 4 }}>Transfer Location Method:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>{(props?.transfer.type === 'sftp') ? 'SFTP (Secure File Transfer Protocol)' : 'AWS S3 (Simple Storage Service)'}</Typography>

        {props?.transfer.type === 'sftp' &&
          <>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>LENS will use the credentials below to access your organization’s SFTP to transfer your match results.</Typography>
            <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>SFTP Server Hostname:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>{props?.transfer.sftp.host}</Typography>
            <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Port:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>{props?.transfer.sftp.port}</Typography>
            <Typography className={props?.transfer?.sftp?.path ? 'show' : 'hide'} variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Subdirectory Path:</Typography>
            <Typography className={props?.transfer?.sftp?.path ? 'show' : 'hide'} variant="body1" color="text.primary" sx={{ mb: 3 }}>{props?.transfer?.sftp?.path}</Typography>
            <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>SFTP Username:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>{props?.transfer.sftp.username}</Typography>
          </>
        }
        {(props?.transfer?.type === 'sftp' && (props?.transfer.sftp?.ssh?.publicKey || props?.transfer.sftp?.sshPublicKey)) &&
          <>
            <DisplayPgpFilePreference pgpKeyRotationInYears={0} pgpPublicKey={props?.transfer.sftp?.ssh?.publicKey || props?.transfer.sftp?.sshPublicKey} fileName={'matches-ssh-' + props?.file.prefix + '.key'} pgpKeyLabel="Public SSH-RSA Key:" showButtons={true} buttonText="key" />
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>The LENS generated SSH-RSA key is used by LENS to access your SFTP to transfer matches.</Typography>
          </>
        }
        {(props?.transfer.type === 'sftp' && props?.transfer.sftp.password) &&
          <>
            <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Password:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>For security reasons, the password is hidden.</Typography>
            <Typography variant="body2" sx={{ mb: 4 }}>The password provided by your organization is used by LENS to access your SFTP to transfer matches.</Typography>
          </>
        }

        {props?.transfer.type === 'sftp' &&
          <>
            <DisplayPgpFilePreference pgpKeyRotationInYears={0} pgpPublicKey={props?.transfer?.sftp?.pgp?.publicKey || props?.transfer?.sftp?.pgpPublicKey} fileName={'matches-pgp-' + props?.file.prefix + '.key'} pgpKeyLabel="Public PGP Key:" showButtons={true} buttonText="key" />
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>The public PGP key provided by your organization is used by LENS to encypt your matches.</Typography>
          </>
        }

        {(props?.transfer.type === 'awsS3') &&
          <>
            <Typography variant="subtitle1" color="text.primary">AWS Account ID <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 0 }}>Carrier AWS Cloud Formation Template<Typography display="inline" variant="body2"> (Provided by LENS):</Typography></Typography>
            <FileDownloadCard variation="icon" fileName={"lens-carrier-aws-" + props?.file.prefix + "-files-option.yml"} file='carrier-aws-match-files-option.yml' fileType='yml' label="Matches Template" />
            <Typography sx={{ mb: 2 }} variant="body2">Downloading and deploying this template with the Lambda in your AWS environment provides S3 Bucket and IAM Role ARN output values.</Typography>
            <Typography variant="subtitle1" color="text.primary" >Matches Lambda Role ARN <Typography variant="body2" display="inline">(Provided by LENS):</Typography></Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy ARN" textToCopy={props?.transfer?.awsS3?.evadataMatchLambdaRoleArn} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer.awsS3.evadataMatchLambdaRoleArn}</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Lambda Role ARN is used by your organization to set permissions for LENS to access your S3 bucket to send matches.</Typography>
            <Typography variant="subtitle1" color="text.primary">S3 Bucket ARN <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer.awsS3.s3Bucket}</Typography>
            <Typography className={props?.transfer?.awsS3?.path ? 'show' : 'hide'} variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Folder Path:</Typography>
            <Typography className={props?.transfer?.awsS3?.path ? 'show' : 'hide'} variant="body1" color="text.primary" sx={{ mb: 4 }}>{props?.transfer?.awsS3?.path}</Typography>
            {(props?.transfer?.awsS3?.evadataMatchLambdaKmsArn || props?.transfer?.awsS3?.evadataMatchKmsArn) &&
              <>
                <Typography variant="subtitle1" color="text.primary">KMS Key ARN <Typography variant="body2" display="inline">(Provided by LENS):</Typography></Typography>
                <Grid container>
                  <Grid item xs={1}>
                    <CopyButton toolTipText="Copy ARN" textToCopy={props?.transfer.awsS3.evadataMatchLambdaKmsArn ? props?.transfer.awsS3.evadataMatchLambdaKmsArn : props?.transfer?.awsS3?.evadataMatchKmsArn} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer.awsS3.evadataMatchLambdaKmsArn ? props?.transfer.awsS3.evadataMatchLambdaKmsArn : props?.transfer?.awsS3?.evadataMatchKmsArn}</Typography>
                  </Grid>
                </Grid>
              </>
            }
            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Use the KMS Key ARN to decrypt your matches.</Typography>
            <Typography variant="subtitle1" color="text.primary">IAM Role ARN <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer.awsS3.iamRole}</Typography>
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>LENS uses the provided Role to transfer matches to the S3 bucket shown.</Typography>
          </>
        }

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>NOTIFICATION PREFERENCES</Typography>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Email(s) to receive notifications related to this list.</Typography>
        {props?.notificationEmails.map((email) => {
          return <Typography variant='body1' sx={{ mb: 4 }} key={email}>{email}</Typography>
        })}
      </Box>

      <hr />

      <Grid container sx={{ px: 5, pb: 4 }}>
        <Grid item sm={6}>
          <Button LinkComponent={Link} to="/verified-deaths-file-setup" sx={{ mt: 3 }} variant="text" startIcon={<ArrowBack />}>
            Previous step
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

MatchResultsFileSetupSummary.propTypes = {
  showCompletedAlert: PropTypes.bool,
  sources: PropTypes.array,
  categories: PropTypes.array,
  holidayCalendarId: PropTypes.string,
  file: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  pgp: PropTypes.shape({
    publicKey: PropTypes.string.isRequired,
  }),
  transfer: PropTypes.shape({
    type: PropTypes.string,
    frequency: PropTypes.string,
    reportDates: PropTypes.array,
    frequencyDay: PropTypes.string,
    annualDmf: PropTypes.string,
    sftp: PropTypes.shape({
      sshPublicKey: PropTypes.string,
      pgpPublicKey: PropTypes.string,
      path: PropTypes.string,
      ssh: PropTypes.object,
      host: PropTypes.string,
      port: PropTypes.number,
      username: PropTypes.string,
      password: PropTypes.string,
      frequency: PropTypes.string,
      frequencyDay: PropTypes.string,
      pgp: PropTypes.object
    }),
    awsS3: PropTypes.shape({
      path: PropTypes.string,
      s3Bucket: PropTypes.string,
      iamRole: PropTypes.string,
      username: PropTypes.string,
      carrierIntegrationAccountId: PropTypes.string,
      evadataMatchLambdaKmsArn: PropTypes.string,
      evadataMatchLambdaRoleArn: PropTypes.string,
      evadataMatchKmsArn: PropTypes.string
    })
  }),
  notificationEmails: PropTypes.arrayOf(PropTypes.string),
  availableFrequencies: PropTypes.arrayOf(PropTypes.string)
};

export default MatchResultsFileSetupSummary;
