import React from 'react';

import PgpKeyTable from './EncryptionTable/PgpTable/pgpKeyTable';
import SftpKeyTable from './TransferTable/SftpTransferTable/sftpKeyTable';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import PropTypes from 'prop-types';

function DisplayMatchTables({ uniqueKeyGroups, viewDetails, uniqueSftpMatchLocations, viewMatchDetails, tableType }) {

  return (
    <>
      {(tableType === 'sftp') && uniqueSftpMatchLocations?.map((item, index) => (
        <SftpKeyTable key={item?.allData?.[0]?.file?.prefix} displayType={'mySftp'} allData={item?.allData} data={item} transferFreq={switchFreqDisplay(item?.result?.transfer?.frequency)} viewDetails={() => viewMatchDetails(null, null, null, item?.allData)} ></SftpKeyTable>
      ))
      }
      {(tableType === 'pgp') && uniqueKeyGroups?.map((uniqueKeyGroup) => (
        <PgpKeyTable key={uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.keyId} uniqueKeyGroup={uniqueKeyGroup} viewDetails={() => viewDetails(uniqueKeyGroup)} />
      ))
      }
    </>
  )
}

DisplayMatchTables.propTypes = {
  uniqueKeyGroups: PropTypes.array, 
  viewDetails: PropTypes.func, 
  uniqueSftpMatchLocations: PropTypes.array, 
  viewMatchDetails: PropTypes.func, 
  tableType: PropTypes.string
};

export default DisplayMatchTables;
