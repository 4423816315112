import { Chip } from '@mui/material';
import ErrorFileDownload from '../ErrorFileDownload';

function getColumns(handleDownloadError) {

  const columns = [
    { 
      field: 'fileName', 
      headerName: 'File Name', 
      flex:1.2,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
      
        if(params.row.nickname != null){
          return <div className="wrap-cell"><p>{params.row.nickname}</p><p>{params.value}</p></div>;
        }
        return <div className="wrap-cell"><p>{params.value}</p></div>;
      }
    },
    {
      field: 'id',
      headerName: 'Dataload ID',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        return <div className="wrap-cell"><p>{params.value}</p></div>;
      },
    },
    {
      field: 'submittedSortable',
      headerName: 'Submitted',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      headerAlign: 'left',
      renderCell: (params) => {
        return <div className="wrap-cell"><p>{params.row.submitted}</p></div>;
      },
    },
    { 
      field: 'completedSortable', 
      headerName: 'Completed',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        return <div className="wrap-cell"><p>{params.row.completed}</p></div>;
      } 
    },
    {
      field: 'totalRecords', 
      headerName: 'Total Records', 
      sortingOrder: ['desc', 'asc'],
      flex: 0.8
    },
    {
      field: 'fileStatus',
      headerName: 'File Status',
      sortingOrder: ['desc', 'asc'],
      flex: 1.2,
      headerAlign: 'left',
      renderCell: (params) => {
        const colors  = {
          'Processing': 'info',
          'Failed': 'error',
          'Errors Found': 'warning',
          'Successful': 'success'
        };
        
        const color = colors[params?.value] || 'error';
        return <Chip size="small" color={color} className={params?.value} label={params?.value} variant="outlined" />;
      },
    },
    { 
      field: 'numOfErrors', 
      headerName: '# of Errors',
      sortingOrder: ['desc', 'asc'],
      flex: 0.8,
    },
    {
      field: 'downloadFile',
      headerName: 'Download',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      cellClassName: 'error',
      renderCell: (params) => {
        const {row} = params;
        const displayDownload = parseInt(row.numOfErrors) > 0 || row.fileStatus === 'Failed';
  
        if(displayDownload) {
          return <ErrorFileDownload handleDownloadError={handleDownloadError} fileType={row.fileType} dataLoadId={row.id} />;
        }
        return <></>
      },
    }
  ];

  return columns;
}

export default getColumns;