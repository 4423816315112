import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

function FileTypeField({ formik, name, formValue1 ='json', formValue2='csv', formLabel1='JSON', formLabel2='CSV', formValue3, formLabel3, mt=0 }) {
  return (
    <>
      <Typography sx={{mt: mt}} className="file-type-vdf" variant="body1" color="text.secondary">File Type</Typography>
      <RadioGroup
        id={name}
        name={name}
        className="file-type-vdf-radio"
        value={formik?.values?.[name]}
        onChange={formik.handleChange}
        sx={{ mb: 3 }}
      >
        <FormControlLabel value={formValue1} control={<Radio />} label={formLabel1} />
        <FormControlLabel value={formValue2} control={<Radio />} label={formLabel2} />
        {formValue3 && <FormControlLabel value={formValue3} control={<Radio />} label={formLabel3} />}
      </RadioGroup>
    </>
  )
}

FileTypeField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  formValue1: PropTypes.string,
  formValue2: PropTypes.string,
  formLabel1: PropTypes.string,
  formLabel2: PropTypes.string,
  formValue3: PropTypes.string,
  formLabel3: PropTypes.string,
  mt: PropTypes.any
};

export default FileTypeField;
