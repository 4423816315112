import React from 'react';
import PropTypes from 'prop-types';

import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import KmsKeyTable from './kmsKeyTable';


function AwsTable({ uniqueKeyGroups, viewAwsDetails }) {
  return (
    <>
      {uniqueKeyGroups?.map((item) => {
        return <KmsKeyTable key={item?.record?.file?.prefix} data={item?.record} transferFreq={switchFreqDisplay(item?.record?.transfer?.frequency)} viewDetails={viewAwsDetails} associatedData={item?.associatedData}></KmsKeyTable>
      })}
    </>
  );
}

AwsTable.propTypes = {
  uniqueKeyGroups: PropTypes.array,
  viewAwsDetails: PropTypes.func
};

export default AwsTable;