import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { LockRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

import PageHeader from 'src/components/PageHeader';
import PgpTable from './PgpTable';
import AwsTable from './AwsTable';


function EncryptionTable({uniqueKeyGroups, viewDetails, viewAwsDetails, isLoading, showError}) {
  const [uniqueKeyCount, setUniqueKeyCount] = useState(0);

  useEffect(() => {
    setUniqueKeyCount(uniqueKeyGroups?.length);
  }, [uniqueKeyGroups]);

  return (
    <>
      <PageHeader className="encryption-title" title="Encryption Keys" subtitle="View encryption keys used to encrypt and decrypt lists." icon={<LockRounded />} />
      <Typography className={(showError || isLoading) ? 'hide' : 'show'} variant="h5">{(uniqueKeyGroups?.[0]?.record?.transfer?.sftp ? 'PGP Keys' : 'KMS Keys') + ` (${uniqueKeyCount})`}</Typography>
      
      {uniqueKeyGroups?.[0]?.record?.transfer?.sftp ?
        <PgpTable uniqueKeyGroups={uniqueKeyGroups} viewDetails={viewDetails} /> :
        <AwsTable uniqueKeyGroups={uniqueKeyGroups} viewAwsDetails={viewAwsDetails} />
      }
    </>
  );
}

EncryptionTable.propTypes = {
  uniqueKeyGroups: PropTypes.array,
  viewDetails: PropTypes.func, 
  viewAwsDetails: PropTypes.func,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default EncryptionTable;