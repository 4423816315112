import { React } from 'react';
import { Grid, Card, styled, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';

import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import { FileDownloadRounded } from '@mui/icons-material';
import CopyButton from 'src/components/CopyButton';
import DisplayFileSample from 'src/components/DisplayFileSample';
import PropTypes from 'prop-types';

const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
    }
    .lock-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .account-id-row {
      border-bottom: 1px solid ${theme.palette.common.black}66;
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    th {
      color: ${theme.palette.common.black};
      font-size: 15px;
    }
    tr {
      border-top: 2px solid ${theme.palette.common.black}33;
    }
    tr.MuiTableRow-head {
      border-top: 3px solid ${theme.palette.common.black}33;
      border-bottom: 1px solid ${theme.palette.common.black}90;
    }
  `
);

function PgpKeyTable({ uniqueKeyGroup, viewDetails }) {

  function downloadText() {
    downloadTxtFile(uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.publicKey, 'pgp-public-key');
  }

  const buttons = <Grid item sm={12} sx={{ textAlign: 'left' }}>
    <div className='copy-click-wrapper'><CopyButton display="inline" buttonText="Copy Public Key" toolTipText="Copy Public Key" textToCopy={uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.publicKey}></CopyButton></div>
    <Button className="pgp-button" onClick={downloadText} sx={{ marginTop: '-5px' }}>Download PGP Key<FileDownloadRounded sx={{ ml: 1, mr: 2 }} /></Button>
  </Grid>

  return (
    <CardWrapper>
      <Card className="encryption-pgp-card" sx={{ minWidth: 275, textAlign: 'left', mt: 3, mb: 3, }}>
        <Grid container>
          <Grid item xs={10} sx={{ px: 2, pt: 2, pb: 0 }}>
            <Typography variant="subtitle1">Key ID: {uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.id ? uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.id : uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.keyId}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ px: 2, pt: 2, textAlign: 'right' }}>
            <Button onClick={viewDetails} variant="outlined" color="primary">Manage</Button>
          </Grid>
          {uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.id && buttons}
          <Grid item xs={12} sx={{ px: 2, pt: 1, pb: 2 }}>
            <Typography variant="body2">{uniqueKeyGroup?.record?.transfer?.sftp?.pgp?.id ? 'Use the public key to encrypt your list(s).' : 'LENS uses the public key to encrypt your matches, so key is hidden.'}</Typography>
          </Grid>
        </Grid>
        <Table className="encryption-pgp-table" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="encryption-pgp-location" sx={{ py: '7px' }}>Lists Using This Key</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[uniqueKeyGroup?.record, ...uniqueKeyGroup.associatedData].map((config) => (
              <TableRow key={config?.file?.prefix} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <DisplayFileSample nickname={config?.nickname} annualDmfEnabled={Boolean(config?.transfer?.annualDmf)} prefix={config?.file?.prefix} type={config?.file?.type} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </CardWrapper>
  )
}

PgpKeyTable.propTypes = {
  uniqueKeyGroup: PropTypes.object,
  viewDetails: PropTypes.func
};

export default PgpKeyTable;