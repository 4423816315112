import React from 'react';

import { Grid, Typography } from '@mui/material';
import CopyButton from '../CopyButton';
import PropTypes from 'prop-types';

import * as Constants from 'src/constants';

function SftpServerDisplayOnly({ sftpServer, subdirectory }) {
  return (
    <>
      <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1  }}>LENS SFTP Server Hostname:</Typography>
      <Grid container>
        <Grid item xs={1}>
          <CopyButton toolTipText="Copy SFTP Server" textToCopy={sftpServer} />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>{sftpServer}</Typography>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" color="text.primary">Port:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{Constants.lensSftpPort}</Typography>
      {subdirectory &&
        <>
          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Subdirectory:</Typography>
          <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{subdirectory}</Typography>
        </>
      }
    </>
  )
}

SftpServerDisplayOnly.propTypes = {
  sftpServer: PropTypes.string, 
  subdirectory: PropTypes.string
};

export default SftpServerDisplayOnly;
