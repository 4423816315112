import React from 'react';

import { Alert, Button, Checkbox, Container, Grid, styled, InputLabel, FormControl, FormControlLabel, FormLabel, FormGroup, Typography, RadioGroup, Radio, NativeSelect, } from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AttributeCard from '../AttributeCard';


const MyWrapper = styled(Container)(
  ({ theme }) => `
    .MuiButton-root {
      margin: 20px 10px 0px 10px;
    }
    .MuiPaper-root {
      margin-top: 10px;
    }
    `
);

const steps = [
  'Step One',
  'Step Two',
  'Step Three',
];



function MaterialsDemo() {
  return (
    <MyWrapper sx={{ mb: 10 }}>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Evadata Materials Theme Demo</Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 10 }}>
          <Typography variant="h1">H1 Style</Typography>
          <Typography variant="h2">H2 Style</Typography>
          <Typography variant="h3">H3 Style</Typography>
          <Typography variant="h4">H4 Style</Typography>
          <Typography variant="h5">H5 Style</Typography>
          <Typography variant="h6">H6 Style</Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ mb: { xs: 10, sm: 0 } }}>
          <Typography variant="h4">Basic Button</Typography>
          <Button variant="text" color="primary">Text</Button>
          <Button variant="contained">Contained</Button>
          <Button variant="outlined">Outlined</Button>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ mb: { xs: 10, sm: 0 } }}>
          <Typography variant="h4">Contained Button</Typography>
          <Button variant="contained" color="secondary">Secondary</Button>

          <Button variant="contained" color="error">
            Error
          </Button>

          <Button variant="contained" color="warning">
            Warning
          </Button>

          <Button variant="contained" color="info">
            Info
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>

          <Button variant="contained" disabled>
            Disabled
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h4">Outlined Button</Typography>
          <Button variant="outlined" color="secondary">Secondary</Button>

          <Button variant="outlined" color="error">
            Error
          </Button>

          <Button variant="outlined" color="warning">
            Warning
          </Button>

          <Button variant="outlined" color="info">
            Info
          </Button>
          <Button variant="outlined" color="success">
            Success
          </Button>

          <Button variant="outlined" disabled>
            Disabled
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Alerts</Typography>
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">This is a warning alert — check it out!</Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">This is a success alert — check it out!</Alert>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Checkboxes</Typography>
          <FormGroup>
            <FormControlLabel control={<Checkbox color='primary' defaultChecked />} label="Primary" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox color='warning' defaultChecked />} label="Warning" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox color='error' defaultChecked />} label="Error" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox color='info' defaultChecked />} label="Info" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox color='success' defaultChecked />} label="Success" />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={4}>

          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Radio Group</Typography>

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Answer</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel value="maybe" control={<Radio />} label="Maybe" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>

          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Basic Select</Typography>

          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Age
            </InputLabel>
            <NativeSelect
              defaultValue={30}
              inputProps={{
                name: 'age',
                id: 'uncontrolled-native',
              }}
            >
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </NativeSelect>
          </FormControl>

        </Grid>

        <Grid item xs={12} sm={12}>

          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Stepper</Typography>

          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>


        </Grid>


        <Grid item xs={12} sm={5}>

          <Typography variant="h4" sx={{ mt: 10, mb: 5 }}>Attribute Card</Typography>
          <AttributeCard title="ssn" required={true} dataType="String (to allow leading 0s)" length="9 (min: 7)" format="Numbers (No dashes)" note="First and last name must be separated. Can include dashes and apostrophes."></AttributeCard>

        </Grid>

      </Grid> {/* End Parent Grid item */}

    </MyWrapper>

  );
}

export default MaterialsDemo;
