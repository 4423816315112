import { React, useEffect, useState } from 'react';
import { Typography, Button, TableRow, TableCell, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreHorizRounded, FileDownloadRounded } from '@mui/icons-material';
import CopyButton from 'src/components/CopyButton';
import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import DisplayFileSample from 'src/components/DisplayFileSample';
import PropTypes from 'prop-types';

function DisplayTableRow({ data, configType, handleClick, handleClose, open, anchorEl, editForm, editSubdirectory, editSftpCredentials, editFrequency }) {
  const [showEditMenu, setShowEditMenu] = useState(false);

  function downloadSshKey(data) {
    downloadTxtFile(data?.transfer?.sftp?.ssh?.publicKey, 'ssh-' + data?.file?.prefix + '.txt')
  }

  useEffect(() => {
    if (data?.configurationType !== 'death_records' || data?.transfer?.type === 'sftp') {
      setShowEditMenu(true);
    }
  }, [setShowEditMenu, data?.configurationType, data?.transfer?.type])

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align='center' id='action'>
        {showEditMenu &&
          <>
            <IconButton id='action-button' aria-controls={open && 'action-menu'} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} variant="contained" onClick={(e) => handleClick(e, data)}>
              <MoreHorizRounded />
            </IconButton>
            <Menu id="action-menu" MenuListProps={{ 'aria-labelledby': 'action-button', }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ style: { flex: 1 }, }}>
              {data?.transfer?.sftp &&
                <MenuItem onClick={editSftpCredentials} aria-controls={editForm && 'edit-field'}>Provide New SFTP Credentials</MenuItem>
              }
              {configType === 'matches' &&
                <MenuItem onClick={editSubdirectory} aria-controls={editForm && 'edit-field'}>{data?.transfer?.type === 'sftp' ? 'Update Subdirectory Path' : 'Update Folder Path'}</MenuItem>
              }
              {configType !== 'death_records' &&
                <MenuItem onClick={editFrequency} aria-controls={editForm && 'edit-field'}>Change Transfer Schedule</MenuItem>
              }
            </Menu>
          </>
        }
      </TableCell>
      <TableCell component="th" scope="row">
        <DisplayFileSample nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} type={data?.file?.type}/>
      </TableCell>
      {data?.transfer?.sftp &&
        <TableCell align="center">
          <Typography variant="body2" sx={{ mb: 1 }}>
            {data?.transfer?.sftp?.username}
          </Typography>
        </TableCell>
      }
      {data?.transfer?.sftp?.ssh && data?.configurationType !== 'matches' &&
        <TableCell align="center"><Typography variant="body2" sx={{ mb: 1 }}>SSH-RSA Key</Typography></TableCell>
      }
      {data?.transfer?.sftp?.ssh?.publicKey && data?.configurationType === 'matches' &&
        <TableCell align="center"><Typography variant="body2" sx={{ mb: 1, ml: 3 }}>
          SSH-RSA Key
          <CopyButton display="inline" toolTipText="Copy Public SSH-RSA Key" paddingLeft="35px" textToCopy={data?.transfer?.sftp?.ssh?.publicKey} />
          <Button className="pgp-button" onClick={() => downloadSshKey(data)} ><Tooltip arrow placement="right" title='Download Public SSH-RSA Key'><FileDownloadRounded sx={{ color: 'grey', ml: 0 }} /></Tooltip></Button>
        </Typography></TableCell>
      }
      {data?.transfer?.sftp && data?.configurationType === 'matches' && !data?.transfer?.sftp?.ssh?.publicKey &&
        <TableCell align="center"><Typography variant="body2" sx={{ mb: 1 }}>Password</Typography></TableCell>
      }
      {data?.transfer?.sftp &&
        <TableCell id="subfolder-field" align='center'>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {(data?.configurationType !== 'matches' || (data?.configurationType === 'matches' && data?.transfer?.sftp?.path === '/')) && '—'}
            {(data?.configurationType === 'matches' && data?.transfer?.sftp?.path !== '/') && data?.transfer?.sftp?.path}
          </Typography>
        </TableCell>
      }
      {data?.transfer?.awsS3 &&
        <TableCell id="subfolder-field">
          <Typography variant="body2">
            {(data?.configurationType !== 'matches' || (data?.configurationType === 'matches' && data?.transfer?.awsS3?.path === '/')) && '—'}
            {(data?.configurationType === 'matches' && data?.transfer?.awsS3?.path !== '/') && data?.transfer?.awsS3?.path}
          </Typography>
        </TableCell>
      }

      {data?.configurationType === 'death_records' ?
        <TableCell align="left"><Typography variant="body2" sx={{ mb: 1 }}>Daily (Monday-Friday)</Typography></TableCell> :
        <TableCell align="center"><Typography variant="body2" sx={{ textTransform: 'capitalize' }}>{switchFreqDisplay(data?.transfer?.frequency)}{(data?.configurationType === 'matches' && data?.transfer?.frequencyDay) ? ' (' + data?.transfer?.frequencyDay + ')' : ''}</Typography></TableCell>}
    </TableRow>
  )
}

DisplayTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  configType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.func,
  anchorEl: PropTypes.string,
  editForm: PropTypes.bool,
  editSubdirectory: PropTypes.func,
  editSftpCredentials: PropTypes.func,
  editFrequency: PropTypes.func
}

export default DisplayTableRow;