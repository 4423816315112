import React, {useState } from 'react';
import { useQuery } from 'src/hooks';
import PropTypes from 'prop-types';

import PageHeader from 'src/components/PageHeader';
import { useAppContext } from 'src/AppContext.js';
import { getAllDataLoadsHistory } from 'src/api/index.js';
import DataLoadsStatus from 'src/components/DataLoadsStatus';
import LoadError from 'src/components/LoadError';

function FileStatus({title, subtitle, queryType, queryKey, icon, sortKey}) {
  const { carrierConfig } = useAppContext();
  const [stateRefreshCount, setStateRefreshCount] = useState(0);
  
  const {data = [], isLoading, isError} = useQuery([queryKey, stateRefreshCount], () => getAllDataLoadsHistory(carrierConfig.carrierId, queryType));

  function reload() {
    const counter = stateRefreshCount + 1;
    setStateRefreshCount(counter);
  }

  return (
    <>
      <PageHeader title={title} subtitle={subtitle} icon={icon} />

      {(isError) && <LoadError reload={reload} />}

      {!isError &&
        <DataLoadsStatus
          dataLoads={data}
          isDataTableLoading={isLoading}
          sortKey={sortKey}
          fileType={queryType}
        />
      }
    </>
  )
}

FileStatus.propTypes = {
  title: PropTypes.string, 
  subtitle: PropTypes.string, 
  queryType: PropTypes.string, 
  queryKey: PropTypes.string, 
  icon: PropTypes.element,
  sortKey: PropTypes.object
};

export default FileStatus;
