import React from 'react';
import { styled, Chip } from '@mui/material'

const ChipStyled = styled(Chip)(
  ({ theme }) => `
    background-color: ${theme.palette.action.selected};
  `
)

function DefaultChip() {

  return (
    <ChipStyled className="default-chip" label="Default" />
  );
}

DefaultChip.propTypes = {};

export default DefaultChip
