import React from 'react';
import PropTypes from 'prop-types';

function CustomPagination(props) {

  return (
      <div className="page-count">Page {props.page}</div>
  );
}

CustomPagination.propTypes = {
  page: PropTypes.number
}

export default CustomPagination;