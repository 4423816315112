import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {Security } from '@okta/okta-react';

import config from '../config/okta';


const OktaSecurity = () => {
  const oktaAuth = new OktaAuth(config.oidc);
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth,  originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Outlet/>
    </Security> 
  )
};

export default OktaSecurity;
