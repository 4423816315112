import { Typography, Paper, Grid, styled, Button, Avatar } from '@mui/material';
import { React, useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { CompareRounded, PeopleRounded, VerifiedRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAppContext } from 'src/AppContext';

function OnboardingWelcome() {
  const [givenName, setGivenName] = useState('');
  const {oktaUser } = useAppContext();

  useEffect(() => {
    if(oktaUser) {
      const _given_name = oktaUser?.given_name;
      setGivenName(_given_name);
    }
  }, [oktaUser]);


  const WelcomeGrid = styled(Grid)(
    ({ theme }) => `
    .top-rectangle {
      background-color: ${theme.palette.primary.dark};
      text-align: center;
      padding: 40px 40px 0px 40px;
      color: ${theme.palette.primary.contrastText};
    }
    .begin-button {
      background-color: ${theme.palette.primary.contrastText};
      margin-bottom: -20px;
      margin-top: 30px;
    }
    .nav-card-circle {
      width: 60px;
      height: 60px;
      background-color: transparent;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
      border: 2px solid ${theme.palette.primary.dark};
        .welcome-icon {
          color: ${theme.palette.primary.dark};
        }
        }
        .not-included {
          color: ${theme.palette.text.secondary};
        }
    `
  );

  return (
    <WelcomeGrid container spacing="3" sx={{}}>
      <Paper sx={{ borderRadius: '6px', width: '100%', maxWidth: '864px', margin: '0px auto' }}>
        <Box className="top-rectangle" >
          <Typography className="welcome-text" variant="h3" sx={{ mb: 2 }}>Welcome, {givenName}!</Typography>
          <Typography variant="body1" >Evadata Life Event Notification Service (LENS) scans data daily against the Death Master File (DMF), Newspaper and Funeral Home obituaries, State sources, in addition to Carrier death records. Providing you with faster and more accurate notifications data to process benefits quickly.
            Begin by configuring your lists in just a few easy steps.</Typography>
          <Button LinkComponent={Link} to="/customer-file-setup" className="begin-button" variant="outlined">Begin Setup</Button>
        </Box>
        <Grid container spacing="3" sx={{ padding: 4, paddingTop: 5 }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
              You Provide LENS
            </Typography>
            <Grid container>
              <Grid item xs={3} sm={2}>
                <Avatar className="nav-card-circle">
                  <PeopleRounded className="welcome-icon" />
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10} sx={{ pl: 1 }}>
                <Typography variant='subtitle1'>Customer List(s)</Typography>
                <Typography variant='body1'>Our system analyzes your customer lists against several sources to identify high-quality matches.</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 3, mb: 3 }}>
              <Grid item xs={3} sm={2}>
                <Avatar className="nav-card-circle">
                  <VerifiedRounded className="welcome-icon" />
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10} sx={{ pl: 1 }}>
                <Typography variant='subtitle1'>Verified Deaths List</Typography>
                <Typography variant='body1'>Including this list as a source, opens access to other carrier matches. These matches expand results and shorten notification time.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.secondary" sx={{
              mb: 3, pl: {
                xs: 0, sm: 3
              }
            }}>
              LENS Provides in Return
            </Typography>
            <Grid container sx={{
              pl: {
                xs: 0, sm: 3
              }
            }}>
              <Grid item xs={3} sm={2}>
                <Avatar className="nav-card-circle">
                  <CompareRounded className="welcome-icon" />
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10} sx={{ pl: 1 }}>
                <Typography variant='subtitle1'>Match Results</Typography>
                <Typography variant='body1'>LENS will return any matches from your customer list.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </WelcomeGrid>
  );
}

OnboardingWelcome.propTypes = {};

export default OnboardingWelcome;