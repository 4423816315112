import { React, useEffect, useState } from 'react';
import { Grid, Typography, Tooltip, Button, Card, Table, TableHead, TableRow, TableCell, TableBody, styled, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert, TextField, InputAdornment, IconButton } from '@mui/material';

import { ArrowBackRounded, CheckRounded, FileDownloadRounded, Visibility, VisibilityOff, ErrorOutline } from '@mui/icons-material';
import EncryptionDetailsIcon from 'src/images/EncryptionDetailsIcon.svg';
import CopyButton from 'src/components/CopyButton';
import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import DisplayFileSample from 'src/components/DisplayFileSample';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { useAppContext } from 'src/AppContext';
import { rotatePgpKey, editMatchPgpKey, editKmsKeyArn } from 'src/api';
import { Box } from '@mui/system';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import CloudTemplates from '../CloudTemplates';
import TooltipInline from '../TooltipInline';
import PropTypes from 'prop-types';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
  .card-header {
    border-bottom: 1px solid ${theme.palette.text.primary}22;
    padding-bottom: 15px;
  }
  &.match-file-pgp {
    min-height: 400px
  }
  &.customer-file-pgp {
    min-height: 440px
  }
  .centered-container {
    margin: 0 auto;
    max-width: 460px;
  }
  .copy-click-wrapper {
    float: left;
    padding-left: 40px;
  }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .centered-container-enc {
      margin: 0 auto 10px;
      max-width: 460px;
    }
  `
);

yup.addMethod(yup.string, 'containsId', function (id, message) {
  return this.test('containsId', message, function (value) {
    if (value && (value?.length > 0)) {
      return value.includes(id);
    }
    else {
      return true;
    }
  });
});

const containsPrivate = (string) => /^((?!PRIVATE).)*$/s.test(string);


const validationSchema = yup.object().shape({
  newPgpKeyRotation: yup
    .string()
    .when(['newMatchFilePgpKey', 'newKmsKeyArn'], (newMatchFilePgpKey, newKmsKeyArn) => {
      if (!newMatchFilePgpKey && !newKmsKeyArn) {
        return yup.string().required('Field is required')
      } else {
        return yup.string()
      }
    }),
  newMatchFilePgpKey: yup
    .string()
    .when(['newPgpKeyRotation', 'newKmsKeyArn'], (newPgpKeyRotation, newKmsKeyArn) => {
      if (!newPgpKeyRotation && !newKmsKeyArn) {
        return yup.string()
          .max(6000, 'Public key exceeds the character limit (maximum is 6000 characters). Provide only one public key.')
          .required('Field is required')
          .matches(/BEGIN PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
          .matches(/END PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
          .test(
            'PGP key is invalid. Provide only the public key.',
            'PGP key is invalid. Provide only the public key.',
            (value) => containsPrivate(value)
          )
      } else {
        return yup.string()
      }
    }),
  newKmsKeyArn: yup
    .string()
    .when(['newPgpKeyRotation', 'newMatchFilePgpKey', 'awsAccountId'], (newPgpKeyRotation, newMatchFilePgpKey, awsAccountId) => {
      if (!newMatchFilePgpKey && !newPgpKeyRotation) {
        return yup
          .string()
          .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
          .matches(/^\S*$/, "Incorrect format entered. ARN should not include spaces.")
          .containsId(awsAccountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<awsAccountId>')
          .required('Field is required.')
      }
    }

    ),
}, [['newPgpKeyRotation', 'newMatchFilePgpKey'], ['newKmsKeyArn', 'newPgpKeyRotation'], ['newKmsKeyArn', 'newMatchFilePgpKey']]);

function EncryptionDetails({ keyGroup, hideDetails, reloadKeys }) {
  const [rotatingPgp, setRotatingPgp] = useState(false);
  const [editingMatchPgp, setEditingMatchPgp] = useState(false);
  const [editingKmsKeyArn, setEditingKmsKeyArn] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedRotation, setUpdatedRotation] = useState(null);
  const [updatedExpiration, setUpdatedExpiration] = useState(null);
  const [updatedPgpKey, setUpdatedPgpKey] = useState(null);
  const [updatedPgpKeyId, setUpdatedPgpKeyId] = useState(null);
  const [updatedKmsKey, setUpdatedKmsKey] = useState(null);
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showKey, setShowKey] = useState();
  const [awsAccountId, setAwsAccountId] = useState(null);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [weakPgpKey, setWeakPgpKey] = useState(false);

  function handleClickShowKey() {
    setShowKey(!showKey);
  };

  function handleMouseDownKey(event) {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPgpKeyRotation: '',
      newMatchFilePgpKey: '',
      newKmsKeyArn: '',
      awsAccountId: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let fileLocation = '';
        let prefix = '';
        let payload = {};
        if (keyGroup?.record?.transfer?.sftp?.pgp?.id) {
          payload = {
            "configs": [{
              "type": keyGroup?.record?.configurationType,
              "filePrefix": keyGroup?.record?.file?.prefix
            }],
            "keyRotationInYears": values.newPgpKeyRotation
          }
          const associatedPayloads = keyGroup?.associatedData?.map((data) => {
            return {
              "type": data?.configurationType,
              "filePrefix": data?.file?.prefix
            }
          }
          )
          payload.configs = payload.configs.concat(associatedPayloads);
        }
        if (keyGroup?.record?.customerFilePrefix) {
          payload = {
            "publicKey": formik.values.newMatchFilePgpKey
          }
        }
        if (keyGroup?.record?.transfer?.awsS3) {
          if (keyGroup?.record?.configurationType === 'matches') {
            prefix = keyGroup?.record?.customerFilePrefix;
            fileLocation = "match-files";
          } else {
            prefix = keyGroup?.record?.file?.prefix;
            if (keyGroup?.record?.configurationType === 'customer_records') {
              fileLocation = 'customer-files';
            }
            if (keyGroup?.record?.configurationType === 'death_records') {
              fileLocation = 'death-files';
            }
          }
          payload = {
            "kmsArn": values.newKmsKeyArn
          }
        }
        if (rotatingPgp) {
          setIsLoading(true);
          setShowForm(false);
          setRotatingPgp(false);
          const result = await rotatePgpKey(carrierConfig?.carrierId, payload);
          if (result.statusCode === 400 || result.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGeneralizedError(true);
          }
          if (result.keyExpiration) {
            reloadKeys();
            setUpdatedPgpKeyId(result.id);
            setUpdatedRotation(result.keyRotationInYears);
            setUpdatedExpiration(result.keyExpiration);
            setUpdatedPgpKey(result.publicKey);
            setIsLoading(false);
            setAlertText('New PGP key was generated. Please use the key for list(s) shown before submitting to LENS.');
            setShowCompletedAlert(true);
          }
        }
        if (editingMatchPgp) {
          setIsLoading(true);
          setShowForm(false);
          setEditingMatchPgp(false);
          setWeakPgpKey(false);
          let matchResults = [];
          for (let data of [keyGroup?.record, ...keyGroup.associatedData]) {
            matchResults = matchResults.concat(await editMatchPgpKey(carrierConfig?.carrierId, data.customerFilePrefix, payload));
          }
          if (matchResults[0]?.statusCode === 400 || matchResults[0]?.statusCode === 500) {
            setIsLoading(false);
            setEditingMatchPgp(true);
            setShowForm(true);
            window.scrollTo(0, 0);

            if (matchResults[0]?.message === "The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.") {
              setWeakPgpKey(true);
            }
            else {
              setGeneralizedError(true);
            }
          }
          if (matchResults[0]?.keyExpiration) {
            reloadKeys();
            setUpdatedExpiration(matchResults[0].keyExpiration);
            setUpdatedPgpKeyId(matchResults[0].keyId);
            setIsLoading(false);
            setAlertText('New PGP key was applied for list(s) shown.');
            setShowCompletedAlert(true);
            setGeneralizedError(false);
          }
        }
        if (editingKmsKeyArn) {
          setIsLoading(true);
          setShowForm(false);
          setEditingKmsKeyArn(false);
          const kmsResult = await editKmsKeyArn(carrierConfig?.carrierId, fileLocation, prefix, payload);
          if (kmsResult.statusCode === 400 || kmsResult.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGeneralizedError(true);
          }
          if (kmsResult?.kmsArn) {
            reloadKeys();
            setUpdatedKmsKey(kmsResult?.kmsArn);
            setIsLoading(false);
            setAlertText('New KMS key ARN was provided. Please update the KMS key for list(s) shown before submitting to LENS.');
            setShowCompletedAlert(true);
          }
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  function downloadText() {
    let downloadableKey = '';
    if (updatedPgpKey) {
      downloadableKey = updatedPgpKey;
    } else {
      downloadableKey = keyGroup?.record?.transfer?.sftp?.pgp?.publickKey;
    }
    downloadTxtFile(downloadableKey, 'pgp-public-key');
  }

  const clearAlert = () => {
    setShowCompletedAlert(false);
  }

  const cancelRotate = () => {
    setRotatingPgp(false);
    setEditingMatchPgp(false);
    setWeakPgpKey(false);
    formik.setErrors({});
    formik.setFieldValue('newMatchFilePgpKey', '');
    formik.setFieldTouched('newMatchFilePgpKey', false);
    formik.setFieldValue('newKmsKeyArn', keyGroup?.record?.transfer?.awsS3?.kmsArn);
    setEditingKmsKeyArn(false);
    setShowForm(false);
    setGeneralizedError(false);
  }

  function editPgpKey() {
    formik.setFieldValue("newPgpKeyRotation", '2');
    setShowForm(true);
    setRotatingPgp(true);
    setShowCompletedAlert(false);
  }

  function callEditMatchPgpKey() {
    formik.setFieldValue('newMatchFilePgpKey', '');
    formik.setFieldTouched('newMatchFilePgpKey', false);
    setShowForm(true);
    setEditingMatchPgp(true);
    setShowCompletedAlert(false);
  }

  function callEditKmsKey() {
    setShowForm(true);
    setEditingKmsKeyArn(true);
    setShowCompletedAlert(false);
  }

  useEffect(() => {
    if (weakPgpKey) {
      setWeakPgpKey(false);
    }
  }, [formik.values.newMatchFilePgpKey])

  useEffect(() => {
    const setId = async function () {
      if (keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId) {
        await formik.setFieldValue("awsAccountId", keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId);
        setAwsAccountId(keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId);
      }
    }
    if (!awsAccountId && keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId) {
      setId().catch((error) => setGeneralizedError(true));
    }
  }, [awsAccountId, keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId, formik])

  const buttons = <Grid item sm={12} sx={{ textAlign: 'centered', mb: 2 }}>
    <div className="centered-container-enc">
      <div className='copy-click-wrapper'><CopyButton display="inline" buttonText="Copy Public Key" toolTipText="Copy Public Key" textToCopy={updatedPgpKey
        || keyGroup?.record?.transfer?.sftp?.pgp?.publicKey}></CopyButton></div>
      <Button className="pgp-button" onClick={downloadText} sx={{ marginTop: '-5px' }}>Download PGP Key<FileDownloadRounded sx={{ ml: 1, mr: 2 }} /></Button>
    </div>
  </Grid>

  return (
    <Grid container spacing={3}>
      <Grid className="customer-accounts-title-enc" item xs={12}>
        <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><ArrowBackRounded className="back-arrow" onClick={hideDetails} sx={{ mr: 2 }} /></Tooltip>{(keyGroup?.record?.transfer?.awsS3 && keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn) ? 'View' : 'Manage'} {keyGroup?.record?.transfer?.awsS3 ? 'KMS' : 'PGP'} Key Details</Typography>
      </Grid>
      {keyGroup?.record?.transfer?.awsS3 &&
        <Grid item xs={12}>
          <Card sx={{ p: 2, mt: 3 }}>
            <Typography variant="body1"><strong>AWS Account ID:</strong> {keyGroup?.record?.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
          </Card>
        </Grid>
      }

      <Grid item xs={12} sm={8}>
        <CardWrapper className={keyGroup?.record?.configurationType === 'customer_records' ? 'customer-file-pgp' : 'match-file-pgp'}>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={6}>
              <Typography className="encrypt-subtitle" variant="subtitle1">Encryption Key</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {(keyGroup?.record?.transfer?.sftp?.pgp?.keyRotationInYears && !rotatingPgp && !showForm) &&
                <Button disabled={isLoading} onClick={editPgpKey} className="edit-encrypt-key" sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Rotate Key</Button>}
              {(keyGroup?.record?.transfer?.sftp && keyGroup?.record?.maxMatches && !editingMatchPgp && !showForm) &&
                <Button disabled={isLoading} onClick={callEditMatchPgpKey} className="edit-encrypt-key" sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Change Key</Button>}
              {(keyGroup?.record?.transfer?.awsS3 && !editingMatchPgp && !showForm && keyGroup?.record?.configurationType !== 'matches') &&
                <Button disabled={isLoading} onClick={callEditKmsKey} className="edit-encrypt-key" sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Change Key</Button>}
              {showForm &&
                <Button onClick={cancelRotate} variant="text" sx={{ mt: '-10px', mb: '-10px' }}>Cancel</Button>
              }
            </Grid>
          </Grid>

          <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>

          {isLoading && <Loading />}
          <Grid className={isLoading ? 'hide' : 'show'} container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <img src={EncryptionDetailsIcon} alt="encryption key icon" />
              <Typography variant="h5" sx={{ mb: 2 }}>
                {(keyGroup?.record?.transfer?.awsS3 && keyGroup?.record?.transfer?.awsS3?.kmsArn) && "My Organization's KMS Key"}
                {(keyGroup?.record?.transfer?.awsS3 && keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn) && "LENS Provided KMS Key"}
                {keyGroup?.record?.transfer?.sftp?.pgp?.id && "LENS Provided PGP Key"}
                {keyGroup?.record?.customerFilePrefix && keyGroup?.record?.transfer?.sftp?.pgp && "My Organization's PGP Key"}
              </Typography>

              {(showForm && editingKmsKeyArn) &&
                <CloudTemplates data={keyGroup?.record} associatedData={keyGroup?.associatedData} evadataLambdaArn={keyGroup?.record?.transfer?.awsS3?.evadataLambdaArn} evadataLambdaRoleArn={keyGroup?.record?.transfer?.awsS3?.evadataLambdaRoleArn} />
              }

              {(keyGroup?.record?.transfer?.sftp && !showForm) &&
                <Typography variant="subtitle1" sx={{ mb: 2 }}> <Typography color="text.secondary" variant="subtitle1" display="inline">Key ID:</Typography> {updatedPgpKeyId || (keyGroup?.record?.transfer?.sftp?.pgp?.id || keyGroup?.record?.transfer?.sftp?.pgp?.keyId)}</Typography>
              }

              {(keyGroup?.record?.transfer?.sftp?.pgp?.id && !showForm) &&
                <>
                  {buttons}
                  <Typography variant="body2" sx={{ mb: 2 }}>Use the public key to encrypt your list(s).</Typography>
                </>
              }

              {(keyGroup?.record?.transfer?.sftp?.pgp?.keyId && !keyGroup?.record?.transfer?.sftp?.pgp?.id && !showForm) &&
                <Typography variant="body2" sx={{ mb: 2 }}>LENS uses the public key to encrypt your matches, so key is hidden.</Typography>
              }

              {(keyGroup?.record?.transfer?.sftp?.pgp?.id && !showForm) &&
                <>
                  <Typography variant="subtitle1"> <Typography color="text.secondary" variant="subtitle1" display="inline">Key Rotation:</Typography> {updatedRotation || keyGroup?.record?.transfer?.sftp?.pgp?.keyRotationInYears} Years</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>Expires: {updatedExpiration || keyGroup?.record?.transfer?.sftp?.pgp?.keyExpiration}</Typography>
                </>
              }

              {(keyGroup?.record?.transfer?.sftp && !keyGroup?.record?.transfer?.sftp?.pgp?.id && !showForm) &&
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>Key Expiration: {updatedExpiration || keyGroup?.record?.transfer?.sftp?.pgp?.keyExpiration}</Typography>
              }

              {(keyGroup?.record?.transfer?.awsS3 && !showForm) &&
                <Typography color="text.secondary" variant="subtitle1" sx={{ mb: 0 }}>Key ARN: </Typography>
              }

              {(keyGroup?.record?.transfer?.awsS3?.kmsArn && !showForm) &&
                <>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>{updatedKmsKey || keyGroup?.record?.transfer?.awsS3?.kmsArn}</Typography>
                  <Typography variant="body2">LENS uses the ARN to decrypt your list(s).</Typography>
                </>
              }

              {(keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn && !showForm) &&
                <>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}><CopyButton toolTipText="Copy ARN" textToCopy={keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn} display="inline" />{keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn}</Typography>
                  <Typography variant="body2">Use the ARN to decrypt your matches.</Typography>
                </>
              }

            </Grid>
          </Grid>
          {(showForm && !isLoading) &&
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: 'left', width: '100%', px: 3, pb: 2 }}>
                  <Typography className={rotatingPgp ? 'show' : 'hide'} variant='body2' color="text.secondary" sx={{ mb: 2 }}>
                    Rotate key and receive the public key from LENS.
                  </Typography>
                  <FormControl className={rotatingPgp ? 'show' : 'hide'} sx={{ width: '100%' }}>
                    <InputLabel id="pgp-select-label" htmlFor="newPgpKeyRotation">PGP Key Rotation</InputLabel>
                    <Select
                      sx={{ width: '100%', maxWidth: '400px' }}
                      disablescrolllock='true'
                      id="newPgpKeyRotation"
                      name="newPgpKeyRotation"
                      value={formik.values.newPgpKeyRotation}
                      onChange={formik.handleChange}
                      label="PGP Key Rotation"
                    >
                      <MenuItem className='new-cust-one-year' value="1">1 Year</MenuItem>
                      <MenuItem className='new-cust-two-years' selected value="2">2 Years<span className="recommended">&nbsp;Recommended</span></MenuItem>
                      <MenuItem className='new-cust-three-years' value="3">3 Years</MenuItem>
                      <MenuItem className='new-cust-five-years' value="5">5 Years</MenuItem>
                    </Select>
                    <FormHelperText>Request new key at this duration.</FormHelperText>
                  </FormControl>
                  <Typography className={rotatingPgp ? 'show' : 'hide'} variant='body1' sx={{ mt: 2 }}>
                    The public PGP key will be available to copy or download after it’s generated.
                  </Typography>

                  <Typography className={editingMatchPgp ? 'show' : 'hide'} variant="body2" color="text.primary" sx={{ mb: 2 }}>Provide LENS new public PGP key to encrypt your matches.</Typography>

                  <TextField className={editingMatchPgp ? 'show' : 'hide'}
                    autoComplete='new-password'
                    fullWidth
                    multiline
                    rows={6}
                    id="newMatchFilePgpKey"
                    name="newMatchFilePgpKey"
                    label="Public PGP Key"
                    type={showKey ? "text" : "password"}
                    value={formik.values.newMatchFilePgpKey}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newMatchFilePgpKey && Boolean(formik.errors.newMatchFilePgpKey)}
                    helperText={formik.touched.newMatchFilePgpKey && !weakPgpKey ? formik.errors.newMatchFilePgpKey : ''}
                    sx={{ mb: 3 }}
                    endadornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowKey}
                          onMouseDown={handleMouseDownKey}
                        >
                          {showKey ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {weakPgpKey ?
                    <FormHelperText sx={{ mt: '-20px', mb: 4, ml: -1 }} error={true}>
                      <ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />
                      The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.
                    </FormHelperText> : ''
                  }

                  <Typography className={editingKmsKeyArn ? 'show' : 'hide'}>
                    <TooltipInline text="Provide new KMS key ARN. " tip="Utilize the AWS Cloud Formation template and Lambdas to create KMS Key ARN." color="text.secondary" />
                  </Typography>

                  <TextField className={editingKmsKeyArn ? 'show' : 'hide'}
                    fullWidth
                    id="newKmsKeyArn"
                    name="newKmsKeyArn"
                    label="KMS Key ARN"
                    value={formik.values.newKmsKeyArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newKmsKeyArn && Boolean(formik.errors.newKmsKeyArn)}
                    helperText={formik.touched.newKmsKeyArn ? formik.errors.newKmsKeyArn : 'ARN should begin with: arn:aws:kms:'}
                    sx={{ mb: 3, mt: 2 }}
                  />

                  <Typography className={editingKmsKeyArn ? 'show' : 'hide'} variant='body2'>
                    The KMS key ARN must be provided for LENS to decrypt your list(s).
                  </Typography>

                  <Typography variant='body2' color="text.secondary" sx={{ mt: 4, mb: 1.5 }}>
                    Apply this key change to the following list(s):
                  </Typography>

                  {[keyGroup?.record, ...keyGroup.associatedData].map((data) => (
                    <div key={data?.file?.prefix}>
                      <DisplayFileSample variant='body' nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} date='' annualDmfDate='' type={data?.file?.type} />
                    </div>
                  ))}
                </Box>
                <hr />
                <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                  <Button type="submit" variant="contained">
                    {rotatingPgp ? 'Generate Key' : 'Save Changes'}
                  </Button>
                </Box>
              </form>
            </FormikProvider>
          }
        </CardWrapper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardWrapper className="encrypt-date-card" sx={{ mb: 3 }}>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={12}>
              <Typography className="encrypt-date-added" variant="subtitle1">Date Added</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ p: 3, pl: 5, }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {keyGroup?.record?.auditDate}
              </Typography>
              {keyGroup?.record?.transfer?.sftp?.pgp?.id &&
                <Typography variant="body2" color="text.secondary">
                  Owner: LENS
                </Typography>
              }
              <Typography variant="body2" color="text.secondary" sx={{ mb: '11px' }}>
                {keyGroup?.record?.transfer?.sftp?.pgp?.id && 'Added'} {keyGroup?.record?.transfer?.sftp?.pgp?.id ? 'by:' : 'By:'} {keyGroup?.record?.transfer?.sftp?.pgp?.id && <br />} {keyGroup?.record?.transfer?.awsS3?.evadataMatchLambdaKmsArn ? 'LENS' : keyGroup?.record?.auditUser}
              </Typography>
            </Grid>
          </Grid>
        </CardWrapper>
        <CardWrapper>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Key's Status</Typography>
            </Grid>
          </Grid>
          <Grid className="encrypt-active-container" container spacing={3} sx={{ p: 3, pl: 5 }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <CheckRounded color="success" sx={{ display: 'block', float: 'left', mr: 1 }} /> Active
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: '11px' }}>
                Currently used for {keyGroup?.associatedData?.length + 1} list{keyGroup?.associatedData?.length > 0 ? 's' : ''}.
              </Typography>
            </Grid>
          </Grid>
        </CardWrapper>
      </Grid>
      <Grid item sm={12}>
        <CardWrapper>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Key's Usage</Typography>
            </Grid>
            <Grid item xs={12} className={showCompletedAlert ? 'show' : 'hide'}>
              <Alert onClick={clearAlert} severity="info">{alertText}</Alert>
            </Grid>
          </Grid>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: '7px' }}>Lists Using This Key</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[keyGroup?.record, ...keyGroup.associatedData].map(data => (
                <TableRow key={data?.file?.prefix}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <DisplayFileSample nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} type={data?.file?.type} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardWrapper>
      </Grid>
    </Grid>
  )
}

EncryptionDetails.propTypes = {
  keyGroup: PropTypes.object,
  hideDetails: PropTypes.func,
  reloadKeys: PropTypes.func
};

export default EncryptionDetails;