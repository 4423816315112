import { AirplanemodeActiveRounded } from "@mui/icons-material";

const items = [
  { 
    items: [
      {
        name: 'Onboarding',
        icon: AirplanemodeActiveRounded,
        link: '/',
      }
    ]
  }
];

export default items;
