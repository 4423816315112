import React, { useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';


const SidebarMenuItem = ({
  children,
  link,
  customClass,
  icon: Icon,
  open: openParent,
  name,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState(openParent);
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Button
        className={customClass + ' myclass'}
        disableRipple
        activeclassname="Mui-active"
        component={RouterLink}
        onClick={closeSidebar}
        to={link}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string
};

SidebarMenuItem.defaultProps = {
  open: false
};

export default SidebarMenuItem;
