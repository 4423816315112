import { PeopleRounded, CompareRounded, VerifiedRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppContext } from "src/AppContext";
import { filterItems } from "../../filterItems";

function FileExchangesItems() {

  const { carrierConfig, lensRoles } = useAppContext();
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    const menuItems = [
      {
        name: 'Customer Lists',
        icon: PeopleRounded,
        link: '/customer-file-status',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
      {
        name: 'Match Results',
        icon: CompareRounded,
        link: '/matches-file',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
      {
        name: 'Verified Deaths',
        icon: VerifiedRounded,
        link: '/verified-deaths-file-status',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
    ];

    if (lensRoles && carrierConfig?.status) {
      let filteredItems = menuItems.filter((item) => filterItems(item, lensRoles, carrierConfig?.status));

      const _displayItems = [
        {
          heading: 'Data Loads & Match Activity',
          items: filteredItems
        }
      ];

      if (filteredItems.length) {
        setDisplayItems(_displayItems);
      }

    }
  }, [lensRoles, carrierConfig?.status])

  return displayItems;

}

export default FileExchangesItems;
