import { VerifiedRounded } from '@mui/icons-material';
import React from 'react';

import FileStatus from 'src/components/FileStatus';

function VerifiedDeathsFileStatus() {
  return (
    <FileStatus title="Verified Deaths" subtitle="All recent and historical Verified Deaths data loads." queryType="death-files" queryKey="deathFileDataLoads" icon={<VerifiedRounded />} sortKey={{'field': 'completed', 'sort': 'desc'}}/>
  )
}

VerifiedDeathsFileStatus.propTypes = {};

export default VerifiedDeathsFileStatus;
