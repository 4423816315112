const deathListColumns = [
  { 
    field: 'deathList', 
    headerName: 'Verified Death List', 
    flex:1.2,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
];

export default deathListColumns;