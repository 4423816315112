import { Link } from 'react-router-dom';
import TooltipInline from '../TooltipInline';
import { Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

function HolidayCalendarCheckbox ({formik}){
  return(
    <Grid container direction="row">
      <FormControlLabel sx={{pl: 1}} label="Observe company holidays" control={<Checkbox id='observeHoliday' name='observeHoliday' checked={formik.values.observeHoliday} onChange={formik.handleChange} inputProps={{ 'aria-label': 'controlled' }}/>}/>
      <Grid item sx={{ ml: -2, mt: 1 }}>
        <TooltipInline tip="Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company's holiday calendar, and will resume the following day (Mon.-Fri.)."/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Visit <Link to={'/holiday-calendar'}>Holiday Calendar</Link> page to manage holidays.</Typography>
      </Grid>
    </Grid>
  )
}

HolidayCalendarCheckbox.propTypes = {
  formik: PropTypes.object
};

export default HolidayCalendarCheckbox;
