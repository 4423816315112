import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Coffee } from '@mui/icons-material';

const AccountLoadWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    z-index: 2;
    background: ${theme.palette.common.white}ed;
    text-align: center;
    width: calc(100% - 300px);
    left: 290px;
    height: calc(100%);
    top: 0px;
    padding-top: calc(21%);
    .MuiSvgIcon-root {
      font-size: 44px;
    }
    .message-wrapper {
      max-width: 500px;
      margin: 0 auto;
    }
    @media screen and (max-width: 1280px) {
      width: 100%;
      left: 0px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 200px;
    }
    .dot1 {
      animation: visibility 2s linear infinite;
    }
    @keyframes visibility {
      0% {
      opacity: 1;
      }
      65% {
      opacity: 1;
      }
      66% {
      opacity: 0;
      }
      100% {
      opacity: 0;
      }
    }
    .dot2 {
      animation: visibility2 2s linear infinite;
    }
    @keyframes visibility2 {
      0% {
      opacity: 0;
      }
      21% {
      opacity: 0;
      }
      22% {
      opacity: 1;
      }
      65% {
      opacity: 1;
      }
      66% {
      opacity: 0;
      }
      100% {
      opacity: 0;
      }
    }
    .dot3 {
      animation: visibility3 2s linear infinite;
    }
    @keyframes visibility3 {
      0% {
      opacity: 0;
      }
      43% {
      opacity: 0;
      }
      44% {
      opacity: 1;
      }
      65% {
      opacity: 1;
      }
      66% {
      opacity: 0;
      }
      100% {
      opacity: 0;
      }
    }
  `
);

function AccountIDSpinner() {
  return (
    <AccountLoadWrapper>
      <Box className="message-wrapper">
        <Coffee color="primary" />
        <Typography variant="h6" color="text.primary" sx={{ width: '100%', mb: 2 }} className="loading">Take a break – this could take 3-5 minutes <span className="dot dot1">. </span><span className="dot dot2">. </span><span className="dot dot3">.</span></Typography>
        <Typography variant="body1">Please stay on this page while we whitelist your AWS environment and generate Lambdas for you to use.</Typography>
      </Box>
    </AccountLoadWrapper>
  )
}

AccountIDSpinner.propTypes = {};

export default AccountIDSpinner;

