import { React } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { ArrowBackRounded } from '@mui/icons-material';
import ToggleEncryptionKey from './toggleEncryptionKey';
import DetailsCard from './detailsCard';
import DisplayAccountId from 'src/components/DisplayAccountId';
import DisplayDateAdded from 'src/components/DisplayDateAdded';
import DisplayActivationStatus from 'src/components/DisplayActivationStatus';
import EditEmails from './editEmails';
import EditTransferSchedule from './editTransferSchedule';

function ManageCustVdfLists({ cancel, data, reload }) {

  return (
    <Grid container spacing={3}>
      <Grid className="manage-customer-vdf-file" item xs={12}>
        <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><ArrowBackRounded className="back-arrow" onClick={cancel} sx={{ mr: 2 }} /></Tooltip> Manage {data?.configurationType === 'customer_records' ? 'Customer' : 'Verified Deaths'} List</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {data?.transfer?.awsS3 && <DisplayAccountId data={data} />}
        <DetailsCard data={data} reload={reload} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DisplayDateAdded data={data} />
        <DisplayActivationStatus data={data} />
      </Grid>
      <ToggleEncryptionKey data={data} reload={reload} />
      <Grid item xs={12} sm={6}>
        <EditTransferSchedule data={data} reload={reload} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EditEmails data={data} reload={reload} />
      </Grid>
    </Grid>
  )
}

ManageCustVdfLists.propTypes = {
  cancel: PropTypes.func,
  data: PropTypes.object,
  reload: PropTypes.func,
};

export default ManageCustVdfLists;