import { React, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Box, Alert } from '@mui/material';
import PropTypes from 'prop-types';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { editCategories } from 'src/api';
import { useAppContext } from 'src/AppContext';
import SelectCategories from 'src/components/SelectCategories';
import { useNavigate } from 'react-router-dom';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const validationSchema = yup.object().shape({
  categories: yup
    .array()
    .min(1, 'Field is required')
})

function EditCategories({ data, reload, setEditingCategories, updateCats, updatedCats }) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [currentlySelectedCats, setCurrentlySelectedCats] = useState(null);
  const [generalizedError, setGeneralizedError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      categories: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let customerFilePrefix = data?.customerFilePrefix;
        let payload = { 'categories': values.categories };
        setIsLoading(true);
        const result = await editCategories(carrierConfig?.carrierId, customerFilePrefix, payload);
        if (result.statusCode === 400 || result.statusCode === 500 ) {
          setIsLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result.categories) {
          reload();
          updateCats(result.categories);
          setIsLoading(false);
          setEditingCategories(false);
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  useEffect(() => {
    const setValue = async function () {
      if (updatedCats) {
        setCurrentlySelectedCats(updatedCats);
        await formik.setFieldValue('categories', updatedCats);
      } else {
        setCurrentlySelectedCats(data?.categories);
        await formik.setFieldValue('categories', data?.categories);
        
      }
      setInitialized(true);
    }

    if (!initialized && (formik?.values?.categories?.length === 0) && data?.categories) {
      setValue().catch((error) => {
        navigate('/500?page=manage-configurations&error=' + error);
      });
    }
  }, [formik, setInitialized, data?.sources, initialized, updatedCats, data?.categories, navigate]);

  function updateSelectedCategories(allSelected) {
    formik.setFieldValue("categories", allSelected);
  }

  return (
    <>
      {isLoading && 
      <Box sx={{minHeight: '400px'}}>
        <Loading />
      </Box>
      }
      <Box className={isLoading ? 'hide' : 'show'}>
        <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ textAlign: 'left', width: '100%', px: 3, py: 4 }}>
              <SelectCategories updateSelectedCategories={updateSelectedCategories} existingCategories={currentlySelectedCats} /> 
            </Box>
            <hr />
            <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
              <Button type="submit" variant="contained">
                Save Changes
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </>
  )
}

EditCategories.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func, 
  setEditingCategories: PropTypes.func,
  updateCats: PropTypes.func, 
  updatedCats: PropTypes.array
};

export default EditCategories;