import React, { useState } from 'react';

import { FileDownloadRounded, HourglassTopRounded, ErrorOutline } from '@mui/icons-material';
import fileDownload from 'js-file-download';

import { useAppContext } from 'src/AppContext.js';
import { getErrorFileSignedUrl } from '../../../api';
import { Box, styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(() => (`
    color: #D9000B;
    background-color: #fbe5e7;
    width: 110px;
    border-bottom: 2px solid #D9000B;
    .MuiTooltip-arrow { 
      color: #fbe5e7;
    }
`));

const DownloadsWrapper = styled(Box)(
  ({ theme }) => `
    .downloads {
      width: 120px;
      padding-left: 25px; 
    }
    .error{
      border: 2px solid ${theme.colors.error.main};
      z-index: 1
    }
    MuiTooltip-tooltip.downloads-tooltip {
      color: ${theme.colors.error.main} !important;
      background-color: red !important;
    }
  `
);

function ErrorFileDownload({ fileType, dataLoadId, handleDownloadError, height = '99px', paddingTop = '35px' }) {
  const { carrierConfig } = useAppContext();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { carrierId } = carrierConfig;

  function handleErrors() {
    setDownloadInProgress(false);
      setHasError(true);
      handleDownloadError();
  }

  async function handleDownload() {
    try {
      setDownloadInProgress(true);
      setHasError(false);
      const { url, filename } = await getErrorFileSignedUrl(carrierId, fileType, dataLoadId);
      const result = await fetch(url);
      if (!filename || !url) {
        setHasError(true);
      }
      const data = await result.blob();
      fileDownload(data, filename);
      setDownloadInProgress(false);
    } catch (error) {
      handleErrors();
    }
  };

  return (
    <DownloadsWrapper>
      <Box sx={{height: height, paddingTop: paddingTop}} className={hasError ? 'error downloads' : 'downloads'}>
        {!downloadInProgress && <FileDownloadRounded onClick={handleDownload} key={dataLoadId} className="download-arrow" color="primary" sx={{ textAlign: 'left' }} />}
        {downloadInProgress && <HourglassTopRounded className="hourglass" color="primary" sx={{ textAlign: 'left' }} />}
        {hasError &&
          <CustomTooltip className="downloads-tooltip" title="Error retrieving file, try again.">
            <ErrorOutline color="error" sx={{ ml: 2, textAlign: 'right' }} />
          </CustomTooltip>}
      </Box>
    </DownloadsWrapper>
  )
}

ErrorFileDownload.propTypes = {
  fileType: PropTypes.string, 
  dataLoadId: PropTypes.number, 
  handleDownloadError: PropTypes.func,
  height: PropTypes.string,
  paddingTop: PropTypes.string
}

export default ErrorFileDownload;

