

import React, {useMemo, useState} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppContext } from './AppContext'; 
import AppRoutes from './AppRoutes';


function App() {
  const [carrierConfig, setCarrierConfig] = useState(null);
  const [lensRoles, setLensRoles] = useState(null);
  const [oktaUser, setOktaUser] = useState(null);
  const [customerFileConfigs, setCustomerFileConfigs] = useState([]);
  const [deathFileConfigs, setDeathFileConfigs] = useState([]);
  const [matchesFileConfigs, setMatchesFileConfigs] = useState([]);

  const queryClient = new QueryClient();

  const provider = useMemo(() => ({
    carrierConfig, 
    setCarrierConfig,
    lensRoles,
    setLensRoles,
    customerFileConfigs,
    setCustomerFileConfigs,
    deathFileConfigs,
    setDeathFileConfigs,
    matchesFileConfigs,
    setMatchesFileConfigs,
    oktaUser,
    setOktaUser
  }), [carrierConfig, customerFileConfigs, deathFileConfigs, matchesFileConfigs, lensRoles, oktaUser ]);
  


  return (
    <AppContext.Provider value={provider}>
      <QueryClientProvider client={queryClient}>  
        <AppRoutes/>
      </QueryClientProvider>  
    </AppContext.Provider>
  );
}

export default App;
