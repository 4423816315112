import React from 'react';

import { Grid, styled, Typography } from '@mui/material';

import PageHeader from 'src/components/PageHeader';

import columns from './columns';
import rows from './rows';

import { DataGrid } from '@mui/x-data-grid';

const GridWrapper = styled(Grid)(
  ( {theme} ) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
    }

    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
} 
.MuiTablePagination-displayedRows {
  display: none;
}
.MuiInputBase-root {
  margin-right: 70px !important;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden;
}
.page-count {
  font-size: 15px;
  bottom: 15px;
  position: absolute;
  right: 100px;
}
.ssn-grid-col {
  border-left: 1px solid #666;
}  
.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #999;
}
.highlight {
  background-color: ${theme.palette.action.focus};
  height: 100%;
  align-items: center;
    display: flex;
    text-align: center;
}
.center {
  text-align: center;
  padding: 0px !important;
}
.MuiDataGrid-cell {
  padding: 0;
}

.highlight.wrap-cell {
  display: grid;
}

.MuiDataGrid-main {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%);
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .MuiDataGrid-cell {
  min-width: 100px !important;
  }
  .MuiDataGrid-columnHeader {
    min-width: 100px !important;
  }
  .cat-level, .data-tier {
    min-width: 240px !important;
  }
}

`
)

function CategoryDefinitions() {

  return (
    <>
      <PageHeader title="Category Definitions" subtitle='Category match levels indicate how the match was made.'/>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Match Results Categories</Typography>
          <Typography sx={{textAlign: 'right', mr: 3, mb: 1}} variant="body2"><strong>Key:</strong> M=Match, F=Fuzzy, N=Null, I=Invalid</Typography>
        <GridWrapper container spacing={3} sx={{ p: 3 }}>
        <div style={{ height: 718, width: '100%' }}>
        <DataGrid
          rows={rows} 
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[5]}
          disableColumnMenu={true}
          rowHeight={60}
          components={{
            Footer: Grid,
          }}
          
          />
          </div>
          <Typography sx={{mt: 1}} variant="caption">*Match includes DOB when provided</Typography>
          </GridWrapper>
        </Grid>
      </Grid>
    </>
  );
}


CategoryDefinitions.propTypes = {};

export default CategoryDefinitions;