import React from 'react';

import { Link } from 'react-router-dom';

import { styled, Box, Typography } from '@mui/material';
import { ReactComponent as EvadataLogo } from 'src/images/EvadataLogo.svg'

const LogoAreaWrapper = styled(Box)(
  ({ theme }) => `
            .logo {
                display: block;
                float: left;
                margin-top: 15px;
            }
            .logo svg {
                filter: invert(${theme.colors.logo.inverse});
            }
            .lens-text {
                text-align: left;
                font-weight: bold; 
                color: ${theme.colors.logo.productText}; 
                display: inline-block;
                margin-left: 7px;
                margin-top: 15px;
            }

            @media screen and (max-width: 1279px) {
                .logo {
                    margin-left: 42px;
                }
            }

            @media screen and (max-width: 425px) {
              .logo {
                  margin-left: 36px;
              }

              @media screen and (max-width: 359px) {
                .logo {
                  margin-left: 20px;
                }
          }
  `
);

function LogoArea() {

  return (
    <LogoAreaWrapper>
      <Link to="/">
        <Typography className="logo">
          <EvadataLogo />
        </Typography>
        <Typography variant="h6" className="lens-text">LENS
        </Typography>
      </Link>
    </LogoAreaWrapper>


  );
}

export default LogoArea;