import { React, useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { Grid, Typography, Button, Card, styled, FormHelperText, IconButton, RadioGroup, Radio, FormControlLabel, TextField, InputAdornment } from '@mui/material';
import { ErrorOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { FormikProvider, useFormik } from 'formik';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { editSftpCredentials } from 'src/api';
import SftpUsername from 'src/components/FormFields/sftpUsername';
import SftpSsh from 'src/components/FormFields/sftpSsh';
import PropTypes from 'prop-types';

function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="40px" />
  );
}

const SftpCardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

const validationSchema = yup.object().shape({
  isMatch: yup
    .bool()
    .required(),
  usernameSFTP: yup
    .string()
    .when('isMatch', (isMatch) => {
      if (!isMatch) {
        return yup.string().max(100, 'A maximum of 100 characters are allowed').matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period").min(3, 'A minimum of 3 characters are allowed').required('Field is required')
      } else {
        return yup.string()
      }
    }),
  SSHRSAKey: yup
    .string()
    .when('isMatch', (isMatch) => {
      if (!isMatch) {
        return yup.string().required('This field is required.')
      } else {
        return yup.string()
      }
    }),
  chooseSshKey: yup
    .boolean()
    .when('isMatch', (isMatch) => {
      if (isMatch) {
        return yup.boolean().required('This field is required.').default(true)
      } else {
        return yup.boolean()
      }
    }),
  usernameMatch: yup
    .string()
    .when('isMatch', (isMatch) => {
      if (isMatch) {
        return yup.string().required('This field is required.')
      } else {
        return yup.string()
      }
    }),
  password: yup
    .string()
    .when(['chooseSshKey', 'isMatch'], (chooseSshKey, isMatch) => {
      if (isMatch && !chooseSshKey) {
        return yup.string().required('This field is required.')
      } else {
        return yup.string()
      }
    }),
});


function DisplayEditSftpCredentials({ data, handleEditClose, editForm, prefix, custFilePrefix, configType, carrierConfig, toggleEditForm, tempAssociatedData, setTempAssociatedData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [badUsernames, setBadUsernames] = useState([]);
  const [usernameInUseError, setUsernameInUseError] = useState(false);
  const [sshKeyError, setSshKeyError] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const inputRef = useRef();
  const sshRef = useRef();
  const isMatch = configType === 'matches';

  function handleResult(result){
    let tempArray = [];
    if (result.sshKey){
      tempArray = tempAssociatedData.map(item => item.file.prefix === result?.filePrefix ? { ...item, 'transfer': { ...item.transfer, [data.transfer.type]: { ...item.transfer[data.transfer.type], 'username': result.username, 'ssh': result.sshKey } } } : item);
      setTempAssociatedData(tempArray);
      setIsLoading(false);
    } else if (result.password){
      tempArray = tempAssociatedData.map(item => item.customerFilePrefix === result?.filePrefix ? { ...item, 'transfer': { ...item.transfer, [data.transfer.type]: { ...item.transfer[data.transfer.type], 'username': result.username, 'password': result.password, 'ssh': null } } } : item);
      setTempAssociatedData(tempArray);
      setIsLoading(false);
    } else if (result.publicKey){
      tempArray = tempAssociatedData.map(item => item.customerFilePrefix === result?.filePrefix ? { ...item, 'transfer': { ...item.transfer, [data.transfer.type]: { ...item.transfer[data.transfer.type], 'username': result.username, 'password': null, 'ssh': {'publicKey': result.publicKey} } } } : item);
      setTempAssociatedData(tempArray);
      setIsLoading(false);
    }  
    handleEditClose()
  };

  const formik = useFormik({
    initialValues: {
      usernameSFTP: '',
      SSHRSAKey: '',
      isMatch: isMatch
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload = {'type': configType, 'filePrefix': prefix}
      if (isMatch){
        payload.username = values.usernameMatch.trim();
        payload.filePrefix = custFilePrefix;
        payload.password = values.password;
      } else {
        payload.username = values.usernameSFTP.trim();
        payload.sshKey = values.SSHRSAKey;
      }
      try{
        setIsLoading(true);
        const result = await editSftpCredentials(carrierConfig?.carrierId, payload);
        if (result.statusCode === 400) {
          setIsLoading(false);
          if (result.message === 'User already exists') {
            setUsernameInUseError(true);
            setBadUsernames([...badUsernames, formik.values.usernameSFTP]);
            inputRef.current.scrollIntoView();
          }
          else if (result.message === 'Invalid SSH key') {
            setSshKeyError(true);
            sshRef.current.scrollIntoView();
          }
        } else if (result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
        } else if (result){
          handleResult(result)
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  });

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(event) {
    event.preventDefault();
  };

  function cancel() {
    handleEditClose();
    setSshKeyError(false);
    formik.setFieldValue('usernameSFTP', '');
    formik.setFieldValue('SSHRSAKey', '');
    formik.setFieldValue('usernameMatch', '');
    formik.setFieldValue('password', '');
  };

  useEffect(() => {
    if (badUsernames?.includes(formik.values.usernameSFTP)) {
      setUsernameInUseError(true);
    } else {
      setUsernameInUseError(false);
    }
  }, [badUsernames, formik.values.usernameSFTP]);


  return (
    <SftpCardWrapper className={editForm ? 'show' : 'hide'}>
      {isLoading ?
        <Loading />
        :
        <>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography variant="subtitle1">{prefix}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button onClick={cancel}>Cancel</Button>
            </Grid>
          </Grid>
          <FormikProvider enableReinitialize={true} value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {isMatch ? (
                <Grid sx={{ pl: 4, pr: 4, pb: 1, pt: 2 }}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 2 }}>Provide credentials for LENS to use when accessing your SFTP to transfer these matches.</Typography>
                  <TextField
                    id="usernameMatch"
                    inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off'
                        }
                    }}
                    type='text'
                    name="usernameMatch"
                    label="SFTP Username"
                    className="usernameMatch"
                    value={formik.values.username}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.usernameMatch && Boolean(formik.errors.usernameMatch)}
                    helperText={formik.touched.usernameMatch ? formik.errors.usernameMatch : 'Lens will use the username to access your SFTP'}
                    sx={{ mb: 1 }}
                    />
                  <Typography variant="subtitle1" color="text.primary" sx={{ mb: 0.5, mt: 2 }}>How should LENS authenticate to access your virtual server instance?</Typography>
                  <RadioGroup
                    id="chooseSshKey"
                    name="chooseSshKey"
                    value={formik.values.chooseSshKey}
                    onChange={formik.handleChange}
                    sx={{ mb: 3 }}
                    defaultValue={true}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Authenticate by SSH-RSA key" />
                    <Typography variant="body2" sx={{ mt: 1, ml: 4 }}>LENS will generate this key and use it to access your SFTP.</Typography>
                    <Typography variant="body3" sx={{ mb: 1, ml: 4 }}>The key will be available after saving.</Typography>
                    <FormControlLabel value={false} control={<Radio />} label="Authenticate by Password" />
                  </RadioGroup>

                  {formik.values.chooseSshKey === 'false' &&
                  <>
                    <TextField
                      fullWidth
                      id='password'
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helpertext={formik.touched.password && formik.errors.password }
                      sx={{ mb: 2, ml: 2, mt: -2 }}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormHelperText sx={{ ml: 2, mt: -1.5 }} error id="password-error">
                      {formik.touched.password && formik.errors.password }
                    </FormHelperText>
                    <Typography  variant="body3" sx={{ mb: 4, ml: 2 }}>For security reasons, the password will be hidden after saving.</Typography>
                  </>}
                </Grid>
              ) : (
                <Grid sx={{ pl: 4, pr: 4, pb: 3, pt: 2 }}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 2 }}>Provide credentials to use when accessing LENS SFTP to transfer this list.</Typography>
      
                  <SftpUsername formik={formik}/>
                  {usernameInUseError &&
                    <FormHelperText sx={{ ml: '-5px', mt: '-5px', mb: 2 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Username entered is already in use, try a different name.</FormHelperText> 
                  }

                  <SftpSsh formik={formik} mb={1}/>
                  {sshKeyError &&
                    <FormHelperText sx={{ ml: '-5px', mt: '-5px', mb: 2 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Unsupported ssh key format. RSA, ECDSA and ED25519 are supported formats.</FormHelperText>
                  }
                  <Typography  variant="body3" sx={{ mb: 4 }}>For security reasons, the key will be hidden after saving.</Typography>
                </Grid>
              )}
            
              <hr />
              <Grid textAlign={'center'} sx={{ pl: 2, pr: 2, pb: 3, pt: 2 }}>
                <Button type="submit" variant="contained" >Save Changes</Button>
              </Grid>
            </form>
          </FormikProvider>
        </>
      }
    </SftpCardWrapper>
  )
}

DisplayEditSftpCredentials.propTypes = {
  data: PropTypes.object,
  handleEditClose: PropTypes.func,
  editForm: PropTypes.func,
  prefix: PropTypes.string, 
  custFilePrefix: PropTypes.string,
  configType: PropTypes.string,
  carrierConfig: PropTypes.object,
  toggleEditForm: PropTypes.func,
  tempAssociatedData: PropTypes.object,
  setTempAssociatedData: PropTypes.func
};

export default DisplayEditSftpCredentials;