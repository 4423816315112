import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import PropTypes from 'prop-types';
import PageHeader from 'src/components/PageHeader';
import { CalendarTodayRounded } from '@mui/icons-material';

const CalendarPageHeader = ({ handleCreateEvent }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={9}>
        <PageHeader title="Holiday Calendar" subtitle="Manage company holidays. Match jobs scheduled to deliver to your organization daily will skip holidays specified below and will resume the following day (Mon.-Fri.)." icon={<CalendarTodayRounded />}/>
      </Grid>
      <Grid item xs={3} sx={{textAlign: 'right'}}>
        <Button
          sx={{ mb: 5 }}
          onClick={handleCreateEvent}
          variant="contained"
          color="primary"
          startIcon={<EventTwoToneIcon fontSize="small" />}
        >
          {t('Add event')}
        </Button>
      </Grid>
    </Grid>
  );
};

CalendarPageHeader.propTypes = {
  handleCreateEvent: PropTypes.func
};

CalendarPageHeader.defaultProps = {
  handleCreateEvent: () => {}
};

export default CalendarPageHeader;
