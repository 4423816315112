export function switchFreqDisplay(param) {
  switch (param) {
    case 'daily':
      return 'Daily (Monday-Friday)';
    case 'weekly':
      return 'Weekly';
    case 'monthly':
      return 'Monthly';
    case 'quarterly':
      return 'Quarterly';
    case 'once':
      return 'Once';
    default:
      return '';
  }
}