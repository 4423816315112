import React from 'react';
import PageHeader from 'src/components/PageHeader';
import { VerifiedRounded, Star } from '@mui/icons-material';
import { Typography, Grid, styled, Box, Divider } from '@mui/material';
import jsonStringCustomerFile from './jsonExample';
import ExampleCodeCard from 'src/components/ExampleCodeCard';
import AttributeCard from 'src/components/AttributeCard';
import FileDownloadCard from 'src/components/FileDownload';
import verifiedDeathsAttributes from './attributes';
import AttributeDownloads from 'src/components/AttributeDownloads';

const VFDFileWrapper = styled(Box)(
  () => `
  .vfd-code-card .MuiPaper-root {
    max-width: 100%;
    width: 100%;
  }

  ul li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 599px) {
    .vfd-code-card {
      margin-bottom: 20px;
    }
  }
  `
);

function VerifiedDeathsFile() {

  return (
    <VFDFileWrapper>
      <PageHeader title="Creating Verified Deaths List" subtitle="Carriers should only submit deaths they are confident in to LENS. The match records from your Verified Deaths List will be shared with other carriers who have that same customer. Below, you will see the complete attribute details as well as the ready to use templates. If not using the template provided, make sure your headers match exactly as shown below in bold text so that LENS will process them at ingestion." icon={<VerifiedRounded />} />
      <Grid container spacing={3} item sx={{ mt: -2 }}>
        <Grid item xs={12} sm={7} md>
          <AttributeDownloads label="JSON" variation="icon" fileType="JSON" fileName='vdf_attributes.json' file='vdf_attributes.json' />
          <Grid container sx={{ mb: 1 }}>
            <Typography sx={{ mr: .5 }} color="text.secondary"><Star fontSize="small" /></Typography>
            <Typography variant="body3">While these values may not be available for every death record, they are critical for higher quality matches.</Typography>
          </Grid>
          {verifiedDeathsAttributes.map((card) => (
            <AttributeCard key={card.title} title={card.title} required={card.required} dataType={card.dataType} length={card.length} format={card.format} note={card.note} url={card.url} urlText={card.urlText}></AttributeCard>
          ))}
        </Grid>
        <Grid className="vfd-code-card" item xs={12} sm={5} md={5} lg={4}>
          <ExampleCodeCard data={jsonStringCustomerFile} fileName="LENS_Verified_Deaths_File_TML.json" showCsv={true} csvFileName="LENS_Verified_Deaths_File_TML.csv" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={7} lg={8}>
          <Typography variant="h5" sx={{ mb: 1 }}>Including Custom Attributes</Typography>
          <Typography variant="body1">LENS is looking for the data attributes headers exactly as shown above. Any other attribute headers you provide will be treated as custom attributes and echoed back on your results. There is no limit on the number of custom attributes you can add. This data is considered private and not shared.</Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={8}>
          <AttributeCard title='<custom>' required={false} dataType='N/A' length='N/A' format='N/A' note='Include one or as many attributes as needed.'></AttributeCard>
          <Typography variant="body2" sx={{ mb: 3, mt: -2 }}>E.g. admin system name, product type, face amount...</Typography>
        </Grid>

      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Divider sx={{ mt: 1, mb: 4 }} />
          <Typography variant="h5">Important Data Quality Considerations</Typography>
          <ul>
            <li>Submitted death records should come from open/active claims currently being processed, closed claims already processed, or other departments. Your list should contain deaths occurring over the last two years.</li>
            <li>The death records file should contain all possible deaths from the customer file - meaning if an individual from your customer file dies, they should eventually show up on your death file. This is important for tracking accuracy over time.</li>
            <li>The customer IDs you use on your death record should match your customer file. LENS uses customer IDs to identify unique individuals. If you change customer IDs, LENS will consider those records as new deaths. Please contact Customer Success before changing IDs.</li>
            <li>Ensure that your team is able to identify records with that ID for support-related concerns. If that’s not feasible, then we advise you to add an identifier that your team can use that is not SSN. You can add this identifier as a custom variable.</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="h5" sx={{ mb: 1 }}>Verified Deaths File Template</Typography>
          <Typography variant="body1">Carriers can submit data to LENS SFTP or their organization’s AWS S3 Bucket in JSON or CSV format. JSON format is preferred. For CSV format, carriers can submit attributes in any order as along as the order is specified in the header.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="JSON" fileName="LENS_Verified_Deaths_File_TML.json" file="LENS_Verified_Deaths_File_TML.json" setHasError={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="CSV" fileName="LENS_Verified_Deaths_File_TML.csv" file="LENS_Verified_Deaths_File_TML.csv" setHasError={false} />
        </Grid>
      </Grid>
    </VFDFileWrapper>
  );
}


VerifiedDeathsFile.propTypes = {};

export default VerifiedDeathsFile;