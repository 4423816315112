import React, { useEffect, useState } from 'react';
import PageHeader from 'src/components/PageHeader';
import { CompareRounded } from '@mui/icons-material';
import { Typography, Grid, styled, Box, Card, CardContent, List, ListItem, Divider, ButtonGroup, Button, Paper, FormControl, MenuItem, Select } from '@mui/material';
import jsonStringCustomerFile from './jsonExample';
import ExampleCodeCard from 'src/components/ExampleCodeCard';
import AttributeCard from 'src/components/AttributeCard';
import topLevelAttributes from './topLevelAttributes';
import carrierAttributes from './carrierAttributes';
import matchesHeaderAttributes from './matchesHeaderAttributes';
import matchesMetadataAttributes from './matchesMetadataAttributes';
import matchesDeathRecordAttributes from './matchesDeathRecordAttributes';
import FileDownloadCard from 'src/components/FileDownload';
import AttributeDownloads from 'src/components/AttributeDownloads';

const PageWrapper = styled(Box)(
  () => `
  .code-card-v-scroll .MuiPaper-root {
    max-height: 1195px;
    overflow-y: scroll;
    max-width: 100%;
    width: 100%;
  }
  hr:nth-last-of-type(1) {
    display: block !important;
  }
  @media screen and (max-width: 1440px) {
    .code-card-v-scroll .MuiPaper-root {
      max-height: 1213px;
      overflow-y: scroll;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1024px) {
    .code-card-v-scroll .MuiPaper-root {
      max-height: 1256px;
      overflow-y: scroll;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .code-card-v-scroll .MuiPaper-root {
      max-height: 1337px;
      overflow-y: scroll;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 599px) {
    .code-card-v-scroll {
      margin-bottom: 20px;
    }
  }
  
    `
);

const FileDefWrapper = styled(Grid)(
  ({ theme }) => `
    .definition-block {
      border-bottom: 1px solid #E0E0E0;
      padding: 16px 20px;
      width: 100%;
      color: ${theme.palette.text.primary}
    }
    .definition-block:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  `
);


function MatchResultsFile() {

  const [matchResultsGroup, setMatchResultsGroup] = useState('top');
  const [matchAttributeSubGroup, setMatchAttributeSubGroup] = useState('header');
  const [activeAttributeJson, setActiveAttributeJson] = useState(topLevelAttributes);

  const handleFilterChange = (event) => {
    setMatchAttributeSubGroup(event.target.value);
  };

  useEffect(() => {
    if (matchResultsGroup === 'top') {
      setActiveAttributeJson(topLevelAttributes);
    }
    if (matchResultsGroup === 'carrier') {
      setActiveAttributeJson(carrierAttributes);
    }
    if (matchResultsGroup === 'matches') {
      if (matchAttributeSubGroup === 'header') {
        setActiveAttributeJson(matchesHeaderAttributes);
      }
      if (matchAttributeSubGroup === 'meta') {
        setActiveAttributeJson(matchesMetadataAttributes);
      }
      if (matchAttributeSubGroup === 'death') {
        setActiveAttributeJson(matchesDeathRecordAttributes);
      }
    }
  }, [matchResultsGroup, matchAttributeSubGroup])

  return (
    <PageWrapper>
      <PageHeader title="Receiving Match Results" subtitle="Upon submission of your Customer and Verified Deaths lists you will receive match results. Below you will see the full attribute details as well as a sample provided below." icon={<CompareRounded />} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md>
          <AttributeDownloads label="JSON ZIP" variation="icon" fileType="ZIP" fileName='match_attributes.zip' file='zip_files/match_attributes.zip' />
          <Paper sx={{ pt: 2, px: 2, mb: 3 }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <ButtonGroup sx={{ mb: 2, width: '100%' }}>
                  <Button sx={{ fontWeight: 400, width: '33.333%' }} onClick={() => setMatchResultsGroup('top')} variant={matchResultsGroup === 'top' ? "contained" : "outlined"}><strong>Top Level</strong></Button>
                  <Button sx={{ fontWeight: 400, width: '33.333%' }} onClick={() => setMatchResultsGroup('carrier')} variant={matchResultsGroup === 'carrier' ? "contained" : "outlined"}><strong>Carrier</strong></Button>
                  <Button sx={{ fontWeight: 400, width: '33.333%' }} onClick={() => setMatchResultsGroup('matches')} variant={matchResultsGroup === 'matches' ? "contained" : "outlined"}><strong>Matches</strong></Button>
                </ButtonGroup>
              </Grid>
              {matchResultsGroup === 'matches' &&
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <FormControl>
                    <Select
                      labelId="select-helper-label"
                      id="select-helper"
                      sx={{ minWidth: '280px', mb: 2, textAlign: 'left' }}
                      MenuProps={{ disableScrollLock: true }}
                      value={matchAttributeSubGroup}
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="header">Header Atrributes</MenuItem>
                      <MenuItem value="meta">Match Metadata Attributes</MenuItem>
                      <MenuItem value="death">Death Record Attributes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              }
            </Grid>
          </Paper>
          {activeAttributeJson.map((card) => (
            <AttributeCard key={card.title} title={card.title} required={card.required} dataType={card.dataType} length={card.length} format={card.format} note={card.note} url={card.url} urlText={card.urlText} childData={card.children}></AttributeCard>
          ))}
        </Grid>
        <Grid className="code-card-v-scroll" item xs={12} sm={5} md={5} lg={4} sx={{ mb: 4 }}>
          <ExampleCodeCard data={jsonStringCustomerFile} fileName="LENS_Match_Results_File_EX.json" xlsxFileName="LENS_Match_Results_File_EX.xlsx" showXlsx={true} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Divider sx={{ mt: 1, mb: 4 }} />
          <Typography variant="h5" sx={{ mb: 1 }}>Match Results Processing Types</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>Results are placed in your organization’s SFTP or AWS S3 Bucket and are either combined in a single file or split across multiple files. Single files will split automatically when the record count is 100k or more, which is generally around 200MB. Carriers can encounter a split file on their first day run or based on the category selections and frequency of reporting.</Typography>
          <Card sx={{ minWidth: 275, textAlign: 'left', mb: 3, boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
            <CardContent sx={{ padding: '0px' }}>
              <FileDefWrapper>
                <Grid container spacing={0} xs={12} item={true}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 2, px: 2, pt: 2 }}>
                      Single File
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      Single file with combined records will be used when record count is under 100k.
                      <Typography variant="body2" sx={{ fontWeight: 700 }}>Name scheme for json or xlsx file types:</Typography>
                      <Typography variant="body2" sx={{ mb: 0 }}>
                        &#x3c;file name&#x3e;-&#x3c;new/updated/deleted&#x3e;.json</Typography>
                      <Typography variant="body2" sx={{ mb: 0 }}>
                        &#x3c;file name&#x3e;-&#x3c;new/updated/deleted&#x3e;.xlsx</Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={0} xs={12} item={true}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ p: 2 }}>
                      Split Files
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ p: 2 }}>
                    <Typography variant="body2" >
                      Single file will be split when record count is 100k or more. Reach out to support if you need a threshold adjusted.
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>Name scheme:</Typography>
                    <Typography variant="body2" sx={{ mb: 0 }}>
                      &#x3c;file name&#x3e;-&#x3c;new/updated/deleted&#x3e;.json</Typography>
                    <Typography variant="body2" sx={{ mb: 0 }}>
                      &#x3c;file name&#x3e;-metadata.json</Typography>
                    <Typography variant="body2" sx={{ mb: 0 }}>
                      The metadata file will list these files:
                    </Typography>
                    <List sx={{ fontSize: '15px', lineHeight: '6px', mt: 0, listStyle: "disc", ml: 2, pt: 0 }}>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>Count of new_matches</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>Count of updated_matches</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>Count of deleted_matches</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>categories</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>sources</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>date_created</ListItem>
                      <ListItem sx={{ display: 'list-item', pl: 0 }}>List of files that contain all the matches</ListItem>
                    </List>
                  </Grid>

                </Grid>
              </FileDefWrapper>
            </CardContent>
          </Card>
          <Typography variant="body1">Download samples of a single file or split file pack. All results are available in JSON OR XLSX.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="JSON" fileName="LENS_Match_Results_File_EX.json" file="LENS_Match_Results_File_EX.json" setHasError={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="JSON ZIP" fileName="LENS_Match_Split_Results_File_EX.zip" file="LENS_Match_Split_Results_File_EX.zip" setHasError={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="XLSX" fileName="LENS_Match_Results_File_EX.xlsx" file="LENS_Match_Results_File_EX.xlsx" setHasError={false} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}


MatchResultsFile.propTypes = {};

export default MatchResultsFile;