import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import items from './items';
import MenuSection from '../MenuSection';
import { styled, Box } from '@mui/material';

const OnBoardingWrapper = styled(Box)(
  ({ theme }) => `
    .MuiListItem-root .MuiButton-root.active .MuiButton-startIcon {
      color: ${theme.palette.primary.main};
    }

    .onboarding-active a.MuiButtonBase-root {
      background-color: #f2f5f9 !important;
      color: rgb(0, 79, 126) !important;
    } 
    .onboarding-active a.MuiButtonBase-root:after {
      content: '';
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      width: 0;
      opacity: 0;
      -webkit-transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background: #00639E;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  `
);

function OnBoarding({roles}) {

  const location = useLocation();
  const [onBoardingClass, setOnBoardingClass] = useState('');

  useEffect(() => {
    if (location.pathname === '/customer-file-setup' || location.pathname === '/verified-deaths-file-setup' || location.pathname === '/match-results-file-setup' || location.pathname === '/onboarding-welcome') {
      setOnBoardingClass('active');
    } else {
      setOnBoardingClass('');
    }
  }, [location.pathname])


  return (
    <OnBoardingWrapper>
      {(roles?.includes('administrator')) && items.map((section) => (
        <MenuSection customClass={onBoardingClass} heading={section.heading} items={section.items} key={section.heading}></MenuSection>
      ))}
    </OnBoardingWrapper>
  );
}

OnBoarding.propTypes = {
  roles: PropTypes.array
}

export default OnBoarding;