import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

function PgpRotationField({ formik, label, name }) {
  return (
      <FormControl sx={{ minWidth: '329px', mt: 2 }}>
        <InputLabel id="demo-simple-select-helper-label" htmlFor="newPgpKeyRotation">{label}</InputLabel>
        <Select
          disablescrolllock='true'
          id={name}
          name={name}
          value={formik?.values?.[name]}
          onChange={formik.handleChange}
          label={label}
        >
          <MenuItem value="1">1 Year</MenuItem>
          <MenuItem selected value="2">2 Years<span className="recommended">&nbsp;Recommended</span></MenuItem>
          <MenuItem value="3">3 Years</MenuItem>
          <MenuItem value="5">5 Years</MenuItem>
        </Select>
        <FormHelperText sx={{ mb: 2 }}>Request new key at this duration.</FormHelperText>
      </FormControl>
  )
}

PgpRotationField.propTypes = {
  formik: PropTypes.object, 
  label: PropTypes.string, 
  name: PropTypes.string, 
};

export default PgpRotationField;
