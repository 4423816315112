import { useState, createContext, useMemo } from 'react';

import PropTypes from 'prop-types';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
  
  const [sidebarToggle, setSidebarToggle] = useState(false);
  
  const sidebarFunctions = useMemo(() => {
    const toggleSidebar = () => {
      setSidebarToggle(!sidebarToggle);
    };
    const closeSidebar = () => {
      setSidebarToggle(false);
    };
    return (
      { sidebarToggle, toggleSidebar, closeSidebar }
    )
  }, [sidebarToggle])

  return (
    <SidebarContext.Provider
      value={sidebarFunctions}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.object
}