import React, {useEffect, useState} from 'react';
import { TextField, Typography, Grid  } from '@mui/material';
import PropTypes from 'prop-types';

function PrefixField({ formik, label, name, prefixError = false, exactPrefixError = false, helperText, showDateExtension}) {
  const [newHelperText, setNewHelperText] = useState(helperText);

  useEffect(() => {
    if(formik?.touched?.[name] && (Boolean(formik?.errors?.[name]))){
      setNewHelperText(formik?.errors?.[name]);
    }
    else if(exactPrefixError){
      setNewHelperText('File prefix entered is already in use.');
    }
    else{
      setNewHelperText(helperText);
    }
  },[formik.touched, formik.errors, exactPrefixError, helperText, name])

  return (
    <>
      <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>Provide file name prefix and file type.</Typography>
      { showDateExtension ? <Grid container >
        <Grid item sm={6}>
          <TextField
            fullWidth
            id={name}
            name={name}
            label={label}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
            onChange={e => {
              formik.setFieldTouched(name);
              formik.handleChange(e);}}
            error={formik.touched[name] && (Boolean(formik.errors[name]) || prefixError || exactPrefixError)}
            helperText={newHelperText}
            sx={{ mb: 3 }}
            inputProps={{ maxLength: 24 }} />
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" sx={{ ml: 1, mt: 2 }}>-YYYYMMDD</Typography>
        </Grid>
        </Grid> : 
        <TextField
          fullWidth
          id={name}
          name={name}
          label={label}
          value={formik?.values?.[name]}
          onBlur={formik?.handleBlur}
          onChange={e => {
            formik.setFieldTouched(name);
            formik.handleChange(e);}}
          error={formik?.touched?.[name] && (Boolean(formik?.errors?.[name]) || prefixError || exactPrefixError)}
          helperText={newHelperText}
          sx={{ mb: 3 }}
          inputProps={{ maxLength: 24 }} />
      }
    </>
  )
}

PrefixField.propTypes = {
  formik: PropTypes.object, 
  label: PropTypes.string, 
  name: PropTypes.string, 
  prefixError: PropTypes.bool, 
  exactPrefixError: PropTypes.bool, 
  helperText: PropTypes.string, 
  showDateExtension: PropTypes.bool
};

export default PrefixField;