import { React } from 'react';
import { Typography, Card, Grid, styled} from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

function DisplayActivationStatus({ data }) {

  return (
    <CardWrapper>
      <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Activation Status</Typography>
        </Grid>
      </Grid>
      <Grid className="transfer-active-container" container spacing={3} sx={{ p: 3, pl: 5 }}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <CheckRounded color="success" sx={{ display: 'block', float: 'left', mr: 1 }} /> Active in LENS
          </Typography>
        </Grid>
      </Grid>
    </CardWrapper>
  )
}

DisplayActivationStatus.propTypes = {
  data: PropTypes.object
};

export default DisplayActivationStatus;