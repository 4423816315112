import { React, useEffect, useState } from 'react';
import * as yup from 'yup';

import { Grid, Typography, Button, Card, styled, FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert, TextField, Box } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { FormikProvider, useFormik } from 'formik';
import { editTransferFrequency, getHolidayCalendars } from 'src/api';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import HolidayCalendarCheckbox from '../FormFields/holidayCalendarCheckbox';
import GeneralErrorAlert from '../GeneralErrorAlert';
import _ from 'lodash';
import TooltipInline from '../TooltipInline';
import { QuarterlyDates, quarterlyValidationSchema, quarterlyInitialValues } from '../FormFields/quarterlyDates';
import { combineMonthsDays, combineMonthDay } from 'src/utilities/combineMonthDay';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import { annualDmfSchema } from 'src/components/AnnualDmfScanSetup';
import AnnualDmfEdit from 'src/components/AnnualDmfEdit';


function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="40px" />
  );
}

const FreqCardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .once {
      padding-right: 45px;
    }
  `
);

const baseValidationSchema = {
  isMatch: yup
    .bool(),
  frequency: yup
    .string()
    .required(),
  frequencyDay: yup
    .string()
    .when(['isMatch', 'frequency'], (isMatch, frequency) => {
      if (isMatch && frequency === 'daily') {
        return yup.string().default('wednesday').required()
      } else {
        return yup.string()
      }
    }),
  frequencyMonth: yup
    .string()
    .when(['isMatch', 'frequency'], (isMatch, frequency) => {
      if (isMatch && frequency === 'monthly') {
        return yup.string().default('first day').required()
      } else {
        return yup.string()
      }
    }),
  observeHoliday: yup
    .bool()
};

function DisplayEditFrequency({ data, matchConfig, matchConfigs, customerConfigs, handleEditClose, editForm, prefix, frequency, freqDay, reportDates, holidayCalendarId, annualDmf, custFilePrefix, configType, carrierConfig, tempAssociatedData, setTempAssociatedData, availableFrequencies, removedFrequencies }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [associatedCalendarId, setAssociatedCalendarId] = useState(data?.holidayCalendarId);
  const [isError, setIsError] = useState(false);
  const [quarterlyErrors, setQuarterlyErrors] = useState([false, false, false, false]);

  const isMatch = configType === 'matches';
  const navigate = useNavigate();

  function handleResult(result) {
    let tempArray = []
    if (configType === 'matches') {
      tempArray = tempAssociatedData.map(item => item.customerFilePrefix === result?.filePrefix ? { ...item, 'holidayCalendarId': result.holidayCalendarId, 'transfer': { ...item.transfer, 'frequency': result.frequency, 'frequencyDay': result.frequencyDay, 'reportDates': result.reportDates, 'annualDmf': result.annualDmf } } : item);
    } else {
      tempArray = tempAssociatedData.map(item => item.file.prefix === result?.filePrefix ? { ...item, 'transfer': { ...item.transfer, 'frequency': result.frequency } } : item);
    }
    setTempAssociatedData(tempArray);
    setIsLoading(false);
    setInitialized(false);
    handleEditClose();
  };

  const validationSchema = yup.object().shape({
    ...baseValidationSchema, 
    ...annualDmfSchema,
    ...quarterlyValidationSchema(isMatch, 'frequency', quarterlyErrors)
  });

  const formik = useFormik({
    initialValues: {
      frequency: '',
      frequencyDay: 'wednesday',
      frequencyMonth: 'first day',
      isMatch: isMatch,
      observeHoliday: false,
      enableAnnualDmf: Boolean(annualDmf),
      annualDmfMonth: annualDmf ? Number(annualDmf?.split('/')[0]) : '',
      annualDmfDay: annualDmf ? Number(annualDmf?.split('/')[1]) : '',
      ...quarterlyInitialValues
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsError(false);
      setIsLoading(true);
      let payload = {
        'type': configType,
        'filePrefix': prefix,
        'frequency': values.frequency
      }
      if (configType === 'matches') {
        payload.filePrefix = custFilePrefix;
        if (values?.frequency === 'weekly') {
          payload.frequencyDay = values?.frequencyDay;
        } else if (values.frequency === 'monthly') {
          payload.frequencyDay = values?.frequencyMonth;
        } else if (values.frequency === 'daily' && values?.observeHoliday) {     
          payload.holidayCalendarId = associatedCalendarId;
        } else if (values.frequency === 'quarterly') {
          const quarterlyMonths = [values?.q1Month, values?.q2Month, values?.q3Month, values?.q4Month];
          const quarterlyDays = [values?.q1Day, values?.q2Day, values?.q3Day, values?.q4Day];
          const newReportDates = combineMonthsDays(quarterlyMonths, quarterlyDays);
          payload.reportDates = newReportDates;
        }
        if (values?.enableAnnualDmf) {
          payload.annualDmf = combineMonthDay(values?.annualDmfMonth, values?.annualDmfDay, true);
        }
      }
      try {
        const result = await editTransferFrequency(carrierConfig?.carrierId, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setIsLoading(false);
          setIsError(true);
          window.scrollTo(0, 0);
        }
        if (result.frequency) {
          handleResult(result);
        }
      } catch (error) {
        console.log(error)
      }
    }
  });

  function cancel() {
    setInitialized(false);
    formik.setFieldValue('frequency', '');
    formik.setFieldTouched('frequency', false);
    handleEditClose();
  };

  const handleSelectClick = (event, name) => {
    const clickedValue = event.target.dataset.value;
    const previousValue = formik.values[name];
    formik.setFieldTouched(name, !clickedValue && previousValue==='');
  }

  useEffect(() => {
    const setValue = async function () {
      if (frequency && (availableFrequencies?.includes(frequency) || !isMatch)) {
        await formik.setFieldValue('frequency', frequency);
      } else {
        formik.setFieldValue('frequency', '');
      }
      if (isMatch) {
        if (frequency === 'weekly') {
          await formik.setFieldValue('frequencyDay', freqDay);
        } else if (frequency === 'monthly') {
          await formik.setFieldValue('frequencyMonth', freqDay);
        }
      }
      setInitialized(true);
    }
    if (!initialized) {
      setValue().catch((error) => {
        navigate('/500?page=transfer-locations&error=' + error);
      })
    }
  }, [availableFrequencies, initialized]);

  useEffect(() => {
    const getHolidayCalendar = async function () {
      const holidayCalendar = await getHolidayCalendars(carrierConfig?.carrierId);
     
      setAssociatedCalendarId(holidayCalendar[0]?.id);
    }

    if(!associatedCalendarId) {
      getHolidayCalendar().catch((error) => {
        navigate('/500?page=transfer-locations&error=' + error);
      });
    }
  }, [carrierConfig?.carrierId, associatedCalendarId, navigate]);

  useEffect(() => {
    formik.setFieldValue('observeHoliday', !_.isEmpty(holidayCalendarId));
  },[tempAssociatedData, holidayCalendarId])


  return (
    <FreqCardWrapper className={editForm ? 'show' : 'hide'}>
      {isLoading ?
        <Loading />
        :
        <>
          <Grid className="card-header" container sx={{ pl: 2, pr: 2, pt: 2, mb: -1 }} alignItems={'center'}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography variant="subtitle1">{prefix}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button onClick={cancel}>Cancel</Button>
            </Grid>
          </Grid>
          <hr/>
          <FormikProvider enableReinitialize={true} value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {isError && <GeneralErrorAlert/>}
              <Grid container sx={{pl: 4, pr: 4, pt: 2}}>
                <Grid item xs={12}>
                  {removedFrequencies?.includes(frequency) && formik.values.frequency === '' &&
                      <Alert severity='warning' sx={{ mb: 2 }}>
                        <Typography variant='body2'>Transfer frequency options changed for your organization. Choose a different frequency from the list below.<br></br><strong>Removed: </strong>{_.chain(removedFrequencies).map(switchFreqDisplay).join(', ').value()}</Typography>
                      </Alert>
                  }
                </Grid>
                <Grid item xs={12}>
                  {!isMatch ? 
                    <Typography variant='subtitle1'>Choose transfer frequency schedule.</Typography> 
                    : 
                    <TooltipInline text='Choose transfer frequency schedule.' color='theme.primary' variant='subtitle1' tip='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.' />
                  }
                </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ mt: 2, mb: 2, width: '100%' }}>
                        <TextField
                          select
                          id="frequency"
                          name="frequency"
                          value={formik.values.frequency}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onClick={(event) => {handleSelectClick(event, 'frequency')}}
                          error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                          label="Transfer Frequency"
                          sx={{ width: '100%' }}
                        >
                          {availableFrequencies?.length === 0 &&
                            <LoadingIcon contained noText />
                          }
                          <MenuItem value="daily" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('daily') && isMatch}>Daily (Monday-Friday)&nbsp;<span className="recommended">&nbsp;Recommended</span></MenuItem>
                          <MenuItem value="weekly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('weekly') && isMatch}>Weekly</MenuItem>
                          <MenuItem value="monthly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('monthly') && isMatch}>Monthly</MenuItem>
                          <MenuItem value="quarterly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('quarterly') && isMatch}>Quarterly</MenuItem>
                          <MenuItem value="once" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('once') && isMatch}>Once</MenuItem>
                          {(availableFrequencies?.length < 5) && isMatch && availableFrequencies?.length > 0 &&
                            <Typography variant="caption" sx={{ display: 'inline-block', pl: 2, pr: 2, pt: 1 }}>Contact LENS to find out how to add a frequency not<br/>available to your organization.</Typography>
                          }
                        </TextField>
                        {formik.touched.frequency && Boolean(formik.errors.frequency) && <FormHelperText sx={{ ml: -1 }} error><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Frequency selection is required.</FormHelperText>}
                        {!isMatch &&
                          <FormHelperText>Schedule informs LENS when to expect your list.</FormHelperText>
                        }
                        {isMatch && formik.values.frequency === "daily" && <>
                          <FormHelperText>Schedule informs LENS on when to send your matches.</FormHelperText>
                          <HolidayCalendarCheckbox formik={formik}/>
                          </>
                        }
                        {isMatch && (formik.values.frequency === 'once' ?
                            <FormHelperText>Matches sent once, 1-2 days after recieving your list.</FormHelperText>
                            :
                            formik.values.frequency !== 'daily' && <FormHelperText>Schedule informs LENS on when to send your matches.</FormHelperText>
                          )
                        }
                        </FormControl>
                        {isMatch && formik.values.frequency === 'quarterly' &&
                          <QuarterlyDates formik={formik} quarterlyErrors={quarterlyErrors} setQuarterlyErrors={setQuarterlyErrors} frequency={frequency} reportDates={reportDates} />
                        }
                    </Grid>

                    {isMatch &&
                      <Grid sx={{ pl: 2, pr: 2, pt: 0, pb: 1 }} item xs={12} sm={6}>
                        {formik.values.frequency === 'once' && <Grid className='once'></Grid>}
                        {formik.values.frequency === 'weekly' &&
                          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }}>
                            <InputLabel htmlFor="frequencyDay">Day of the Week</InputLabel>
                            <Select
                              id="frequencyDay"
                              name="frequencyDay"
                              value={formik.values.frequencyDay}
                              onChange={formik.handleChange}
                              label="File Transfer Frequency"
                              sx={{ width: '100%' }}
                            >
                              <MenuItem value="monday">Monday</MenuItem>
                              <MenuItem value="tuesday">Tuesday</MenuItem>
                              <MenuItem selected value="wednesday">Wednesday</MenuItem>
                              <MenuItem value="thursday">Thursday</MenuItem>
                              <MenuItem value="friday">Friday</MenuItem>
                            </Select>
                            <FormHelperText>Matches sent on this day every week.</FormHelperText>
                          </FormControl>
                        }
                        {formik.values.frequency === 'monthly' &&
                          <FormControl sx={{ mt: 2, mb: 1, width: '100%' }}>
                            <InputLabel htmlFor="frequencyMonth">Day of the Month</InputLabel>
                            <Select
                              id="frequencyMonth"
                              name="frequencyMonth"
                              value={formik.values.frequencyMonth}
                              onChange={formik.handleChange}
                              label="File Transfer Frequency"
                              sx={{ width: '100%' }}
                            >
                              <MenuItem selected value="first day">First Day</MenuItem>
                              <MenuItem value="15th">15th</MenuItem>
                              <MenuItem value="last day">Last Day</MenuItem>
                            </Select>
                            <FormHelperText>Matches sent on this day every month.</FormHelperText>
                          </FormControl>
                        }
                      </Grid>
                    }
                    {availableFrequencies?.includes('dmf-annual') && isMatch &&
                      <Grid item xs={12}>
                        <Box mt={2} mb={3}>
                          <hr/>
                        </Box>
                        <Box mb={2}>
                          <AnnualDmfEdit formik={formik} matchConfig={matchConfig} matchConfigs={matchConfigs} customerConfigs={customerConfigs} />
                        </Box>
                      </Grid>
                    }
                    {!isMatch && data?.transfer?.type === 'sftp' &&
                      <Grid sx={{ pl: 2, pr: 2, pb: 2 }} item xs={12} sm={8}>
                        <Typography variant='body3'>At the transfer frequency, place your list in LENS SFTP subdirectory (<strong>/customer_files</strong>).</Typography>
                      </Grid>}
                    {!isMatch && data?.transfer?.type === 'awsS3' && <Grid className='once' item xs={12} sm={6}></Grid>}
                  </Grid>
              <hr />
              <Grid item textAlign={'center'} sx={{ pt: 2, pb: 3 }}>
                <Button type="submit" variant="contained">Save Changes</Button>
              </Grid>
            </form>
          </FormikProvider>
        </>
      }
    </FreqCardWrapper>
  )
}

DisplayEditFrequency.propTypes = {
  data: PropTypes.object,
  matchConfig: PropTypes.object,
  handleEditClose: PropTypes.func,
  editForm: PropTypes.func,
  prefix: PropTypes.string,
  frequency: PropTypes.string,
  freqDay: PropTypes.string,
  reportDates: PropTypes.array,
  holidayCalendarId: PropTypes.string,
  annualDmf: PropTypes.string,
  custFilePrefix: PropTypes.string,
  configType: PropTypes.string,
  carrierConfig: PropTypes.object,
  tempAssociatedData: PropTypes.object,
  setTempAssociatedData: PropTypes.func,
  availableFrequencies: PropTypes.array,
  removedFrequencies: PropTypes.array,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};

export default DisplayEditFrequency;
