import { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';
import { date } from 'yup'
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import LoadingIcon from 'src/components/Loading/loadingIcon';

import {
  Box,
  Card,
  TextField,
  Button,
  Divider,
  Alert,
  Typography,
  FormHelperText
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'react-i18next';
import { putHolidayCalendars } from 'src/api';
import { DeleteForeverRounded } from '@mui/icons-material';

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);

const DatePickerWrapper = styled(Box)(
  ({ theme }) => `
  .error fieldset, .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border: 1px solid ${theme.palette.error.main} !important;
  }
`
);

const EventDrawer = ({
  carrierId,
  calendarId,
  calendarName,
  event,
  events,
  onAddComplete,
  onCancel,
  onEditComplete,
  range,
  reload
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState(false);

  const getInitialValues = (event, range) => {
    if (event?.start) {
      const eventDate = new Date(event.start).toString();
      return _.merge(
        {},
        {
          allDay: true,
          start: eventDate,
          title: '',
          submit: null
        },
        event
      );
    }

    if (event?.date) {
      return _.merge(
        {},
        {
          allDay: true,
          start: event.date,
          title: event.title,
          submit: null
        },
        event
      );
    }

    return {
      allDay: true,
      start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      title: '',
      submit: null
    };
  };

  const putCalendar = async function (carrierId, payload, isCreating, data, action) {
    try {
      setGenericError(false);
      setIsLoading(true);
      const results = await putHolidayCalendars(carrierId, payload);
      if (results.error) {
        setGenericError(true);
        setIsLoading(false);
      }
      if (results?.calendar) {
        reload();
        if (isCreating) {
          let displayDate;
          if (data?.start?.$d) {
            displayDate = data.start.$d.toLocaleDateString();
          } else {
            displayDate = data.start.toLocaleDateString();
          }
          let message = data?.title + ' on ' + displayDate + ' added successfully!'
          onAddComplete(message);
        } else {
          let title = event.title;
          let date = event.date.toLocaleDateString();
          if (action === 'modified') {
            title = data.title;
            if (data.start.$d) {
              date = data.start.$d.toLocaleDateString();
            } else {
              date = data.start.toLocaleDateString();
            }
          }
          let message = title + ' on ' + date + ' ' + action + ' successfully!'
          onEditComplete(message);
        }
      }
    }
    catch (e) {
      setGenericError(true);
      setIsLoading(false);
    }
  };

  const reformatCalendar = (calendar) => {
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });

    const _reformattedCalendar = Object.fromEntries(
      calendar?.map(item => {
        const _date = formatter.format(item?.start);
        return [_date, item?.title]
      })
    );
    return _reformattedCalendar;
  }

  const createEvent = (data) => {
    setIsLoading(false);
    let newDate = new Date(dayjs(data?.start));
    const newLocalCalendar = [...events, { id: data?.id, start: newDate, title: data?.title }];
    let reformattedCalendar = reformatCalendar(newLocalCalendar);
    let _payload = { id: calendarId, name: calendarName, calendar: reformattedCalendar };
    putCalendar(carrierId, _payload, isCreating, data);
  }

  const updateEvent = (data) => {
    setIsLoading(false);
    let modifiedEvents = [...events];
    let date;
    if (data.start.$d) {
      date = data.start.$d
    } else {
      date = data.start
    }
    modifiedEvents[event.id] = { id: Number(event?.id), start: date, title: data?.title }
    let reformattedCalendar = reformatCalendar(modifiedEvents);
    let _payload = { id: calendarId, name: calendarName, calendar: reformattedCalendar };
    putCalendar(carrierId, _payload, isCreating, data, 'modified');
  }

  const deleteEvent = () => {
    setIsLoading(false);
    const filteredCalendar = events.filter((item) => (item.id.toString() !== event.id));
    let reformattedCalendar = reformatCalendar(filteredCalendar);
    let _payload = { id: calendarId, name: calendarName, calendar: reformattedCalendar };
    putCalendar(carrierId, _payload, isCreating, null, 'deleted');
  }

  const isCreating = !event?.title;

  const { t } = useTranslation();

  function getMinDate() {
    return new Date();
  }

  return (
    <Formik
      initialValues={getInitialValues(event, range)}
      validationSchema={Yup.object().shape({
        allDay: Yup.bool(),
        description: Yup.string().max(5000),
        start: Yup.lazy(() => date('Must be a valid date')
          .min(getMinDate(), "Date entered should be after today's date.").required().nullable()
        ),
        title: Yup.string().max(30).required(t('The title field is required'))
      })}
      validateOnMount
      validateOnChange
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            id: (events?.length || 0),
            start: values?.start,
            title: values?.title
          };

          if (event?.title) {
            updateEvent(data);
          } else {
            createEvent(data);
          }
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box p={3}>
            <Typography variant="h4">
              {isCreating
                ? t('Add New Event')
                : t('Edit Calendar Event')}
            </Typography>
          </Box>
          <Divider />
          <Box px={3} py={2} sx={{ minWidth: '660px' }}>
            <Alert className={genericError ? 'show' : 'hide'} severity="error" sx={{ mb: 2, maxWidth: '600px' }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
            {isLoading ? <LoadingIcon contained /> :
              <>
                <TextField
                  sx={{ width: '600px' }}
                  error={Boolean(touched.title && values.title.length < 1)}
                  helperText={touched.title && values.title.length < 1 ? errors.title : '30 character limit'}
                  label={t('Event Title')}
                  name="title"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  inputProps={{ maxLength: 30 }}
                  variant="outlined"
                />
                <DatePickerWrapper>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '600px', borderRadius: '12px' }}
                      className={errors.start ? 'error' : ''}
                      value={dayjs(values.start)}
                      onChange={(date) => setFieldValue('start', date)}
                      label={t('Date')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          name="start"
                        />
                      )}
                    />
                    {errors.start && <FormHelperText error>{errors.start !== "start must be a `date` type, but the final value was: `Invalid Date` (cast from the value `null`)." ? errors.start : 'This field is required.'}</FormHelperText>}
                  </LocalizationProvider>
                </DatePickerWrapper>

                {Boolean(touched.end && errors.end) && (
                  <Alert
                    sx={{
                      mt: 2,
                      mb: 1
                    }}
                    severity="error"
                  >
                    {errors.end}
                  </Alert>
                )}
              </>
            }
          </Box>
          <CardActionsWrapper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2
            }}
          >
            <Box>
              {!isCreating && !isLoading && (
                <Button
                  variant="outlined"
                  sx={{
                    mr: 1
                  }}
                  color="error"
                  onClick={() => deleteEvent()}
                  startIcon={<DeleteForeverRounded />}
                >Delete Event</Button>
              )}
            </Box>
            {!isLoading &&
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    mr: 2
                  }}
                  color="secondary"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {isCreating ? t('Add event') : t('Update Event')}
                </Button>
              </Box>
            }
          </CardActionsWrapper>
        </form>
      )}
    </Formik>
  );
};

EventDrawer.propTypes = {
  event: PropTypes.object,
  carrierId: PropTypes.string,
  calendarId: PropTypes.string,
  calendarName: PropTypes.string,
  events: PropTypes.object,
  setEvents: PropTypes.func,
  range: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  reload: PropTypes.func
};

EventDrawer.defaultProps = {
  onAddComplete: () => { },
  onCancel: () => { },
  onDeleteComplete: () => { },
  onEditComplete: () => { }
};

export default EventDrawer;
