import React from 'react';
import { Skeleton, Box } from '@mui/material';

function SkeletonRow() {
  return (
    <Box sx={{}}>
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "120px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "195px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "100px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "100px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "130px", float: 'left' }} />
      <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 2, width: "80px", float: 'left' }} />
    </Box>
  )
}

export default SkeletonRow;