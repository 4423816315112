import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, styled, Box, Paper, TextField, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl, Alert, Grid, Button } from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';

import { useAppContext } from 'src/AppContext.js';
import { setupCustomerFile } from 'src/api';
import { getCurrentDate } from 'src/utilities/getDate';
import CopyButton from 'src/components/CopyButton';
import { ArrowForward } from '@mui/icons-material';
import { useCurrentUserEmail } from 'src/utilities/getCurrentUser';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import ErrorAlert from 'src/components/ErrorAlert';
import AwsFields from 'src/components/AwsFields';
import SftpCredentials from 'src/components/FormFields/sftpCredentials';
import EncryptionPreferenceField from 'src/components/FormFields/encryptionPreference';
import NotificationPreferences from 'src/components/FormFields/notificationPreferences';
import PrefixField from 'src/components/FormFields/filePrefix';
import TooltipInline from 'src/components/TooltipInline';
import { prefixRestrictionText, prefixRestrictionRegex } from 'src/utilities/filePrefixRestriction';

import * as Constants from 'src/constants';

const validationSchema = yup.object().shape({
  newCustFilePrefix: yup
    .string()
    .matches(prefixRestrictionRegex, prefixRestrictionText)
    .max(24, 'A maximum of 24 characters are allowed')
    .required('File prefix is required')
    .when(['prefixError', 'exactPrefixError'], (prefixError, exactPrefixError) => {
      if (prefixError || exactPrefixError) {
        return yup
          .string()
          .matches(prefixRestrictionRegex, prefixRestrictionText)
          .max(0, '')
          .required('File prefix is required')
      }
    }),
  newCustFileType: yup
    .string()
    .required('Field is required'),
  encryptNewCustFile: yup
    .boolean()
    .when('newCustTransMethod', (newCustTransMethod) => {
      if (newCustTransMethod === 'sftp') {
        return yup.boolean().required()
      }
    }
    ),
  newCustPgpKeyRotation: yup
    .string()
    .when('newCustTransMethod', (newCustTransMethod) => {
      if (newCustTransMethod === 'sftp') {
        return yup.string().required()
      }
    }
    ),
  newCustTransMethod: yup
    .string()
    .required(),
  useExistingCustTransferUser: yup
    .boolean()
    .required('Field is required'),
  newCustUsernameSFTP: yup
    .string()
    .when(['newCustTransMethod'], (newCustTransMethod) => {
      if (newCustTransMethod === 'sftp') {
        return yup.string().max(100, 'A maximum of 100 characters are allowed').matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period").min(3, 'A minimum of 3 characters are allowed').required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  newCustFileArnS3Bucket: yup
    .string()
    .when('newCustTransMethod', (newCustTransMethod) => {
      if (newCustTransMethod === 'awsS3') {
        return yup.string().required('This field is required.')
          .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN entered should begin with: arn:aws:s3:::")
          .matches(/^\S*$/, "Incorrect format entered. ARN should not include spaces.")
      } else {
        return yup.string()
      }
    }
    ),
  newCustFileIamRoleArn: yup
    .string()
    .when(['newCustTransMethod', 'awsAccountID'], (newCustTransMethod, awsAccountID) => {
      if (newCustTransMethod === 'awsS3') {
        return yup.string().required('This field is required.')
          .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
          .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam::<AWSAccountID>')
      } else {
        return yup.string()
      }
    }
    ),
  newCustFileKmsKeyArn: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      return yup
        .string()
        .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
        .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<AWSAccountID>')
    }
    ),
  newCustSshRsaKey: yup
    .string()
    .when(['newCustTransMethod', 'useExistingCustTransferUser'], (newCustTransMethod, useExistingCustTransferUser) => {
      if (newCustTransMethod === 'sftp' && !useExistingCustTransferUser) {
        return yup.string().required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  newCustTransferFreq: yup
    .string()
    .required(),
  newCustExistingEmails: yup
    .array()
    .when("newCustEmails", (newCustEmails) => {
      if (newCustEmails.length < 1) {
        return yup.array().min(1, "Must enter email address");
      }
      if (!newCustEmails[0]) {
        return yup.array().min(1, "Must enter email address");
      }
      return yup.array();
    }),
  newCustEmails: yup
    .array()
    .when("newCustExistingEmails", (newCustExistingEmails) => {
      if (newCustExistingEmails) {
        return yup.array().unique(newCustExistingEmails, '').of(yup.string().email())
      }
      else {
        return yup.array().of(yup.string().email())
      }
    }),
}, ['newCustEmails', 'newCustExistingEmails']);

const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function AddCustomerConfiguration({ customerResult, availableEmailAddresses, customerPrefixes, goToMatchSetup, cancel, usernames, reload }) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [usernameInUseError, setUsernameInUseError] = useState(false);
  const [sshKeyError, setSshKeyError] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [badUsernames, setBadUsernames] = useState([]);
  const inputRef = useRef();
  const sshRef = useRef();
  const date = getCurrentDate();
  const userEmail = useCurrentUserEmail();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const transType = customerResult.transfer?.type;
  const setupCustomerFileApi = async function (values, newCustEmails) {
    const payload = {
      file: {
        prefix: values.newCustFilePrefix,
        type: values.newCustFileType
      },
      transfer: {
        type: values.newCustTransMethod,
        frequency: values.newCustTransferFreq,
      },
      notificationEmails: newCustEmails
    };
    if (values.newCustTransMethod === 'sftp') {
      payload.transfer.sftp = {
        username: values.newCustUsernameSFTP,
      }
      if (values.encryptCustFile === true || values.encryptCustFile === 'true') { // even though yup def is boolean, this can be a string depending on the validation flow
        payload.transfer.sftp.pgpKeyRotationInYears = values.pgpKeyRotation;
      }
      if (values.useExistingCustTransferUser === false || values.useExistingCustTransferUser === 'false') {
        payload.transfer.sftp.sshKey = values.newCustSshRsaKey;
      }
      if (values.encryptNewCustFile === true || values.encryptNewCustFile === 'true') { // even though yup def is boolean, this can be a string depending on the validation flow
        payload.transfer.sftp.pgpKeyRotationInYears = values.newCustPgpKeyRotation;
      }
    }
    if (values.newCustTransMethod === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.newCustFileArnS3Bucket,
        iamRole: values.newCustFileIamRoleArn
      }
      if (values.newCustFileKmsKeyArn) {
        payload.transfer.awsS3.kmsArn = values.newCustFileKmsKeyArn
      }
    }


    return setupCustomerFile(carrierConfig.carrierId, payload);
  };

  const formik = useFormik({
    initialValues: {
      newCustFilePrefix: '',
      newCustFileType: 'json',
      encryptNewCustFile: true,
      newCustPgpKeyRotation: '2',
      newCustTransMethod: transType,
      useExistingCustTransferUser: false,
      newCustUsernameSFTP: '',
      newCustSshRsaKey: '',
      newCustTransferFreq: 'Daily',
      newCustExistingEmails: [],
      newCustEmails: [],
      newCustFileArnS3Bucket: '',
      newCustFileKmsKeyArn: '',
      newCustFileIamRoleArn: '',
      awsAccountID: customerResult?.transfer?.awsS3?.carrierIntegrationAccountId,
      prefixError: false,
      exactPrefixError: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (values.prefixError || values.exactPrefixError) {

          window.scrollTo(0, 0);
          setIsLoading(false);
        } else {
          let _allEmails;
          if (values.newCustExistingEmails.length > 0 && values.newCustEmails.length > 0) {
            _allEmails = values.newCustExistingEmails.concat(values.newCustEmails.filter(x => x));
          } else if (values.newCustExistingEmails.length > 0) {
            _allEmails = values.newCustExistingEmails;
          } else {
            _allEmails = values.newCustEmails;
          }
          for (let i = 0; i < _allEmails.length; i++) {
            if (_allEmails[i] === '') {
              _allEmails.splice(i, 1);
            }
          }
          const result = await setupCustomerFileApi(values, _allEmails);
          if (result.statusCode === 400 && transType === 'sftp') {
            setIsLoading(false);
            if (result.message === 'User already exists') {
              setUsernameInUseError(true);
              setBadUsernames([...badUsernames, formik.values.newCustUsernameSFTP]);
              inputRef.current.scrollIntoView();
            } else if (result.message === 'Unsupported SSH key format') {
              setSshKeyError(true);
              sshRef.current.scrollIntoView();
            } else {
              window.scrollTo(0, 0);
              setGenericError(true);
            }
          }
          else if (result.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGenericError(true);
          }
          else {
            reload();
            setIsLoading(false);
            goToMatchSetup(result);
          }
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  useEffect(() => {
    if (customerPrefixes) {
      const _matchedPrefixes = customerPrefixes.filter(checkPrefixes);
      function checkPrefixes(prefix) {
        return formik.values.newCustFilePrefix.startsWith(prefix) || prefix.startsWith(formik.values.newCustFilePrefix);
      }
      if (_matchedPrefixes.length > 0) {
        if (_matchedPrefixes[0] === formik.values.newCustFilePrefix) {

          formik.values.exactPrefixError = true;
        }
        else {
          formik.values.prefixError = true;
          formik.values.exactPrefixError = false;
        }
      } else {
        formik.values.prefixError = false;
        formik.values.exactPrefixError = false;
      }
    }
  }, [customerPrefixes, formik.values])

  const [currentEmailInit, setCurrentEmailInit] = useState(false);
  const [formInitIsSet, setFormInitIsSet] = useState(false);

  useEffect(() => {
    if ((formik.values.newCustExistingEmails.length === 0) && (currentEmailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("newCustExistingEmails", [userEmail]);
        setCurrentEmailInit(true);
      }
    }
    if (currentEmailInit === true && formInitIsSet === false) {
      setFormInitIsSet(true);
    }
  }, [formik, currentEmailInit, formInitIsSet, userEmail]);

  const validateErrors = () => {
    if (formik.errors) {
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }

  return (
    <>
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />}
      {isLoading && <LoadingIcon />}
      <FormWrapper className="customer-config-form">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
              <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
                <Alert className={genericError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                <Typography variant="overline" color="text.primary">Step 1 of 2</Typography>
                <Typography variant="h5" color="primary">Customer List Setup</Typography>
              </Box>
              <Box sx={{ p: 5, backgroundColor: 'background.paper' }}>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>

                <PrefixField name="newCustFilePrefix" label="File Prefix" formik={formik} prefixError={formik.values.prefixError} exactPrefixError={formik.values.exactPrefixError} isManageConfiguration helperText={'Uniquely name files. Names that build off each other (customer, customer1) are not allowed. 24 character limit. Exclude file extension.'} />

                <Typography className="file-type-cf" variant="body1" color="text.secondary">File Type</Typography>
                <RadioGroup
                  id="newCustFileType"
                  name="newCustFileType"
                  className="file-type-cf-radio"
                  value={formik.values.newCustFileType}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                >
                  <FormControlLabel value="json" control={<Radio />} label="JSON" />
                  <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                </RadioGroup>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ display: 'inline-flex' }} variant="body1" color="text.secondary">File Name Example:  </Typography><TooltipInline placement="right" display='inline-flex' tip="Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily, weekly or monthly." />
                </Box>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{formik.values.newCustFilePrefix}-{date}.{formik.values.newCustFileType}
                </Typography>

                <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Prefix and file type are used for LENS to identify your list.</Typography>

                <Typography variant="subtitle1" color="text.primary">Choose transfer frequency schedule.</Typography>

                <FormControl sx={{ mt: 2, mb: 2 }}>
                  <InputLabel htmlFor="newCustTransferFreq">Transfer Frequency</InputLabel>
                  <Select
                    id="newCustTransferFreq"
                    name="newCustTransferFreq"
                    value={formik.values.newCustTransferFreq}
                    onChange={formik.handleChange}
                    label="File Transfer Frequency"
                    sx={{ width: '400px' }}
                  >
                    <MenuItem className='new-cust-daily' selected value="Daily">Daily (Monday-Friday)&nbsp;<span className="recommended">&nbsp;Recommended</span></MenuItem>
                    <MenuItem className='new-cust-weekly' value="Weekly">Weekly</MenuItem>
                    <MenuItem className='new-cust-monthly' value="Monthly">Monthly</MenuItem>
                    <MenuItem className='new-cust-quarterly' value="Quarterly">Quarterly</MenuItem>
                    <MenuItem className='new-cust-once' value="Once">Once (One time submission)</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Schedule informs LENS on when to expect your list. This can be changed on the List Configurations page after adding new configuration.</Typography>

                <hr />

                <Typography className="trans-location-cf" variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>
                {transType === 'sftp' &&
                  <><Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 4 }}>LENS SFTP Server Hostname:</Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 2, mt: 1 }}><CopyButton display="inline" toolTipText="Copy SFTP Server" textToCopy={customerResult?.transfer?.sftp?.sftpServer} />{customerResult?.transfer?.sftp?.sftpServer}</Typography>

                    <Typography variant="subtitle1" color="text.primary">Port:</Typography>
                    <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{Constants.lensSftpPort}</Typography>

                    <Typography variant="subtitle1" color="text.primary">Subdirectory:</Typography>
                    <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>/customer_files</Typography>

                    <Typography ref={inputRef} variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 4 }}>How would you like to access LENS virtual server instance to transfer your Customer list?</Typography>

                    <SftpCredentials useExistingUser="useExistingCustTransferUser" newUsername="newCustUsernameSFTP" newSSHRsaKey="newCustSshRsaKey" formik={formik} usernameInUseError={usernameInUseError} usernames={usernames} sshRef={sshRef} sshKeyError={sshKeyError} />

                    <EncryptionPreferenceField name="encryptNewCustFile" formik={formik} pgpField="newCustPgpKeyRotation" /> </>}

                {transType === 'awsS3' && <>
                  <AwsFields
                    instructionText={'Provide S3 Bucket, KMS Key and IAM Role ARNs for LENS to access your Customer list.'}
                    showTooltip
                    showLambdaArn
                    accountId={customerResult?.transfer?.awsS3?.carrierIntegrationAccountId}
                    evadataLambdaArn={customerResult?.transfer?.awsS3?.evadataLambdaArn}
                    evadataLambdaRoleArn={customerResult?.transfer?.awsS3?.evadataLambdaRoleArn}
                    cfTemplateFile="carrier-aws-customer-files-option.yml"
                    cfTemplateFileName={"lens-carrier-aws-" + formik.values.newCustFilePrefix + "-files-option.yml"}
                    cfTemplateLabel="Customer File Template"
                  />

                  <TextField
                    fullWidth
                    id="newCustFileArnS3Bucket"
                    name="newCustFileArnS3Bucket"
                    label="S3 Bucket ARN"
                    value={formik.values.newCustFileArnS3Bucket}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newCustFileArnS3Bucket && Boolean(formik.errors.newCustFileArnS3Bucket)}
                    helperText={formik.touched.newCustFileArnS3Bucket && Boolean(formik.errors.newCustFileArnS3Bucket) ? (formik.errors.newCustFileArnS3Bucket) : 'ARN should begin with: arn:aws:s3:::'}
                    sx={{ mb: '16px' }}
                  />

                  <TextField
                    fullWidth
                    id="newCustFileKmsKeyArn"
                    name="newCustFileKmsKeyArn"
                    label="KMS Key ARN (Provide if encrypting file)"
                    value={formik.values.newCustFileKmsKeyArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newCustFileKmsKeyArn && Boolean(formik.errors.newCustFileKmsKeyArn)}
                    helperText={formik.touched.newCustFileKmsKeyArn && Boolean(formik.errors.newCustFileKmsKeyArn) ? formik.errors.newCustFileKmsKeyArn : 'ARN should begin with: arn:aws:kms:'}
                    sx={{ mb: '16px' }}
                  />

                  <TextField
                    fullWidth
                    id="newCustFileIamRoleArn"
                    name="newCustFileIamRoleArn"
                    label="IAM Role ARN"
                    value={formik.values.newCustFileIamRoleArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newCustFileIamRoleArn && Boolean(formik.errors.newCustFileIamRoleArn)}
                    helperText={formik.touched.newCustFileIamRoleArn && Boolean(formik.errors.newCustFileIamRoleArn) ? formik.errors.newCustFileIamRoleArn : 'ARN should begin with: arn:aws:iam::'}
                    sx={{ mb: '16px' }}
                  />
                  <Typography variant="body2" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown. If encrypting list, the KMS Key ARN must be provided for LENS to decrypt.</Typography></>}
                <hr />

                <NotificationPreferences formik={formik} availableEmailAddresses={availableEmailAddresses} existingEmails="newCustExistingEmails" newEmails="newCustEmails" />


              </Box>
              <hr />
              <Box sx={{ pb: 5, pr: 5, pl: 5, backgroundColor: 'background.paper' }}>
                <Grid container>
                  <Grid item sm={6}>
                    <Button onClick={cancel} sx={{ mt: 3 }} variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Button onClick={validateErrors} type="submit" sx={{ mt: 3 }} variant="contained" disabled={false} endIcon={<ArrowForward />}>
                      Save & Next Step
                    </Button>
                  </Grid>
                  {formik.values.errors}
                </Grid>
              </Box>
            </Paper>
          </form>
        </FormikProvider>
      </FormWrapper>
    </>
  )
}

AddCustomerConfiguration.propTypes = {
  customerResult: PropTypes.object,
  availableEmailAddresses: PropTypes.array,
  customerPrefixes: PropTypes.array,
  goToMatchSetup: PropTypes.func,
  cancel: PropTypes.func,
  usernames: PropTypes.array,
  reload: PropTypes.func
};

export default AddCustomerConfiguration;