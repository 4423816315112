import React from 'react';
import PropTypes from 'prop-types';

import { TextField, FormHelperText } from '@mui/material';

function SubdirectoryField({ formik, label, name, enableSubPath }) {
  return (
    <>
      <TextField
        fullWidth
        id={name}
        name={name}
        label={label}
        type="text"
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        value={formik?.values?.[name]}
        error={formik?.touched?.[name] && Boolean(formik?.errors?.[name])}
        helpertext={'Example: evadata/matches'}
        sx={{ mb: 0, ml: 0 }}
      />
      <FormHelperText sx={{ mt: '5px', mb: '16px' }}>Example: evadata/matches/</FormHelperText>
    </>
  )
}

SubdirectoryField.propTypes = {
  formik: PropTypes.object, 
  label: PropTypes.string, 
  name: PropTypes.string,
  enableSubPath: PropTypes.bool
};

export default SubdirectoryField;
