import React, { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import { RequiredAuth } from './RequiredAuth';
import { useAppContext } from 'src/AppContext.js';

import VerifiedDeathsFile from 'src/pages/VerifiedDeathsFile';
import CustomerFile from 'src/pages/CustomerFile';
import MatchResultsFile from 'src/pages/MatchResultsFile';
import MaterialsDemo from 'src/components/MaterialsDemo';
import GettingStarted from 'src/pages/GettingStarted';
import CategoryDefinitions from 'src/pages/CategoryDefinitions';
import NotFound404 from 'src/pages/404';
import ErrorPage500 from 'src/pages/500';
import CustomerFileStatus from 'src/pages/CustomerFileStatus';
import VerifiedDeathsFileStatus from 'src/pages/VerifiedDeathsFileStatus';
import MatchesFile from 'src/pages/MatchesFile';
import OnboardingWelcome from 'src/pages/OnboardingWelcome';
import CustomerFileSetup from 'src/pages/CustomerFileSetup';
import VerifiedDeathsFileSetup from 'src/pages/VerifiedDeathsFileSetup';
import MatchResultsFileSetup from 'src/pages/MatchResultsFileSetup';
import InitialLoader from 'src/components/InitialLoader';
import ProcessOverview from 'src/pages/ProcessOverview';
import ManageConfigurations from 'src/pages/ManageConfigurations';
import TransferLocations from 'src/pages/TransferLocations';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import OktaSecurity from './OktaSecurity';
import EncryptionKeys from 'src/pages/EncryptionKeys';
import ActiveListSummary from 'src/pages/ActiveListSummary';
import ActivityLog from 'src/pages/ActivityLog';
import NotAuthorized401 from 'src/pages/401';
import HelpfulGuidance from 'src/pages/HelpfulGuidance';
import ApplicationsCalendar from 'src/pages/Calendar';
import EvadatasMatchCategories from 'src/pages/EvadatasMatchCategories';
import ReleaseNotes from 'src/pages/ReleaseNotes';

const AppRoutes = () => {
  const { carrierConfig } = useAppContext();
  const [onboardingStage, setOnboardingStage] = useState('pending');
  const { lensRoles } = useAppContext();

  useEffect(() => {
    if (carrierConfig?.onBoarding) {
      if (carrierConfig?.onBoarding.customer_file_completed && !carrierConfig.onBoarding.death_file_completed && !carrierConfig.onBoarding.match_file_completed) {
        setOnboardingStage('cust-setup-completed');
      }
      if (carrierConfig?.onBoarding.customer_file_completed && carrierConfig.onBoarding.death_file_completed && !carrierConfig.onBoarding.match_file_completed) {
        setOnboardingStage('vfd-setup-completed');
      }
      if (carrierConfig?.onBoarding.customer_file_completed && carrierConfig.onBoarding.death_file_completed && carrierConfig.onBoarding.match_file_completed) {
      setOnboardingStage('match-setup-completed');
      }
    }
  }, [carrierConfig?.onBoarding])

  const router = createBrowserRouter(
    createRoutesFromElements (
      <Route element={<OktaSecurity/>} errorElement={<ErrorPage500/>}>
        <Route path='/' element={<RequiredAuth/>}>
          <Route path='/' element={<InitialLoader/>}>
          { carrierConfig?.status === 'prospect' && 
            <Route path='/' element={<ProcessOverview />} />
          }
          { (carrierConfig?.status === 'on-boarding') && !lensRoles?.includes('administrator') && 
            <Route path='/' element={<ProcessOverview />} />
          }
          { (carrierConfig?.status === 'on-boarding') && (onboardingStage === 'pending') && 
            <Route path='/' element={<OnboardingWelcome />} />
          }
          { (carrierConfig?.status === 'on-boarding') && (onboardingStage === 'cust-setup-completed') && 
            <Route path='/' element={<CustomerFileSetup />} />
          }
          { (carrierConfig?.status === 'on-boarding') && (onboardingStage === 'vfd-setup-completed') && 
            <Route path='/' element={<VerifiedDeathsFileSetup />} />
          }
          { (carrierConfig?.status === 'on-boarding') && (onboardingStage === 'match-setup-completed') && 
            <Route path='/' element={<MatchResultsFileSetup />} />
          }
          { carrierConfig?.status === 'live' && 
            <Route path='/' element={<ActiveListSummary />} />
          }
          <Route path='/process-overview' element={<ProcessOverview />} />
          <Route path='/getting-started' element={<GettingStarted />} />
          <Route path='/helpful-guidance' element={<HelpfulGuidance />} />
          <Route path='/customer-file' element={<CustomerFile />} />
          <Route path='/verified-deaths-file' element={<VerifiedDeathsFile />} />
          <Route path='/match-results-file' element={<MatchResultsFile />} />
          <Route path='/category-definition' element={<CategoryDefinitions />} />
          <Route path='/customer-file-status' element={<CustomerFileStatus />} />
          <Route path='/verified-deaths-file-status' element={<VerifiedDeathsFileStatus/>} />
          <Route path='/matches-file' element={<MatchesFile />} />
          <Route path='/onboarding-welcome' element={<OnboardingWelcome />} />
          <Route path='/customer-file-setup' element={<CustomerFileSetup />} />
          <Route path='/verified-deaths-file-setup' element={<VerifiedDeathsFileSetup />} />
          <Route path='/match-results-file-setup' element={<MatchResultsFileSetup />} />
          <Route path='/manage-configurations' element={<ManageConfigurations />} />
          <Route path='/transfer-locations' element={<TransferLocations />} />
          <Route path='/encryption-keys' element={<EncryptionKeys />} />
          <Route path='/active-list-summary' element={<ActiveListSummary />} />   
          <Route path='/activity-log' element={<ActivityLog />}/> 
          <Route path='/holiday-calendar' element={<ApplicationsCalendar/>}/> 
          <Route path='/evadatas-match-categories' element={<EvadatasMatchCategories />}/>
          <Route path='/release-notes' element={<ReleaseNotes />}/>
          </Route>
        </Route>
        <Route path='/login/callback' element={<LoginCallback loadingElement={<LoadingIcon />}  errorComponent={NotAuthorized401} />} />
        <Route path='*' element={<NotFound404 />} />
        <Route path='500' element={<ErrorPage500 />} />
        <Route path='401' element={<NotAuthorized401 />} />
        <Route path='/demo' element={<MaterialsDemo/>} />
        <Route path='/loading' element={<InitialLoader />} />
      </Route>
    )
  );

  return(
    <RouterProvider router={router}/>
  );
};

export default AppRoutes;
