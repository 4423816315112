import { React } from 'react';

import PgpKeyTable from './pgpKeyTable';
import PropTypes from 'prop-types';

function DisplayCustomerPgpTables({ uniqueKeyGroups, viewDetails }) {

  return (
    <>
      {uniqueKeyGroups.map((uniqueKeyGroup) =>
        <PgpKeyTable key={uniqueKeyGroup?.record?.file?.prefix} uniqueKeyGroup={uniqueKeyGroup} viewDetails={() => viewDetails(uniqueKeyGroup)} ></PgpKeyTable>
      )}
    </>
  )
}

DisplayCustomerPgpTables.propTypes = {
  uniqueKeyGroups: PropTypes.array,
  viewDetails: PropTypes.func
};

export default DisplayCustomerPgpTables;
