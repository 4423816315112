import { React } from 'react';
import { Grid, Card, styled, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip } from '@mui/material';
import CopyButton from '../../../CopyButton';
import { FileDownloadRounded } from '@mui/icons-material';
import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import DisplayFileSample from 'src/components/DisplayFileSample';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import PropTypes from 'prop-types';

import * as Constants from 'src/constants';

const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
    }
    .lock-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .account-id-row {
      border-bottom: 1px solid ${theme.palette.common.black}66;
    }
    .pgp-button {
      margin-left: -10px;
      font-weight:500;
      color: grey;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    th {
      color: ${theme.palette.common.black};
      font-size: 15px;
    }
    tr {
      border-top: 2px solid ${theme.palette.common.black}33;
    }
    tr.MuiTableRow-head {
      border-top: 3px solid ${theme.palette.common.black}33;
      border-bottom: 1px solid ${theme.palette.common.black}90;
    }
  `
);

function SftpServer(data) {
  const sftpServer = data?.transfer?.sftp?.host || data?.transfer?.sftp?.sftpServer;
  const port = data?.transfer?.sftp?.port || '22';
  return (
    <>
      <Typography display='inline' variant='subtitle1' color='text.primary' paddingRight={1}>{sftpServer}</Typography>
      <Typography display='inline' variant='subtitle1' color='text.secondary'>Port: </Typography>
      <Typography display='inline' variant='subtitle1' color='text.primary'>{port}</Typography>
    </>
  )
}

function SftpServerMatch(data) {
  const sftpServer = data?.transfer?.sftp?.host || data?.transfer?.sftp?.sftpServer;

  return (
    <>
      <CopyButton toolTipText="Copy SFTP Server" textToCopy={sftpServer} display="inline" paddingLeft={3} color="primary"/>
      <Typography display='inline' variant='subtitle1' color='text.primary' paddingRight={1}>{sftpServer}</Typography>
      <Typography display='inline' variant='subtitle1' color='text.secondary'>Port: </Typography>
      <Typography display='inline' variant='subtitle1' color='text.primary'>{Constants.lensSftpPort}</Typography>
    </>
  )
}

function SftpKeyTable({ vfdData, transferFreq, viewDetails, allData = [], allVfdData = [], displayType = 'lensSftp' }) {

  // Rewrite this to make sure each element downloads its own key.
  function downloadSshKey(data) {
    downloadTxtFile(data?.transfer?.sftp?.ssh?.publicKey, 'ssh-' + data?.file?.prefix + '.txt')
  }

  return (
    <CardWrapper>
      <Card sx={{ minWidth: 275, textAlign: 'left', mt: 3, mb: 3, }}>
        <Grid container>
          <Grid item xs={10} sx={{ p: 2, }}>
            <Typography variant="subtitle1" display='inline' color='text.secondary'>{displayType === 'lensSftp' ? 'LENS SFTP: ' : 'My SFTP: '}
              {(displayType === 'lensSftp') && SftpServerMatch(allData[0])}
              {(displayType === 'mySftp') && SftpServer(allData[0])}
            </Typography>
            <Typography variant="body2">{displayType === 'lensSftp' ? 'Transfer your list(s) to LENS SFTP.' : 'LENS uses this SFTP to transfer your matches.'}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ p: 2, textAlign: 'right' }}>
            <Button variant="outlined" color="primary" onClick={viewDetails}>Manage</Button>
          </Grid>
        </Grid>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: '7px' }}>Lists Using This Location</TableCell>
              <TableCell sx={{ py: '7px' }} align="center">SFTP Username</TableCell>
              <TableCell sx={{ py: '7px' }} align="center">Server Authentication</TableCell>
              <TableCell sx={{ py: '7px' }} align="center">Subdirectory {allData[0]?.configurationType === 'matches' && 'Path'}</TableCell>
              <TableCell sx={{ py: '7px' }} align="center">Transfer Schedule</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map(data => (
              <TableRow key={data?.file?.prefix + '-' + data?.file?.configurationType}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <DisplayFileSample nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} type={data?.file?.type} />
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {data?.transfer?.sftp?.username}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {(data?.configurationType !== 'matches') && 'SSH-RSA Key'}
                  {(data?.configurationType === 'matches' && data?.transfer?.sftp?.ssh?.publicKey) &&
                    <>
                      SSH-RSA Key
                      <CopyButton display="inline" toolTipText="Copy Public SSH-RSA Key" paddingLeft="35px" textToCopy={data?.transfer?.sftp?.ssh?.publicKey} />
                      <Button className="pgp-button" onClick={() => downloadSshKey(data)} ><Tooltip arrow placement="right" title='Download Public SSH-RSA Key'><FileDownloadRounded sx={{ color: 'grey', ml: 0 }} /></Tooltip></Button>
                    </>
                  }
                  {(data?.configurationType === 'matches' && !data?.transfer?.sftp?.ssh?.publicKey) && 'Password'}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {data?.configurationType === 'customer_records' && '/customer_files'}
                    {(data?.configurationType === 'matches' && data?.transfer?.sftp?.path !== '/') && data?.transfer?.sftp?.path}
                    {(data?.configurationType === 'matches' && (!data?.transfer?.sftp?.path || data?.transfer?.sftp?.path === '/')) && '—'}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ textTransform: 'capitalize' }}>{switchFreqDisplay(data?.transfer?.frequency)}{(data?.configurationType === 'matches' && data?.transfer?.frequencyDay) ? ' (' + data?.transfer?.frequencyDay + ')' : ''}</TableCell>
              </TableRow>
            ))}

            {allVfdData?.map(vfdData => (
              <TableRow
                key={vfdData?.file?.prefix + '-' + vfdData?.configurationType} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <DisplayFileSample nickname={vfdData?.nickname} prefix={vfdData?.file?.prefix} type={vfdData?.file?.type} />
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {vfdData?.transfer?.sftp?.username}
                  </Typography>
                </TableCell>
                <TableCell align="center">SSH-RSA Key</TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    /death_files
                  </Typography>
                </TableCell>
                <TableCell align="center">{switchFreqDisplay(vfdData?.transfer?.frequency)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </CardWrapper>
  )
}

SftpKeyTable.propTypes = {
  vfdData: PropTypes.object,
  transferFreq: PropTypes.string,
  viewDetails: PropTypes.func,
  allData: PropTypes.array,
  allVfdData: PropTypes.array,
  displayType: PropTypes.string
};

export default SftpKeyTable;