import React from 'react';
import { Typography } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

function CheckItem(props) {
  return (

    <>
    <Typography variant='body2' sx={{ mb: '-8px' }}><CheckRounded className='check' /><span className='copy'>{props.copy}</span></Typography><br />
  </>

  )
}
CheckItem.propTypes = {
  copy: PropTypes.string
}

export default CheckItem;