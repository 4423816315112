import React from 'react';

import DisplayTransferAndEncryption from 'src/components/DisplayTransferAndEncryption';

function EncryptionKeys() {
  return (
    <DisplayTransferAndEncryption keyType="encryption" />
  );
}

EncryptionKeys.propTypes = {};

export default EncryptionKeys;