import React from 'react';
import SkeletonRow from './skeletonRow';

function SkeletonRows() {

  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
}

export default SkeletonRows;