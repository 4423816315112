import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Grid, Button, styled, Box, IconButton } from '@mui/material';
import {FileDownloadRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { downloadTxtFile } from 'src/utilities/downloadTextFile';
import CopyButton from '../CopyButton';

const PgpWrapper = styled(Box)(
  () => `
    .pgp-partial {
        max-height: 150px;
        overflow-y: hidden;
        word-break: break-word;
      }
      .copy-click-wrapper {
        display: inline-block;
      }
      pre {
        white-space: break-spaces !important;
        word-break: break-word;
      }
      .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: capitalize;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
  `
);

function DisplayPgpFilePreference({ pgpKeyRotationInYears, pgpPublicKey, pgpKeyLabel, showButtons, buttonText, fileName }) {

  const [showFullPgp, setShowFullPgp] = useState(false);
  const [showKey, setShowKey] = useState(false);

  function togglePgpVisibility() {
    setShowFullPgp(!showFullPgp);
  }

  const dotText = '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••';

  function handleClickShowKey() {
    setShowKey(!showKey);
  }

  function downloadText() {
    downloadTxtFile(pgpPublicKey, fileName);
  }

  const buttons = <Grid item sm={8} sx={{ textAlign: 'right' }}>
    {/* <div className='copy-click-wrapper' ref={wrapperRef}>{wasCopied ? confirmCopied : copyBtn}</div> */}
    <div className='copy-click-wrapper'><CopyButton buttonText="Copy Key"  toolTipText="Copy PGP Key" textToCopy={pgpPublicKey}></CopyButton></div>
    <Button className="pgp-button" onClick={downloadText} sx={{ marginTop: '-5px', marginRight: '-10px', marginLeft: '-10px' }}>Download {buttonText}<FileDownloadRounded sx={{ ml: 1 }} /></Button>
  </Grid>

  return (
    <PgpWrapper>
      {pgpKeyRotationInYears > 0 ?
        <>
          <Typography variant="subtitle1" color="text.primary">PGP Key Rotation:</Typography>
          <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{pgpKeyRotationInYears} years</Typography>
        </> : ''
      }
      <Grid container>
        <Grid item sm={4} >
          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 0 }}>{pgpKeyLabel || 'Generated Public PGP Key'}</Typography>
        </Grid>

        {showButtons ?
          <>
            {buttons}
          </>
          : ''}

      </Grid>
      <Grid container>
        <Grid item sm>
          <Typography className={showFullPgp ? '' : 'pgp-partial'} variant="body1" color="text.primary" sx={{ mt: '-20px' }}><pre>{showKey ? pgpPublicKey : dotText}</pre></Typography>
        </Grid>
        <Grid item sm={1}>
          <IconButton onClick={handleClickShowKey}>
            {showKey ? <Visibility sx={{ color: '#666' }} /> : <VisibilityOff sx={{ color: '#666' }} />}
          </IconButton>
        </Grid>
      </Grid>

      <Button className="pgp-button show-hide" variant="text" size="medium" sx={{ mb: 2 }} onClick={togglePgpVisibility}>Show {showFullPgp ? 'Less' : 'More'}</Button>
    </PgpWrapper>
  );
}

DisplayPgpFilePreference.propTypes = {
  pgpKeyRotationInYears: PropTypes.number.isRequired,
  pgpPublicKey: PropTypes.string.isRequired,
  showButtons: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  pgpKeyLabel: PropTypes.string,
  buttonText: PropTypes.string
};

export default DisplayPgpFilePreference;