import React from 'react';

import { Grid, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function EmptyState(props) {

  function defaultText() {
    return (
      <>
        The files you submit will display here. <br /> Learn how to setup and submit files.
      </>
      )
    }

  return (
    <Grid container spacing={3} sx={{ mt: '150px', mb: 3, pr: 3, textAlign: 'center' }}>
      <Grid item sm={12}>
        {props.icon}
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" sx={{ mb: 3 }}>{props.headerText ? props.headerText : 'Welcome, lets get started!'}</Typography>
        <Typography variant="body1" sx={{ mb: 3}}>{props.bodyText ? props.bodyText : defaultText()}</Typography>
        <Button LinkComponent={Link} to="/getting-started" variant="outlined" color="primary" size="small">Get Started</Button>
      </Grid>
    </Grid>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.element,
  headerText: PropTypes.string,
  bodyText: PropTypes.string
};

export default EmptyState;