import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Box, styled, ThemeProvider } from '@mui/material';
import Sidebar from '../Sidebar';
import '../../App.css';
import CssBaseline from "@mui/material/CssBaseline";

import { SidebarProvider } from 'src/contexts/SidebarContext';
import AppBar from '../AppBar';
import Footer from '../Footer';
import { EvadataTheme } from 'src/theme/schemes/EvadataTheme';
import { GreenFieldsTheme } from 'src/theme/schemes/GreenFieldsTheme';
import ThemeSettings from 'src/components/ThemeSettings';
import ScrollToTop from '../ScrollToTop';

const MainContentWrapper = styled(Box)(
  () => `
    margin-bottom: 20px;
    text-align: center;
    min-height: calc(100vh - 190px);
    margin-left: 288px;

    @media screen and (max-width: 1279px) {
      margin-left: 0px;
    }
`
);

const LayoutWrapper = styled(Grid)(
  () => `
    hr {
      height: 1px;
      background-color: #ccc;
      border: none;
    }
`
);



function Layout(props) {

  const [data, setData] = useState('EvadataTheme');

  function childToParent(childData) {
    setData(childData);
  };

  return (
    <LayoutWrapper>
      <Grid className='primary-layout' container spacing={2} justifyContent="center" textAlign="left">
        <ScrollToTop />
        <ThemeProvider theme={data === 'EvadataTheme' ? EvadataTheme : GreenFieldsTheme}>
          <CssBaseline />
          <AppBar />
          <Grid item>
            <SidebarProvider>
              <Sidebar></Sidebar>
            </SidebarProvider>
          </Grid>
          <Grid item sm={12} sx={{ mt: '67px' }}>
            <MainContentWrapper sx={{ px: 3, textAlign: 'left' }}>
              {props.children}
            </MainContentWrapper>
            <Footer />
          </Grid>
          <ThemeSettings childToParent={childToParent} />
        </ThemeProvider>
      </Grid>
    </LayoutWrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout;