import { React } from 'react';
import { Typography, Card} from '@mui/material';
import PropTypes from 'prop-types';

function DisplayAccountId({ data }) {

  return (
    <Card sx={{ p: 2, mb: 3 }}>
      <Typography variant="body1"><strong>AWS Account ID:</strong> {data?.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
    </Card>
  )
}

DisplayAccountId.propTypes = {
  data: PropTypes.object
};

export default DisplayAccountId;