import React, { useEffect, useState } from 'react';
import { Typography, styled, Box, Paper } from '@mui/material';
import OnboardingStepper from 'src/components/Stepper';
import MatchResultsFileSetupForm from './matchResultsFileSetupForm';
import MatchResultsFileSetupSummary from './MatchResultsFileSetupSummary';
import { getEmailAddresses, getMatchFileConfigurations, getCustomerFileConfigurations, getLambdaStatus, getDeathFileConfigurations, getHolidayCalendars, getTransferFrequencies } from 'src/api';
import { useAppContext } from 'src/AppContext.js';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { useNavigate } from 'react-router-dom';

const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
    .sources {
      text-transform: uppercase;
    }
  `
);

function Loading() {
  return (
    <LoadingIcon />
  );
}

function MatchResultsFileSetup() {
  const [result, setResult] = useState(null);
  const [deathResult, setDeathResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [availableEmailAddresses, setAvailableEmailAddresses] = useState([null]);
  const [customerFileConfig, setCustomerFileConfig] = useState(null);
  const [evadataMatchLambdaRoleArn, setEvadataMatchLambdaRoleArn] = useState(null);
  const [holidayCalendarId, setHolidayCalendarId] = useState(null);
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const { lensRoles } = useAppContext();
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  useEffect(() => {
    const getConfigurations = async function (carrierId) {
      const configs = await getMatchFileConfigurations(carrierId);
      if (configs.length > 0) {
        setResult(configs[0]);
      }
    };
    const _getDeathConfigurations = async function (carrierId) {
      const configs = await getDeathFileConfigurations(carrierId);
      if (configs.length > 0) {
        setDeathResult(configs[0]);
      }
    };
    const getEmails = async function (carrierId) {
      const emails = await getEmailAddresses(carrierId);
      const emailsWithId = emails.map((email, index) => ({ 'id': index, 'email': email }));
      setAvailableEmailAddresses(emailsWithId);
    };
    const getHolidayCalendarId = async function (carrierId) {
      const calendars = await getHolidayCalendars(carrierId);
      setHolidayCalendarId(calendars[0]?.id);
    };
    const getFrequencies = async function (carrierId) {
      const { scanFrequencies } = await getTransferFrequencies(carrierId);
      setAvailableFrequencies(scanFrequencies);
    };
    const _getExistingConfigurations = async function (carrierId) {
      const configs = await getCustomerFileConfigurations(carrierId);

      if (configs.length > 0) {
        setCustomerFileConfig(configs[0]); // we are only supporting one config for now

        const payload = {
          accountId: configs[0].transfer?.awsS3?.carrierIntegrationAccountId,
          copyEnabled: "true"
        }

        const lambdaConfig = await getLambdaStatus(carrierId, payload);

        if (lambdaConfig.status === 'complete') {
          setEvadataMatchLambdaRoleArn(lambdaConfig.evadataMatchLambdaRoleArn);
        }
      }
    }

    if (carrierConfig?.carrierId) {
      Promise.all([
        getConfigurations(carrierConfig.carrierId),
        getEmails(carrierConfig.carrierId),
        getHolidayCalendarId(carrierConfig.carrierId),
        getFrequencies(carrierConfig.carrierId),
        _getDeathConfigurations(carrierConfig.carrierId),
        _getExistingConfigurations(carrierConfig.carrierId)
      ]).finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId]);

  return (
    <FormWrapper>
      <OnboardingStepper step={2} customerResult={customerFileConfig} deathResult={deathResult} matchResult={result} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 3</Typography>
          <Typography variant="h5" color="primary.text">{result ? 'Summary of Match Results' : 'Match Results Setup'}</Typography>
        </Box>
        {(!result && !isLoading) && <MatchResultsFileSetupForm
          setResult={setResult}
          availableEmailAddresses={availableEmailAddresses}
          customerFilePrefix={customerFileConfig.file.prefix}
          customerFilePgp={customerFileConfig.pgp?.publicKey}
          transferType={customerFileConfig?.transfer?.type}
          accountId={customerFileConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
          evadataMatchLambdaRoleArn={evadataMatchLambdaRoleArn}
          holidayCalendarId={holidayCalendarId}
          availableFrequencies={availableFrequencies}
          setShowCompletedAlert={setShowCompletedAlert}
        />}
        {(result && !isLoading) && <MatchResultsFileSetupSummary
          file={result.file}
          transfer={result?.transfer}
          holidayCalendarId={result?.holidayCalendarId}
          notificationEmails={result.notificationEmails}
          pgp={result.pgp}
          maxMatches={result.maxMatches}
          sources={result.sources}
          accountId={customerFileConfig.transfer?.awsS3?.carrierIntegrationAccountId}
          categories={result.categories}
          availableFrequencies={availableFrequencies}
          showCompletedAlert={showCompletedAlert}
        />}
        {isLoading && <Loading />}
      </Paper>
    </FormWrapper>
  );
}

MatchResultsFileSetup.propTypes = {};

export default MatchResultsFileSetup;