import React from 'react';

import MenuSection from '../MenuSection';
import FileExchangesItems from './items';

function FileExchanges() {

  return (
    <>
      {FileExchangesItems().map((section) => (
        <MenuSection key={section?.heading} heading={section?.heading} items={section?.items}></MenuSection>
      ))}
    </>
  );
}

export default FileExchanges;