import React from 'react';
import { Typography, styled, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';

const ErrorWrapper = styled(Box)(
  () => `
    min-height: calc(60vh);
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-content: center;
  `
);

function LoadError({reload}) {

  return (
    <ErrorWrapper>
      <Box sx={{width: '100%', alignSelf: 'center'}}>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>We're having trouble loading the content, please try again.</Typography>
      <Button size="small" variant="contained" color="primary" onClick={reload}>Retry</Button>
      </Box>
    </ErrorWrapper>
  );
}

LoadError.propTypes = {
  reload: PropTypes.func
}

export default LoadError;