import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

export function useCurrentUserEmail(){

  const { oktaAuth, authState } = useOktaAuth();
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async function() {
      const _user = await oktaAuth.getUser();
      setUserEmail(_user.email);
    };

    if(authState?.isAuthenticated) {
      getUser().catch((error) => {
        navigate('/500?page=' + window.location.pathname.replace(/^\/+/g, '') + '&error=' + error);
      });
    } else {
      setUserEmail(null);
    }

    return () => {
      setUserEmail(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated]);

  return userEmail;
  }