import { React, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Typography, Button, Box, Alert, Grid, TextField } from '@mui/material';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { editKmsKeyArn } from 'src/api';
import { useAppContext } from 'src/AppContext';
import TooltipInline from 'src/components/TooltipInline';
import CloudTemplates from 'src/components/CloudTemplates';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const validationSchema = yup.object().shape({
  kmsKeyArn: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      return yup
        .string()
        .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
        .matches(/^\S*$/, "Incorrect format entered. ARN should not include spaces.")
        .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<AWSAccountID>')
        .required('KMS Key ARN is required')
    }
    )
})

function AddKmsKey({ data, reload, setAddingKey, setNewKey, setHasKey, setKeyEnabled }) {
  const { carrierConfig } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: {
      kmsKeyArn: '',
      awsAccountID: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let prefix = data?.file?.prefix;
        let fileLocation = data?.configurationType;
        let payload = {
          kmsArn: values?.kmsKeyArn,
          enableEncryption: true
        }
        const result = await editKmsKeyArn(carrierConfig?.carrierId, fileLocation, prefix, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result?.filePrefix) {
          reload();
          setNewKey(result?.kmsArn);
          setAddingKey(false);
          setHasKey(true);
          setKeyEnabled(true);
          setLoading(false);
        }
      }
      catch (e) {
        setLoading(false);
        throw (e);
      }
    },
  });

  useEffect(() => {
    const setValue = async function () {
      if (data?.transfer?.awsS3?.carrierIntegrationAccountId) {
        await formik.setFieldValue('awsAccountID', data?.transfer?.awsS3?.carrierIntegrationAccountId);
      }
      setInitialized(true);
    }
    if (!initialized) {
      setValue().catch((error) => setGeneralizedError(true));
    }
  }, [formik, setInitialized, data?.transfer?.awsS3?.carrierIntegrationAccountId, initialized])

  return (
    <>
      {loading &&
        <Box sx={{ minHeight: '349px' }}>
          <Loading />
        </Box>
      }
      <Box className={loading ? 'hide' : 'show'}>
        <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ textAlign: 'left', width: '100%', pl: 2, pr: 4 }}>
              <Box sx={{ mb: 4 }} role="fieldset" aria-labelledby="checkbox-group">
                <CloudTemplates data={data} evadataLambdaArn={data?.transfer?.awsS3?.evadataLambdaArn} evadataLambdaRoleArn={data?.transfer?.awsS3?.evadataLambdaRoleArn} textAlign="left" />
                <Grid container >
                  <Grid item xs={12} sx={{ ml: 2 }}>
                    <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0, mb: 2 }}><TooltipInline text="Provide KMS key ARN output value from the Cloud Formation Template." tip="Utilize the AWS Cloud Formation template and Lambdas to create KMS Key ARN." color="text.primary" variant="subtitle1" /></Typography>
                    <TextField
                      fullWidth
                      id="kmsKeyArn"
                      name="kmsKeyArn"
                      label="KMS Key ARN"
                      value={formik.values.kmsKeyArn}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.kmsKeyArn && Boolean(formik.errors.kmsKeyArn)}
                      helperText={formik.touched.kmsKeyArn ? formik.errors.kmsKeyArn : 'ARN should begin with: arn:aws:kms:'}
                      sx={{ mb: 3 }}
                    />
                    <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>The KMS key ARN must be provided for LENS to decrypt your list. </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <hr />
            <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
              <Button type="submit" variant="contained">
                Add Key
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </>
  )
}

AddKmsKey.propTypes = {
  data: PropTypes.object,
  reload: PropTypes.func,
  setAddingKey: PropTypes.func,
  setNewKey: PropTypes.func,
  setHasKey: PropTypes.func,
  setKeyEnabled: PropTypes.func
};

export default AddKmsKey;