import { React, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Button, Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { useAppContext } from 'src/AppContext';
import { generatePgpKey } from 'src/api';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import PgpRotationField from 'src/components/FormFields/pgpRotation';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const validationSchema = yup.object().shape({
  newPgpKeyRotation: yup
    .string().required()
})

function AddPgpKey({ data, reload, setAddingKey, setNewKey, setNewKeyId, setNewRotation, setHasKey, setKeyEnabled }) {
  const { carrierConfig } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);


  const formik = useFormik({
    initialValues: {
      newPgpKeyRotation: '2',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let payload = {
          pgpKeyRotationInYears: values.newPgpKeyRotation,
          enableEncryption: true
        }
        const result = await generatePgpKey(carrierConfig?.carrierId, data?.configurationType, data?.file?.prefix, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result?.pgpId) {
          setAddingKey(false);
          reload();
          setNewKeyId(result?.pgpId);
          setNewKey(result?.publicKey);
          setNewRotation(values?.newPgpKeyRotation);
          setHasKey(true);
          setKeyEnabled(true);
          setLoading(false);
        }
      }
      catch (e) {
        setLoading(false);
        throw (e);
      }
    },
  });

  return (
    <>
      {loading &&
        <Box sx={{ minHeight: '349px' }}>
          <Loading />
        </Box>
      }
      {!loading &&
      <Box>
        {generalizedError &&
          <GeneralErrorAlert />
        }
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ textAlign: 'left', width: '100%', pl: 2, pr: 4  }}>
              <Box sx={{ mb: 4 }} role="fieldset" raria-labelledby="checkbox-group">
                <Grid container >
                  <Grid item xs={12} sx={{ ml: 0 }}>
                    <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>Encrypt list with PGP key created by LENS.</Typography>
                    <PgpRotationField name="newPgpKeyRotation" label="PGP Key Rotation" formik={formik} />
                    <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>The public PGP key will be available to copy or download after it’s generated.</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <hr />
            <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
              <Button type="submit" variant="contained">
                Generate Key
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    }
    </>
  )
}

AddPgpKey.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func, 
  setAddingKey: PropTypes.func, 
  setNewKey: PropTypes.func, 
  setNewKeyId: PropTypes.func, 
  setNewRotation: PropTypes.func, 
  setHasKey: PropTypes.func, 
  setKeyEnabled: PropTypes.func
};

export default AddPgpKey;