import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, FormControlLabel, Radio, RadioGroup, TextField, Typography, Box, InputAdornment, IconButton, OutlinedInput, FormHelperText, Checkbox, FormControl, Alert, MenuItem, InputLabel, Select, Paper, styled, Tooltip } from '@mui/material';
import { Field, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useAppContext } from 'src/AppContext.js';
import { setupMatchFile, getSources, getTransferFrequencies } from 'src/api';
import { getCurrentDate } from 'src/utilities/getDate';
import EmailMultiEntry from 'src/components/EmailMultiEntry';
import EmailCheckboxEntry from 'src/components/EmailCheckboxEntry';
import { InfoRounded, Visibility, VisibilityOff, OpenInNewRounded, ErrorOutline } from '@mui/icons-material';
import AwsFields from 'src/components/AwsFields';
import { useCurrentUserEmail } from 'src/utilities/getCurrentUser';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import ErrorAlert from 'src/components/ErrorAlert';
import SelectCategories from 'src/components/SelectCategories';
import SubdirectoryField from 'src/components/FormFields/subdirectory';
import CopyButton from 'src/components/CopyButton';
import PrefixField from 'src/components/FormFields/filePrefix';
import FileTypeField from 'src/components/FormFields/fileType';
import { prefixRestrictionText, prefixRestrictionRegex } from 'src/utilities/filePrefixRestriction';
import { QuarterlyDates, quarterlyValidationSchema, quarterlyInitialValues } from 'src/components/FormFields/quarterlyDates';
import { combineMonthsDays, combineMonthDay } from 'src/utilities/combineMonthDay';
import TransferFrequencySelect from 'src/components/TransferFrequencySelect';
import { AnnualDmfScanSetup, annualDmfSchema, annualDmfInitialValues } from 'src/components/AnnualDmfScanSetup';

const containsPrivate = (string) => /^((?!PRIVATE).)*$/s.test(string);

const MatchPasswordWrapper = styled(Box)(
  () => `
    .MuiInputBase-root legend span {
      margin-top: -7px;
      display: block;
      opacity: 1;
    }
  `
)

const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function AddMatchFileConfiguration({ customerResults, matchResults, matchResult, availableEmailAddresses, matchPrefixes, finishSetup, customerFilePgp, customerFilePrefix, cancel, holidayCalendarId }) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const date = getCurrentDate();
  const userEmail = useCurrentUserEmail();
  const [showPassword, setShowPassword] = useState();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [availableSources, setAvailableSources] = useState([]);
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const [weakPgpKey, setWeakPgpKey] = useState(false);
  const [quarterlyErrors, setQuarterlyErrors] = useState([false, false, false, false]);

  const validationSchema = yup.object().shape({
    newMatchPrefix: yup
      .string('24 character limit. Exclude file extension.')
      .matches(prefixRestrictionRegex, prefixRestrictionText)
      .max(24, 'A maximum of 24 characters are allowed')
      .required('File prefix is required')
      .when(['prefixError', 'exactPrefixError'], (prefixError, exactPrefixError) => {
        if (prefixError || exactPrefixError) {
          return yup
            .string()
            .matches(prefixRestrictionRegex, prefixRestrictionText)
            .max(0, '')
            .required('File prefix is required')
        }
      }),
    newMatchFileType: yup
      .string()
      .required('This field is required'),
    newMatchFrequency: yup
      .string()
      .required('This field is required'),
    newMatchFrequencyDay: yup
      .string(),
    newMatchFrequencyMonth: yup
      .string(),
    ...quarterlyValidationSchema(true, 'newMatchFrequency', quarterlyErrors),
    assignHolidayCalendar: yup
      .boolean(),
    ...annualDmfSchema,
    newMatchSource: yup
      .array()
      .min(1, 'Field is required'),
    newMatchSelectedCategories: yup
      .array()
      .min(1),
    newMatchTransMethod: yup
      .string()
      .required(),
    newMatchSftpAddress: yup
      .string()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string()
            .matches(/^(?!https:\/\/).*$/, "Do not include https:// at the beginning of the SFTP address.")
            .matches(/^(?!http:\/\/).*$/, "Do not include http:// at the beginning of the SFTP address.")
            .matches(/^(?!^\d+$).*$/, "Incorrect format entered.") //do not allow hostname that is all numbers
            .matches(/^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/, "Incorrect format entered.")
            .required('This field is required.')
        } else {
          return yup.string()
        }
      }
      ),
    newMatchPortNum: yup
      .string()
      .matches(/^\d+$/, "Numeric only")
      .max(5)
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    newSubdirectory: yup
      .string(),
    newMatchUseSshKey: yup
      .boolean()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.boolean().required()
        } else {
          return yup.boolean()
        }
      }
      ),
    newMatchUsernameSFTP: yup
      .string()
      .max(100, 'A maximum of 100 characters are allowed')
      .matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period")
      .min(3)
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    newMatchPassword: yup
      .string()
      .when(['newMatchUseSshKey', 'newMatchTransMethod'], (newMatchUseSshKey, newMatchTransMethod) => {
        if (!newMatchUseSshKey && newMatchTransMethod) {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }),
    newMatchPgpKey: yup
      .string()
      .max(6000, 'Public key exceeds the character limit (maximum is 6000 characters). Provide only one public key.')
      .matches(/BEGIN PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
      .matches(/END PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
      .test(
        'PGP key is invalid. Provide only the public key.',
        'PGP key is invalid. Provide only the public key.',
        (value) => containsPrivate(value)
      )
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    newMatchFileArnS3Bucket: yup
      .string()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'awsS3') {
          return yup.string().required('This field is required')
            .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
            .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        } else {
          return yup.string()
        }
      }
      ),
    newMatchFileIamRoleArn: yup
      .string()
      .when(['newMatchTransMethod', 'awsAccountId'], (newMatchTransMethod, awsAccountId) => {
        if (newMatchTransMethod === 'awsS3') {
          return yup.string()
            .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
            .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
            .containsId(awsAccountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam:<AWSAccountID>')
            .required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    newMatchExistingEmails: yup
      .array()
      .when("newMatchEmails", (newMatchEmails) => {
        if (newMatchEmails.length < 1) {
          return yup.array().min(1, "")
        }
        else if (newMatchEmails.length >= 1) {
          if (!newMatchEmails[0]) {
            return yup.array().min(1, "")
          }
          else {
            return yup.array()
          }
        }
      }),
    newMatchEmails: yup
      .array()
      .when("newMatchExistingEmails", (newMatchExistingEmails) => {
        if (newMatchExistingEmails) {
          return yup.array().uniqueMF(newMatchExistingEmails, '').of(yup.string().email())
        }
        else {
          return yup.array().of(yup.string().email())
        }
      })
  }, ['newMatchEmails', 'newMatchExistingEmails']);

  const categoriesRef = useRef(null);
  const transType = matchResult?.transfer?.type;
  const awsAccountID = matchResult?.transfer?.awsS3?.carrierIntegrationAccountId;
  const KmsKeyArn = matchResult?.transfer?.awsS3?.evadataMatchLambdaKmsArn;
  const sourcesMapping = {
    "carrier": "Carrier-to-Carrier",
    "dmf": "DMF",
    "funeral-home-obituary": "Obit Funeral Home",
    "newspaper-obituary": "Obit Newspaper",
    "state": "State"
  }

  const setupMatchFileApi = async function (values, emails) {
    const payload = {
      file: {
        prefix: values.newMatchPrefix,
        type: values.newMatchFileType
      },
      customerFilePrefix,
      transfer: {
        type: values.newMatchTransMethod,
        frequency: values.newMatchFrequency,
      },
      sources: values.newMatchSource,
      notificationEmails: emails,
      categories: values.newMatchSelectedCategories
    };
    if (values.newMatchTransMethod === 'sftp') {
      payload.transfer.sftp = {
        username: values.newMatchUsernameSFTP.trim(),
        host: values.newMatchSftpAddress.trim(),
        port: values.newMatchPortNum,
        pgpPublicKey: values.newMatchPgpKey
      }
    }
    if (values.newMatchTransMethod === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.newMatchFileArnS3Bucket,
        iamRole: values.newMatchFileIamRoleArn
      }

    }
    if (values?.newSubdirectory?.length > 0) {
      if (values.newMatchTransMethod === 'sftp') {
        payload.transfer.sftp.path = values.newSubdirectory;
      }
      else {
        payload.transfer.awsS3.path = values.newSubdirectory;
      }
    }

    if (values.newMatchFrequency === 'Daily' && values.assignHolidayCalendar) {
      payload.holidayCalendarId = holidayCalendarId;
    }

    if (values.newMatchFrequency === 'Weekly') {
      payload.transfer.frequencyDay = values.newMatchFrequencyDay;
    }

    if (values.newMatchFrequency === 'Monthly') {
      payload.transfer.frequencyDay = values.newMatchFrequencyMonth;
    }

    if (values.newMatchFrequency === 'Quarterly') {
      const quarterlyMonths = [values?.q1Month, values?.q2Month, values?.q3Month, values?.q4Month];
      const quarterlyDays = [values?.q1Day, values?.q2Day, values?.q3Day, values?.q4Day];
      const newReportDates = combineMonthsDays(quarterlyMonths, quarterlyDays);
      payload.transfer.reportDates = newReportDates;
    }

    if (values.enableAnnualDmf === 'true') {
      const _date = combineMonthDay(values.annualDmfMonth, values.annualDmfDay, true);
      payload.transfer.annualDmf = _date;
    }

    if (values.newMatchUseSshKey === false || values.newMatchUseSshKey === 'false') { // even though yup def is boolean, this can be a string depending on the validation flow
      payload.transfer.sftp.password = values.newMatchPassword;
    }

    return setupMatchFile(carrierConfig.carrierId, payload);
  };

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(event) {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newMatchPrefix: '',
      newMatchFileType: 'json',
      newMatchSource: [],
      newMatchPgpKey: '',
      newMatchSftpAddress: '',
      newMatchPortNum: 22,
      newSubdirectory: '',
      newMatchUsernameSFTP: '',
      newMatchPassword: '',
      newMatchFrequency: '',
      newMatchFrequencyDay: 'Wednesday',
      newMatchFrequencyMonth: 'First Day',
      ...annualDmfInitialValues,
      accountId: '',
      newMatchExistingEmails: [],
      newMatchEmails: [],
      newMatchUseSshKey: true,
      newMatchFileIamRoleArn: '',
      newMatchFileArnS3Bucket: '',
      newMatchTransMethod: transType,
      newMatchFileKmsKeyArn: KmsKeyArn,
      awsAccountId: awsAccountID,
      prefixError: false,
      exactPrefixError: false,
      assignHolidayCalendar: false,
      ...quarterlyInitialValues
    },
    validationSchema: validationSchema,
    onSubmit: async (formValues) => {
      setIsLoading(true);
      setGeneralizedError(false);
      setWeakPgpKey(false);
      setShowErrorAlert(false);

      const _allEmails = formValues.newMatchExistingEmails.concat(formValues.newMatchEmails.filter(x => x));
      const _result = await setupMatchFileApi(formValues, _allEmails);

      if (_result.statusCode === 400 || _result.statusCode === 500) {
        setIsLoading(false);
        window.scrollTo(0, 0);

        if (_result.message === "The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.") {
          formik.errors.newMatchPgpKey = _result.message;
          setShowErrorAlert(true);
          setWeakPgpKey(true);
        }
        else {
          setGeneralizedError(true);
        }
      } else {
        finishSetup();
      }
    },
  });

  const [emailInit, setEmailInit] = useState(false);
  const [matchFormInitIsSet, setMatchFormInitIsSet] = useState(false);

  useEffect(() => {
    if ((formik.values.newMatchExistingEmails.length === 0) && (emailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("newMatchExistingEmails", [userEmail]);
        setEmailInit(true);
      }
      if (emailInit === true && matchFormInitIsSet === false) {
        setMatchFormInitIsSet(true);
      }
    }
  }, [formik, emailInit, userEmail, matchFormInitIsSet]);

  const [pgpKeyInUse, setPGPKeyInUse] = useState(false);

  useEffect(() => {
    if (customerFilePgp && formik.values.newMatchPgpKey.length > 0) {
      if (formik.values.newMatchPgpKey.replace(/(\r\n|\n|\r)/gm, "").replace(/ /g, '') === customerFilePgp.replace(/(\r\n|\n|\r)/gm, "").replace(/ /g, '')) {
        setPGPKeyInUse(true);
      }
      else {
        setPGPKeyInUse(false);
      }
    }
  }, [customerFilePgp, formik.values.newMatchPgpKey, formik.errors]);

  useEffect(() => {
    if (matchPrefixes) {
      const _matchedPrefixes = matchPrefixes.filter(checkPrefixes);
      function checkPrefixes(prefix) {
        return formik.values.newMatchPrefix.startsWith(prefix) || prefix.startsWith(formik.values.newMatchPrefix);
      }
      if (_matchedPrefixes.length > 0) {
        if (_matchedPrefixes[0] === formik.values.newMatchPrefix) {
          formik.values.exactPrefixError = true;
        }
        else {
          formik.values.prefixError = true;
          formik.values.exactPrefixError = false;
        }
      } else {
        formik.values.prefixError = false;
        formik.values.exactPrefixError = false;
      }
    }
  }, [matchPrefixes, formik.values])



  useEffect(() => {
    async function getAvailableSources(carrierId) {
      const _source = await getSources(carrierId);
      setAvailableSources(_source);
    }

    async function getAvailableFrequencies(carrierId) {
      const { scanFrequencies } = await getTransferFrequencies(carrierId);
      setAvailableFrequencies(scanFrequencies);
    }

    getAvailableSources(carrierConfig?.carrierId);
    getAvailableFrequencies(carrierConfig?.carrierId);
    
  }, [carrierConfig.carrierId])

  const validate = () => {
    if (formik.errors) {
      setFormTouched(true);
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }

  function updateSelectedCategories(allSelected) {
    formik.setFieldValue("newMatchSelectedCategories", allSelected);
  }

  return (
    <>
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />}
      {isLoading && <LoadingIcon />}
      <FormWrapper>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
              <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
                <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                <Typography variant="overline" color="text.primary">Step 2 of 2</Typography>
                <Typography variant="h5" color="primary">Match Results Setup</Typography>
              </Box>
              <Box sx={{ p: 5, backgroundColor: 'background.paper' }}>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>
                <PrefixField name="newMatchPrefix" label="File Prefix" formik={formik} prefixError={formik.values.prefixError} exactPrefixError={formik.values.exactPrefixError} showDateExtension isManageConfiguration helperText={'Uniquely name files. Names that build off each other (customer, customer1) are not allowed. 24 character limit. Exclude file extension.'} />
                <FileTypeField formik={formik} name='newMatchFileType' formValue2='xlsx' formLabel2='XLSX' formValue3='both' formLabel3='Both JSON & XLSX' />
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0 }}>File Name Example:</Typography>
                {formik.values.newMatchFileType === 'both' ?
                  <>
                    <Typography variant="body2" color="text.primary">{formik.values.newMatchPrefix}-{date}.json</Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>{formik.values.newMatchPrefix}-{date}.xlsx</Typography>
                  </>
                  :
                  <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>{formik.values.newMatchPrefix}-{date}.{formik.values.newMatchFileType}</Typography>
                }
                <Typography variant="body2" color="text.primary" sx={{ mb: 3 }}>Prefix and file type are used by LENS to create your matches.</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 3 }}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mt: 1 }}>Choose transfer frequency schedule.</Typography>
                  <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <TransferFrequencySelect formik={formik} availableFrequencies={availableFrequencies} formInputName='newMatchFrequency' />
                  </Grid>
                  {formik.values.newMatchFrequency === 'Daily' ?
                    <Grid item xs={12}>
                      <FormControl>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2, mt: 0, ml: 1 }}>
                          <Field as={FormControlLabel} type="checkbox" id='assignHolidayCalendar' name='assignHolidayCalendar' label='Observe company holidays' control={<Checkbox />} />
                          <Tooltip placement="right" arrow sx={{ ml: -1 }} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" /></Tooltip>
                        </Box>
                      </FormControl>
                      <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
                    </Grid> : ''}

                  <Grid item xs={12} sm={4}>
                    {formik.values.newMatchFrequency === 'Weekly' ?
                      <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel htmlFor="newMatchFrequencyDay">Day of the Week</InputLabel>
                        <Select
                          id="newMatchFrequencyDay"
                          name="newMatchFrequencyDay"
                          value={formik.values.newMatchFrequencyDay}
                          onChange={formik.handleChange}
                          label="File Transfer Frequency"
                          sx={{ width: '100%' }}
                        >
                          <MenuItem value="Monday">Monday</MenuItem>
                          <MenuItem value="Tuesday">Tuesday</MenuItem>
                          <MenuItem selected value="Wednesday">Wednesday</MenuItem>
                          <MenuItem value="Thursday">Thursday</MenuItem>
                          <MenuItem value="Friday">Friday</MenuItem>
                        </Select>
                        <FormHelperText>File sent on this day every week.</FormHelperText>
                      </FormControl> : ''}

                    {formik.values.newMatchFrequency === 'Monthly' ?
                      <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel htmlFor="newMatchFrequencyMonth">Day of the Month</InputLabel>
                        <Select
                          id="newMatchFrequencyMonth"
                          name="newMatchFrequencyMonth"
                          value={formik.values.newMatchFrequencyMonth}
                          onChange={formik.handleChange}
                          label="File Transfer Frequency"
                          sx={{ width: '100%' }}
                        >
                          <MenuItem selected value="First Day">First Day</MenuItem>
                          <MenuItem value="15th">15th</MenuItem>
                          <MenuItem value="Last Day">Last Day</MenuItem>
                        </Select>
                        <FormHelperText>File sent on this day every month.</FormHelperText>
                      </FormControl> : ''}
                    {formik.values.newMatchFrequency === 'Once' ? <Typography sx={{ mt: { sm: 3, xs: 1 }, mb: 5 }} variant="body2" >File sent once, 1-2 days after recieving your customer file.</Typography> : ''}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {formik.values.newMatchFrequency === 'Quarterly' &&
                      <Box mb={3}>
                        <QuarterlyDates formik={formik} quarterlyErrors={quarterlyErrors} setQuarterlyErrors={setQuarterlyErrors} />
                      </Box>
                    }
                  </Grid>
                </Grid>
                {availableFrequencies?.includes('dmf-annual') && 
                  <Box mb={4}>
                    <AnnualDmfScanSetup formik={formik} filePrefix={formik.values.newMatchPrefix} fileType={formik.values.newMatchFileType} matchConfigs={matchResults} customerConfigs={customerResults} />
                  </Box>
                }

                <hr />

                <Typography variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>SOURCE SELECTION</Typography>
                <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>Specify sources your customer records should be matched against.</Typography>
                <FormHelperText sx={{ mb: 0, mt: 1 }} error={(formik.touched.newMatchSource && formik.errors.newMatchSource)}>Minimum of one source required.</FormHelperText>

                <Box sx={{ mb: 4 }} role="optgroup" aria-labelledby="checkbox-group">
                  <FormControl component="fieldset">
                    {availableSources?.sort().map((source) => {
                      return <Field as={FormControlLabel} key={source} type="checkbox" name='newMatchSource' label={sourcesMapping[source]} value={source} control={<Checkbox />} sx={{ ml: 1 }} />
                    })}

                    {formik.touched.newMatchSource && formik.errors.newMatchSource ? <FormHelperText sx={{ mb: 4, mt: 1 }} error={true}>At least one source selection is required.</FormHelperText> : ''}
                  </FormControl>
                </Box>

                <hr />

                <Typography ref={categoriesRef} variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>MATCH CATEGORIES</Typography>
                <SelectCategories updateSelectedCategories={updateSelectedCategories} formTouchedProp={formTouched} />

                <hr />

                <Typography variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

                {transType === 'sftp' && <><Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 4 }}>Provide credentials for LENS to use when accessing your SFTP to transfer matches.</Typography>

                  <Grid container>
                    <Grid item xs={9}>

                      <TextField
                        fullWidth
                        id="newMatchSftpAddress"
                        name="newMatchSftpAddress"
                        label="SFTP Server Hostname"
                        value={formik.values.newMatchSftpAddress}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newMatchSftpAddress && Boolean(formik.errors.newMatchSftpAddress)}
                        helperText={formik.touched.newMatchSftpAddress ? formik.errors.newMatchSftpAddress : 'Do not include https:// or subdirectory path when entering server hostname.'}
                        sx={{ mb: 3, pr: 2 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="newMatchPortNum"
                        name="newMatchPortNum"
                        label="Port"
                        value={formik.values.newMatchPortNum}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newMatchPortNum && Boolean(formik.errors.newMatchPortNum)}
                        helperText={formik.touched.newMatchPortNum ? formik.errors.newMatchPortNum : '5 character limit. Numeric only.'}
                        sx={{ mb: 3 }}
                        inputProps={{ maxLength: 5 }}
                      />
                    </Grid>
                  </Grid>

                  <SubdirectoryField formik={formik} label='Subdirectory Path (Optional)' name='newSubdirectory' />

                  <TextField
                    fullWidth
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    type='text'
                    id="newMatchUsernameSFTP"
                    name="newMatchUsernameSFTP"
                    label="SFTP Username"
                    value={formik.values.newMatchUsernameSFTP}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newMatchUsernameSFTP && Boolean(formik.errors.newMatchUsernameSFTP)}
                    helperText={formik.touched.newMatchUsernameSFTP ? formik.errors.newMatchUsernameSFTP : 'LENS will use the username to access your SFTP.'}
                    sx={{ mb: 3 }}
                  />

                  <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>How should LENS authenticate to access your virtual server instance?</Typography>

                  <RadioGroup
                    id="newMatchUseSshKey"
                    name="newMatchUseSshKey"
                    value={formik.values.newMatchUseSshKey}
                    onChange={formik.handleChange}
                    sx={{ mb: 3 }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Authenticate by SSH-RSA key" />
                    {(formik.values.newMatchUseSshKey === 'true' || formik.values.newMatchUseSshKey === true) && <Typography variant="body2" color="text.primary" sx={{ mb: 1, mt: 1, ml: 4 }}>LENS will generate this key and use it to access your SFTP. The public key will be available after saving.</Typography>}
                    <FormControlLabel value={false} control={<Radio />} label="Authenticate by Password" />
                  </RadioGroup>

                  {formik.values.newMatchUseSshKey === 'false' &&
                    <MatchPasswordWrapper>
                      <FormControl>
                        <OutlinedInput
                          fullWidth
                          id="newMatchPassword"
                          name="newMatchPassword"
                          label="Password"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newMatchPassword}
                          error={formik.touched.newMatchPassword && Boolean(formik.errors.newMatchPassword)}
                          helpertext={formik.touched.newMatchPassword ? formik.errors.newMatchPassword : ''}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{ mb: 0, ml: 4, width: '595px' }}
                        />
                      </FormControl>

                      <FormHelperText sx={{ ml: 4 }} error id="password-error">
                        {formik.touched.newMatchPassword ? formik.errors.newMatchPassword : ''}
                      </FormHelperText>
                      <Typography variant="body2" sx={{ ml: 4, mt: 2 }}>For security reasons, the password will be hidden after saving.</Typography>

                    </MatchPasswordWrapper>
                  }

                  <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 4 }}>Create and provide LENS a public PGP key to encrypt your matches.</Typography>

                  <Typography variant='body2' color="text.secondary" sx={{ mb: 3, mt: 2 }}>If your organization does not have a PGP key generator tool, create a new key pair at <a className="pgp-link" href="https://pgptool.org" target="blank"> https://pgptool.org<OpenInNewRounded sx={{ fontSize: '18px', mb: '-4px', ml: '4px' }} /></a></Typography>

                  <TextField
                    autoComplete='new-password'
                    fullWidth
                    multiline
                    rows={6}
                    id="newMatchPgpKey"
                    name="newMatchPgpKey"
                    label="Public PGP Key"
                    value={formik.values.newMatchPgpKey}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newMatchPgpKey && Boolean(formik.errors.newMatchPgpKey)}
                    helperText={formik.touched.newMatchPgpKey ? '' : 'Key entered must be a minimum of 2048 bits.'}
                    sx={{ mb: 3 }}
                  />

                  {(pgpKeyInUse || weakPgpKey) ?
                    <FormHelperText sx={{ mt: '-20px', mb: 4, ml: -1 }} error={true}>
                      <ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />
                      {pgpKeyInUse ?
                        'Public key entered is already in use. Provide your own public key, not the key LENS generated.' :
                        'The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.'
                      }
                    </FormHelperText> : ''
                  }

                  <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>The public key provided is used by LENS to encrypt your matches.</Typography></>}

                {transType === 'awsS3' && <>
                  <AwsFields
                    instructionText={'Provide S3 Bucket and IAM Role ARNs for LENS to send matches.'}
                    isMatchFileForm
                    showTransferLocationMethod={true}
                    showTooltip
                    accountId={matchResult?.transfer?.awsS3?.carrierIntegrationAccountId}
                    evadataLambdaArn={matchResult?.transfer?.awsS3?.evadataMatchLambdaArn}
                    evadataLambdaRoleArn={matchResult?.transfer?.awsS3?.evadataMatchLambdaRoleArn}
                    cfTemplateFile="carrier-aws-match-files-option.yml"
                    cfTemplateFileName={"lens-carrier-aws-" + formik.values.newMatchPrefix + "-files-option.yml"}
                    cfTemplateLabel="Matches Template" />

                  <TextField
                    fullWidth
                    id="newMatchFileArnS3Bucket"
                    name="newMatchFileArnS3Bucket"
                    label="S3 Bucket ARN"
                    value={formik.values.newMatchFileArnS3Bucket}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newMatchFileArnS3Bucket && Boolean(formik.errors.newMatchFileArnS3Bucket)}
                    helperText={formik.touched.newMatchFileArnS3Bucket && Boolean(formik.errors.newMatchFileArnS3Bucket) ? (formik.errors.newMatchFileArnS3Bucket) : 'ARN should begin with: arn:aws:s3:::'}
                    sx={{ mb: '16px' }}
                  />

                  <SubdirectoryField formik={formik} label='Folder Path (Optional)' name='newSubdirectory' />
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>KMS Key ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
                  <Grid container>
                    <Grid item xs={1}>
                      <CopyButton toolTipText="Copy ARN" textToCopy={matchResult?.transfer?.awsS3?.evadataMatchLambdaKmsArn} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{matchResult?.transfer?.awsS3?.evadataMatchLambdaKmsArn}</Typography>
                    </Grid>
                    <Typography variant="body2">Use the KMS Key ARN to decrypt your matches.</Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    id="newMatchFileIamRoleArn"
                    name="newMatchFileIamRoleArn"
                    label="IAM Role ARN"
                    value={formik.values.newMatchFileIamRoleArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newMatchFileIamRoleArn && Boolean(formik.errors.newMatchFileIamRoleArn)}
                    helperText={formik.touched.newMatchFileIamRoleArn && Boolean(formik.errors.newMatchFileIamRoleArn) ? formik.errors.newMatchFileIamRoleArn : 'ARN should begin with: arn:aws:iam::'}
                    sx={{ mt: '16px', mb: 3 }}
                  />

                  <Typography variant="body2" sx={{ mb: 4 }}>LENS uses the provided Role to transfer matches to the S3 bucket shown.</Typography>

                </>}
                <hr />

                <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>NOTIFICATION PREFERENCES</Typography>
                <EmailCheckboxEntry availableEmailAddresses={availableEmailAddresses} formInputName="newMatchExistingEmails" hasError={(formik.errors.newMatchExistingEmails || formik.errors.newMatchEmails)} />
                <EmailMultiEntry formik={formik} fieldName="newMatchEmails" availableEmailAddresses={availableEmailAddresses} />
                {(formik.errors.newMatchExistingEmails || formik.errors.newMatchEmails) ? <FormHelperText sx={{ mb: 4, mt: 1, fontWeight: 400 }} error={true}>*One email selection or email entry is required.</FormHelperText> : ''}
              </Box>
              <hr />
              <Box sx={{ pb: 5, pr: 5, pl: 5, backgroundColor: 'background.paper' }}>
                <Grid container>
                  <Grid item sm={6}>
                    <Button onClick={cancel} sx={{ mt: 3 }} variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Button onClick={validate} type="submit" sx={{ mt: 3 }} variant="contained">
                      Add Configuration
                    </Button>
                  </Grid>
                  {formik.values.errors}
                </Grid>
              </Box>
            </Paper>
          </form>
        </FormikProvider>
      </FormWrapper>
    </>
  )
}

AddMatchFileConfiguration.propTypes = {
  matchResult: PropTypes.object,
  customerResults: PropTypes.array,
  matchResults: PropTypes.array,
  availableEmailAddresses: PropTypes.array,
  matchPrefixes: PropTypes.array,
  finishSetup: PropTypes.func,
  customerFilePgp: PropTypes.string,
  customerFilePrefix: PropTypes.string,
  cancel: PropTypes.func,
  holidayCalendarId: PropTypes.string
};

export default AddMatchFileConfiguration;