import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

function TransferLocationMethodDisplayOnly({transferType}) {
  return (
    <>
      <Typography className="trans-location-method-vfd" variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 4 }}>Transfer Location Method:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{transferType === 'sftp' ? 'SFTP (Secure File Transfer Protocol)' : 'AWS S3 (Simple Storage Service)'}</Typography>
    </>
  )
}

TransferLocationMethodDisplayOnly.propTypes = {
  transferType: PropTypes.string
};

export default TransferLocationMethodDisplayOnly;
