import React from 'react';
import PropTypes from 'prop-types';

import AwsKeyTable from './awsKeyTable';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import { Typography } from '@mui/material';


function AwsS3TransferTable({customerResult, viewAwsDetails, uniqueBucketRolePairs}) {
  return(
    <>
      {uniqueBucketRolePairs?.map((uniquePair) => (
        <AwsKeyTable key={uniquePair?.record?.file?.prefix} data={uniquePair?.record} associatedData={uniquePair?.associatedData} transferFreq={switchFreqDisplay(uniquePair?.record?.transfer?.frequency)} viewAwsDetails={viewAwsDetails}></AwsKeyTable>
      ))}
      {(customerResult?.transfer?.type === 'awsS3') && <Typography variant="subtitle2">* Folder path only applies to match results.</Typography> }
    </>
  );
}

AwsS3TransferTable.propTypes = {
  customerResult: PropTypes.object,
  viewAwsDetails: PropTypes.func,
  uniqueBucketRolePairs: PropTypes.array,
};

export default AwsS3TransferTable;
