import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography, Grid, styled } from '@mui/material';

const StepperWrapper = styled(Grid)(
  () => `
    max-width: 720px;
    margin: 0 auto;
    text-align: center;
    
    .MuiStepper-root {
      background-color: transparent;
    }

    @media screen and (max-width: 600px) {
      .MuiStepLabel-label {
        display:none;
      }
    }
  `
);


function OnboardingStepper({ step, customerResult, deathResult, matchResult }) {

  const [isCustCompleted, setIsCustCompleted] = useState(false);
  const [isDeathCompleted, setIsDeathCompleted] = useState(false);
  const [isMatchCompleted, setIsMatchCompleted] = useState(false);

  const steps = [
    { label: 'Customer List Setup', completed: isCustCompleted },
    { label: 'Verified Deaths List Setup', completed: isDeathCompleted },
    { label: 'Match Results Setup', completed: isMatchCompleted }];

  useEffect(() => {
    if (customerResult) {
      setIsCustCompleted(true);
    }
    if (deathResult) {
      setIsDeathCompleted(true);
    }
    if (matchResult) {
      setIsMatchCompleted(true);
    }
  })

  return (
    <StepperWrapper container>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ mb: 2 }}>Set Up Configurations for LENS</Typography>
        <Stepper className="stepper" activeStep={step}>

          {steps.map((step) => {
            return (
              <Step key={step.label} completed={step.completed}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

      </Grid>

    </StepperWrapper>
  );
}

OnboardingStepper.propTypes = {
  step: PropTypes.number,
  customerResult: PropTypes.object,
  deathResult: PropTypes.object,
  matchResult: PropTypes.object
};

export default OnboardingStepper;