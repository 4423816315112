import React from 'react';
import { Skeleton, Box } from '@mui/material';

const ActivityLogSkeletonRow = () => (
  <Box sx={{}}>
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 1, width: "18%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 1, width: "18%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 1, width: "18%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 1, width: "18%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: 4, mb: 5, ml: 1, mr: 1, width: "18%", float: 'left' }} />
  </Box>
);

function SkeletonRows() {

  return (
    <>
      <ActivityLogSkeletonRow />
      <ActivityLogSkeletonRow />
      <ActivityLogSkeletonRow />
    </>
  )
}

export default SkeletonRows;