import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Button, Box, FormControl, FormHelperText, MenuItem, Grid, InputLabel, Select, Card, styled, Tooltip, Alert, TextField } from '@mui/material';
import { EditRounded, InfoRounded, ErrorOutline } from '@mui/icons-material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import { editTransferFrequency, getHolidayCalendars } from 'src/api';
import { useAppContext } from 'src/AppContext';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import HolidayCalendarCheckbox from 'src/components/FormFields/holidayCalendarCheckbox';
import { QuarterlyDates, quarterlyValidationSchema, quarterlyInitialValues } from 'src/components/FormFields/quarterlyDates';
import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';
import { combineMonthsDays, combineMonthDay } from 'src/utilities/combineMonthDay';
import { annualDmfSchema } from 'src/components/AnnualDmfScanSetup';
import AnnualDmfEdit from 'src/components/AnnualDmfEdit';
import AnnualDmfDisplay from 'src/components/AnnualDmfDisplay';

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

const baseValidationSchema = {
  frequency: yup
    .string()
    .required(),
  frequencyDay: yup
    .string(),
  observeHoliday: yup
    .bool()
};

function EditTransferSchedule({ data, reload, frequencies, matchConfigs, customerConfigs, availableFrequencies, removedFrequencies }) {
  const { carrierConfig } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [editingTransfer, setEditingTransfer] = useState(false);
  const [updatedFreq, setUpdatedFreq] = useState(null);
  const [updatedFreqDay, setUpdatedFreqDay] = useState(null);
  const [updatedReportDates, setUpdatedReportDates] = useState(null);
  const [updatedObserveHoliday, setUpdatedObserveHoliday] = useState(!_.isEmpty(data?.holidayCalendarId));
  const [updatedAnnualDmfDay, setUpdatedAnnualDmfDay] = useState(data?.transfer?.annualDmf?.split('/')[1]);
  const [updatedAnnualDmfMonth, setUpdatedAnnualDmfMonth] = useState(data?.transfer?.annualDmf?.split('/')[0]);
  const [associatedCalendarId, setAssociatedCalendarId] = useState(data?.holidayCalendarId);
  const [quarterlyErrors, setQuarterlyErrors] = useState([false, false, false, false]);
  const [frequency, setFrequency] = useState();

  function cancelEditTransfer() {
    setEditingTransfer(false);
    formik.setFieldValue('frequency', '');
    formik.setFieldTouched('frequency', false);
    formik.setFieldValue('enableAnnualDmf', Boolean(updatedAnnualDmfDay))
    formik.setFieldTouched('enableAnnualDmf', false);
    formik.setFieldValue('annualDmfMonth', Number(updatedAnnualDmfMonth));
    formik.setFieldTouched('annualDmfMonth', false);
    formik.setFieldValue('annualDmfDay', Number(updatedAnnualDmfDay))
    formik.setFieldTouched('annualDmfDay', false);
    setInitialized(false);
  }

  function updateTransfer(freq, freqDay) {
    setUpdatedFreq(freq);
    setUpdatedFreqDay(freqDay);
  }

  function editTransfer() {
    setEditingTransfer(true);
  }

  const handleSelectClick = (event, name) => {
    const clickedValue = event.target.dataset.value;
    const previousValue = formik.values[name];
    formik.setFieldTouched(name, !clickedValue && previousValue==='');
  }

  const validationSchema = yup.object().shape({
    ...baseValidationSchema, 
    ...annualDmfSchema, 
    ...quarterlyValidationSchema(data?.configurationType === 'matches', 'frequency', quarterlyErrors)
  });

  const formik = useFormik({
    initialValues: {
      frequency: '',
      frequencyDay: 'wednesday',
      frequencyMonth: 'first day',
      observeHoliday: updatedObserveHoliday,
      enableAnnualDmf: Boolean(data?.transfer?.annualDmf),
      annualDmfMonth: data?.transfer?.annualDmf ? Number(data?.transfer?.annualDmf?.split('/')[0]) : '',
      annualDmfDay: data?.transfer?.annualDmf ? Number(data?.transfer?.annualDmf?.split('/')[1]) : '',
      ...quarterlyInitialValues
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let payload = {
          'type': data?.configurationType,
          'filePrefix': data?.file?.prefix,
          'frequency': values?.frequency,
        };
        if (data?.configurationType === 'matches') {
          payload.filePrefix = data?.customerFilePrefix
          if (values?.frequency === 'weekly') {
            payload.frequencyDay = values?.frequencyDay
          }
          if (values?.frequency === 'monthly') {
            payload.frequencyDay = values?.frequencyMonth
          }
          if (values?.frequency === 'daily' && values?.observeHoliday) {     
            payload.holidayCalendarId = associatedCalendarId;
          } 
          if (values?.frequency === 'quarterly') {
            const quarterlyMonths = [values?.q1Month, values?.q2Month, values?.q3Month, values?.q4Month];
            const quarterlyDays = [values?.q1Day, values?.q2Day, values?.q3Day, values?.q4Day];
            const newReportDates = combineMonthsDays(quarterlyMonths, quarterlyDays);
            payload.reportDates = newReportDates;
          }
          if (values?.enableAnnualDmf) {
            payload.annualDmf = combineMonthDay(values?.annualDmfMonth, values?.annualDmfDay, true);
          }
        }
        setLoading(true);
        const result = await editTransferFrequency(carrierConfig?.carrierId, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result.frequency) {
          reload();
          if (result?.frequency === 'daily') {
            updateTransfer(result?.frequency, '');
            setUpdatedObserveHoliday(!_.isEmpty(result?.holidayCalendarId));
          }
          if (result?.frequency !== 'daily') {
            updateTransfer(result?.frequency, result?.frequencyDay);
          }
          if (result?.reportDates) {
            setUpdatedReportDates(result?.reportDates);
          } else {
            formik.setValues({...formik.values, q1Day: '', q2Day: '', q3Day: '', q4Day: '', q1Month: '', q2Month: '', q3Month: '', q4Month: ''});
          }
          if (result?.annualDmf) {
            setUpdatedAnnualDmfDay(values?.annualDmfDay);
            setUpdatedAnnualDmfMonth(values?.annualDmfMonth);
          } else {
            setUpdatedAnnualDmfDay(null);
            setUpdatedAnnualDmfMonth(null);
            formik.setValues({...formik.values, annualDmfDay: '', annualDmfMonth: ''});
            formik.setFieldTouched('annualDmfMonth', false);
            formik.setFieldTouched('annualDmfDay', false);
          }
          setLoading(false);
          setEditingTransfer(false);
        }
      }
      catch (e) {
        setLoading(false);
        throw (e);
      }
    },
  });

  useEffect(() => {
    const setValue = async function () {
      if (updatedFreq) {
        await formik.setFieldValue('frequency', updatedFreq);
        setGeneralizedError(false);
      } 
      else if (availableFrequencies?.includes(data?.transfer?.frequency) || data?.configurationType === 'customer_records') {
        await formik.setFieldValue('frequency', data?.transfer?.frequency);
      }

      const freqDay = updatedFreqDay || data?.transfer?.frequencyDay; 
      const freq = updatedFreq || data?.transfer?.frequency;
      if (freq === 'weekly') {
        await formik.setFieldValue('frequencyDay', freqDay);
      }
      else if (freq === 'monthly') {
        await formik.setFieldValue('frequencyMonth', freqDay);
      }
      setGeneralizedError(false);

      let dates = [];
      if (updatedReportDates) {
        dates = updatedReportDates;
      } else if (!updatedFreq && data?.transfer?.frequency === 'quarterly') {
        dates = data?.transfer?.reportDates;
      }
  
      _.each(dates, (date,i) => {
        formik.setFieldValue(`q${i+1}Month`, _.chain(date).split('/').head().toNumber().value());
        formik.setFieldValue(`q${i+1}Day`, _.chain(date).split('/').tail().toNumber().value());
      })

      setInitialized(true);
    }
    if (!initialized) {
      setValue().catch((error) => setGeneralizedError(true));
    }
  }, [setInitialized, initialized, data?.transfer?.frequency, data?.transfer?.frequencyDay, updatedFreq, updatedFreqDay, data?.transfer?.reportDates, updatedReportDates, availableFrequencies])

  useEffect(() => {
    const getHolidayCalendar = async function () {
      const holidayCalendar = await getHolidayCalendars(carrierConfig?.carrierId);
     
      setAssociatedCalendarId(holidayCalendar[0]?.id);
    }

    if(!associatedCalendarId) {
      getHolidayCalendar();
    }
  }, [carrierConfig?.carrierId, associatedCalendarId]);

  useEffect(() => {
    setFrequency(updatedFreq || data?.transfer?.frequency);
  }, [updatedFreq, data?.transfer?.frequency])

  return (
    <CardWrapper>
      {loading &&
        <Box sx={{ minHeight: '349px' }}>
          <Loading />
        </Box>
      }
      {!loading &&
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0 }}>Transfer Schedule</Typography>
                </Grid>
                {data?.configurationType !== 'death_records' &&
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    {!editingTransfer ?
                      <Button onClick={editTransfer} className="edit-trans-locations" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button> :
                      <Button onClick={cancelEditTransfer} className="cancel-edit-cats" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>}
                  </Grid>
                }
              </Grid>

              {generalizedError && <GeneralErrorAlert />}

              {!editingTransfer &&
                <Grid container spacing={3} sx={{ p: 3 }}>
                  <Grid item xs={12}>
                    <Typography className="" variant="subtitle1">
                      {switchFreqDisplay(frequency)}
                      {data?.configurationType === 'matches' &&
                        <>
                          <Typography display="inline" variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                            {(frequency === 'weekly' || frequency === 'monthly') && ' (' + (updatedFreqDay || data?.transfer?.frequencyDay) + ')'}
                          </Typography>
                          {frequency === 'quarterly' &&
                            <DisplayQuarterlyDates formik={formik} />
                          }
                        </>
                      }
                    </Typography>
                    {data?.configurationType === 'matches' ?
                      <Typography variant='body1' sx={{ mt: 1.5 }}>At this frequency, LENS delivers match results to your organization's transfer location.</Typography> :
                      <Typography variant='body1' sx={{ mt: 1.5 }}>At the schedule shown, place your list in LENS SFTP.</Typography>}
                    {data?.configurationType === 'matches' && frequency === 'daily' &&
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2, mt: 3 }}>
                          <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0, mr: 1}}>Observe Company Holidays:</Typography>
                          <Typography variant="body1" color="text.primary">{updatedObserveHoliday ? 'Yes' : 'No'}</Typography>
                          <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" /></Tooltip>
                        </Box>
                        <Typography variant="body2" color="text.primary">Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
                      </>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <AnnualDmfDisplay spacing={0.5} variant='card' frequencies={frequencies} matchConfig={data} matchConfigs={matchConfigs} customerConfigs={customerConfigs} updatedAnnualDmf={updatedAnnualDmfDay ? `${updatedAnnualDmfMonth}/${updatedAnnualDmfDay}` : ' '} />
                  </Grid>
                </Grid>
              }
            </Box>

            {editingTransfer &&
              <Box sx={{ textAlign: 'left', width: '100%', px: 3 }}>
                <Box sx={{ mb: 3, mt: 2 }} role="optgroup" aria-labelledby="checkbox-group">
                  {removedFrequencies?.includes(frequency) && formik.values.frequency === '' &&
                    <Alert severity='warning'>
                      <Typography variant='body2'>Transfer frequency options changed for your organization. Choose a different frequency from the list below.<br></br><strong>Removed: </strong>{_.chain(removedFrequencies).map(switchFreqDisplay).join(', ').value()}</Typography>
                    </Alert>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 0, mt: 0 }}>
                        <Typography variant='subtitle1'>Choose transfer frequency schedule.</Typography>
                        {data?.configurationType === 'matches' && <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ mt: 1, width: '100%' }}>
                        <TextField
                          select
                          id="frequency"
                          name="frequency"
                          value={formik.values.frequency}
                          error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                          onBlur={formik.handleBlur}
                          onClick={(event) => {handleSelectClick(event, 'frequency')}}
                          onChange={formik.handleChange}
                          label="Transfer Frequency"
                          sx={{ width: '100%' }}
                        >
                          {availableFrequencies?.length === 0 &&
                            <LoadingIcon contained noText />
                          }
                          <MenuItem value="daily" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('daily') && data?.configurationType === 'matches'}>Daily (Monday-Friday)&nbsp;<span className="recommended">&nbsp;Recommended</span></MenuItem>
                          <MenuItem value="weekly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('weekly') && data?.configurationType === 'matches'}>Weekly</MenuItem>
                          <MenuItem value="monthly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('monthly') && data?.configurationType === 'matches'}>Monthly</MenuItem>
                          <MenuItem value="quarterly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('quarterly') && data?.configurationType === 'matches'}>Quarterly</MenuItem>
                          <MenuItem value="once" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('once') && data?.configurationType === 'matches'}>Once</MenuItem>
                          {(availableFrequencies?.length < 5) && data?.configurationType === 'matches' && availableFrequencies?.length > 0 &&
                            <Typography variant="caption" sx={{ display: 'inline-block', pl: 2, pr: 2, pt: 1 }}>Contact LENS to find out how to add a frequency not<br/>available to your organization.</Typography>
                          }
                        </TextField>
                        {formik.touched.frequency && Boolean(formik.errors.frequency) && <FormHelperText sx={{ ml: -1 }} error><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Frequency selection is required.</FormHelperText>}
                        {data?.configurationType === 'matches' ?
                          <div>
                            <FormHelperText>Schedule informs LENS on when to send your matches.</FormHelperText>{formik.values.frequency === 'daily' && <HolidayCalendarCheckbox formik={formik} />}
                            {formik.values.frequency === 'once' && <Typography variant='body2' sx={{marginTop: 1}}>File sent once, 1-2 days after recieving your customer file.</Typography>}
                          </div> :
                          <FormHelperText>Schedule informs LENS on when to expect your list.</FormHelperText>}
                      </FormControl>
                    </Grid>
                    {data?.configurationType === 'matches' &&
                      <Grid item xs={12}>
                        {formik.values.frequency === 'weekly' &&
                          <FormControl sx={{ mt: 1, width: '100%' }}>
                            <InputLabel htmlFor="frequencyDay" defaultValue={'wednesday'}>Day of the Week</InputLabel>
                            <Select
                              id="frequencyDay"
                              name="frequencyDay"
                              value={formik.values.frequencyDay}
                              onChange={formik.handleChange}
                              label="File Transfer Frequency"
                              sx={{ width: '100%' }}
                            >
                              <MenuItem value="monday">Monday</MenuItem>
                              <MenuItem value="tuesday">Tuesday</MenuItem>
                              <MenuItem selected value="wednesday">Wednesday</MenuItem>
                              <MenuItem value="thursday">Thursday</MenuItem>
                              <MenuItem value="friday">Friday</MenuItem>
                            </Select>
                            <FormHelperText>File sent on this day every week.</FormHelperText>
                          </FormControl>
                        }
                        {formik.values.frequency === 'monthly' &&
                          <FormControl sx={{ mt: 1, width: '100%' }}>
                            <InputLabel htmlFor="frequencyMonth">Day of the Month</InputLabel>
                            <Select
                              id="frequencyMonth"
                              name="frequencyMonth"
                              value={formik.values.frequencyMonth}
                              onChange={formik.handleChange}
                              label="File Transfer Frequency"
                              sx={{ width: '100%' }}
                            >
                              <MenuItem selected value="first day">First Day</MenuItem>
                              <MenuItem value="15th">15th</MenuItem>
                              <MenuItem value="last day">Last Day</MenuItem>
                            </Select>
                            <FormHelperText>File sent on this day every month.</FormHelperText>
                          </FormControl>
                        }
                        {formik.values.frequency === 'quarterly' &&
                          <QuarterlyDates formik={formik} quarterlyErrors={quarterlyErrors} setQuarterlyErrors={setQuarterlyErrors} frequency={data?.transfer?.frequency} updatedFreq={updatedFreq} reportDates={data?.transfer?.reportDates} updatedReportDates={updatedReportDates}/>
                        }
                        {availableFrequencies?.includes('dmf-annual') && data?.configurationType === 'matches' &&
                          <Grid item xs={12}>
                            <Box mt={2} mb={3}>
                              <hr/>
                            </Box>
                            <AnnualDmfEdit formik={formik} matchConfig={data} matchConfigs={matchConfigs} customerConfigs={customerConfigs} />
                          </Grid>
                        }
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Box>
            }
            {editingTransfer &&
              <div>
                <hr />
                <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                  <Button type="submit" variant="contained">Save Changes</Button>
                </Box>
              </div>
            }
          </form>
        </FormikProvider>
      }
    </CardWrapper>
  )
}

EditTransferSchedule.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func,
  frequencies: PropTypes.object,
  availableFrequencies: PropTypes.arrayOf(PropTypes.string),
  removedFrequencies: PropTypes.array,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};

export default EditTransferSchedule;