import PropTypes from 'prop-types';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { Typography, Box } from '@mui/material';

import AnnualDmfUnavailable from '../AnnualDmfUnavailable';


function AnnualDmfDisplay({ variant='card', frequencies, matchConfig, matchConfigs, customerConfigs, updatedAnnualDmf }) {
  const dmfAnnualContracted = frequencies?.scanFrequencies?.includes('dmf-annual');

  if (dmfAnnualContracted) {
    const dmfAnnualMatchConfig = _.find(matchConfigs, (matchConfig) => _.has(matchConfig, 'transfer.annualDmf'));
    const dmfAnnualCustomerConfig = _.find(customerConfigs, (customerConfig) => customerConfig?.file?.prefix === dmfAnnualMatchConfig?.customerFilePrefix);
    const dmfEnabled = updatedAnnualDmf ? (updatedAnnualDmf !== ' ') : matchConfig?.transfer?.annualDmf;
    
    const variantMap = {
      card: {
        includeHr: true,
        includeMatchResultsCopy: false
      },
      list: {
        includeHr: false,
        includeMatchResultsCopy: true
      }
    }

    return (
      <>
      {variantMap[variant]?.includeHr && <Box mb={3}><hr /></Box>}
      {dmfAnnualMatchConfig && matchConfig?.file?.prefix !== dmfAnnualMatchConfig?.file?.prefix ?
        <AnnualDmfUnavailable dmfAnnualCustomerConfig={dmfAnnualCustomerConfig} dmfAnnualMatchConfig={dmfAnnualMatchConfig}/>
        :
        <>
        <Typography mb={1}><strong>Annual Full DMF Scans:</strong> <Typography display="inline">{dmfEnabled ? 'Enabled' : 'Disabled'}</Typography></Typography>
        {dmfEnabled &&
          <>
            <Typography><strong>Delivery Date:</strong> <Typography display="inline">{DateTime.fromFormat(updatedAnnualDmf || matchConfig?.transfer?.annualDmf, 'M/d').toFormat('MMMM d')}</Typography></Typography>
            <Typography mt={1} mb={1} variant="subtitle1" color="text.secondary">File Name: <Typography display={matchConfig?.file?.type === 'both' ? '' : 'inline'} sx={{ whiteSpace: 'pre-line' }} color="text.primary">{matchConfig?.file?.type === 'both' ? `${matchConfig?.file?.prefix}_dmf_annual-XXXXXXXX.json\n${matchConfig?.file?.prefix}_dmf_annual-XXXXXXXX.xlsx` : `${matchConfig?.file?.prefix}_dmf_annual-XXXXXXXX.${matchConfig?.file?.type}`}</Typography></Typography>
            {variantMap[variant]?.includeMatchResultsCopy && <Typography variant="body2">Annual match results files are delivered alongside ongoing matches.</Typography>}
          </>
        }
      </>}
    <Typography variant="body2">Within each contract year, only one customer list at a time can undergo an annual DMF scan.</Typography>
    </>
    );
  }
}

AnnualDmfDisplay.propTypes = {
  variant: PropTypes.string,
  frequencies: PropTypes.object,
  matchConfig: PropTypes.object,
  customerConfigs: PropTypes.array,
  matchConfigs: PropTypes.array,
  updatedAnnualDmf: PropTypes.string
};

export default AnnualDmfDisplay;