import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';


function SftpUsername({ formik }){
  return (
    <TextField
    id="usernameSFTP"
    inputProps={{
        autoComplete: 'new-password',
        form: {
        autoComplete: 'off',
        },
    }}
    type='text'
    name="usernameSFTP"
    label="SFTP Username"
    className="username"
    value={formik.values.usernameSFTP}
    onBlur={formik.handleBlur}
    onChange={formik.handleChange}
    error={formik.touched.usernameSFTP && Boolean(formik.errors.usernameSFTP)}
    helperText={formik.touched.usernameSFTP ? formik.errors.usernameSFTP : 'Minimum 3 characters. Username can’t start with hyphen –, @ symbol or period.'}
    sx={{ mb: 3 }}
    />
  )
}

SftpUsername.propTypes = {
  formik: PropTypes.object 
};

export default SftpUsername;