import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DisplayMatchTables from '../../displayMatchTables';
import DisplayCustomerPgpTables from './displayCustomerPgpTables';


function PgpTable ({uniqueKeyGroups, viewDetails}) {
  const [uniqueCustomerVdfKeyGroups, setUniqueCustomerVdfKeyGroups] = useState([]);
  const [uniqueMatchKeyGroups, setUniqueMatchKeyGroups] = useState([])
  useEffect(() => {
    if (uniqueKeyGroups) {
      setUniqueCustomerVdfKeyGroups(uniqueKeyGroups.filter((keyGroup) => keyGroup.record?.configurationType === 'customer_records' || keyGroup.record?.configurationType === 'death_records'));
      setUniqueMatchKeyGroups(uniqueKeyGroups.filter((keyGroup) => keyGroup.record?.configurationType === 'matches'));
    }
  },[uniqueKeyGroups])
  

  return(
    <>
    {(uniqueCustomerVdfKeyGroups[0]?.record?.transfer?.type === 'sftp') &&
      <DisplayCustomerPgpTables uniqueKeyGroups={uniqueCustomerVdfKeyGroups} viewDetails={viewDetails} />
    }
    {(uniqueMatchKeyGroups[0]?.record?.transfer?.type === 'sftp') &&
      <DisplayMatchTables uniqueKeyGroups={uniqueMatchKeyGroups} viewDetails={viewDetails} tableType="pgp" />
    }
    </>
  );
};

PgpTable.propTypes = {
  uniqueKeyGroups: PropTypes.array,
  viewDetails: PropTypes.func
};

export default PgpTable;
