import React from 'react';
import { Tooltip, Box, Typography } from '@mui/material'
import { InfoRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

function TooltipInline({ text, tip, variant = 'body2', color = 'primary', display = 'flex', placement = "bottom", mt = null }) {

  return (
    <Box sx={{ display: display, alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography variant={variant} color={color}>{text}
      </Typography>
      <Tooltip placement={placement} arrow sx={mt ? { ml: 1, mt: mt } : { ml: 1 }} title={tip}>
        <InfoRounded color="primary" />
      </Tooltip>
    </Box>
  );
}

TooltipInline.propTypes = {
  text: PropTypes.string,
  tip: PropTypes.string, 
  variant: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string,
  placement: PropTypes.string,
  mt: PropTypes.number
};

export default TooltipInline;
