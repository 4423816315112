import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import PageHeader from 'src/components/PageHeader';
import TableWithActionButton from 'src/components/TableWithActionButton';
import SkeletonRows from './SkeletonRows';
import evadataMatchCatColumns from './columns';
import matchCategories from './matchCategories';
import DefinitionCard from 'src/components/DefinitionCard';
import categoryDefinitions from './categoryDefinitions';

const GridWrapper = styled(Grid)(
  () => `
    @media screen (max-width: 768px) {
      .MuiDataGrid-cell {
        min-width: 150px !important;
      }
      .MuiDataGrid-columnHeader {
        min-width: 150px !important;
      }
    }
  `
)

const MatchingLogicWrapper = styled(Grid)(
  () => `
    white-space: pre-line;
  `
);

function EvadatasMatchCategories() {

  return (
    <>
      <PageHeader title="Evadata's Match Categories" subtitle="Use these 77 match category definitions to assist in determining the outcomes for death record matches in each customer record." />
      <Typography variant="h5" sx={{ mb: 3 }}>Match Results Categories</Typography>
      <GridWrapper>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{float: 'right', mb: '3px'}}><Typography display="inline" variant="subtitle2">Key: </Typography>M = Exact Match, F = Fuzzy Match</Typography>
                <TableWithActionButton
                  useHeader={true}
                  skeleton={<SkeletonRows />}
                  rows={matchCategories}
                  columns={evadataMatchCatColumns}
                  tableHeight={632}
                  useAutoRowHeight={true}
                  customClass="match-categories"
                  hideFooterPagination
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            {categoryDefinitions.map((data, index) => (
              <MatchingLogicWrapper key={data?.title}>
                <Box sx={{ mt: '-90px', pt: '90px' }}></Box>
                <DefinitionCard key={data.title} title={data.title} definitions={data.definitions}></DefinitionCard>
                {data?.note &&
                  <Typography variant="body2"><Typography display="inline" variant="subtitle2" color="text.primary">Note:</Typography> {data?.note}</Typography>
                }
              </MatchingLogicWrapper>
            ))}
          </Grid>
        </Grid>
      </GridWrapper>
    </>
  );
}

EvadatasMatchCategories.propTypes = {};

export default EvadatasMatchCategories;