import React, { useEffect, useState } from 'react';
import { Typography, styled, Box, Paper} from '@mui/material';

import { useAppContext } from 'src/AppContext.js';
import OnboardingStepper from 'src/components/Stepper';
import VerifiedDeathsFileSetupForm from './verifiedDeathsFileSetupForm';
import VerifiedDeathsFileSetupSummary from './verifiedDeathsFileSetupSummary';

import { getEmailAddresses, getDeathFileConfigurations, getCustomerFileConfigurations, getMatchFileConfigurations } from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { useNavigate } from 'react-router-dom';


const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function Loading() {
  return (
    <LoadingIcon />
  );
}

function VerifiedDeathsFileSetup() {
  const [result, setResult] = useState(null);
  const [matchResult, setMatchResult] = useState(null);
  const [customerFileConfig, setCustomerFileConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [availableEmailAddresses, setAvailableEmailAddresses] = useState(null);
  const [availablePgpKeys, setAvailablePgpKeys] = useState(null);
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const {lensRoles } = useAppContext();

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  useEffect(() => {
    const getConfigurations = async function(carrierId) {
      const configs = await getDeathFileConfigurations(carrierId);

      if(configs.length > 0) {
        setResult(configs[0]);
      }
    };

    const getAvailableEmails = async function(carrierId) {
      const emails = await getEmailAddresses(carrierId);
      const emailsWithId = emails.map((email, index) => ({'id' : index, 'email': email}));
      setAvailableEmailAddresses(emailsWithId);
    };

    const _getCustomerFileConfigurations = async function(carrierId) {
      const configs = await getCustomerFileConfigurations(carrierId);

      if(configs.length > 0) {
        setCustomerFileConfig(configs[0]); // we are only supporting one config for now
      }
    }

    const _getMatchConfigurations = async function (carrierId) {
      const configs = await getMatchFileConfigurations(carrierId);
      if (configs.length > 0) {
        setMatchResult(configs[0]);
      }
    };

    if(carrierConfig?.carrierId) {
      Promise.all([
        getAvailableEmails(carrierConfig.carrierId),
        getConfigurations(carrierConfig.carrierId),
        _getCustomerFileConfigurations(carrierConfig.carrierId),
        _getMatchConfigurations(carrierConfig.carrierId)
      ]).finally (() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId]);

  useEffect(() => {
    if(customerFileConfig?.pgp) {
      setAvailablePgpKeys([customerFileConfig.pgp]);
    }
  }, [customerFileConfig]);

  // determine if the pgpKey used in the death file is the same as the customer file
  // if the keys are the same, then only send the key id. The summary and form components handle
  // rendering appropriately
  useEffect(() => {
    if(customerFileConfig && result) {
      const customerFilePgpKey = customerFileConfig.pgp?.id;
      const deathFilePgpKey = result.pgp?.id;

      if(customerFilePgpKey && deathFilePgpKey) {
        if(customerFilePgpKey === deathFilePgpKey) {
          const {pgp, ..._result} = result;
          _result.pgp = {
            id: pgp.id
          };
          setResult(_result);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <FormWrapper>
      <OnboardingStepper step={1} customerResult={customerFileConfig} deathResult={result} matchResult={matchResult} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 2</Typography>
          <Typography variant="h5" color="primary.text">{result ? 'Summary of Verified Deaths List' : 'Verified Deaths List Setup'}</Typography>
        </Box>
        {(!result && !isLoading) && <VerifiedDeathsFileSetupForm 
          setResult={setResult} 
          availableEmailAddresses={availableEmailAddresses} 
          availablePgpKeys={availablePgpKeys}
          transferType={customerFileConfig?.transfer?.type}
          existingTransferUser={customerFileConfig?.transfer?.sftp?.username}
          sftpServer={customerFileConfig?.transfer?.sftp?.sftpServer}
          existingTransferFrequency={customerFileConfig?.transfer?.frequency}
          accountId={customerFileConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
          evadataLambdaArn={customerFileConfig?.transfer?.awsS3?.evadataLambdaArn}
          evadataLambdaRoleArn={customerFileConfig?.transfer?.awsS3?.evadataLambdaRoleArn} />}
        {(result && !isLoading) && <VerifiedDeathsFileSetupSummary 
          result={result}
          file={result.file}
          transfer={result.transfer}
          notificationEmails={result.notificationEmails}
          sftpServer={result.sftpServer}
          pgp={result.pgp}
          accountId={customerFileConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
          customerFileUser={customerFileConfig?.transfer?.sftp?.username}
          setResult={setResult}/>}
        {isLoading && <Loading/>}
      </Paper>
    </FormWrapper>
  );
}

VerifiedDeathsFileSetup.propTypes = {};

export default VerifiedDeathsFileSetup;