import React from 'react';
import PageHeader from 'src/components/PageHeader';
import { Card, Grid, Typography, styled, Button, Divider } from '@mui/material';
import { AirplanemodeActiveRounded, AssignmentRounded, TouchAppRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import CardTop from './cardTop';
import CheckItem from './checkItem';

function ProcessOverview() {

  const ProcessCardTop = styled(Card)(
    ({ theme }) => `
      .nav-card-circle {
        width: 60px;
        height: 60px;
        background-color: transparent;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
        border: 2px solid ${theme.palette.primary.text};
        margin: 0 auto;
          }
        .MuiSvgIcon-root {
            color: ${theme.palette.primary.text};
        }
        .small-rule {
          max-width: 80px;
          margin: 20px auto;
        }
        .check {
          float: left;
          margin: -2px 10px 10px 20px;
        }
        .copy {
          display: flex;
        }
        .helper-text {
          text-align: center;
          margin: 15px 20px 20px;
          font-size: 13px;
        }
        .check-wrapper {
          min-height: 350px;
        }
    `
  );

  return (
    <>
      <PageHeader title="Onboarding Process Overview" subtitle="Review the data integration steps to get your account ready to start receiving match results." />
      <Typography variant="h5" sx={{ mb: 3 }}>Learn About the LENS Stages</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <ProcessCardTop>
            <Grid container>
              <CardTop title={'Stage 1'} icon={<AssignmentRounded />} subtitle={'Reviewing Documentation'} />
              <Grid className='check-wrapper' item xs={12} sx={{ mr: 2 }}>
                <CheckItem copy='Read data use guidelines' />
                <CheckItem copy='View each list’s data attributes' />
                <CheckItem copy='Learn more about the LENS process from the Helpful Guidance page' />
                <CheckItem copy='Download templates to use as guides when building lists' />
              </Grid>
            </Grid>

            <Divider />
            <Typography className="helper-text" variant="body2">We'll transition your account to onboarding immediately after the LENS contract is approved and signed.</Typography>

          </ProcessCardTop>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProcessCardTop>
            <Grid container>
              <CardTop title={'Stage 2'} icon={<AirplanemodeActiveRounded />} subtitle={'Self-Guided Onboarding'} />
              <Grid className='check-wrapper' item xs={12} sx={{ mr: 2 }}>
                <CheckItem copy='Set up SFTP or AWS S3 list transfers' />
                <CheckItem copy='Select sources to match against' />
                <CheckItem copy='Customize match categories' />
                <CheckItem copy='Encrypt with PGP or KMS keys' />
                <CheckItem copy='Configure how LENS will deliver results to your environment' />
              </Grid>
            </Grid>

            <Divider />
            <Typography className="helper-text" variant="body2">Once you’ve completed onboarding, we’ll reach out to determine the best date to move your account to live.</Typography>
          
          </ProcessCardTop>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProcessCardTop>
            <Grid container>
              <CardTop title={'Stage 3'} icon={<TouchAppRounded />} subtitle={'Live on LENS'} />
              <Grid className='check-wrapper' item xs={12} sx={{ mr: 2 }}>
                <CheckItem copy='Send LENS your Customer & Verified Deaths lists' />
                <CheckItem copy='Lists are matched against selected sources and categories' />
                <CheckItem copy='From LENS, monitor list status (e.g. processing, metadata, errors)' />
                <CheckItem copy='Access match results from your SFTP or AWS S3 bucket' />
                <CheckItem copy='Easily make changes to your active list transfers' />
                <CheckItem copy='Set up additional match workflows' />
              </Grid>
            </Grid>

            <Divider />
            <Typography className="helper-text" variant="body2">Your account is now live on LENS!<br />
              Send us your lists to start receiving your match results.</Typography>

          </ProcessCardTop>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" sx={{ mb: 1 }}>View List Data Attributes</Typography>
                <Typography variant="body1">Everything you need to know when submitting data to LENS.</Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: { md: 'right', xs: 'left' } }}>
                <Button LinkComponent={Link} to="/getting-started" sx={{ mt: 1 }} variant="outlined">Get Started</Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid >
    </>
  );
}

ProcessOverview.propTypes = {};

export default ProcessOverview;