import { useEffect, useState } from 'react';
import { Typography, Link} from '@mui/material';


import { useQuery } from 'src/hooks';
import { getReleaseNotesAuth } from 'src/api';
import PageHeader from 'src/components/PageHeader';
import LoadingIcon from 'src/components/Loading/loadingIcon';

function ReleaseNotes() {
  const {data = [], isLoading} = useQuery(['releaseNotes'], () => getReleaseNotesAuth());

  return <>
    {isLoading ? <LoadingIcon contained={true} height="337px" marginTop="40px" /> :
      <>
        <PageHeader title={"Release Notes"} subtitle={"Click the Link below to view and authenticate to LENS release notes"} />
        <Typography variant="body2" color="text.secondary">
          <Link target="_blank" href={data.url}>{"Release Notes Here"}</Link>
        </Typography>
      </>
    }
    </>
}


export default ReleaseNotes;
