import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { styled, Grid, Avatar, Card, Typography, CardActionArea } from '@mui/material';
import { ArrowForwardIosRounded } from '@mui/icons-material';

function NavigationCard(props) {

  const NavCardContainer = styled(Card)(
    ({ theme }) => `
      text-align: left;
      &:hover {
        background-color: ${theme.palette.primary.lighter};
      }
      .MuiSvgIcon-root {
        color: ${theme.palette.primary.text};
        font-size: 25px;
      }
      .nav-card-circle {
        width: 60px;
        height: 60px;
        background-color: transparent;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
        border: 2px solid ${theme.palette.primary.text};
          }
      .MuiTouchRipple-child {
        background-color: ${theme.palette.action.selected};
      }
    `
  );

  const icon = props.icon;
  let navIcon = '';
  if (icon) {
    navIcon = <Grid item xs={4} sm={1}><Avatar className="nav-card-circle">
      {icon}
    </Avatar></Grid>
  }

  return (

    <NavCardContainer sx={{ textAlign: 'left', borderRadius: '6px', boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)', padding: '0px', mb: 2 }}>
      <CardActionArea LinkComponent={Link} to={props.link}>
        <Grid container sx={{ alignItems: 'center', padding: '20px' }}>
          {navIcon}
          <Grid item xs sm={10} sx={{ pl: { xs: 0, sm: 1, md: 0 }, ml: { sm: 0, md: '-10px' }, mr: { sm: 0, md: '10px' } }}>
            <Typography color="primary.text" variant='overline'>{props.topText}</Typography>
            <Typography variant='subtitle1'>{props.cardTitle}</Typography>
            <Typography variant='body1'>{props.cardContent}</Typography>
          </Grid>
          <Grid item sm={1} sx={{ textAlign: 'right' }}>
            <ArrowForwardIosRounded />
          </Grid>
        </Grid>
      </CardActionArea>
    </NavCardContainer>
  );
}
NavigationCard.propTypes = {
  icon: PropTypes.element,
  link: PropTypes.string,
  topText: PropTypes.string,
  cardTitle: PropTypes.string,
  cardContent: PropTypes.string
}

export default NavigationCard;