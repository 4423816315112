import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Typography, Grid, styled, Alert, Chip } from '@mui/material';

import TableWithActionButton from 'src/components/TableWithActionButton';
import CollapsibleTable from '../CollapsibleTable';
import getColumns from './DataGridColumns';
import CalloutCardWithBtn from 'src/components/CalloutCardWithBtn/index.js';
import { ReactComponent as EmptyStateSvg } from 'src/images/EmptyStateDataSavedOn.svg'
import EmptyState from 'src/components/EmptyState/index.js';
import ErrorFileDownload from 'src/components/DataLoadsStatus/ErrorFileDownload';

import SkeletonRows from './SkeletonRows';

const GridWrapper = styled(Grid)(
  () => `

@media screen and (max-width: 768px) {
  .MuiDataGrid-cell {
  min-width: 150px !important;
  }
  .MuiDataGrid-columnHeader {
    min-width: 150px !important;
  }
}
`
)

function DataLoadsStatus({ isDataTableLoading, dataLoads, sortKey, fileType }) {

  const [isDownloadError, setIsDownloadError] = useState(false);
  const [customerDataLoads, setCustomerDataLoads] = useState([]);
  const [dateModifiedDataLoads, setDateModifiedDataLoads] = useState([]);

  function handleDownloadError() {
    setIsDownloadError(true);
  }

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  useEffect(() => {
    if (dataLoads?.length > 0 && fileType !== 'customer-files') {
      let _tempData = dataLoads.map((item) => {
        item.completedSortable = Date.parse(item.completed);
        item.submittedSortable = Date.parse(item.completed);
        return item;
      })
      setDateModifiedDataLoads(_tempData);
    }
    if (dataLoads?.length > 0 && fileType === 'customer-files') {
      const arrayMap = dataLoads.map((item) => {
        item.completedSortable = Date.parse(item.completed);
        item.submittedSortable = Date.parse(item.completed);
        item.variant = 'customer dataloads table';
        const colors  = {
          'Processing': 'info',
          'Failed': 'error',
          'Errors Found': 'warning',
          'Successful': 'success'
        };
        const color = colors[item?.fileStatus];
        if (color) {
          item.fileStatus = <Chip size="small" color={color} className={item?.fileStatus} label={item?.fileStatus} variant="outlined" />
        }
        const displayDownload = parseInt(item?.numOfErrors) > 0 || item?.fileStatus?.props?.label === 'Failed';
        if (displayDownload) {
          item.downloadFile = <ErrorFileDownload paddingTop="10px" height="40px" handleDownloadError={handleDownloadError} fileType='customer-files' dataLoadId={item?.id} />
        }
        return item;
      });
      setCustomerDataLoads(arrayMap);
    }
  },[dataLoads, fileType]) 

  return (
      <GridWrapper>
        {(dataLoads?.length === 0 && !isDataTableLoading) ?
          <EmptyState icon={<EmptyStateSvg />} /> :
          <>
            <Typography variant="h5" sx={{ mb: 1 }}>Submitted Lists</Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>LENS will process list against validation requirements upon intake.</Typography>
            {isDownloadError &&
              <Alert sx={{ mb: 2, mr: 3 }} onClick={clearDownloadError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>
            }
            {fileType === 'customer-files' ?
              <CollapsibleTable
                columns={getColumns(handleDownloadError)}
                data={customerDataLoads}
                message="No file activity to display."
                usePagination
                isLoading={isDataTableLoading} />
              :
              <TableWithActionButton
                sortKey={sortKey}
                useAutoRowHeight={true}
                skeleton={<SkeletonRows />}
                isDataTableLoading={isDataTableLoading}
                rows={dateModifiedDataLoads}
                columns={getColumns(handleDownloadError)}
                rowHeight={100}
                tableHeight={600} />
            }
            <Grid item xs={12} sx={{ pt:3 }}>
              <CalloutCardWithBtn />
            </Grid>
          </>
        }
      </GridWrapper>
  );
}

DataLoadsStatus.propTypes = {
  isDataTableLoading: PropTypes.bool.isRequired,
  dataLoads: PropTypes.array,
  sortKey: PropTypes.object,
  fileType: PropTypes.string
};

export default DataLoadsStatus;
