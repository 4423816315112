import React from 'react';
import { styled, Button, Box } from '@mui/material';
import { MailRounded } from '@mui/icons-material';

const BottomButtonWrapper = styled(Box)(
    ({ theme }) => `
            position: absolute;
            bottom: -100px;
            height: 100px;
            box-shadow: ${theme.sidebar.boxShadow};
            width: 100%;
            text-align: center;
            padding-top: 28px;
            background: ${theme.sidebar.background};
  `
  );

function SidebarBottomButton() {

    return (
        <BottomButtonWrapper>
            <div className="bottomButtonWrapper">
                <Button href="mailto:support@evadata.com" startIcon={<MailRounded />} variant="outlined" color="primary">Email Support</Button>
            </div>
        </BottomButtonWrapper>
        
    );
}

export default SidebarBottomButton;