const jsonExample = `{
  "header": {
    "new_matches": 2,
    "updated_matches": 2,
    "deleted_matches": 1,
    "categories": [
      "15.1",
      "15.2",
      "20.1",
      "20.2",
      "24.4",
      "26.2"
   ],
   "sources": [
      "state",
      "carrier-to-carrier",
      "funeral-home-obituary",
      "dmf",
      "newspaper-obituary"
   ], 
   "date_created": "20230323"
  },
  "new_matches": [
    {
      "carrier": {
        "ssn": "132586879",
        "country": "US",
        "first_name": "JAYNE",
        "last_name": "SMITH",
        "middle_name": "A.",
        "dob": "19651129",
        "address_line_1": "14725 88TH AVE",
        "address_line_2": "APT 7G",
        "city": "BOZEMAN",
        "state": "FL",
        "postal_code": "11435",
        "customer_file_prefix": "customer_file",
        "customer_id": "aa13300a1-1111-0aa0-0000-a00000test133v12",
        "data_load_id": "45c4bc10-8044-49f0-a933-3a0403e49927",
        "is_ssn_empty": false,
        "is_ssn_valid": true
      },
      "matches": [
        {
          "em_id": "3c436e97-d7b9-42e5-bce4-b66b676f1926",
          "match_date": "20230323",
          "category": "15.1",
          "operation": "new",
          "match": {
            "dob_fuzzy_match": false,
            "ssn_exact_match": null,
            "dob_exact_match": true,
            "first_name_exact_match": true,
            "first_name_fuzzy_match": false,
            "last_name_exact_match": true,
            "last_name_fuzzy_match": false,
            "city_exact_match": true,
            "state_exact_match": true,
            "ssn_fuzzy_match": null,
            "nickname_match": false
          },
          "death_record": {
            "first_name": "JAYNE",
            "last_name": "SMITH",
            "middle_name": "ANNA",
            "dod": "20220820",
            "dob": "19651129",
            "aka_1_first_name": "JAY",
            "maiden_name": "JOHNSON",
            "residence_city": "CLEARWATER",
            "residence_state": "FL",
            "associated_funeral_home": "Church of Florida",
            "funeral_service_city": "BOZEMAN",
            "funeral_service_info": "Visitation: Word of Life for Jayne",
            "funeral_service_state": "FL",
            "obit_start_date": "20220821",
            "obit_text": "Example Obit Test: Jayne died August 20th, 2022.",
            "salutation": "Ms.",
            "gender": "FEMALE",
            "military": "Jayne served in the US Military",
            "education": "Bachelor's Degree",
            "is_ssn_empty": true,
            "is_ssn_valid": false,
            "source_created": "20230111",
            "source": "funeral-home-obituary"
          }
        }
      ]
    },
    {
      "carrier": {
        "ssn": "102030407",
        "first_name": "AIDEN",
        "last_name": "BAKER",
        "dob": "19930607",
        "address_line_1": "1000 20TH AVE",
        "address_line_2": "APT 20",
        "city": "OAKLAND",
        "state": "CA",
        "country": "US",
        "postal_code": "94601",
        "customer_file_prefix": "customer_file",
        "customer_id": "aa33333a3-3333-3aa3-3333-a33333",
        "data_load_id": "45c4bc10-8044-49f0-a933-3a0403e49927",
        "is_ssn_empty": false,
        "is_ssn_valid": true
      },
      "matches": [
        {
          "em_id": "73a65ec2-8648-4585-afa8-465f26f9451f",
          "match_date": "20230323",
          "category": "20.2",
          "operation": "new",
          "match": {
            "dob_fuzzy_match": true,
            "ssn_exact_match": null,
            "dob_exact_match": false,
            "first_name_exact_match": null,
            "nick_name_match": null,
            "first_name_fuzzy_match": null,
            "last_name_exact_match": true,
            "last_name_fuzzy_match": false,
            "city_exact_match": false,
            "state_exact_match": true,
            "ssn_fuzzy_match": null
          },
          "death_record": {
            "first_name": "THOMAS",
            "last_name": "BAKER",
            "middle_name": "MATTHEW",
            "maiden_name": "ANDERSON",
            "aka_1_first_name":"TOM",
            "dod": "20220817",
            "dob": "19930608",
            "obit_start_date": "20230110",
            "obit_link": "https://www.legacy.com/us/obituaries/name/Thomas-baker",
            "funeral_service_info": "Visitation",
            "funeral_service_postal_code": "32801",
            "funeral_service_city": "ORLANDO",
            "funeral_service_state": "FL",
            "newspaper_city": "ORLANDO",
            "newspaper_postal_code": "32801",
            "newspaper_source": "ORLANDO HERALD",
            "is_ssn_empty": true,
            "is_ssn_valid": false,
            "source_created": "20230111",
            "source": "newspaper-obituary"
          }
        },
        {
          "em_id":"bac2e6d7-d225-43a7-8120-61f9e3ab720f",
          "match_date":"20230324",
          "category":"24.4",
          "operation":"new",
          "match": {
            "dob_fuzzy_match":true,
            "ssn_exact_match":false,
            "dob_exact_match":false,
            "first_name_exact_match":false,
            "nick_name_match": false,
            "first_name_fuzzy_match":true,
            "last_name_exact_match":null,
            "last_name_fuzzy_match":null,
            "city_exact_match":false,
            "state_exact_match":true,
            "ssn_fuzzy_match":false
          },
          "death_record":{
            "ssn":"128182182",
            "first_name":"AIDENN",
            "last_name":"PATTON",
            "middle_name":"JAMES",
            "fathers_last_name":"PATTON",
            "mothers_maiden_name":"JACKSON",
            "dob":"19930608",
            "dod":"20220405",
            "sex":"M",
            "is_ssn_empty":false,
            "is_ssn_valid":true,
            "death_state":"CA",
            "death_city":"APPLE VALLEY",
            "place_of_death":"2",
            "place_of_death_facility_name":"VEHICLE",
            "place_of_death_county":"SAN FRANCISCO",
            "place_of_death_address_street_number":"1901",
            "place_of_death_address_street_name":"APPLE VALLEY STREET",
            "place_of_birth_state_or_foreign_country":"CA",
            "source_created": "20230111",
            "source":"state"
          }
        },
        {
          "em_id": "dfc2e6d7-d225-43a7-8af0-61f9e3ab717d",
          "match_date": "20230323",
          "category": "26.2",
          "operation": "new",
          "match": {
            "dob_fuzzy_match":false,
            "ssn_exact_match":false,
            "dob_exact_match":true,
            "first_name_exact_match":true,
            "nick_name_match": null,
            "first_name_fuzzy_match":false,
            "last_name_exact_match":null,
            "last_name_fuzzy_match":null,
            "city_exact_match":false,
            "state_exact_match":false,
            "ssn_fuzzy_match":false
          },
          "death_record": {
            "ssn":"128128999",
            "first_name":"AIDEN",
            "last_name":"PATTON",
            "middle_name":"JAMES",
            "dob":"19930607",
            "dod":"20230103",
            "date_of_death_notification":"20230223",
            "is_ssn_empty":false,
            "is_ssn_valid":true,
            "residence_address_line_1":"1000 PEAR AVE",
            "residence_address_line_2":"APT 100",
            "residence_city":"ORLANDO",
            "residence_state":"FL",
            "residence_country":"US",
            "residence_postal_code":"32801",
            "source_created": "20230111",
            "source":"other-carrier"
          }
      }
      ]
    }
  ],
  "updated_matches": [
    {
      "carrier": {
        "ssn": "102030409",
        "first_name": "SARAH",
        "middle_name": "LYNN",
        "last_name": "GILMER",
        "dob": "19050227",
        "address_line_1": "1900 Apple Street",
        "city": "Apple Town",
        "state": "XM",
        "country": "MM",
        "postal_code": "19921",
        "customer_file_prefix": "customer_file",
        "customer_id": "aa5555e5-5555-5aa5-5555-a55555",
        "data_load_id": "8d49546a-79bd-4156-aadd-386369502803",
        "is_ssn_empty": false,
        "is_ssn_valid": true
      },
      "matches": [
        {
          "em_id": "3bd5a610-a5ab-40a9-8b4c-da3964a348a4",
          "match_date": "20230306",
          "category": "7.1",
          "operation": "update",
          "status": "new",
          "match": {
            "dob_fuzzy_match": null,
            "ssn_exact_match": true,
            "dob_exact_match": null,
            "first_name_exact_match": false,
            "nick_name_match": false,
            "first_name_fuzzy_match": true,
            "last_name_exact_match": false,
            "last_name_fuzzy_match": true,
            "city_exact_match": null,
            "state_exact_match": null,
            "ssn_fuzzy_match": null
          },
          "death_record": {
            "ssn":"102030409",
            "first_name": "SARAHS",
            "last_name": "GILMERR",
            "middle_name": "ALEX",
            "suffix":"MR",
            "aka_1_first_name": "SARA",
            "aka_1_middle_name": "Alexander",
            "aka_1_last_name": "GILMAR",
            "aka_1_suffix_name": "MR",
            "aka_2_first_name": "S",
            "aka_2_middle_name": "Alex",
            "aka_2_last_name": "GIL",
            "aka_2_suffix_name": "MR",
            "aka_3_first_name": "SAR",
            "aka_3_middle_name": "Al",
            "aka_3_last_name": "GILMERS",
            "aka_3_suffix_name": "MR",
            "dod": "20210927",
            "dob": "19730623",
            "residence_address_line_1":"5000 PEAR AVE",
            "residence_address_line_2":"APT 500",
            "residence_city":"GREENSBORO",
            "residence_state": "MT",
            "residence_county": "HENNIPEN",
            "residence_postal_code": "33190",
            "place_of_death_state": "MT",
            "place_of_death_county": "HENNIPEN",
            "place_of_birth_country": "US",
            "place_of_birth_state":"NC",
            "place_of_death":"Hospital/Inpatient",
            "place_of_death_facility_name": "Hennipen Hospitial",
            "place_of_death_city":"ASHVILLE",
            "usual_occupation": "bus driver",
            "usual_occupation_industry": "government",
            "fathers_first_name": "JIMMY",
            "fathers_middle_name": "JOHN",
            "fathers_last_name": "GILMER",
            "mothers_first_name": "MICHELA",
            "mothers_middle_name": "LANE",
            "mothers_maiden_name": "SMITH",
            "sex": "M",
            "race": "HISPANIC",
            "marital_status":"married",
            "education":"Bachelor's Degree",
            "is_ssn_empty":false,
            "is_ssn_valid":true,
            "source_created": "20230203",
            "source": "state"
          }
        }
      ]
    },
    {
      "carrier": {
        "ssn": "102030406",
        "first_name": "PAUL",
        "last_name": "JOHNSON",
        "dob": "19910202",
        "address_line_1": "9101 Pear Blvd",
        "city": "ORLANDO",
        "state": "FL",
        "country": "US",
        "postal_code": "32801",
        "customer_file_prefix": "customer_file",
        "customer_id": "aa22222a2-2222-2aa2-2222-a22222",
        "data_load_id": "45c4bc10-8044-49f0-a933-3a0403e49927",
        "is_ssn_empty": false,
        "is_ssn_valid": true
      },
      "matches": [
        {
          "em_id": "21dc0235-0ef4-44b9-91cc-071e3ffd7cc1",
          "match_date": "20230323",
          "operation": "update",
          "status": "updated",
          "death_record": {
            "ssn": "605040302",
            "first_name": "PAUL",
            "last_name": "NELSON",
            "middle_name": "ZAC",
            "dod": "20220215",
            "dob": "19910202",
            "suffix": "Mr",
            "ssa_verification_type": "V",
            "is_ssn_empty": false,
            "is_ssn_valid": true,
            "source_created": "20230111",
            "source": "dmf"
          }
        }
      ]
    }
  ],
  "deleted_matches": [
      {
          "carrier":{
            "ssn":"014813797",
            "first_name":"PEDRO",
            "middle_name":"E",
            "last_name":"FERNANDO",
            "dob":"19570809",
            "address_line_1":"13 IVKKBXN FBIB JIBFI",
            "city":"VERNON",
            "state":"CT",
            "postal_code":"06066",
            "country":"US",
            "customer_file_prefix": "customer_file",
            "customer_id": "bb3292e1-1771-4cb6-9228-d00832e23b90x2",
            "data_load_id": "8d49546a-79bd-4156-aadd-386369502803",
            "is_ssn_empty": false,
            "is_ssn_valid": true
          },
          "matches":[
            {
              "em_id": "f103d4fd-b569-4ad9-b8b1-f310c3f7b7ae",
              "match_date": "20230305",
              "operation": "delete",
              "reason": {
                "description": "Source requested record deleted with no additional context.",
                "code": "01"
              },
              "death_record": {
                "ssn": "014813796",
                "first_name": "PEDRI",
                "last_name": "FERNANDA",
                "middle_name": "DOC",
                "dod": "20221105",
                "dob": "19570808",
                "suffix": "Mr",
                "is_ssn_empty": false,
                "is_ssn_valid": true,
                "source_created": "20230201",
                "source": "dmf"
              }
            }
          ]
        }
  ]
}`;

export default jsonExample;
