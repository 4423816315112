import React from 'react';
import { Typography, Grid } from '@mui/material';
import CopyButton from '../CopyButton';
import FileDownloadCard from '../FileDownload';
import AwsInstructionFields from './awsinstructionfields';
import PropTypes from 'prop-types';

function AwsFields(props) {
     
    return (
      <>
        {props.showTransferLocationMethod && <><Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>Transfer Location Method:</Typography><Typography variant="body1" sx={{ mb: 2 }}>{'AWS S3 (Simple Storage Service)'}</Typography></>}
        <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>AWS Account ID <Typography display="inline" variant="body2">(Provided by my organization):</Typography></Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>{props.accountId}</Typography>
        <Typography variant="subtitle1" sx={{ mt: 1, mb: 0 }}>Carrier AWS Cloud Formation Template<Typography display="inline" variant="body2"> (Provided by LENS):</Typography></Typography>
        <FileDownloadCard variation="icon" fileName={props?.cfTemplateFileName} file={props?.cfTemplateFile} fileType='yml' label={props?.cfTemplateLabel}/>
        <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>{"Downloading and deploying this template with the Lambdas in your AWS environment provides S3 Bucket" + (!props.isMatchFileForm ? ", KMS Key (optional)" : '') + " and IAM Role ARN output values."}</Typography>
        {props.showLambdaArn && <><Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>Lambda ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
        <Grid container>
          <Grid item xs={1}>
            <CopyButton toolTipText="Copy ARN" textToCopy={props.evadataLambdaArn} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" sx={{ mb: 1 }}>{props.evadataLambdaArn} </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ mb: '16px' }}>Use Lambda ARN to set the event trigger for the S3 bucket.</Typography></>}
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{props.isMatchFileForm && 'Matches '}Lambda Role ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
        <AwsInstructionFields isMatchFileForm={props.isMatchFileForm} instructionText={props.instructionText} evadataLambdaRoleArn={props.evadataLambdaRoleArn} enableTooltip={props.showTooltip}></AwsInstructionFields>
      </>
    );
  }

  AwsFields.propTypes = {
    showTransferLocationMethod: PropTypes.bool,
    accountId: PropTypes.string,
    cfTemplateFileName: PropTypes.string,
    cfTemplateFile: PropTypes.string,
    cfTemplateLabel: PropTypes.string,
    isMatchFileForm: PropTypes.bool,
    showLambdaArn: PropTypes.bool,
    evadataLambdaArn: PropTypes.string,
    instructionText: PropTypes.string,
    evadataLambdaRoleArn: PropTypes.string,
    showTooltip: PropTypes.bool,
  };

  export default AwsFields;
