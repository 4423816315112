import React from 'react';
import { Grid, Typography, Card, CardContent, styled, Button } from '@mui/material';

import { Link } from 'react-router-dom';

const CardWrapper = styled(Card)(
  () => `
    border-radius: 6px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    }
    .button {
      text-align: right;
      align-self:center;
    }

    @media screen and (max-width: 960px) {
      .button {
        text-align: left;
        margin-top: 20px;
      }
    }
  `
);

function CalloutCardWithBtn() {

  return (
    <CardWrapper>
      <CardContent>
        <Grid className="cardTop" container sx={{ padding: 1 }}>
          <Grid item sm={12} md={6}>
            <Typography variant="h5" color="primary.dark" sx={{mb:2}}>Getting Started with LENS?</Typography>
            <Typography variant="body1" color="text.secondary">Everything you need to know when submitting data to LENS.</Typography>
          </Grid>
          <Grid className="button" item sm={12} md={6}>
              <Button LinkComponent={Link} to="/getting-started" variant="outlined" color="primary">Learn More</Button>
          </Grid>
        </Grid>
      </CardContent>
    </CardWrapper>
  );
}

export default CalloutCardWithBtn;