import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { styled, Box, Alert, Button } from '@mui/material';

const AlertWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    top: 75px;
    z-index:9;
    width: calc(100% - 290px);
    left: 290px;
    .MuiPaper-root {
      position: relative;
      box-shadow: 0px 2px 4px ${theme.palette.text.primary}99;
      width: 300px;
      margin: 0 auto;
    }
    .MuiButtonBase-root {
      position: absolute;
      right: 0px;
      top: 5px;
      font-weight: 500;
      text-transform: capitalize;
      text-decoration: underline;
    }
    @media screen and (max-width: 1279px) {
      left: 0px;
      width: 100%;
    }
  `
);

function ErrorAlert({ formik, showErrorAlert, specialRef = null }) {
  const [errorCount, setErrorCount] = useState(0);
  useEffect(() => {
    if (formik.errors) {
      let _count = Object.keys(formik.errors).length;
      setErrorCount(_count);
    }
  }, [formik])
  const scrollErrorIntoView = () => {
    if (Object.keys(formik.errors).length > 0) {

      const element = document.getElementsByName(Object.keys(formik?.errors)[0])[0];

      if (element) {
        element.focus();
      }
      if (!element && specialRef) {
        specialRef.current.scrollIntoView();
      }
    }
  }

  return (
    ((errorCount > 0) && showErrorAlert) && <AlertWrapper>
      <Alert
        action={
          <Button onClick={scrollErrorIntoView} variant="text" color="error">View</Button>
        } severity="error">
        {errorCount} Error(s) Found</Alert>
    </AlertWrapper>
  );
}

ErrorAlert.propTypes = {
  formik: PropTypes.object,
  showErrorAlert: PropTypes.bool,
  specialRef: PropTypes.string
}

export default ErrorAlert;