import React from 'react';
import PropTypes from 'prop-types';

import { Typography, styled, Grid, Avatar } from '@mui/material';


function PageHeader({title, subtitle, icon, titlePaddingTop = 0 }) {

  const HeaderIconContainer = styled(Grid)(
    ({ theme }) => `
      text-align: left;
      .MuiSvgIcon-root {
        color: ${theme.palette.primary.text};
        font-size: 40px;
      }
    `
  );

  let headerIcon;
  if (icon) {
    headerIcon = <Grid item xs sx={{maxWidth: '90px !important', display: {xs: 'none', md: 'flex' } }}><Avatar sx={{width: '72px', height: '72px', backgroundColor: 'primary.contrast', borderRadius: '6px', boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2);'}} variant="square">
      {icon}
  </Avatar></Grid>
  } else {
    headerIcon = '';
  }

  return (
    <HeaderIconContainer container sx={{mb:5}}>
        {headerIcon}
        <Grid item xs>
            <Typography variant="h4" color="primary.text" sx={{mb:1, pt: titlePaddingTop }}>{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
        </Grid>
    </HeaderIconContainer>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string, 
  subtitle: PropTypes.string,
  icon: PropTypes.element,
  titlePaddingTop: PropTypes.string
}


export default PageHeader;