import React from 'react';

import PropTypes from 'prop-types';
import { Button, Typography, Box, Grid } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { Link } from 'react-router-dom';

import DisplayFilePrefixAndType from 'src/components/DisplayFilePrefixAndType';
import DisplayPgpFilePreference from "src/components/DisplayPgpFilePreference";
import CopyButton from 'src/components/CopyButton';
import FileDownloadCard from 'src/components/FileDownload';

import * as Constants from 'src/constants';

function VerifiedDeathsFileSetupSummary(props) {

  let pgpMessage = 'Do not encrypt list with PGP key.';
  let isPgpGenerated = false;

  if (props?.transfer?.type === 'sftp') {
    if (props?.transfer?.sftp?.pgp && !props?.transfer?.sftp?.pgp?.keyRotationInYears) {
      pgpMessage = 'Encrypt my file with same PGP key used for Customer File';
    } else if (props?.transfer?.sftp?.pgp) {
      pgpMessage = 'Encrypt list with new public PGP key created by LENS.'
      isPgpGenerated = true;
    }
  }

  return (
    <>
      <Box sx={{ p: 5 }}>
        <DisplayFilePrefixAndType filePrefix={props?.file?.prefix} fileType={props?.file?.type} transferFreq={props?.transfer?.frequency} fileExampleToolTip="Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily." scheduleNote="Schedule informs LENS on when to expect your list." />

        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 4, mb: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

        <Typography variant="subtitle1" color="text.primary">Transfer Location Method:</Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.type === 'sftp' ? 'SFTP (Secure File Transfer Protocol)' : 'AWS S3 (Simple Storage Service)'}</Typography>

        {(props?.transfer?.type === 'sftp') &&

          <>
            <Typography variant="subtitle1" color="text.primary">LENS SFTP Server Hostname:</Typography>
            <Grid container className="vfd-summary-sftp">
              <Grid item xs={1}>
                <CopyButton className="vfd-summary-sftp-copy-btn" toolTipText="Copy SFTP Server" textToCopy={props?.transfer?.sftp?.sftpServer} />
              </Grid>
              <Grid item xs={11}>
                <Typography className="vfd-summary-sftp-copy-btn-text" variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.sftp?.sftpServer}</Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" color="text.primary">Port:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{Constants.lensSftpPort}</Typography>
            <Typography variant="subtitle1" color="text.primary">Subdirectory:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>/death_files</Typography>
            <Typography variant="subtitle1" color="text.primary">What credentials would you like to use when accessing LENS SFTP to transfer your Verified Deaths list?</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.sftp?.username === props?.customerFileUser ? 'Use same username and SSH-RSA key used for Customer list.' : 'Provide new username and SSH-RSA key for Verified Deaths list.'}</Typography>

            <Typography variant="subtitle1" color="text.primary">SFTP Username:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.sftp?.username}</Typography>

            <Typography variant="subtitle1" color="text.primary" >SSH-RSA key to authenticate to access the virtual server instance:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>For security reasons, the public SSH-RSA key provided is hidden.</Typography>

            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Use the credentials to access LENS SFTP. At the transfer frequency identified, place your Verified Deaths list in the SFTP subdirectory <strong>(/death_files)</strong>.</Typography>

            <Typography variant="subtitle1" color="text.primary">File Encryption Preference:</Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{pgpMessage}</Typography>
          </>
        }
        {
          ((props?.transfer?.type === 'sftp') && isPgpGenerated) ? <>
            <DisplayPgpFilePreference pgpKeyRotationInYears={props?.transfer?.sftp?.pgp?.keyRotationInYears} pgpPublicKey={props?.transfer?.sftp?.pgp?.publicKey} showButtons={true} buttonText="KEY" fileName={'deaths-' + props?.file?.prefix + '.key'} />
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Download the public PGP key to encrypt your Verified Deaths list.</Typography>
          </>
            : ''}

        {(props?.transfer?.type === 'awsS3') &&
          <>
            <Typography variant="subtitle1" color="text.primary">AWS Account ID <Typography variant="body2" display="inline">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.accountId}</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 0 }}>Carrier AWS Cloud Formation Template<Typography display="inline" variant="body2"> (Provided by LENS):</Typography></Typography>
            <FileDownloadCard variation="icon" fileName={"lens-carrier-aws-" + props.file.prefix + "-files-option.yml"} file='carrier-aws-vdf-option.yml' fileType='yml' label="Verified Deaths Template" />
            <Typography sx={{ mb: 2 }} variant="body2" >Downloading and deploying this template with the Lambdas in your AWS environment provides S3 Bucket, KMS Key (optional) and IAM Role ARN output values.</Typography>
            <Typography variant="subtitle1" color="text.primary">Lambda ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy ARN" textToCopy={props?.transfer?.awsS3?.evadataLambdaArn} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.evadataLambdaArn}</Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" color="text.primary" >Lambda Role ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
            <Grid container>
              <Grid item xs={1}>
                <CopyButton toolTipText="Copy ARN" textToCopy={props?.transfer?.awsS3?.evadataLambdaRoleArn} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.evadataLambdaRoleArn}</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Lambda ARNs are used by your organization to set the event trigger for the S3 bucket, and to set role permissions for LENS to access your S3 bucket to retrieve your list.</Typography>
            <Typography variant="subtitle1" color="text.primary">S3 Bucket ARN <Typography display="inline" variant="body2">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.s3Bucket}</Typography>
            {props?.transfer?.awsS3?.kmsArn && <>

              <Typography variant="subtitle1" color="text.primary">KMS Key ARN <Typography display="inline" variant="body2">(Provided by my organization):</Typography></Typography>
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.kmsArn}</Typography>
              <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>LENS uses the KMS Key ARN to decrypt your transferred list.</Typography>
            </>
            }
            <Typography variant="subtitle1" color="text.primary">IAM Role ARN <Typography display="inline" variant="body2">(Provided by my organization):</Typography></Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{props?.transfer?.awsS3?.iamRole}</Typography>
            <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown.</Typography>
          </>
        }
        <hr />

        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>NOTIFICATION PREFERENCES</Typography>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Email(s) to receive notifications related to this list.</Typography>
        {props?.notificationEmails?.map((email) => {
          return <Typography variant='body1' sx={{ mb: 4 }} key={email}>{email}</Typography>
        })}
      </Box>

      <hr />

        <Grid container sx={{ px: 5, pb: 4 }}>
          <Grid item sm={6}>
            <Button LinkComponent={Link} to="/customer-file-setup" sx={{ mt: 3 }} variant="text" startIcon={<ArrowBack />}>
              Previous step
            </Button>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: 'right' }}>
            <Button LinkComponent={Link} to="/match-results-file-setup" sx={{ mt: 3 }} variant="contained" type="submit" endIcon={<ArrowForward />}>
              Next Step
            </Button>
          </Grid>
        </Grid>
    </>
  );
}

VerifiedDeathsFileSetupSummary.propTypes = {
  customerFileUser: PropTypes.string,
  accountId: PropTypes.string,
  file: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  transfer: PropTypes.shape({
    frequency: PropTypes.string,
    username: PropTypes.string,
    type: PropTypes.string,
    sftp: PropTypes.object,
    awsS3: PropTypes.object
  }),
  pgp: PropTypes.shape({
    publicKey: PropTypes.string,
    rotationInYears: PropTypes.string
  }),
  notificationEmails: PropTypes.arrayOf(PropTypes.string),
  sftpServer: PropTypes.string,
};

export default VerifiedDeathsFileSetupSummary;