import { useState, useRef, useEffect } from 'react';
import { useAppContext } from 'src/AppContext';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import {
  Grid,
  Drawer,
  Box,
  Card,
  Divider,
  styled,
  Alert,
  Backdrop,
} from '@mui/material';

import PageHeader from './CalendarPageHeader';

import Actions from './Actions';
import EventDrawer from './EventDrawer';
import { getHolidayCalendars } from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};

    & .fc-license-message {
      display: none;
    }
    .fc {

      .fc-col-header-cell {
        background: ${theme.colors.alpha.black[5]};
        font-weight: bold;
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      a.fc-event {
        background-color: ${theme.palette.primary.main} !important;
        text-align: center;
        border-radius: 8px;
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover, 
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: #eeeee !important;
      }

      .fc-cell-shaded, 
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[3]} !important;
        color: ${theme.colors.alpha.black[100]} !important;
        height: 60px;
        padding-top: 18px;
      }

      &.fc-theme-standard td, 
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }
      .fc-event-time, .fc-list-event-time, .fc-list-event-dot {
        display: none;
      }
      td.fc-list-event-graphic {
        display: none;
      }
      .fc-list-event-title {
        height: 60px;
        padding-top: 18px;
    }
    .fc-list-event-title {
      cursor: pointer;
    }
`
);
const BackdropWrapper = styled(Box)(
  ({ theme }) => `
    .calendar-drawer-backdrop {
      opacity: 0.5 !important;
    }
`
);

function ApplicationsCalendar() {
  const { carrierConfig } = useAppContext();
  const carrierId = carrierConfig.carrierId;
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [calendarId, setCalendarId] = useState(null);
  const [calendarName, setCalendarName] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('listYear');
  const [alertMessage, setAlertMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const [genericError, setGenericError] = useState(false);

  function reload() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  const openDrawerPanel = () => {
    setIsDrawerOpen(true);
  }

  const closeDrawerPanel = () => {
    setIsDrawerOpen(false);
  }

  const handleDateToday = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.today();
      setDate(calApi.getDate());
    }
  };

  const changeView = (changedView) => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.changeView(changedView);
      setView(changedView);
    }
  };

  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      setDate(calApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      setDate(calApi.getDate());
    }
  };

  const handleAddClick = () => {
    setSelectedEvent(null);
    openDrawerPanel();
  };

  const handleDateClick = (info) => {
    setSelectedEvent(info);
    openDrawerPanel();
  };

  const handleEventSelect = (arg) => {
    setSelectedEvent({ date: arg.event.start, title: arg.event.title, id: arg.event.id });
    openDrawerPanel();
  };

  const onAddEditComplete = (alertMessage) => {
    setAlertMessage(alertMessage);
  }

  const transformEvents = (_rawEvents) => {
    if (_rawEvents) {
      const result = Object.keys(_rawEvents?.[0]?.calendar).map((key, index) => [
        {
          id: index,
          start: new Date(key),
          title: _rawEvents[0]?.calendar[key]
        }]);
      return result.flat(1);
    }
  }

  const getEvents = (info, successCallback) => {
    successCallback(events);
  };

  useEffect(() => {
    if (events?.length === 0) {
      setIsLoading(true);
    }
    let _events = [];
    const getCalendar = async function (carrierId) {
      const results = await getHolidayCalendars(carrierId);

      if (results[0]?.calendar) {
        try {
          setCalendarId(results[0]?.id);
          setCalendarName(results[0]?.name);
          _events = transformEvents(results);
          setEvents(_events);
        }
        catch (e) {
          setIsLoading(false);
          setGenericError(true);
        }
      }
    }
    getCalendar(carrierId).finally(() => {
      setIsLoading(false);
      closeDrawerPanel();
      if (alertMessage) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      }
    });
  }, [carrierId, stateRefreshCounter, events?.length]);

  return (
    <>
      {isLoading && <LoadingIcon />}
      <BackdropWrapper>
        <Backdrop
          className='calendar-drawer-backdrop'
          sx={{ color: '#fff', zIndex: 999 }}
          open={isDrawerOpen}
        />
      </BackdropWrapper>
      <Box>
        <PageHeader handleCreateEvent={handleAddClick} />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {genericError && <Alert severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
          {showAlert && <Alert sx={{ width: '100%', mb: 2 }}>{alertMessage}</Alert>}
          <Card>
            <Box p={3}>
              <Actions
                date={date}
                onNext={handleDateNext}
                onPrevious={handleDatePrev}
                onToday={handleDateToday}
                changeView={changeView}
                view={view}
              />
            </Box>
            <Divider />
            <FullCalendarWrapper>
              <FullCalendar
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                dateClick={handleDateClick}
                droppable
                editable
                eventDisplay="block"
                eventClick={handleEventSelect}
                dayMaxEventRows={4}
                eventResizableFromStart
                events={(info, successCallback) => getEvents(info, successCallback)}
                headerToolbar={false}
                height={660}
                ref={calendarRef}
                rerenderDelay={10}
                selectable
                stickyHeaderDates={false}
                weekends
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin
                ]}
              />
            </FullCalendarWrapper>
          </Card>
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        anchor='right'
        onClose={closeDrawerPanel}
        open={isDrawerOpen}
        elevation={9}
      >
        {isDrawerOpen && (
          <EventDrawer
            sx={{ width: '500px' }}
            calendarId={calendarId}
            calendarName={calendarName}
            carrierId={carrierId}
            events={events}
            setEvents={setEvents}
            event={selectedEvent}
            onAddComplete={onAddEditComplete}
            onCancel={closeDrawerPanel}
            onDeleteComplete={closeDrawerPanel}
            onEditComplete={onAddEditComplete}
            reload={reload}
          />
        )}
      </Drawer>
    </>
  );
}

export default ApplicationsCalendar;
