import { React } from 'react';
import { Grid, Card, styled, Typography, Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Button } from '@mui/material';
import CopyButton from 'src/components/CopyButton';
import PropTypes from 'prop-types';
import DisplayFileSample from 'src/components/DisplayFileSample';

const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
    }
    .lock-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .account-id-row {
      border-bottom: 1px solid ${theme.palette.common.black}66;
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    th {
      color: ${theme.palette.common.black};
      font-size: 15px;
    }
    tr {
      border-top: 2px solid ${theme.palette.common.black}33;
    }
    tr.MuiTableRow-head {
      border-top: 3px solid ${theme.palette.common.black}33;
      border-bottom: 1px solid ${theme.palette.common.black}90;
    }
  `
);

function KmsKeyTable({ data, transferFreq, viewDetails, associatedData }) {
  const associatedEncryptedData = associatedData.filter((item) => item?.transfer?.encryptionEnabled || item?.configurationType === 'matches');

  return (
    <CardWrapper>
      <Card sx={{ minWidth: 275, textAlign: 'left', mt: 3, mb: 3, }}>
        <Grid container>
          <Grid className="account-id-row-kms-key" item xs={12} sx={{ px: 2, py: 1 }}><Typography color='text.secondary' variant='overline' >AWS Account ID: {data?.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item sx={{ pt: 1, pl: 2, pb: 1 }} >
                <Typography variant="subtitle1" color="text.secondary" display="inline" >Key ARN: </Typography> 
                <Typography sx={{ mb: 1 }} variant="subtitle1" display="inline">
                  {data?.transfer?.awsS3?.evadataMatchLambdaKmsArn && <CopyButton display="inline" toolTipText="Copy ARN" textToCopy={data?.transfer?.awsS3?.evadataMatchLambdaKmsArn} />}
                  {data?.transfer?.awsS3?.evadataMatchLambdaKmsArn || data?.transfer?.awsS3?.kmsArn}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ px: 2, pb: 2 }}>{data?.transfer?.awsS3?.kmsArn ? 'LENS uses the ARN to decrypt your list(s).' : 'Use the ARN to decrypt your matches.'} </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} sx={{ p: 2, textAlign: 'right' }}>
            <Button onClick={() => viewDetails(data, associatedEncryptedData)} variant="outlined" color="primary">{data?.transfer?.awsS3?.kmsArn ? 'Manage' : 'View Details'}</Button>
          </Grid>
        </Grid>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: '7px' }}>Lists Using This Key</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="aws-kms-key-table">
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {data?.file?.prefix ? 
                  <DisplayFileSample nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} type={data?.file?.type} />
                  :
                  <Typography variant="caption"><Skeleton variant="rectangle" /></Typography>
                }
              </TableCell>
            </TableRow>
            {associatedEncryptedData?.map((item) => (
              <TableRow key={item?.file?.prefix}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item?.file?.prefix ? 
                    <DisplayFileSample nickname={item?.nickname} annualDmfEnabled={Boolean(item?.transfer?.annualDmf)} prefix={item?.file?.prefix} type={item?.file?.type} />
                    :
                    <Typography variant="caption"><Skeleton variant="rectangle" /></Typography>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </CardWrapper>
  )
}

KmsKeyTable.propTypes = {
  data: PropTypes.object,
  transferFreq: PropTypes.string,
  viewDetails: PropTypes.func,
  associatedData: PropTypes.array
};

export default KmsKeyTable;