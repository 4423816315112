export function mailError(error) {
  let body;
  const subject = encodeURIComponent('LENS Application error');

  if(error) {
    if(error.stack) {
      body = encodeURIComponent(error.stack);
    } else {
      body = encodeURIComponent(error);
    }
  }

  const location = `mailto:support@evadata.com?subject=${subject}&body=${body}`;

  window.location = location;
};