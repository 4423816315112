import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, FormHelperText, FormControl, Select, MenuItem, Radio, RadioGroup, TextField, Typography, Box, styled } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { OpenInNewRounded } from '@mui/icons-material';

const UsernameSelectWrapper = styled(Box)(
  () => `
    .MuiList-root-MuiMenu-list .MuiMenuItem-root.MuiButtonBase-root {
      flex-wrap: wrap !important;
    }
  `
);

function SftpCredentials({ useExistingUser, newUsername, newSSHRsaKey, formik, usernameInUseError, usernames, sshRef, sshKeyError  }) {

  function onExistingUserChange(event) {
    formik.handleChange(event);
    formik.setFieldValue(newUsername, '', false);
    formik.setTouched({newUsername:false});
  }

  return (
      <RadioGroup
        id={useExistingUser}
        name={useExistingUser}
        value={formik?.values?.[useExistingUser]}
        onChange={onExistingUserChange}
        sx={{ mb: 3 }}
      >

        <FormControlLabel sx={{ mb: 1 }} value={false} control={<Radio />} label="New Username and SSH-RSA key." />
        
        {(formik?.values?.[useExistingUser] === 'false' || formik?.values?.[useExistingUser] === false) &&
        <div>
          <TextField ref={sshRef}
            className="username"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            type='text'
            id={newUsername}
            name={newUsername}
            label="SFTP Username"
            value={formik?.values?.[newUsername]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik?.touched?.[newUsername] && Boolean(formik?.errors?.[newUsername])}
            helperText={formik?.touched?.[newUsername] ? formik?.errors?.[newUsername] : 'Minimum 3 characters. Username can’t start with hyphen –, @ symbol or period.'}
            sx={{ mb: 2, ml: 4 }}
          />

          {usernameInUseError ? <FormHelperText sx={{ mt: '-20px', mb: 4 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Username entered is already in use, try a different name.</FormHelperText> : ''}

          <Typography variant="body1" color="text.secondary" sx={{ mb: 2, ml: 4 }}>Create SSH-RSA key to authenticate to access LENS virtual server instance. <a className="ssh-link" href="https://docs.aws.amazon.com/transfer/latest/userguide/key-management.html#sshkeygen" target="blank">Learn More <OpenInNewRounded sx={{ fontSize: '18px', mb: '-4px', ml: '4px' }} /></a></Typography>

          <TextField
            fullWidth
            multiline
            rows={6}
            id={newSSHRsaKey}
            name={newSSHRsaKey}
            label="Enter Public SSH-RSA Key"
            value={formik?.values?.[newSSHRsaKey]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik?.touched?.[newSSHRsaKey] && Boolean(formik?.errors?.[newSSHRsaKey])}
            helperText={formik?.touched?.[newSSHRsaKey] ? formik?.errors?.[newSSHRsaKey] : ''}
            sx={{ mb: 3, ml: 4 }}
          />

          {sshKeyError ? <FormHelperText sx={{ mt: '-20px', mb: 4, ml: 3 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Unsupported ssh key format. RSA, ECDSA and ED25519 are supported formats.</FormHelperText> : ''}

          <Typography sx={{ ml: 4, mb: 2 }} variant="body2">For security reasons, the public SSH-RSA key provided will be hidden after saving.</Typography>
        </div>
        }

        <FormControlLabel value={true} control={<Radio />} label="Existing Username and SSH-RSA key." />
        {(formik?.values?.[useExistingUser] === true || formik?.values?.[useExistingUser] === 'true') &&

        <div>

          <UsernameSelectWrapper>
            <FormControl sx={{ mt: 2, mb: 2, ml: 4 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>Use access credentials from an existing list.</Typography>
              <Select
                className="username-select"
                displayEmpty
                id={newUsername}
                name={newUsername}
                value={formik?.values?.[newUsername]}
                onChange={formik.handleChange}
                error={formik?.touched?.[newUsername] && Boolean(formik?.errors?.[newUsername])}
                sx={{ width: '400px' }}
              >
                <MenuItem selected value="">Choose List</MenuItem>
                {usernames?.map((item) =>
                (
                  <MenuItem key={item?.username} value={item?.username}>
                    <div>
                      <Typography sx={{ width: '100%' }} variant="subtitle1">{(item?.filePrefix.length > 1) ? item?.filePrefix.join(', ') : item?.filePrefix}</Typography><Typography sx={{ width: '100%' }} variant="body2" color="text.primary"><Typography display="inline" variant="body2" color="text.secondary">Username: </Typography>{item?.username}</Typography>
                    </div>
                  </MenuItem>
                ))
                }
              </Select>

              {formik?.touched?.[newUsername] && Boolean(formik?.errors?.[newUsername]) ? <FormHelperText sx={{ ml: '-10px' }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />List selection is required.</FormHelperText>: ''}

            </FormControl>
          </UsernameSelectWrapper>

          <Typography variant="body1" color="text.primary" sx={{ mb: 1, ml: 4 }}>For security reasons, the public SSH-RSA key your organization provided is hidden. If you do not have access to this key, please choose the option to create new credentials.</Typography>
        </div>
        }
      </RadioGroup>
  )
}

SftpCredentials.propTypes = {
  useExistingUser: PropTypes.string, 
  newUsername: PropTypes.string, 
  newSSHRsaKey: PropTypes.string, 
  formik: PropTypes.object, 
  usernameInUseError: PropTypes.bool,  
  usernames: PropTypes.array, 
  sshRef: PropTypes.string,
  sshKeyError: PropTypes.bool
};

export default SftpCredentials;
