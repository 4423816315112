export function combineMonthsDays(months, days){
  return months.map((month, i) => {
    const _month = `${month < 10 ? '0' : ''}${month}`;
    const _day = `${days[i] < 10 ? '0' : ''}${days[i]}`;
    
    return `${_month}/${_day}`;
  })
}

export function combineMonthDay(month, day, includeSlash=false){
  const _month = `${month < 10 ? '0' : ''}${month}`;
  const _day = `${day < 10 ? '0' : ''}${day}`;

  return `${_month}${includeSlash ? '/' : ''}${_day}`;
}