import React from 'react';
import DisplayTransferAndEncryption from 'src/components/DisplayTransferAndEncryption';

function TransferLocations() {

  return (
    <DisplayTransferAndEncryption keyType="transfer" />
  );
}

TransferLocations.propTypes = {};

export default TransferLocations;