import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

function TransferFreqDisplayOnly({frequency}) {
  return (
    <>
      <Typography variant="subtitle1" color="text.primary">Transfer Frequency Schedule:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 2, textTransform: 'capitalize' }}>{frequency}</Typography>

      <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Schedule informs LENS on when to expect your file.</Typography>
    </>
  )
}

TransferFreqDisplayOnly.propTypes = {
  frequency: PropTypes.string
};

export default TransferFreqDisplayOnly;
