import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'formik';

import { FormControlLabel, Box, Checkbox, Typography, FormHelperText } from '@mui/material';
import { useCurrentUserEmail } from 'src/utilities/getCurrentUser';
import DefaultChip from '../DefaultChip';

function EmailCheckboxEntry({ availableEmailAddresses, formInputName, hasError }) {

  const userEmail = useCurrentUserEmail();

  return (
    <Box sx={{ mb: 2 }} role="optgroup" aria-labelledby="checkbox-group">
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Email(s) to receive notifications related to this list:
      </Typography>

      {hasError ? 
      <FormHelperText sx={{ mb: 1, mt: 1, ml: -1}} error={hasError}>Minimum of one email required.*</FormHelperText> :
      <FormHelperText sx={{ mb: 1, mt: 1, ml: -1 }}>Minimum of one email required.</FormHelperText>}

      {availableEmailAddresses?.map((item) => (
        <div key={item?.id}>
          {item?.email === userEmail ?
            <>
              <Field as={FormControlLabel} name={formInputName} label={item?.email} value={item?.email} control={<Checkbox defaultChecked />} sx={{ ml: 1 }} /><DefaultChip /> <br />
            </>
            :
            ''
          }
        </div>
      ))}
      {availableEmailAddresses?.length > 1 &&
       <FormHelperText sx={{ ml: -1 }}><Typography variant="body1">Select emails previously added to LENS.</Typography></FormHelperText>}

      {availableEmailAddresses?.map((item) => (
        <div key={item?.id}>
          {item?.email !== userEmail ?
            <>
              <Field as={FormControlLabel} type="checkbox" name={formInputName} label={item?.email} value={item?.email} control={<Checkbox />} sx={{ ml: 1 }} /><br />
            </> : ''
          }
        </div>
      ))}
    </Box>
  );
}

EmailCheckboxEntry.propTypes = {
  availableEmailAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  formInputName: PropTypes.string.isRequired,
  hasError: PropTypes.any
};

export default EmailCheckboxEntry;
