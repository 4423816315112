import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Typography, Grid, Button, ThemeProvider } from '@mui/material';
import { ReactComponent as EvadataLogo } from 'src/images/EvadataLogo404.svg'
import UnauthorizedGraphic from 'src/images/401UnauthorizedGraphic.png';
import { ArrowBack, MailRounded } from '@mui/icons-material';
import { useRouteError } from 'react-router-dom';
import { EvadataTheme } from 'src/theme/schemes/EvadataTheme';
import { mailError } from 'src/utilities/mailError';

function NotAuthorized401({error}) { // the LoginCallback uses this as an errorComponent, hence error prop
  const routeError = useRouteError();
  const _error = error|| routeError;

  const { oktaAuth } = useOktaAuth();
  const handleLogout = async () => oktaAuth.signOut();

  return (
    <ThemeProvider theme={EvadataTheme}>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '100px' }}>
          <EvadataLogo />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={UnauthorizedGraphic} alt="Unauthorized error 401" />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
          <Typography variant="h2" sx={{ maxWidth: '700px', margin: '0 auto' }}>No authentication found</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography variant="h5" color="black" sx={{ maxWidth: '700px', margin: '0 auto' }}>Sorry but we couldn’t validate your credentials. Please try signing in again or contact LENS support if the issue persists.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { sm: 'right', xs: 'center' }, mt: 6 }}>
          <Button sx={{ mr: { sm: 3, xs: 0 }, minWidth: '192px' }} startIcon={<MailRounded />} onClick={() => mailError(_error)} variant="outlined" color="primary">Email Support</Button>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { sm: 'left', xs: 'center' }, mt: { sm: 6, xs: 2 }, mb: 5 }}>
          <Button sx={{ ml: { sm: 3, xs: 0 }, minWidth: '192px' }} onClick={handleLogout} variant="contained" color="primary" startIcon={<ArrowBack />}>Back to Sign In</Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

NotAuthorized401.propTypes = {};

export default NotAuthorized401;