import { React } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function DisplayFileSample({ nickname=false, annualDmfEnabled=false, prefix, date='-XXXXXXXX', annualDmfDate='-XXXXXXXX', type, variant='table' }) {
  const variantMap = {
    body: {
      nickname: 'body1',
      fileSample: 'body2'
    },
    table: {
      nickname: 'body2',
      fileSample: 'caption'
    }
  }

  const fileSample = type === 'both' ?
    `${prefix}${date}.json\n${prefix}${date}.xlsx\n`
    :
    `${prefix}${date}.${type}\n`;

  const annualDmfFileSample = type === 'both' ? 
    `${prefix}_dmf_annual${annualDmfDate}.json\n${prefix}_dmf_annual${annualDmfDate}.xlsx`
    :
    `${prefix}_dmf_annual${annualDmfDate}.${type}`;

  return (
    <>
      {nickname && <Typography variant={variantMap[variant]?.nickname}>{nickname}</Typography>}
      <Typography variant={variantMap[variant]?.fileSample} whiteSpace="pre-wrap">{fileSample}</Typography>
      {annualDmfEnabled && <>
        {nickname && <Typography variant={variantMap[variant]?.nickname} mt={1.5}>{nickname}</Typography>}
        <Typography variant={variantMap[variant]?.fileSample} whiteSpace="pre-wrap">{annualDmfFileSample}</Typography>
      </>}
    </>
  );
}

DisplayFileSample.propTypes = {
  nickname: PropTypes.string,
  annualDmfEnabled: PropTypes.bool,
  prefix: PropTypes.string,
  date: PropTypes.string,
  annualDmfDate: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
};

export default DisplayFileSample;