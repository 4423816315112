import React from 'react';
import PageHeader from 'src/components/PageHeader';
import { PeopleRounded, Star } from '@mui/icons-material';
import { Typography, Grid, styled, Box, Divider } from '@mui/material';
import jsonStringCustomerFile from './jsonExample';
import ExampleCodeCard from 'src/components/ExampleCodeCard';
import AttributeCard from 'src/components/AttributeCard';
import customerFileAttributes from './attributes';
import FileDownloadCard from 'src/components/FileDownload';
import AttributeDownloads from 'src/components/AttributeDownloads';

const CustomerFileWrapper = styled(Box)(
  () => `
  .code-card .MuiPaper-root {
    max-width: 100%;
    width: 100%;
  }

  @media screen and (max-width: 599px) {
    .code-card {
      margin-bottom: 20px;
    }
  }
  `
);


function CustomerFile() {

  return (
    <CustomerFileWrapper>
      <PageHeader title="Creating Customer List" subtitle="Start by creating your customer list. This list is required to use LENS and can consist of active and lapsed policyholders. Your customer list is private and will never be shared. Below you will see the full attribute details as well as the ready to use templates. If not using the template provided, make sure your headers match exactly as shown below in bold text so that LENS will process them at ingestion." icon={<PeopleRounded />} />
      <Grid container spacing={3} item sx={{ mt: -2 }}>
        <Grid item xs={12} sm={7} md>
          <AttributeDownloads label="JSON" variation="icon" fileType="JSON" fileName='customer_attributes.json' file='customer_attributes.json' />
          <Grid container sx={{ mb: 1 }}>
            <Typography sx={{ mr: .5 }} color="text.secondary"><Star fontSize="small" /></Typography>
            <Typography variant="body3">While these values may not be available for every customer record, they are critical for higher quality matches.</Typography>
          </Grid>
          {customerFileAttributes.map((card) => (
            <AttributeCard key={card.title} title={card.title} required={card.required} dataType={card.dataType} length={card.length} format={card.format} note={card.note} url={card.url} urlText={card.urlText}></AttributeCard>
          ))}
        </Grid>
        <Grid className="code-card" item xs={12} sm={5} md={5} lg={4}>
          <ExampleCodeCard data={jsonStringCustomerFile} fileName="LENS_Customer_File_TML.json" showCsv={true} csvFileName="LENS_Customer_File_TML.csv" />
        </Grid>
      </Grid>
      <Grid className="customer-file-container" container spacing={3}>
        <Grid item xs={12} sm={7} md={7} lg={8}>
          <Typography variant="h5" sx={{ mb: 1 }}>Including Custom Attributes</Typography>
          <Typography variant="body1">LENS is looking for the data attributes headers exactly as shown above. Any other attribute headers you provide will be treated as custom attributes and echoed back on your results. There is no limit on the number of custom attributes you can add. This data is considered private and not shared.</Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={8}>
          <AttributeCard className="customer-file-attribute-card" title='<custom>' required={false} dataType='N/A' length='N/A' format='N/A' note='Include one or as many attributes as needed.'></AttributeCard>
          <Typography variant="body2" sx={{ mb: 3, mt: -2 }}>E.g. admin system name, product type, face amount...</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Divider sx={{ mt: 1, mb: 4 }} />
          <Typography variant="h5" sx={{ mb: 1 }}>Customer File Template</Typography>
          <Typography variant="body1">Carriers can submit data to LENS SFTP or their organization’s AWS S3 Bucket in JSON or CSV format. JSON format is preferred. For CSV format, carriers can submit attributes in any order as along as the order is specified in the header.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="JSON" fileName="LENS_Customer_File_TML.json" file="LENS_Customer_File_TML.json" setHasError={false} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FileDownloadCard variation="card" fileType="CSV" fileName="LENS_Customer_File_TML.csv" file="LENS_Customer_File_TML.csv" setHasError={false} />
        </Grid>
      </Grid>
    </CustomerFileWrapper>
  );
}

CustomerFile.propTypes = {};

export default CustomerFile;