import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import TooltipInline from '../TooltipInline';

function FileNameExample({ prefix, date, fileType, configurationType, toolTip }) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ display: 'inline-flex' }} variant="body1" color="text.secondary">File Name Example:  </Typography>{toolTip && <TooltipInline placement="right" display='inline-flex' tip={toolTip} />}
      </Box>
      {fileType === 'both' ?
        <>
          <Typography variant="body1" color="text.primary">{prefix}-{date}.json</Typography>
          <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{prefix}-{date}.xlsx</Typography>
        </>
        :
        <Typography variant="body1" color="text.primary" sx={{ mb: 4 }}>{prefix}-{date}.{fileType}</Typography>
      }
      {configurationType === 'matches' ? <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Prefix is used by LENS to create your matches.</Typography> : <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Prefix and file type are used for LENS to identify your list.</Typography>}
    </>
  )
}

FileNameExample.propTypes = {
  prefix: PropTypes.string,
  date: PropTypes.string,
  fileType: PropTypes.string,
  configurationType: PropTypes.string,
  toolTip: PropTypes.string
};

export default FileNameExample;
