import React from 'react';
import PropTypes from 'prop-types';

function LoadingSkeleton({skeleton}) {

  return (
    <>
      {skeleton}
    </>
  );
}

LoadingSkeleton.propTypes = {
  skeleton: PropTypes.element
}

export default LoadingSkeleton;