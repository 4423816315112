import PropTypes from 'prop-types';

import { Typography } from '@mui/material';


function AnnualDmfUnavailable({ dmfAnnualMatchConfig, dmfAnnualCustomerConfig }) {
  return (
    <>
      <Typography variant="subtitle1" mb={1}>Annual Full DMF Scans</Typography>
      <Typography mb={1}>Unavailable for this list configuration.</Typography>
      <Typography variant="subtitle2" color="text.primary">Configuration currently receiving these scans:</Typography>
      <Typography variant="body2">{dmfAnnualCustomerConfig?.nickname} {dmfAnnualCustomerConfig?.nickname ? `(${dmfAnnualCustomerConfig?.file?.prefix})` : dmfAnnualCustomerConfig?.file?.prefix}</Typography>
      <Typography variant="body2" mb={2}>{dmfAnnualMatchConfig?.nickname} {dmfAnnualMatchConfig?.nickname ? `(${dmfAnnualMatchConfig?.file?.prefix}_dmf_annual)`: `${dmfAnnualMatchConfig?.file?.prefix}_dmf_annual`}</Typography>
    </>
  );
}

AnnualDmfUnavailable.propTypes = {
  dmfAnnualMatchConfig: PropTypes.object,
  dmfAnnualCustomerConfig: PropTypes.object
}

export default AnnualDmfUnavailable;