import React from 'react';
import _ from 'lodash';

import { Typography, Switch, Box } from '@mui/material';

import PropTypes from 'prop-types';
import { MonthDaySelect } from '../AnnualDmfScanSetup';
import AnnualDmfUnavailable from '../AnnualDmfUnavailable';


function AnnualDmfEdit({ formik, matchConfig, matchConfigs, customerConfigs }) {
  const dmfAnnualMatchConfig = _.find(matchConfigs, (matchConfig) => _.has(matchConfig, 'transfer.annualDmf'));
  const dmfAnnualCustomerConfig = _.find(customerConfigs, (customerConfig) => customerConfig?.file?.prefix === dmfAnnualMatchConfig?.customerFilePrefix);
  const dmfAnnualUnavailable = dmfAnnualMatchConfig && matchConfig?.file?.prefix !== dmfAnnualMatchConfig?.file?.prefix;

  return (
    <>
      {dmfAnnualUnavailable ?
        <AnnualDmfUnavailable dmfAnnualCustomerConfig={dmfAnnualCustomerConfig} dmfAnnualMatchConfig={dmfAnnualMatchConfig}/>
        :
        <>
          <Switch name='enableAnnualDmf' checked={formik?.values?.enableAnnualDmf} onChange={(event) => { formik.setFieldValue('enableAnnualDmf', event.target.checked) }} />
          <Typography sx={{ display: 'inline' }} variant='subtitle1'>Annual Full DMF Scans <Typography display='inline'>({formik?.values?.enableAnnualDmf ? 'Enabled' : 'Disabled'})</Typography></Typography>
          {formik.values.enableAnnualDmf &&
            <Box ml={4} mt={1}>
              <Typography color="text.secondary">Choose match file delivery date:</Typography>
              <MonthDaySelect formik={formik} variant='edit' />
              <Typography mt={2} mb={1} variant="body2">Select a date 7 days from today's date to allow enough time for processing.</Typography>
            </Box>
          }
        </>
      }
      <Typography variant="body2" mt={2}>Within each contract year, only one customer list at a time can undergo an annual DMF scan.</Typography>
    </>
  );
}

AnnualDmfEdit.propTypes = {
  formik: PropTypes.object.isRequired,
  matchConfig: PropTypes.object,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};

export default AnnualDmfEdit;