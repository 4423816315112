import React, { useEffect, useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';

import Layout from 'src/components/Layout';
import { useAppContext } from 'src/AppContext.js';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';

// See https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
// Uses react-router-dom 6.x
export const RequiredAuth = () => {
  const { carrierConfig, oktaUser } = useAppContext();
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else { 
      setLoading(false);
    }

  }, [oktaAuth, carrierConfig, oktaUser, authState, authState?.isAuthenticated]);

  return (
    <>
      { loading ? <LoadingIcon/> :<Layout> <Outlet /></Layout>}
    </>
  );
};

