import { React, useEffect, useState } from 'react';
import { Grid, Typography, Button, Card, TextField, styled } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { editFolderPath } from 'src/api';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="40px" />
  );
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

function DisplayEditSubdirectory({ data, custFilePrefix, handleEditClose, editForm, prefix, subdirectory, carrierConfig, toggleEditForm, tempAssociatedData, setTempAssociatedData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      newSubdirectory: ''
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      let type;
      if (data?.transfer?.sftp) {
        type = 'sftp'
      } else {
        type = 'aws'
      }
      const payload = { "type": 'matches', "filePrefix": custFilePrefix, "path": values.newSubdirectory };
      try {
        const result = await editFolderPath(carrierConfig?.carrierId, payload, type);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
        }
        if (result.path) {
          // For future enhancement: the tempAssociatedData array will need to be updated to reflect changes to transfer schedule and any other data that we add the ability to change here. This is due to the complexity of passing in associated arrays of data, which do not get updated when we call reload at the top level. See me for details.    -- Sean
          let tempArray = [];
          if (result) {
            tempArray = tempAssociatedData.map(item => item.customerFilePrefix === result?.filePrefix ? { ...item, 'transfer': { ...item.transfer, [data.transfer.type]: { ...item.transfer[data.transfer.type], 'path': result.path } } } : item);
          }
          setTempAssociatedData(tempArray);
          setIsLoading(false);
          handleEditClose();
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  });

  function cancel() {
    setInitialized(false);
    handleEditClose();
  }

  useEffect(() => {
    const setValue = async function () {
      if (subdirectory && subdirectory !== '/') {
        await formik.setFieldValue('newSubdirectory', subdirectory);
      }
      setInitialized(true);
    }

    if (!initialized && subdirectory && (formik.values.newSubdirectory !== subdirectory)) {
      setValue().catch((error) => {
        navigate('/500?page=manage-configurations&error=' + error);
      });
    }
  });

  return (
    <CardWrapper className={editForm ? 'show' : 'hide'}>
      {isLoading ?
        <Loading />
        :
        <>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography variant="subtitle1">{prefix}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button onClick={cancel}> Cancel</Button>
            </Grid>
          </Grid>
          <FormikProvider enableReinitialize={true} value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
                <Grid sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}><Typography variant="subtitle1">{data?.transfer?.sftp ? 'Update SFTP Subdirectory Path' : 'Update AWS S3 Bucket Folder Path'}</Typography></Grid>
                <Grid sx={{ pl: 2, pr: 2, pb: 1, pt: 2, }}>
                  <TextField
                    id="newSubdirectory"
                    name="newSubdirectory"
                    label={data?.transfer?.type === 'sftp' ? 'Subdirectory Path (Optional)' : 'Folder Path (Optional)'}
                    value={formik.values.newSubdirectory}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={data?.transfer?.type === 'sftp' ? 'Example: /evadata/matches/' : 'Example: evadata/matches/'}
                    sx={{ mb: 3, width: '60%' }}
                    inputProps={{ maxLength: 24 }}
                  /></Grid>
              </Grid>
              <hr />
              <Grid textAlign={'center'} sx={{ pl: 2, pr: 2, pb: 3, pt: 2 }}>
                <Button type="submit" variant="contained" >Save Changes</Button></Grid>
            </form>
          </FormikProvider>
        </>
      }
    </CardWrapper>
  )
}

DisplayEditSubdirectory.propTypes = {
  data: PropTypes.object, 
  custFilePrefix: PropTypes.string,
  handleEditClose: PropTypes.func,
  editForm: PropTypes.func,
  prefix: PropTypes.string, 
  subdirectory: PropTypes.string, 
  carrierConfig: PropTypes.object,
  toggleEditForm: PropTypes.func,
  tempAssociatedData: PropTypes.object,
  setTempAssociatedData: PropTypes.func
};

export default DisplayEditSubdirectory;