import { React, useEffect, useState } from 'react';
import { useAppContext } from 'src/AppContext.js';
import PropTypes from 'prop-types';

import { useFormik, FormikProvider, Field, Form } from 'formik';
import * as yup from 'yup';

import { Grid, Typography, Card, styled, Button, FormHelperText, Checkbox, Box, FormControlLabel } from '@mui/material';
import { EditRounded, ExpandLess, ExpandMore } from '@mui/icons-material';

import { editEmailAddresses, getEmailAddresses } from 'src/api';

import EmailMultiEntry from 'src/components/EmailMultiEntry';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';


const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
    .copy-click-wrapper {
      float: left;
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
  `
);

yup.addMethod(yup.array, 'uniqueMF', function (payload, message, mapper = a => a) {
  return this.test('uniqueMF', message, function (list) {
    const masterEmailList = list.concat(payload)
    return masterEmailList?.length === new Set(masterEmailList.map(mapper)).size;
  });
});


function EditEmails({ data, reload }) {
  const [generalizedError, setGeneralizedError] = useState(false);
  const [showEmails, setShowEmails] = useState(false);
  const [editingEmails, setEditingEmails] = useState(false);
  const [availableEmailAddresses, setAvailableEmailAddresses] = useState([null]);
  const [notificationEmailAddresses, setNotificationEmailAddresses] = useState(data?.notificationEmails);
  const [loading, setLoading] = useState(false);

  const { carrierConfig } = useAppContext();

  const editEmailAddressesApi = async function (emails) {
    const payload = {
      filePrefix: data?.file?.prefix,
      type: data?.configurationType,
      notificationEmails: emails
    };
    if (data?.configurationType === 'matches') {
      payload.filePrefix = data?.customerFilePrefix;
    }
    return editEmailAddresses(carrierConfig.carrierId, payload);
  };

  const validationSchema = yup.object().shape({
    existingEmailAddressList: yup
      .array()
      .when("emailAddressList", (emailAddressList) => {
        if (emailAddressList?.length < 1) {
          return yup.array().min(1, "")
        }
        else if (emailAddressList?.length >= 1) {
          if (!emailAddressList[0]) {
            return yup.array().min(1, "")
          }
          else {
            return yup.array()
          }
        }
      }),
    emailAddressList: yup
      .array()
      .when("existingEmailAddressList", (existingEmailAddressList) => {
        if (existingEmailAddressList) {
          return yup.array().uniqueMF(availableEmailAddresses.map((x) => x.email), '').of(yup.string().email())
        }
        else {
          return yup.array().of(yup.string().email())
        }
      }),
  }, ['emailAddressList', 'existingEmailAddressList']);

  const formik = useFormik({
    initialValues: {
      existingEmailAddressList: notificationEmailAddresses,
      emailAddressList: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (formValues) => {
      try {
        setLoading(true);

        let _allEmails;
        if (formValues.existingEmailAddressList.length > 0 && formValues.emailAddressList.length > 0) {
          _allEmails = formValues.existingEmailAddressList.concat(formValues.emailAddressList);
        } else if (formValues.existingEmailAddressList.length > 0) {
          _allEmails = formValues.existingEmailAddressList;
        } else {
          _allEmails = formValues.emailAddressList;
        }
        for (let i = 0; i < _allEmails.length; i++) {
          if (_allEmails[i] === '') {
            _allEmails.splice(i, 1);
          }
        }
        const result = await editEmailAddressesApi(_allEmails);

        if (result.statusCode === 400 || result.statusCode === 500) {
          setLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result?.notificationEmails) {
          reload();
          setNotificationEmailAddresses(result?.notificationEmails);
          formik.setFieldValue('emailAddressList',[]);
          setEditingEmails(false);
          setLoading(false);
          setGeneralizedError(false);
        }
      }
      catch (e) {
        setLoading(false);
        throw (e);
      }
    }
  });

  useEffect(() => {
    const getEmails = async function (carrierId) {
      const emails = await getEmailAddresses(carrierId);
      const emailsWithId = emails.map((email, index) => ({'id' : index, 'email': email}));
      setAvailableEmailAddresses(emailsWithId);
    };
    if (carrierConfig?.carrierId) {
      Promise.all([
        getEmails(carrierConfig.carrierId),
      ])
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId, editingEmails]);

  const cancelEditing = () => {
    setEditingEmails(false);
    setGeneralizedError(false);
    formik.setFieldValue('emailAddressList', []);
    formik.setFieldValue('existingEmailAddressList', notificationEmailAddresses);
  }

  return (
    <CardWrapper>
      <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
        <Grid item xs={6} sx={{ display: 'flex' }}>
          <Typography variant="subtitle1">Notifications</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          {editingEmails ? 
            !loading && <Button onClick={cancelEditing} className="cancel-add-key" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button> : 
            <Button onClick={() => setEditingEmails(true)} className="edit-trans-locations" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>
          }
        </Grid>
      </Grid>
      {loading &&
        <Box sx={{ minHeight: '349px' }}>
          <LoadingIcon contained={true} />
        </Box>
      }
      {!loading &&
        <FormikProvider value={formik}>
          <Form>
            <Grid container spacing={3} sx={{ px: 3, pb: 3, pt: 3 }}>
              {generalizedError &&
                <Grid item xs={12}>
                  <GeneralErrorAlert />
                </Grid>
              }
              <Grid item xs={12}>
                <Typography>Email(s) receiving notifications for this list.</Typography>
                {!editingEmails &&
                  <Button className="pgp-button show-hide" variant="text" size="medium" onClick={() => setShowEmails(!showEmails)}>
                    {showEmails ? <>Hide Email Addresses <ExpandLess /></> : <>Show Email Addresses <ExpandMore /></>}
                  </Button>
                }
                {!editingEmails && showEmails &&
                  notificationEmailAddresses.map((email) => <Typography key={email} variant="subtitle1">{email}</Typography>)
                }
                {editingEmails &&
                  <>
                    <Box sx={{ mb: 2 }} role="fieldset" aria-labelledby="checkbox-group">

                      {formik.errors.existingEmailAddressList ?
                      <FormHelperText sx={{ mb: 1, mt: 1, ml: -1}} error={(formik.errors.existingEmailAddressList)}><Typography display="inline" variant="body1">Minimum of one email required.*</Typography></FormHelperText> :
                      <FormHelperText sx={{ mb: 1, mt: 1, ml: -1 }}><Typography display="inline" variant="body1">Minimum of one email required.</Typography></FormHelperText>}

                      {notificationEmailAddresses?.map((item) => (
                        <div key={item}>
                          <Field as={FormControlLabel} name='existingEmailAddressList' label={item} value={item} control={<Checkbox defaultChecked />} sx={{ ml: 1 }} /> <br />
                        </div>
                      ))}
                      {availableEmailAddresses?.map((item) => (
                        <div key={item.id}>
                          {notificationEmailAddresses?.includes(item.email) ? '' :
                            <>
                              <Field as={FormControlLabel} type="checkbox" name='existingEmailAddressList' label={item.email} value={item.email} control={<Checkbox />} sx={{ ml: 1 }} /><br />
                            </>
                          }
                        </div>
                      ))}
                    </Box>
                    <EmailMultiEntry formik={formik} fieldName='emailAddressList' availableEmailAddresses={availableEmailAddresses}></EmailMultiEntry>
                    {(formik.errors.existingEmailAddressList) ? <FormHelperText sx={{ mt: 1, fontWeight: 400 }} error={true}>*One email selection or email entry is required.</FormHelperText> : ''}
                  </>
                } 
              </Grid>
            </Grid>
            {editingEmails &&
              <div>
                <hr />
                <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                  <Button type="submit" variant="contained">Save Changes</Button>
                </Box>
              </div>
            }
          </Form>
        </FormikProvider>  
      }
    </CardWrapper>
  )
}

EditEmails.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func
}

export default EditEmails;