import React, { useEffect, useState } from 'react';

import { useAppContext } from 'src/AppContext.js';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations, getTransferFrequencies } from 'src/api';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import EncryptionDetails from './encryptionDetails';
import LoadError from 'src/components/LoadError';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import TransferDetails from './transferDetails';
import TransferTable from './TransferTable';
import EncryptionTable from './EncryptionTable';
import PropTypes from 'prop-types';

function Loading() {
  return (
    <LoadingIcon />
  );
}

function DisplayTransferAndEncryption(props) {
  const [customerResult, setCustomerResult] = useState(null);
  const [verifiedDeathsResult, setVerifiedDeathsResult] = useState(null);
  const [allVerifiedDeathsResults, setAllVerifiedDeathsResults] = useState([]);
  const [matchFileResult, setMatchFileResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { carrierConfig } = useAppContext();
  const [showDetails, setShowDetails] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [detailsDataTwo, setDetailsDataTwo] = useState(null);
  const [transFreq, setTransFreq] = useState(null);
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const [showError, setShowError] = useState(false);
  const [allCustomerResults, setAllCustomerResults] = useState([]);
  const [allMatchResults, setAllMatchResults] = useState([]);
  const [associatedData, setAssociatedData] = useState(null);
  const [uniqueBucketRolePairs, setUniqueBucketRolePairs] = useState(null);
  const [uniqueKeyGroups, setUniqueKeyGroups] = useState(null);
  const [keyGroup, setKeyGroup] = useState(null);
  const [uniqueSftpMatchLocations, setUniqueSftpMatchLocations] = useState(null);
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const [removedFrequencies, setRemovedFrequencies] = useState([]);

  function setBucketPairs(custConfigs, deathConfigs, matchConfigs) {
    let uniquePairs = [];

    if ((custConfigs?.length > 0) && (matchConfigs?.length > 0) && deathConfigs) {
      let allData = [...custConfigs, ...matchConfigs];
      if (deathConfigs?.length > 0) {
        allData = [...allData, ...deathConfigs];
      }
      allData?.forEach((result) => {
        let bucket = result?.transfer?.awsS3?.s3Bucket;
        let role = result?.transfer?.awsS3?.iamRole;
        let matchedIndex = uniquePairs.findIndex(element => ((element.record.transfer?.awsS3?.s3Bucket === bucket) && (element.record.transfer?.awsS3?.iamRole === role)));
        if ((matchedIndex === -1) || (uniquePairs?.length === 0)) {
          uniquePairs.push({ record: result, associatedData: [] })
        }
        else if (matchedIndex > -1) {
          uniquePairs?.[matchedIndex].associatedData.push(result);
        }
        else {
          uniquePairs.push({ record: result, associatedData: [] })
        }
      })
      setUniqueBucketRolePairs(uniquePairs);
    }
  }

  function setKeyGroups(custConfigs, deathConfigs, matchConfigs) {
    let uniqueKeys = [];
    let allData = [];

    allData = allData.concat(custConfigs, deathConfigs, matchConfigs);
    allData = allData?.filter((data) => data?.transfer?.encryptionEnabled || data?.configurationType === 'matches');
    allData?.forEach((result) => {
      let key = result?.transfer?.awsS3?.kmsArn || result?.transfer?.sftp?.pgp?.id;
      let matchKey = result?.transfer?.awsS3?.evadataMatchLambdaKmsArn || result?.transfer?.sftp?.pgp?.keyId;
      let matchedIndex;
      if (key || matchKey) {
        matchedIndex = key ? uniqueKeys.findIndex(element => ((key === (element.record.transfer?.awsS3?.kmsArn || element.record.transfer?.sftp?.pgp?.id)))) 
          : uniqueKeys.findIndex(element => ((matchKey === (element.record.transfer?.awsS3?.evadataMatchLambdaKmsArn || element.record.transfer?.sftp?.pgp?.keyId))));
        if (((matchedIndex === -1) || (uniqueKeys?.length === 0))) {
          uniqueKeys.push({ record: result, associatedData: [] })
        }
        if (matchedIndex > -1) {
          uniqueKeys?.[matchedIndex].associatedData.push(result);
        }
      }
    })
    setUniqueKeyGroups(uniqueKeys);
  }

  function setSftpMatchLocations(matchConfigs) {
    let uniqueMatchLocations = [];

    if (matchConfigs?.length > 0) {
      
      matchConfigs?.forEach((result) => {
        let host = result?.transfer?.sftp?.host;
        let port = result?.transfer?.sftp?.port;
        let matchedIndex = uniqueMatchLocations.findIndex(element => ((element.record.transfer?.sftp?.host === host) && (element.record.transfer?.sftp?.port === port)));

        if ((matchedIndex === -1) || (uniqueMatchLocations?.length === 0)) {
          uniqueMatchLocations.push({ record: result, allData: [result] })
        }
        else if (matchedIndex > -1) {
          uniqueMatchLocations?.[matchedIndex].allData.push(result);
        }
        else {
          uniqueMatchLocations.push({ record: result, allData: [result] })
        }
      })
      setUniqueSftpMatchLocations(uniqueMatchLocations);
    }
  }

  function viewAwsDetails(result, additionalData) {
    let _combinedData = {"record": result, "associatedData": additionalData};
    setDetailsData(result);
    setAssociatedData([result, ...additionalData]);
    setKeyGroup(_combinedData);
    setTransFreq(switchFreqDisplay(result?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewCustomerDetails() {
    setDetailsData(customerResult);
    if (allCustomerResults) {
      setAssociatedData(allCustomerResults);
    }
    setTransFreq(switchFreqDisplay(customerResult?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewCustomerDetailsSftp(setSecondaryData, allData, data) {
    if (setSecondaryData === true) {
      setDetailsDataTwo(verifiedDeathsResult);
    }
    if (setSecondaryData === false) {
      setDetailsDataTwo(null);
    }
    if (allData) {
      setAssociatedData(allData);
    }
    if (!allData) {
      setAssociatedData(null);
    }
    if (data) {
      setDetailsData(data);
    } else {
      setDetailsData(customerResult);
    }
    setTransFreq(switchFreqDisplay(customerResult?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewVfdDetails() {
    setAssociatedData(allVerifiedDeathsResults);
    setDetailsData(verifiedDeathsResult);
    setTransFreq(switchFreqDisplay(verifiedDeathsResult?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewMatchDetails(prefixes, matchConfigs, matchDetail, associatedData) {
    if (matchConfigs) {
      setAssociatedData(matchConfigs);
    } else {
      setAssociatedData([]);
    }

    if (matchDetail) {
      setDetailsData(matchDetail);
    } else {
      setDetailsData(matchFileResult);
    }

    if (associatedData) {
      setAssociatedData(associatedData);
    } else {
      setAssociatedData([]);
    }

    setDetailsDataTwo(matchConfigs);
    setTransFreq(switchFreqDisplay(matchFileResult?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewDetails(keyGroup) {
    setKeyGroup(keyGroup);
    setShowDetails(true);
  }

  useEffect(() => {
    let _custConfigs = [];
    let _deathConfigs = [];
    let _matchConfigs = [];
    let _availableFrequencies = [];
    let _removedFrequencies = [];
    const getCustConfigs = async function (carrierId) {
      const configs = await getCustomerFileConfigurations(carrierId);
      if (configs.length > 0) {
        setCustomerResult(configs[0]);
        setAllCustomerResults(configs);
        _custConfigs = configs;
      }
    };

    const getVdfConfigs = async function (carrierId) {
      const configs = await getDeathFileConfigurations(carrierId);
      if (configs.length > 0) {
        setVerifiedDeathsResult(configs[0]);
        setAllVerifiedDeathsResults(configs);
        _deathConfigs = configs;
      }
    };

    const getMatchConfigs = async function (carrierId) {
      const configs = await getMatchFileConfigurations(carrierId);

      if (configs.length > 0) {
        setMatchFileResult(configs[0]);
        setAllMatchResults(configs);
        _matchConfigs = configs;
      }
    };

    const getAvailableFrequencies = async function (carrierId) {
      const { scanFrequencies, removed } = await getTransferFrequencies(carrierId);

      _availableFrequencies = scanFrequencies;
      _removedFrequencies = removed || [];
    };

    if (carrierConfig?.carrierId) {
      Promise.all([
        getVdfConfigs(carrierConfig?.carrierId),
        getCustConfigs(carrierConfig?.carrierId),
        getMatchConfigs(carrierConfig?.carrierId),
        getAvailableFrequencies(carrierConfig?.carrierId)
      ]).finally(() => {
        setBucketPairs(_custConfigs, _deathConfigs, _matchConfigs);
        setKeyGroups(_custConfigs, _deathConfigs, _matchConfigs);
        setSftpMatchLocations(_matchConfigs);
        setAvailableFrequencies(_availableFrequencies);
        setRemovedFrequencies(_removedFrequencies);
        setIsLoading(false);
      })
    } else {
      setIsLoading(false);
      setShowError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId, stateRefreshCounter]);

  function hideDetails() {
    setShowDetails(false);
  }

  function reload() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
    setShowError(false);
    setIsLoading(true);
  }

  function reloadKeys() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  useEffect(() => {
    if (!uniqueBucketRolePairs) {
      setBucketPairs(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
    }
    if (!uniqueKeyGroups) {
      setKeyGroups(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
    }
    if (!uniqueSftpMatchLocations) {
      setSftpMatchLocations(allMatchResults);
    }
  }, [stateRefreshCounter, uniqueBucketRolePairs, allCustomerResults, allVerifiedDeathsResults, allMatchResults, uniqueKeyGroups, uniqueSftpMatchLocations])

  return (
    <>
      {(!showDetails && props.keyType === "encryption") &&
        <EncryptionTable uniqueKeyGroups={uniqueKeyGroups} viewDetails={viewDetails} viewAwsDetails={viewAwsDetails} isLoading={isLoading} showError={showError}/>
      }
      {(!showDetails && props.keyType === "transfer") &&
        <TransferTable key={stateRefreshCounter} allCustomerResults={allCustomerResults} customerResult={customerResult} allMatchResults={allMatchResults} uniqueBucketRolePairs={uniqueBucketRolePairs} matchFileResult={matchFileResult} uniqueSftpMatchLocations={uniqueSftpMatchLocations} verifiedDeathsResult={verifiedDeathsResult} viewCustomerDetailsSftp={viewCustomerDetailsSftp} viewVfdDetails={viewVfdDetails} allVerifiedDeathsResults={allVerifiedDeathsResults} viewMatchDetails={viewMatchDetails} viewCustomerDetails={viewCustomerDetails} viewAwsDetails={viewAwsDetails} isLoading={isLoading} showError={showError} />
      }

      {(showDetails && props.keyType === "encryption") && <EncryptionDetails keyGroup={keyGroup} hideDetails={hideDetails} reloadKeys={reloadKeys} />}

      {(showDetails && props.keyType === "transfer") && <TransferDetails key={stateRefreshCounter} hideDetails={hideDetails} data={detailsData} matchConfigs={allMatchResults} customerConfigs={allCustomerResults} transFreq={transFreq} dataTwo={detailsDataTwo} associatedData={associatedData} availableFrequencies={availableFrequencies} removedFrequencies={removedFrequencies} reloadKeys={reloadKeys} />}

      {isLoading && <Loading />}

      {showError && <LoadError reload={reload} />}
    </>
  );
}

DisplayTransferAndEncryption.propTypes = {
  keyType: PropTypes.string.isRequired
};

export default DisplayTransferAndEncryption;