import React from 'react';
import PageHeader from 'src/components/PageHeader';
import NavigationCard from 'src/components/NavigationalCard';
import { PeopleRounded, VerifiedRounded, CompareRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

function GettingStarted() {

  return (
    <>
      <PageHeader title="Getting Started" subtitle="Everything you need to get up and running with LENS."/>
      <Typography variant="h5" sx={{mb:3}}>Creating Files for LENS</Typography>
      <NavigationCard icon={<PeopleRounded />} topText="ALL CARRIERS" cardTitle="Customer List" cardContent="Start by creating your customer list. Review data attributes, requirements and ready to use templates." link="/customer-file"></NavigationCard>
      <NavigationCard icon={<VerifiedRounded />} topText="Carriers to carrier" cardTitle="Verified Deaths List" cardContent="Start by creating your verified deaths list. Review data attributes, requirements and ready to use templates." link="/verified-deaths-file"></NavigationCard>
      <Typography variant="h5" sx={{mb:3, mt: 3}}>Receiving Files from LENS</Typography>
      <NavigationCard icon={<CompareRounded />} topText="ALL Carriers" cardTitle="Match Results" cardContent="Review file structure, data attributes and example match files." link="/match-results-file"></NavigationCard>
    </>
  );
}

GettingStarted.propTypes = {};

export default GettingStarted;