import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { getCurrentDate } from 'src/utilities/getDate';
import TooltipInline from '../TooltipInline';

function DisplayFilePrefixAndType({ filePrefix, fileType, transferFreq, fileExampleToolTip = "Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily, weekly or monthly.", scheduleNote = "Schedule informs LENS on when to expect your list. This can be changed when your LENS account is moved to Live." }) {

  const date = getCurrentDate();

  return (
    <>
      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>
      <Typography variant="subtitle1" color="text.primary">File Prefix:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{filePrefix}</Typography>
      <Typography variant="subtitle1" color="text.primary">File Type:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 2, textTransform: 'uppercase' }}>{fileType}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ display: 'inline-flex' }} variant="body1" color="text.secondary">File Name Example:  </Typography><TooltipInline placement="right" display='inline-flex' tip={fileExampleToolTip} />
      </Box>
      <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}> {filePrefix}-{date}.{fileType}</Typography>
      <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Prefix and file type are used for LENS to identify your list.</Typography>

      <Typography variant="subtitle1" color="text.primary">Transfer Frequency Schedule:</Typography>
      <Typography variant="body1" color="text.primary" sx={{ mb: 2, textTransform: 'capitalize' }}>{transferFreq === 'daily' ? 'Daily (Monday-Friday)' : transferFreq}</Typography>

      <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>{scheduleNote}</Typography>

    </>
  );
}

DisplayFilePrefixAndType.propTypes = {
  filePrefix: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  transferFreq: PropTypes.string.isRequired,
  fileExampleToolTip: PropTypes.string,
  scheduleNote: PropTypes.string
};

export default DisplayFilePrefixAndType;