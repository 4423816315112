import React from 'react';

import { useAppContext } from 'src/AppContext.js';
import FileExchangesSection from './Data Loads and Match Activity';
import ActiveList from './Active List Summary';
import OnBoarding from './OnBoarding';
import ManageSettings from './Manage';
import Documentation from './Documentation';


function SidebarMenu() {
  const { carrierConfig } = useAppContext();
  const { lensRoles } = useAppContext();

  if (carrierConfig?.status === 'live') {
    return (
      <>
        <ActiveList />
        <FileExchangesSection />
        <ManageSettings />
        <Documentation/>
      </>
    );
  }

  if (carrierConfig?.status === 'on-boarding') {
    return (
      <>
        <OnBoarding roles={lensRoles} />
        <ManageSettings />
        <Documentation />
      </>
    )
  }
  return (
    <>
      <ManageSettings />
      <Documentation />
    </>
  );
}

export default SidebarMenu;
