import React, { useEffect, useState } from 'react';
import { Typography, styled, Box, Paper } from '@mui/material';

import { useAppContext } from 'src/AppContext.js';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getEmailAddresses, getMatchFileConfigurations } from 'src/api';
import OnboardingStepper from 'src/components/Stepper';
import CustomerFileSetupSummary from './customerFileSetupSummary';
import CustomerFileSetupForm from './customerfileSetupForm';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { useNavigate } from 'react-router-dom';


const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }

    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function Loading() {
  return (
    <LoadingIcon />
  );
}

function CustomerFileSetup() {
  const [result, setResult] = useState(null);
  const [deathResult, setDeathResult] = useState(null);
  const [matchResult, setMatchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [availableEmailAddresses, setAvailableEmailAddresses] = useState(null);
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const {lensRoles } = useAppContext();

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  useEffect(() => {
    const getConfigurations = async function(carrierId) {
      const configs = await getCustomerFileConfigurations(carrierId);
      if(configs.length > 0) {
        setResult(configs[0]);
      }
    };
    const _getDeathConfigurations = async function(carrierId) {
      const configs = await getDeathFileConfigurations(carrierId);
      if(configs.length > 0) {
        setDeathResult(configs[0]);
      }
    };
    const _getMatchConfigurations = async function (carrierId) {
      const configs = await getMatchFileConfigurations(carrierId);
      if (configs.length > 0) {
        setMatchResult(configs[0]);
      }
    };
    const getEmails = async function(carrierId) {
      const emails = await getEmailAddresses(carrierId);
      const emailsWithId = emails.map((email, index) => ({'id' : index, 'email': email}))
      setAvailableEmailAddresses(emailsWithId);
    }; 

    if(carrierConfig?.carrierId) {
      Promise.all([
        getConfigurations(carrierConfig.carrierId),
        _getDeathConfigurations(carrierConfig.carrierId),
        _getMatchConfigurations(carrierConfig.carrierId),
        getEmails(carrierConfig.carrierId)
      ]).finally (() => setIsLoading(false))
    } else {
      console.log('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierConfig?.carrierId]);

  return (
    <>
    <FormWrapper>
      <OnboardingStepper step={0} customerResult={result} deathResult={deathResult} matchResult={matchResult} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0', p: 0 }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 1</Typography>
          <Typography variant="h5" color="primary.text">{result ? 'Summary of Customer List' : 'Customer List Setup'}</Typography>
        </Box>
        {(!result && !isLoading) && <CustomerFileSetupForm setResult={setResult} availableEmailAddresses={availableEmailAddresses}/> }
        {(result && !isLoading) && <CustomerFileSetupSummary 
          file={result.file} 
          transfer={result.transfer}
          notificationEmails={result.notificationEmails}
          pgp={result.pgp}
          sftpServer={result.sftpServer}
          setResult={setResult}/>}
        
      </Paper>
    </FormWrapper>
    {isLoading && <Loading/>}
    </>
  );
}

CustomerFileSetup.propTypes = {};

export default CustomerFileSetup;