import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Link} from '@mui/material';
import { ContentPasteRounded } from '@mui/icons-material';

function AttributeNote(props) {
    return (
      <Grid item xs>
      <Grid container>
        <Grid item sx={{ mr: 1 }}>
          <Typography variant="body2" color="text.secondary">
            <ContentPasteRounded fontSize="small" />
          </Typography>
        </Grid>
        <Grid item sm={11}>
          <Typography variant="body2" color="text.secondary">
            <span>Note: {props?.note} <Link target="blank" href={props?.url}>{props?.urlText}</Link></span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    );
}

AttributeNote.propTypes = {
  note: PropTypes.string,
  urlText: PropTypes.string,
  url: PropTypes.string,
};

export default AttributeNote;
