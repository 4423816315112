import React, { useEffect } from 'react';

import { authorize } from '../../api';
import { useAppContext } from '../../AppContext'
import LoadingIcon from './loadingIcon';
import { useQuery } from 'src/hooks';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';


const InitialLoader = () => {
  const { setCarrierConfig } = useAppContext();
  const { setLensRoles } = useAppContext();
  const { setOktaUser } = useAppContext();
  const { oktaAuth, authState } = useOktaAuth();

  const carrierConfig = useQuery('carrierConfig', (() => authorize(oktaAuth)));
  const user = useQuery('userInfo', (() => oktaAuth.getUser()));

  useEffect(() => {
    if(!authState?.isAuthenticated) {
      return;
    }

    if(!user.isLoading) {
      setOktaUser(user.data);
      setLensRoles(user.data?.lens_roles);
    }

    if(!carrierConfig.isLoading) {
      setCarrierConfig(carrierConfig.data);
    }

  }, [carrierConfig, user, setCarrierConfig, setLensRoles, setOktaUser, authState]);

  
  return (
    <>
      { (user.isLoading || carrierConfig.isLoading) ? <LoadingIcon />: <Outlet/> }
    </>
  );
};

export default InitialLoader;