import { Check } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const defaultField = (params, customClass) =>  <div className={'wrap-cell ' + customClass}><Typography variant="body1">{params.value}</Typography></div>;

const sourceField = (params, customClass) => <div className={'wrap-cell ' + customClass}>{params.value ? <Check /> : <Typography variant="body1" color="text.secondary">N/A</Typography>}</div>;

const evadataMatchCatColumns = [
  { 
    field: 'category', 
    headerName: '', 
    width: 97,
    headerClassName: 'custom-header',
    sortable: false,
    renderHeader: () => {
      return (<>&nbsp;</>)
    },
    renderCell: (params) => {
      return <div className="wrap-cell"><Typography variant="body1">{params.value}</Typography></div>;
    },
  },
  {
    field: 'dmf',
    headerName: 'DMF',
    width: 90,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return sourceField(params, 'border');
    },
  },
  {
    field: 'state',
    headerName: 'State',
    width: 85,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return sourceField(params);
    },
  },
  {
    field: 'otherCarriers',
    headerName: 'Other Carriers',
    width: 85,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return sourceField(params);
    },
  },
  {
    field: 'obituary',
    headerName: '',
    width: 76,
    sortingOrder: ['desc', 'asc'],
    renderHeader: () => {
      return (<Box sx={{borderRight: '3px solid #333'}}>Obituary</Box>)
    },
    renderCell: (params) => {
      return sourceField(params);
    },
  },
  {
    field: 'ssn',
    headerName: 'SSN',
    width: 122,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return defaultField(params, 'border');
    },
  },
  {
    field: 'dob',
    headerName: 'DOB',
    flex: 0.9,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return defaultField(params);
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1.2,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><Typography variant="body1">{params.value}</Typography></div>;
    },
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1.15,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><Typography variant="body1">{params.value}</Typography></div>;
    },
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1.25,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><Typography variant="body1">{params.value}</Typography></div>;
    },
  },
  {
    field: 'stateCriteria',
    headerName: 'State',
    flex: 1.1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><Typography variant="body1">{params.value}</Typography></div>;
    },
  },
]

export default evadataMatchCatColumns;