import { Box, Typography } from "@mui/material";

function renderCellFX(params) {
  return <Box className={params.value === 'M' ? 'highlight wrap-cell' : 'wrap-cell'}>
    {params.value}
    </Box>;
}

const columns = [
  {
    field: 'id', 
    headerName: 'ID', 
    hide: true
  },
  { 
    field: 'categoryLevel', 
    headerName: 'Category Level', 
    width: 240,
    sortable: false,
    cellClassName: 'data-tier wrap-cell',
    renderCell: (params) => {
      return <Box>
        <Typography sx={{fontWeight: 700, fontSize: '15px'}} variant="body1">{params.value[0]}</Typography>
       <Typography variant="caption" color="text.secondary" >{params.value[1]}</Typography>
        </Box>;
    } 
  },
  {
    field: 'ssn', 
    headerName: 'SSN', 
    sortable: false,
    flex: 1,
    cellClassName: 'ssn-grid-col center ssn wrap-cell',
    headerAlign: 'center',
    renderCell: (params) => {
      return <Box className={params.value === 'M' ? 'highlight wrap-cell' : 'wrap-cell'}>
        {params.value}
        </Box>;
    },
  },
  {
    field: 'dob', 
    headerName: 'DOB*', 
    sortable: false,
    flex: 1,
    cellClassName: 'center dob',
    headerAlign: 'center',
    renderCell: (params) => renderCellFX(params)
  },
  {
    field: 'firstName', 
    headerName: 'First Name', 
    sortable: false,
    flex: 1,
    cellClassName: 'center first',
    headerAlign: 'center',
    renderCell: (params) => renderCellFX(params)
  },
  {
    field: 'lastName', 
    headerName: 'Last Name', 
    sortable: false,
    flex: 1,
    cellClassName: 'center last',
    headerAlign: 'center',
    renderCell: (params) => renderCellFX(params)
  },
  {
    field: 'city', 
    headerName: 'City', 
    sortable: false,
    flex: 1,
    cellClassName: 'center city',
    headerAlign: 'center',
    renderCell: (params) => renderCellFX(params)
  },
  {
    field: 'state', 
    headerName: 'State', 
    sortable: false,
    flex: 1,
    cellClassName: 'center state',
    headerAlign: 'center',
    renderCell: (params) => renderCellFX(params)
  },
];

export default columns;