import React from 'react';

import MenuSection from '../MenuSection';
import ManageSidebarItems from './items';


function ManageSettings() {
  
  return (
    <>
      {ManageSidebarItems().map((section) => (
        <MenuSection heading={section.heading} items={section.items} key={section.heading}></MenuSection>
      ))}
    </>
  );
}

ManageSettings.propTypes = {}

export default ManageSettings;