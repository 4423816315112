import { React } from 'react';
import { Grid, Card, styled, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';
import PropTypes from 'prop-types';
import DisplayFileSample from 'src/components/DisplayFileSample';

const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
    }
    .lock-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .account-id-row {
      border-bottom: 1px solid ${theme.palette.common.black}66;
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    th {
      color: ${theme.palette.common.black};
      font-size: 15px;
    }
    tr {
      border-top: 2px solid ${theme.palette.common.black}33;
    }
    tr.MuiTableRow-head {
      border-top: 3px solid ${theme.palette.common.black}33;
      border-bottom: 1px solid ${theme.palette.common.black}90;
    }
  `
);

function AwsKeyTable({ data, transferFreq, viewAwsDetails, associatedData }) {

  return (
    <CardWrapper>
      <Card sx={{ minWidth: 275, textAlign: 'left', mt: 3, mb: 3, }}>
        <Grid container>
          <Grid className="account-id-row" item xs={12} sx={{ px: 2, py: 1 }}><Typography color='text.secondary' variant='overline' >AWS Account ID: {data?.transfer?.awsS3?.carrierIntegrationAccountId}</Typography>
          </Grid>
          <Grid item xs={10} sx={{ p: 2, }}>
            <Typography sx={{ mb: 1 }} variant="subtitle1"><Typography variant="subtitle1" color="text.secondary" display="inline">S3 Bucket ARN: </Typography>{data?.transfer?.awsS3?.s3Bucket}
            </Typography>
            <Typography sx={{ mb: 1 }} variant="subtitle1"><Typography variant="subtitle1" color="text.secondary" display="inline">Role ARN: </Typography>{data?.transfer?.awsS3?.iamRole}
            </Typography>
            <Typography variant="body2">LENS uses the provided Role to retrieve your list(s) and/or to transfer matches to your S3 bucket.</Typography>
          </Grid>
          <Grid item xs={2} sx={{ p: 2, textAlign: 'right' }}>
            <Button variant="outlined" color="primary" onClick={() => viewAwsDetails(data, associatedData)}>Manage</Button>
          </Grid>
        </Grid>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: '7px' }}>Lists Using This Location</TableCell>
              <TableCell sx={{ width: '34%', py: '7px' }} align="left">Folder Path*</TableCell>
              <TableCell sx={{ width: '34%', py: '7px' }} align="left">Transfer Schedule</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="aws-key-table">
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <DisplayFileSample nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} type={data?.file?.type} />
              </TableCell>
              <TableCell className="aws-freq" align="left">
                {(data?.configurationType === 'customer_records' || data?.configurationType === 'death_records') && '—'}
                {(data?.configurationType === 'matches' && data?.transfer?.awsS3?.path !== '/') && data?.transfer?.awsS3?.path}
                {(data?.configurationType === 'matches' && (!data?.transfer?.awsS3?.path || data?.transfer?.awsS3?.path === '/')) && '—'}
              </TableCell>
              {data?.configurationType === 'death_records' ?
                <TableCell className="aws-freq" align="left">Daily (Monday-Friday)</TableCell> :
                <TableCell className="aws-freq" align="left" sx={{ textTransform: 'capitalize', mb: 1 }}>{switchFreqDisplay(data?.transfer?.frequency)}{(data?.configurationType === 'matches' && data?.transfer?.frequencyDay) ? ' (' + data?.transfer?.frequencyDay + ')' : ''}</TableCell>}
            </TableRow>
            {associatedData?.map((item) => (
              <TableRow key={item?.file?.prefix}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <DisplayFileSample nickname={item?.nickname} annualDmfEnabled={Boolean(item?.transfer?.annualDmf)} prefix={item?.file?.prefix} type={item?.file?.type} />
                </TableCell>
                <TableCell className="aws-freq" align="left">
                  {(item?.configurationType === 'customer_records' || item?.configurationType === 'death_records') && '—'}
                  {(item?.configurationType === 'matches' && item?.transfer?.awsS3?.path !== '/') && item?.transfer?.awsS3?.path}
                  {(item?.configurationType === 'matches' && (!item?.transfer?.awsS3?.path || item?.transfer?.awsS3?.path === '/')) && '—'}
                </TableCell>
                {item?.configurationType === 'death_records' ?
                  <TableCell className="aws-freq" align="left">Daily (Monday-Friday)</TableCell> :
                  <TableCell className="aws-freq" align="left" sx={{ textTransform: 'capitalize', mb: 1 }}>{switchFreqDisplay(item?.transfer?.frequency)}{(item?.configurationType === 'matches' && item?.transfer?.frequencyDay) ? ' (' + item?.transfer?.frequencyDay + ')' : ''}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </CardWrapper>
  )
}

AwsKeyTable.propTypes = {
  data: PropTypes.object,
  transferFreq: PropTypes.string,
  viewAwsDetails: PropTypes.func,
  associatedData: PropTypes.array
};

export default AwsKeyTable;