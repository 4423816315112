const helpfulGuidanceEntries = [
  {
    title: 'Access Stage Environment',
    ref: 'access',
    definitions: [
      {
        text: 'If you want to use a Stage environment for LENS, kindly contact your account representative for access. Please note that LENS stage environments are not authorized to accommodate production data. The stage environment only permits the use of dummy data. It is important to know that LENS stage environment mirrors LENS production environment in function. Stage and Prod are decoupled. Therefore, any changes you make on Stage will not be reflected on Production. You must make changes in each environment separately.',
      }
    ]
  },
  {
    title: 'Change Categories / Sources  ',
    ref: 'categoriesRef',
    definitions: [
      {
        text: 'Customers can modify their sources or categories whenever they want, but it is important to note that such changes must be made before the match job runs. To illustrate, let us consider a scenario where you want to activate or deactivate a category (same applies for sources). If the match file is set to a daily frequency, you should expect to see the changes in the match results accordingly.',
        list: ['Match runs Tuesday', 'Add additional category Wednesday morning between 5am CT - 9:30am CT', 'File delivered on Wednesday from Tuesdays run will not have new category', 'Remove additional category Thursday morning between 5am CT - 9:30am CT', "File delivered on Thursday from Wednesday's run will have the new category", "File delivered on Friday from Thursday's run will not have the new category"]
      },
    ]
  },
  {
    title: 'Correct Data Issues ',
    ref: 'dataIssues',
    definitions: [
      {
        text: 'Customers can transmit a file to LENS to scan it for matches against death records. The system uses the latest transmitted file for scanning. If you find an issue with a record on your list, you can update that record and resubmit the list for scanning. As long as the customer ID remains the same, the new information will be used for the scan and will report as new or update-new depending on match history for that customer ID.\n\n However, now suppose you have a situation where customer IDs and individual data were mixed up among records, and those previously submitted records matched with death records. As expected, LENS would produce matches against that customer ID, but these matches will not appear as new matches because the customer ID is already associated with a prior match. Please contact support if you need assistance in this regard.\n\n  LENS only accepts delta files for death records. If your organization has accidentally submitted non deceased records, please contact Support asap so those records can be removed before they are matched to other carriers.  ',
      },
    ]
  },
  {
    title: 'Establish Customer IDs ',
    ref: 'customerIds',
    definitions: [
      {
        text: 'It is important to remember that customer IDs should remain consistent for the same individual over time. LENS uses the customer ID to identify unique individuals, so any change in the customer ID for the same customer record would lead to LENS treating this record as a new individual. Also, customer IDs should never contain PII. ',
      },
    ]
  },
  {
    title: 'Handle Deletes and Updates  ',
    ref: 'handleDeletes',
    definitions: [
      {
        heading: 'Deletes',
        text: "When a source requests us to delete a record from the database, we pass on those delete notices to you as a precautionary measure. Currently, only the Death Master File (DMF) provides delete requests. Deletes occur infrequently and can vary in delay from the initial match. Unfortunately, the DMF does not give any reason for the `delete` requests.\n\nEvadata recommends that customers take note of the delete and intervene when there isn't sufficient proof of death from other sources. Also, note that when a delete occurs against a previously matched record, LENS will reset that match. You will receive another DMF match if the same individual is re-added to the DMF.",
      },
      {
        heading: 'Updates',
        text: "When a source record makes a change to a death record that has previously matched your customer record, LENS will provide this information to you as an 'updated' match. Updates can occur on DMF, Obituary, Other Carrier, and certain States.",
      },
    ],
    note: 'LENS only relays updated death records or delete notices; neither updates nor deletes are considered actual matches. Updates and deletes may occur at any time if a unique customer record matches a death record in LENS, as there is no maturity date.'
  },
  {
    title: 'LENS Scans',
    ref: 'lensScans',
    definitions: [
      {
        text: 'LENS scans are separate queries based on the selected sources and categories. LENS Portal allows control over the scanning frequency, sources, and categories. LENS match definitions are designed to accommodate a wide range of regulatory guidance while allowing customers to choose based on their needs and prior assessments. If you have questions about fuzzy matching scenarios, please contact our Customer Success team. ',
      },
    ]
  },
  {
    title: 'Lookback Periods',
    ref: 'lookbackPeriods',
    definitions: [
      {
        text: 'LENS runs a standard lookback period of 24 months from the scan date to the death date across our source databases. If your organization is interested in scanning beyond 24 months, please inform your Customer Success Representative. ',
      },
    ]
  },
  {
    title: 'Manage Keys',
    ref: 'manageKeys',
    definitions: [
      {
        text: 'All outgoing files must be encrypted with file-based encryption. SFTP requires PGP encryption, and AWS Native requires KMS encryption. Customers should use file-based encryption for incoming files as well. You can manage your encryption keys on the LENS portal. Please note that any encryption keys provided to LENS must be at least 2048 bits. ',
      },
    ]
  },
  {
    title: 'Manage Users',
    ref: 'manageUsers',
    definitions: [
      {
        text: "There are two types of users in the LENS Portal. If you have questions about roles or need a user's role changed or added, please reach out to Customer Success.",
      },
      {
        heading: 'Admin',
        text: "Has read/write privileges and access to all pages within LENS Portal. ",
      },
      {
        heading: 'Collaborator',
        text: "Has read privilege and access to documentation, active list summary and activity log.",
      },
    ]
  },
  {
    title: 'Run Multiple Files  ',
    ref: 'multipleFiles',
    definitions: [
      {
        text: "LENS allows customers to configure multiple file runs. To do this, customers can create new match workflows by accessing the Configurations section of the portal. Each configuration is managed under a file prefix. If there are overlapping customer IDs across different files, these customers will be treated as unique under separate prefixes. For instance, if Customer ID 123, Jane Doe is listed in both prefix A and B, two different match jobs will return a result for Jane Doe upon match. Each job will be treated as independent of the other match workflows, with no knowledge of the other match job. ",
      },
    ]
  },
  {
    title: 'Set Up Recurring Scans versus One Time Scans ',
    ref: 'recurringScans',
    definitions: [
      {
        text: "There are two types of scans - recurring scans and one-time scans. Recurring scans are scheduled regularly, such as daily, weekly, or monthly. They are designed to scan lists over time and track whether a match is a net new match or a subsequent match for a customer ID. On the other hand, one-time scans are individual match jobs that cannot be rerun and are best suited for isolated studies.",
      },
    ]
  },
  {
    title: 'Data Use Summary',
    ref: 'dataUseSummary',
    hideDivider: true,
    definitions: [
      {
        heading: "DATA SETS",
      },
      {
        heading: 'Submitted Data',
        text: "LENS analyzes records from various third-party sources, including carrier death files, to vastly improve the accuracy of death notifications for your customer base. When submitting your own death records, it is critical that your records are actual deaths — whether they are part of your active or closed claims. Our network relies on each participant submitting high confidence deaths.",
      },
      {
        heading: 'Matched Results Data',
        text: "You will receive match results from LENS. It is up to your organization to determine how to best incorporate these results into your existing systems and processes.",
      },
    ]
  },
  { hideDivider: true,
    definitions: [
      {
        heading: "DATA ACCURACY",
      },
      {
        heading: 'Data Limitations',
        text: "LENS matches are based on third-party sources and Evadata does not guarantee the accuracy of information received from a third-party. Our customers must validate death matches accordingly.",
      },
      {
        heading: 'False Deaths',
        text: "False death positives are rare but can happen. If you receive a carrier-to-carrier death notification from Evadata and confirm that such match is a living policyholder, you should immediately notify us of a false death notification at ",
        endLink: 'mailto:support@evadata.com',
        endLinkText: 'support@evadata.com',
        finalText: 'so that we can contact the carrier who originally reported the individual as deceased.'
      },
    ]
  },
  {
    definitions: [
      {
        heading: "DATA RESTRICTIONS",
      },
      {
        heading: 'Internal Use Only',
        text: "LENS (mortality) match results data is restricted to specific use cases. Please refer to your operative agreement with Evadata for more information.",
      },
    ]
  },
]

export default helpfulGuidanceEntries;