import React from 'react';
import PropTypes from 'prop-types';

import SftpKeyTable from './sftpKeyTable';
import DisplayMatchTables from '../../displayMatchTables';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';


function SftpTransferTable({allCustomerResults, customerResult, verifiedDeathsResult, allVerifiedDeathsResults, viewCustomerDetailsSftp, allMatchResults, matchFileResult, viewMatchDetails, uniqueSftpMatchLocations}) {


  return (
    <>
    {(customerResult?.transfer?.type === 'sftp') &&
      <SftpKeyTable displayType={'lensSftp'} allData={allCustomerResults} data={customerResult} allVfdData={allVerifiedDeathsResults} vfdData={verifiedDeathsResult} transferFreq={switchFreqDisplay(customerResult?.transfer?.frequency)} viewDetails={() => viewCustomerDetailsSftp(false, [...allCustomerResults, ...allVerifiedDeathsResults])} ></SftpKeyTable>
    }
    {(matchFileResult?.transfer?.type === 'sftp') &&
      <DisplayMatchTables uniqueSftpMatchLocations={uniqueSftpMatchLocations}  results={allMatchResults} matchFileResult={matchFileResult} viewMatchDetails={viewMatchDetails} tableType="sftp" />
    }
    </>
  );
}

SftpTransferTable.propTypes = {
  allCustomerResults: PropTypes.array,
  customerResult: PropTypes.object,
  verifiedDeathsResult: PropTypes.object,
  viewCustomerDetailsSftp: PropTypes.func,
  allMatchResults: PropTypes.array,
  matchFileResult: PropTypes.object,
  viewMatchDetails: PropTypes.func,
  allVerifiedDeathsResults: PropTypes.array,
  uniqueSftpMatchLocations: PropTypes.array

};

export default SftpTransferTable;
