import { OktaAuth } from '@okta/okta-auth-js';
import config from 'src/config/okta';

const internals = {};
const _oktaAuth = new OktaAuth(config.oidc);

export const downloadDocumentationFile = async function(filename) {
  const url = `${process.env.REACT_APP_LENS_API}/documentation/download?filename=${filename}`;

  return internals.get(url);
};

export const authorize = async function() {
  const url = `${process.env.REACT_APP_LENS_API}/authorize`;
  
  return internals.get(url);
};

export const getCustomerFileDataLoads = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/customer-files/${filePrefix}?timezone=${timeZone}`;

  return internals.get(url);
};

export const getCustomerFileLatestCompletedDataLoad = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/customer-files/${filePrefix}/completed?timezone=${timeZone}`;

  return internals.get(url);
};

export const setupCustomerFile = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/customer-files`;

  return internals.put(url, payload);
};

export const getDeathFileDataLoads = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/death-files/${filePrefix}?timezone=${timeZone}`;

  return internals.get(url);
};

export const getDeathFileLatestCompletedDataLoad = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/death-files/${filePrefix}/completed?timezone=${timeZone}`;

  return internals.get(url);
};

export const setupDeathFile = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/death-files`;

  return internals.put(url, payload);
};

export const getMatchesFileDataLoads = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/match-files/${filePrefix}?timezone=${timeZone}`;

  return internals.get(url);
};

export const getMatchesFileLatestCompletedDataLoad = async function(carrierId, filePrefix) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/match-files/${filePrefix}/completed?timezone=${timeZone}`;

  return internals.get(url);
};

export const setupMatchFile = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/match-files`;

  return internals.put(url, payload)
};

export const getCustomerFileConfigurations = async function(carrierId) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/customer-files?timezone=${timeZone}`;

  return internals.get(url);
};

export const getDeathFileConfigurations = async function(carrierId) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/death-files?timezone=${timeZone}`;
  
  return internals.get(url);
};

export const getMatchFileConfigurations = async function(carrierId) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/match-files?timezone=${timeZone}`;

  return internals.get(url);
};

export const getAuditConfigEntries = async function(carrierId, fileType) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/${fileType}/audit-entries`;

  return internals.get(url);
};

export const getEmailAddresses = async function(carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/emails`;

  return internals.get(url);
};

export const editEmailAddresses = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/emails`;

  return internals.put(url, payload);
}

export const getPgpKeys = async function(carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/pgp-keys`;

  return internals.get(url);
};

export const getErrorFileSignedUrl = async function(carrierId, fileType, dataLoadId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/${fileType}/errors/download?dataLoadId=${dataLoadId}`;

  return internals.get(url);
}

export const submitIDGenerateLambda = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/integration-account-id`;
  
  return internals.put(url, payload);
};

export const getLambdaStatus = async function(carrierId, payload) {
  const accountId = payload.accountId;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/integration-account-id/${accountId}/true`;
  
  return internals.get(url);
};

export const returnLambda = async function(carrierId, payload) {
    
    const result = await getLambdaStatus(carrierId, payload);

    if (result.status === 'complete')  {
      return result;
    }

    if (result.status === 'failed')  {
      return result;
    }

    if (result.status === 'processing') {
      return returnLambda(carrierId, payload);
    } 
  }

  export const getSftpUsernames = async function(carrierId) {
    const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/sftp-users`;
  
    return internals.get(url);
  };

export const rotatePgpKey = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/pgp-keys`;

  return internals.put(url, payload);
}

export const editMatchPgpKey = async function (carrierId, customerFilePrefix, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/match-files/${customerFilePrefix}/pgpKey`;

  return internals.put(url, payload);
}

export const editKmsKeyArn = async function (carrierId, fileLocation, prefix, payload) {
  const fileLocations = {
    'customer_records': 'customer-files',
    'customer-files': 'customer-files',
    'death_records': 'death-files',
    'death-files': 'death-files'
  }
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/${fileLocations[fileLocation]}/${prefix}/kmsKey`;

  return internals.put(url, payload);
}

export const editSources = async function (carrierId, customerFilePrefix, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/match-files/${customerFilePrefix}/sources`;

  return internals.put(url, payload);
}

export const getSources = async function (carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/datasources`;

  return internals.get(url);
}

export const editCategories = async function (carrierId, customerFilePrefix, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/match-files/${customerFilePrefix}/categories`;

  return internals.put(url, payload);
}

export const editSftpCredentials = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/sftp-credentials`;

  return internals.put(url, payload);
}

export const editSftpLocations = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/sftp-locations`;
  
  return internals.put(url, payload);
}

export const editAwsLocations = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/aws-locations`;
  
  return internals.put(url, payload);
}

export const getAllDataLoads = async function(carrierId, fileType) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/${fileType}?timezone=${timeZone}`
  
  return internals.get(url);
};

export const getAllDataLoadsHistory = async function(carrierId, fileType) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/dataloads/all/${fileType}?timezone=${timeZone}`
  
  return internals.get(url);
};

export const getTransferFrequencies = async function (carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/frequency`;

  return internals.get(url);
}

export const editTransferFrequency = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/frequency`;

  return internals.put(url, payload);
}

export const editEncryption = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/encryption`;

  return internals.put(url, payload);
};

export const editFolderPath = async function (carrierId, payload, type) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/transfer/${type}/folder-path`;

  return internals.put(url, payload);
};

export const editFileType = async function (carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/file-type`;

  return internals.put(url, payload);
}

export const generatePgpKey = async function (carrierId, fileLocation, prefix, payload) {
  const fileLocations = {
    'customer_records': 'customer-files',
    'customer-files': 'customer-files',
    'death_records': 'death-files',
    'death-files': 'death-files'
  }

  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/${fileLocations[fileLocation]}/${prefix}/pgpKey`;

    return internals.put(url, payload);
}

export const updateFileTag = async function (carrierId, payload, fileType, prefix, tagType) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/files/${fileType}/prefix/${prefix}/tags`;

  return internals.put(url, payload);
};

export const getHolidayCalendars = async function(carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/holiday-calendars`;

  return internals.get(url);
};

export const putHolidayCalendars = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/holiday-calendars`;

  return internals.put(url, payload);
};

export const getReleaseNotesAuth = async function() {
  const url = `${process.env.REACT_APP_LENS_API}/release-notes`;

  return internals.get(url);
}

internals.get = async function(url) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

internals.put = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload)
  });
};

internals.fetch = async function(url, payload) {
  const result = await fetch(url, payload);

  const _json = await result.json();

  const {statusCode} = _json; // when the server throws a Joi error, then this is available and we want the body
  const {status} = result; // availabel from the fetch api;

  if(status > 199 && status < 300) { // we have success, return the json payload
    return _json;
  }

  if(statusCode > 200) {
    return _json;
  }

  return result;
};
