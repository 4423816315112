import { CalendarTodayRounded, ReadMoreRounded, SettingsSuggestRounded, VpnKeyRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppContext } from "src/AppContext";
import { filterItems } from "../../filterItems";

function ManageSidebarItems() {

  const { carrierConfig, lensRoles } = useAppContext();
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    const menuItems = [
      {
        name: 'List Configurations',
        icon: SettingsSuggestRounded,
        link: '/manage-configurations',
        statuses: ['live'],
        roles: ['administrator']
      },
      {
        name: 'Transfer Locations & Credentials',
        icon: ReadMoreRounded,
        link: '/transfer-locations',
        statuses: ['live'],
        roles: ['administrator']
      },
      {
        name: 'Encryption Keys',
        icon: VpnKeyRounded,
        link: '/encryption-keys',
        statuses: ['live'],
        roles: ['administrator']
      },
      {
        name: 'Holiday Calendar',
        icon: CalendarTodayRounded,
        link: '/holiday-calendar',
        statuses: ['live', 'on-boarding'],
        roles: ['administrator', 'collaborator']
      }
    ];

    if (lensRoles && carrierConfig?.status) {
      let filteredItems = menuItems.filter((item) => filterItems(item, lensRoles, carrierConfig?.status));

      const _displayManageItems = [
        {
          heading: 'Manage',
          items: filteredItems
        }
      ];

      if (filteredItems.length) {
        setDisplayItems(_displayManageItems);
      }

    }
  }, [lensRoles, carrierConfig?.status])

  return displayItems;
}

export default ManageSidebarItems;
