import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Typography, FormControl, FormHelperText, MenuItem, Grid, TextField, styled } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import { numDaysPerMonth, months } from 'src/utilities/months';

import _ from 'lodash';

const FormWrapper = styled(FormControl)(
  () => `
    div.Mui-disabled{
      .MuiOutlinedInput-notchedOutline {
          border: dotted 1px rgba(0, 0, 0, 0.23) !important;
      }
    }
  `
);

export const quarterlyInitialValues = {
  q1Month: '',
  q2Month: '',
  q3Month: '',
  q4Month: '',
  q1Day: '',
  q2Day: '',
  q3Day: '',
  q4Day: ''
}

export const quarterlyValidationSchema = function(isMatch, frequencyName, quarterlyErrors) {
  const validationSchema = {};

  [...Array(4)].forEach((val, i) => {
    validationSchema[`q${i+1}Month`] = yup
      .number()
      .when([frequencyName], (frequency) => {
        if (_.toLower(frequency) === 'quarterly') {
          if (quarterlyErrors[i]){
            return yup.number().max(0).required();
          }
          return yup.number().required();
        }
      });
    validationSchema[`q${i+1}Day`] = yup
      .number()
      .when([frequencyName, `q${i+1}Month`], (frequency, month) => {
        if (_.toLower(frequency) === 'quarterly' && month) {
          return yup.number().required();
        }
      });
  });

  return isMatch ? validationSchema : {}
}

export function QuarterlyDates({ formik, quarterlyErrors, setQuarterlyErrors, frequency=null, updatedFreq=null, reportDates=null, updatedReportDates=null }) {

  useEffect(() => {
    let dates;

    if (updatedReportDates) {
      dates = updatedReportDates;
    } else if (!updatedFreq && frequency === 'quarterly') {
      dates = reportDates;
    } else {
      dates = [];
    }

    _.each(dates, (date,i) => {
      formik.setFieldValue(`q${i+1}Month`, _.chain(date).split('/').head().toNumber().value());
      formik.setFieldValue(`q${i+1}Day`, _.chain(date).split('/').tail().toNumber().value());
    })
  }, [reportDates, updatedReportDates, updatedFreq, frequency])

  useEffect(() => {
    const combinedDayMonths = [...Array(4)].map((val, i) => {
      return [formik.values[`q${i+1}Day`], formik.values[`q${i+1}Month`]];
    });
    let quarterlyErrorsCopy = [false, false, false, false];
    combinedDayMonths.forEach((dayMonth, i) => {
      combinedDayMonths.slice(i+1).forEach((otherDayMonth, j) => {
        if(!_.includes(dayMonth, '')){
          if(_.isEqual(dayMonth, otherDayMonth)){
            quarterlyErrorsCopy[i] = true;
            quarterlyErrorsCopy[j+i+1] = true;
          }
        }
      })
    })
    setQuarterlyErrors(quarterlyErrorsCopy);
  }, [formik.values])

  const handleMonthChange = (event, name) => {
    formik.setFieldValue(name, '');
    formik.setFieldTouched(name, false);
    formik.handleChange(event);
  }

  const handleClick = (event, name) => {
    const clickedValue = event.target.dataset.value;
    const previousValue = formik.values[name];
    formik.setFieldTouched(name, !clickedValue && previousValue==='');
  }

  return(
    <FormWrapper sx={{ mt: 2, mb: 1, width: '100%' }}>
      <Grid container textAlign='left' spacing={3}>
        {[...Array(4)].map((val, i) => {
          const quarter = i + 1;
          return(
            <>
              <Grid item xs={1}>
                <Typography variant='subtitle1' sx={{paddingTop: 1.5}}>{`Q${quarter}:`}</Typography>
              </Grid>
              <Grid item xs={7} >
                <TextField
                  select
                  id={`q${quarter}Month`}
                  name={`q${quarter}Month`}
                  value={formik.values[`q${quarter}Month`]}
                  onChange={(event) => handleMonthChange(event, `q${quarter}Day`)}
                  onBlur={formik.handleBlur}
                  onClick={(event) => handleClick(event, `q${quarter}Month`)}
                  error={(formik?.touched[`q${quarter}Month`] && Boolean(formik?.errors[`q${quarter}Month`])) || quarterlyErrors[i]}
                  label="Month"
                  sx={{ width: '100%' }}
                >
                  {months.map((month, k) => {
                    return <MenuItem key={`q${quarter}${month}`} value={k+1}>{month}</MenuItem>
                  })}
                </TextField>
                {((formik?.touched[`q${quarter}Month`] && formik?.errors[`q${quarter}Month`]) || quarterlyErrors[i]) &&
                  <FormHelperText error sx={{marginBottom: -2}}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />{quarterlyErrors[i] ? 'Each quarter combination must be unique and cannot be duplicated.' : 'Selection is required.'}</FormHelperText>
                }
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  disabled={formik?.values[`q${quarter}Month`] === ''}
                  id={`q${quarter}Day`}
                  name={`q${quarter}Day`}
                  value={formik.values[`q${quarter}Day`]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onClick={(event) => handleClick(event, `q${quarter}Day`)}
                  label="Day"
                  error={((formik?.touched[`q${quarter}Day`] && Boolean(formik?.errors[`q${quarter}Day`])) || quarterlyErrors[i]) && formik?.values[`q${quarter}Month`] !== ''}
                  sx={{ width: '100%' }}
                >
                  {[...Array(numDaysPerMonth[formik.values[`q${quarter}Month`]-1])].map((val, i) => {
                    const day = i + 1;
                    return <MenuItem key={`q${quarter}day${day}`} value={day}>{day}</MenuItem>
                  })}
                </TextField>
                {formik?.touched[`q${quarter}Day`] && formik?.errors[`q${quarter}Day`] && formik?.values[`q${quarter}Month`] !== '' &&
                  <FormHelperText error sx={{marginBottom: -2}}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Selection is required.</FormHelperText>
                }
              </Grid>
            </>
          )
        })}
        <Grid item>
          <Typography variant='body2'>Match files will be delivered on the specified dates, and will consistently maintain this schedule year after year.</Typography>
        </Grid>
      </Grid>
    </FormWrapper>
  );
}

QuarterlyDates.propTypes = {
  formik: PropTypes.object, 
  quarterlyErrors: PropTypes.array, 
  setQuarterlyErrors: PropTypes.func,
  frequency: PropTypes.string,
  updatedFreq: PropTypes.string,
  reportDates: PropTypes.array,
  updatedReportDates: PropTypes.array
};