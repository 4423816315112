const customerListColumns = [
  { 
    field: 'customerList', 
    headerName: 'Customer List', 
    flex:0.6,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'matchWorkflow',
    headerName: 'Match Workflow',
    flex: 0.6,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  {
    field: 'sources',
    headerName: 'Sources',
    flex: 0.6,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  { 
    field: 'matchCategories', 
    headerName: 'Match Categories',
    flex: 1.2,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value.map((cat) => {
        return cat + ', ';
      })}</p></div>;
    }
  },
];

export default customerListColumns;