import React from 'react';
import { Typography, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CopyButton from '../CopyButton';
import PropTypes from 'prop-types';

function AwsInstructionFields({ evadataLambdaRoleArn, instructionText, enableTooltip, isMatchFileForm }){
    const toolTipText = 'Utilize the AWS Cloud Formation template and Lambdas to create S3 Bucket' + (!isMatchFileForm ? ', KMS Key': '') + ' and IAM Role ARNs.';
    const LambdaRoleArnDescription = 'Use Lambda Role ARN to set permissions for LENS to access your S3 bucket to ' +(!isMatchFileForm ? 'retrieve your list.' : 'send matches.')
    return (
      <>
        <Grid container>
          <Grid item xs={1}>
            <CopyButton toolTipText="Copy Role ARN" textToCopy={evadataLambdaRoleArn} />
          </Grid>
          <Grid item xs={11}>
          <Typography variant="body1" sx={{ mb: 1 }}> {evadataLambdaRoleArn}</Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ mb: '16px' }}>{LambdaRoleArnDescription}</Typography>
        <Grid container direction={"row"}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>{ instructionText }{enableTooltip && <Tooltip arrow placement="bottom"  title={toolTipText}><InfoIcon color='primary' fontSize='small' sx={{ ml: '5px', mt: '15px' }}></InfoIcon></Tooltip>}</Typography>
        </Grid>
      </>
    );
  }

  AwsInstructionFields.propTypes = {
    evadataLambdaRoleArn: PropTypes.string, 
    instructionText: PropTypes.string, 
    enableTooltip: PropTypes.bool, 
    isMatchFileForm: PropTypes.bool
  };

export default AwsInstructionFields;
