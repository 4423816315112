import React from 'react';

import { Grid, Typography, Card, CardContent, styled, Link, Box, Alert } from '@mui/material';
import { FileDownloadRounded, ErrorOutline, DownloadRounded, DownloadingRounded, ContentPasteRounded } from '@mui/icons-material';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import { downloadDocumentationFile } from '../../api';

const DownloadIconWrapper = styled(Box)(
  ({ theme }) => `
            width: 100%;
            text-align: center;
            border: 1px solid transparent;
            text-align: center;
            margin-top:1px;
            .error-text {
              position: absolute;
              width: 100%;
              left: 0;
              text-align: left;
              cursor: pointer;
              .MuiAlert-root {
                border-radius: 0px !important;
              }
            }
            &.error {
              border: 1px solid ${theme.palette.error.main};
            }
            .download-icon {
              background-color: transparent;
              &.label {
                width: auto;
                text-align: left;
              }
              .downloading {
                color: ${theme.palette.action.disabled};
                font-size: 26px;
              }
              .MuiSvgIcon-root {
                border-radius: 100px;
                text-align: center;
                vertical-align: middle;
              }
              .MuiSvgIcon-root:hover {
                background-color: ${theme.palette.action.hover};
              }
              .MuiLink-root:hover {
                text-decoration: none;
                cursor: pointer;
              }
              .icon-link {
                vertical-align: middle;
                text-transform: uppercase;
              }
          }
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `
  margin-bottom: 3px;
            &.error {
              border: 1px solid ${theme.palette.error.main};
            }
            .download-arrow {
              font-size: 50px;
            }
            .cardTop:hover {
              background-color: ${theme.palette.action.hover};
              cursor: pointer;
            }
            .file-name {
              background-color: ${theme.palette.background.paper};
            }
          }
  `
);

function FileDownloadCard({ variation, fileType, fileName, file, label, disableInlineError = false, disableErrorOutline = false, setHasError, iconFontSize = 16, iconWidth = '30px', iconTopPadding="6px" }) {
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);
  const [hasLocalErrorState, setHasLocalErrorState] = React.useState(false);

  const handleDownload = async () => {
    try {
      if(setHasError) {
        setHasError(false);
      }
      setDownloadInProgress(true);
      setHasLocalErrorState(false);
      const { url } = await downloadDocumentationFile(file);
      const result = await fetch(url);
      const data = await result.blob();

      if (!url) {
        setHasError(true);
        setHasLocalErrorState(true);
        setDownloadInProgress(false);
        return;
      }

      fileDownload(data, fileName);

      setDownloadInProgress(false);
      
    } catch (error) {
      // do something with the error
      setDownloadInProgress(false);
      setHasLocalErrorState(true);
      if(setHasError) {
        setHasError(true);
      }
      
    }
  };

  const clearError = () => {
    setHasLocalErrorState(false);
  }

  const downloadButton = <Typography variant="text" color="primary" sx={{ fontWeight: 500, letterSpacing: '0.46px', fontSize: '13px', mb: '8.5px', display: 'inline-flex' }}>DOWNLOAD {fileType}</Typography>;

  const loadingMessage = <Typography sx={{ mt: '10px', display: 'inline-flex' }} variant="body2" color="text.secondary">Retrieving file, please wait...</Typography>;

  const iconClass = (hasLocalErrorState && !disableErrorOutline) ? 'error' : 'no-error'
  const labelClass = label ? 'download-icon label' : 'download-icon icon-only'

  return (
    <>
      <div className={variation === 'card' ? 'show' : 'hide'} >
        <CardWrapper className={hasLocalErrorState === false ? '' : 'error'}>
          <CardContent sx={{ padding: 0 }}>
            <Grid onClick={handleDownload} className="cardTop" container sx={{ textAlign: 'center' }}>
              <Grid item xs={12} sx={{ pt: 2 }}>
                {downloadInProgress === false ? <FileDownloadRounded className="download-arrow" color="primary" /> : <DownloadingRounded color="primary" className="downloading" />}
              </Grid>
              <Grid item xs={12} sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
                {downloadInProgress === false ? downloadButton : loadingMessage}
              </Grid>
              <Grid className="file-name" item xs={12} sx={{ textAlign: 'left', padding: '15px 20px 0px 20px', minHeight: '70px', display: 'flex', alignItems: 'center' }}>
                <div>
                  <Typography sx={{ width: '100%' }} variant="body2">{fileName}</Typography>
                  {fileType === 'CSV' ?
                      <Grid container sx={{ mt: 1 }}>
                        <Grid item sx={{ mr: 1 }}>
                          <Typography variant="body2" color="text.secondary" >
                            <ContentPasteRounded fontSize="small" />
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="text.secondary" >
                            Note: Must be comma or pipe delimited.
                          </Typography>
                        </Grid>
                      </Grid>
                    : ''
                  }
                </div>

              </Grid>
            </Grid>
          </CardContent>
        </CardWrapper>
        {(hasLocalErrorState && !disableInlineError) && <span><ErrorOutline sx={{ color: 'error.main', float: 'left', ml: 3, fontSize: '20px', mt: '4px', mr: '4px' }} /><Typography variant="caption"> Error retrieving file, try again.</Typography></span>}
      </div>

      {(variation === 'icon') &&
        <DownloadIconWrapper className={iconClass}>
          <div className={labelClass}>
            <Link onClick={handleDownload}><Typography display='inline' className="icon-link" sx={{fontSize: iconFontSize}}>{label}</Typography>
              {downloadInProgress === false ? <DownloadRounded sx={{width: iconWidth, paddingTop: iconTopPadding}} /> : <DownloadingRounded className="downloading" />}
            </Link>
          </div>
          {(hasLocalErrorState && !disableInlineError) &&
            <div className="error-text">
              <Alert onClick={clearError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>
            </div>}
        </DownloadIconWrapper>
      }
    </>
  );
}

FileDownloadCard.propTypes = {
  variation: PropTypes.string, 
  fileType: PropTypes.string.isRequired, 
  fileName: PropTypes.string.isRequired, 
  file: PropTypes.string.isRequired, 
  label: PropTypes.string,
  disableInlineError: PropTypes.bool,
  setHasError: PropTypes.func,
  disableErrorOutline: PropTypes.bool,
  iconFontSize: PropTypes.any,
  iconWidth: PropTypes.any, 
  iconTopPadding: PropTypes.any
};

export default FileDownloadCard;