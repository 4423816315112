import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Grid, styled } from '@mui/material';
import { Star } from '@mui/icons-material';


const ChipWrapper = styled(Grid)(
  () => `
  .MuiChip-icon {
    order: 1;
    margin-left: -2px !important;
    margin-right: 5px !important;
    font-size: 16px !important;
  }
  `
);

function AttributeChip(props) {
  const color = props.required ? 'required' : 'optional';
  const label = props.required ? 'Required' : 'Optional';

  return (
    <ChipWrapper>
    {props.required === 'matching variable' ?    
      <Chip color={color} size='small' icon={<Star/>} label='Matching Variable'/> : 
      <Chip color={color} label={label} size='small'/>
    }
    </ChipWrapper>
  );
}

AttributeChip.propTypes = {
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
};

export default AttributeChip;
