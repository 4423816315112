import React from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import PgpRotationField from './pgpRotation';
import PropTypes from 'prop-types';

function EncryptionPreferenceField({ formik, name, pgpField }) {
  return (
    <>

      <Typography variant="h6" color="text.primary" sx={{ mb: 2, mt: 4 }}>Select File Encryption Preference</Typography>

      <RadioGroup
        id={name}
        name={name}
        value={formik?.values?.[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        sx={{ mb: 3 }}
      >
        <FormControlLabel value={true} control={<Radio />} label="Encrypt list with public PGP key created by LENS (Public key will be available to copy or download after adding new configuration)." />

        {(formik?.values?.[name] === 'true' || formik?.values?.[name] === true) &&
          <PgpRotationField name={pgpField} label="PGP Key Rotation" formik={formik} />
        }

        <FormControlLabel value={false} control={<Radio />} label="Do not encrypt list with PGP key." />
      </RadioGroup>

    </>
  )
}

EncryptionPreferenceField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  pgpField: PropTypes.string
};

export default EncryptionPreferenceField;
