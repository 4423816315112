import React from 'react';

import { TextField, Typography, FormHelperText, FormControl, MenuItem } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import PropTypes from 'prop-types';

import LoadingIcon from 'src/components/Loading/loadingIcon';

function TransferFrequencySelect({ formik, availableFrequencies, formInputName }) {

  const handleSelectClick = (event, name) => {
    const clickedValue = event.target.dataset.value;
    const previousValue = formik.values[name];
    formik.setFieldTouched(name, !clickedValue && previousValue==='');
  }

  return (
    <FormControl sx={{ mt: 2, width: '100%' }}>
      <TextField
        select
        id={formInputName}
        name={formInputName}
        value={formik.values[formInputName]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onClick={(event) => {handleSelectClick(event, formInputName)}}
        error={formik.touched[formInputName] && Boolean(formik.errors[formInputName])}
        label="Transfer Frequency"
        sx={{ width: '100%' }}
      >
        {availableFrequencies?.length === 0 &&
          <LoadingIcon contained noText />
        }
        <MenuItem value="Daily" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('daily')}>Daily (Monday-Friday)&nbsp;<span className="recommended">&nbsp;Recommended</span></MenuItem>
        <MenuItem value="Weekly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('weekly')}>Weekly</MenuItem>
        <MenuItem value="Monthly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('monthly')}>Monthly</MenuItem>
        <MenuItem value="Quarterly" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('quarterly')}>Quarterly</MenuItem>
        <MenuItem value="Once" className={availableFrequencies?.length === 0 && 'hide'} disabled={!availableFrequencies?.includes('once')}>Once</MenuItem>
        {(availableFrequencies?.length < 5) && availableFrequencies?.length > 0 &&
          <Typography variant="caption" sx={{ display: 'inline-block', pl: 2, pr: 2, pt: 1 }}>Contact LENS to find out how to add a frequency not<br/>available to your organization.</Typography>
        }
      </TextField>
      {formik.touched[formInputName] && Boolean(formik.errors[formInputName]) && <FormHelperText sx={{ ml: -1 }} error><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Frequency selection is required.</FormHelperText>}
      <FormHelperText>Schedule informs LENS on when to send your matches.</FormHelperText>
    </FormControl>
  );
}

TransferFrequencySelect.propTypes = {
  formik: PropTypes.object,
  availableFrequencies: PropTypes.array,
  formInputName: PropTypes.string
};

export default TransferFrequencySelect;