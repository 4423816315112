import React from 'react';
import { Avatar, Grid, Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';

function CardTop(props) {
  return (

    <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
      <Typography variant="overline" color="primary.text">
        {props.title}
        <Avatar className="nav-card-circle">
          {props.icon}
        </Avatar>
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>{props.subtitle}</Typography>
      <Divider className="small-rule"/>
    </Grid>

  )
}

CardTop.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  subtitle: PropTypes.string
}


export default CardTop;