import React, { useState } from 'react';

import { styled, Card, Typography, CardContent, Box, Grid, FormHelperText, Tab, Tabs } from '@mui/material';
import FileDownloadCard from '../FileDownload';
import { ErrorOutline } from '@mui/icons-material';
import PropTypes from 'prop-types';

const CardWrapper = styled(Box)(
  ({ theme }) => `
    .error {
      border: 1px solid ${theme.palette.error.main};
    }

    .MuiButtonBase-root.MuiTab-root {
      background-color: ${theme.palette.background.paper};
      margin: 0;
      padding: 0;
      border: 0;
      box-shadow: none;
      outline: none;
    }

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.background.paper} !important;
      box-shadow: none;
      outline: none;
      border: 0;
      border-radius: 0px;
      border-bottom: 4px solid ${theme.palette.primary.main};
    }
  `
)

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function ExampleCodeCard({ data, fileName, xlsxFileName, showXlsx, showCsv, csvFileName }) {

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const CodeWrapper = styled(Box)(
    () => `
      .preformatted-code {
        pre {
        font-family: 'Lato' !important;
        font-size: 15px;
        margin-top: 0px;
        white-space: pre-wrap;
      }

      @media screen and (max-width: 600px) {
        .MuiPaper-root {
          float: none !important;
      }
    }

      @media screen and (max-width: 450px) {
          height: 400px !important;
          overflow-y: scroll;
      }
    `
  )

  const [hasError, setHasError] = useState(false);

  return (
    <CardWrapper>
      <Card className={hasError ? 'error' : ''} sx={{ padding: 0, float: { xs: 'none', sm: 'right' }, borderRadius: '6px 6px 0px 0px', boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
        <CardContent sx={{ px: 0, width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs TabIndicatorProps={{
              style: { display: 'none' }
            }} sx={{ width: '100%' }} value={value} onChange={handleChange} aria-label="basic tabs example">
              {showXlsx && <Tab elevation={0} sx={{ width: '50%' }} label="XLSX Example" {...a11yProps(0)} />}
              {showCsv && <Tab elevation={0} sx={{ width: '50%' }} label="CSV Example" {...a11yProps(0)} />}
              <Tab elevation={0} sx={{ width: '50%' }} label="JSON Example" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Grid container sx={{ px: 0, width: '100%', display: 'block' }} className="mine">
            {showXlsx && <CustomTabPanel sx={{ pl: 0 }} value={value} index={0}>
              <Box sx={{ pl: 6, pt: 1 }}>
                <FileDownloadCard label="Download XLSX Template" variation="icon" fileType="XLSX" fileName={xlsxFileName} file={xlsxFileName} setHasError={setHasError} disableInlineError={true} />
              </Box>
              <CodeWrapper>
                <hr />
                <Typography variant="body2" sx={{ px: 3, py: 1 }}>The XLSX example is best viewed by downloading the template above or at the bottom of this page.</Typography>
              </CodeWrapper>
            </CustomTabPanel>}
            {showCsv && <CustomTabPanel value={value} index={0}>
              <Box sx={{ pl: 6, pt: 1 }}>
                <FileDownloadCard label="Download CSV Template" variation="icon" fileType="CSV" fileName={csvFileName} file={csvFileName} setHasError={setHasError} disableInlineError={true} />
              </Box>
              <hr />
              <Typography variant="body2" sx={{ px: 3, py: 1 }}>The CSV example is best viewed by downloading the template above or at the bottom of this page.</Typography>
            </CustomTabPanel>}
            <CustomTabPanel value={value} index={1} sx={{ width: '100%' }}>
              <Box sx={{ pl: 6, pt: 1 }}>
                <FileDownloadCard label="Download JSON Template" variation="icon" fileType="JSON" fileName={fileName} file={fileName} setHasError={setHasError} disableInlineError={true} />
              </Box>
              <CodeWrapper>
                <hr />
                <CardContent className="preformatted-code">
                  <pre>{data}</pre>
                </CardContent>
              </CodeWrapper>
            </CustomTabPanel>
          </Grid>
        </CardContent>
      </Card>
      {hasError ? <FormHelperText sx={{ mt: '-20px', mb: 4 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Error retrieving file. Try again. </FormHelperText> : ''}
    </CardWrapper>
  );
}

ExampleCodeCard.propTypes = {
  fileName: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  showXlsx: PropTypes.bool,
  showCsv: PropTypes.bool,
  xlsxFileName: PropTypes.string,
  csvFileName: PropTypes.string
}

export default ExampleCodeCard;