export function getCurrentDate(){

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let fullMonth;
let fullDate;

if (month < 10) {
  fullMonth = 0+''+month;
} else {
  fullMonth = month;
}

if (date < 10) {
  fullDate = 0+''+date;
} else {
  fullDate = date;
}

return year+''+fullMonth+''+fullDate
}