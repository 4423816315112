import { React } from 'react';
import { Alert } from '@mui/material';
function GeneralErrorAlert() {

  return (
    <Alert severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
  )
}

GeneralErrorAlert.propTypes = {};

export default GeneralErrorAlert;