import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled, Button } from '@mui/material';
import { CheckCircleOutlineRounded } from '@mui/icons-material';

const AccountLoadWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    z-index: 2;
    background: ${theme.palette.common.white}ed;
    text-align: center;
    width: calc(100% - 300px);
    left: 290px;
    height: calc(100%);
    top: 0px;
    padding-top: calc(21%);
    .message-wrapper {
      max-width: 500px;
      margin: 0 auto;
    }
    .MuiSvgIcon-root {
      font-size: 44px;
    }
    @media screen and (max-width: 1280px) {
      width: 100%;
      left: 0px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 200px;
    }
  `
);

function ProcessingCompleted({ finishProcessing }) {
  return (
    <AccountLoadWrapper>
      <Box className="message-wrapper">
        <CheckCircleOutlineRounded color="success" />
        <Typography variant="h6" color="success.main">Completed</Typography>
        <Typography variant="body2" sx={{ pt: 1, width: '100%' }}>Please stay on this page while we whitelist your AWS environment and generate Lambdas for you to use.</Typography>
        <Button variant="outlined" size="small" onClick={finishProcessing} sx={{ mt: 2 }}>Back To Setup</Button>
      </Box>

    </AccountLoadWrapper>
  )
}

ProcessingCompleted.propTypes = {
  finishProcessing: PropTypes.func
};

export default ProcessingCompleted;