import React from 'react';
import PropTypes from 'prop-types';

import { TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { OpenInNewRounded } from '@mui/icons-material';


function SftpSsh({formik, mb, sshRef }){
  return (
    <>
      <Typography ref={sshRef} variant="body1" color="text.secondary" sx={{ mb: 2 }}>Create SSH-RSA key to authenticate to access LENS virtual server instance. <Link className="ssh-link" to="https://docs.aws.amazon.com/transfer/latest/userguide/key-management.html#sshkeygen" target="blank">Learn More <OpenInNewRounded sx={{ fontSize: '18px', mb: '-4px', ml: '4px' }} /></Link></Typography>
      <TextField
      fullWidth
      multiline
      rows={6}
      id="SSHRSAKey"
      name="SSHRSAKey"
      label="Enter Public SSH-RSA Key"
      value={formik.values.SSHRSAKey}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={formik.touched.SSHRSAKey && Boolean(formik.errors.SSHRSAKey)}
      helperText={formik.touched.SSHRSAKey ? formik.errors.SSHRSAKey : 'For security reasons, the public SSH-RSA key provided will be hidden after saving.'}
      sx={{ mb: mb }}
      />
    </>
  )
}

SftpSsh.propTypes = {
  formik: PropTypes.object,
  mb: PropTypes.number,
  sshRef: PropTypes.string 
};

export default SftpSsh;