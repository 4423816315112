import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { ReadMoreRounded } from '@mui/icons-material';

import PageHeader from 'src/components/PageHeader';

import SftpTransferTable from './SftpTransferTable';
import AwsS3TransferTable from './AwsS3TransferTable';


function TransferTable({ allCustomerResults, customerResult, verifiedDeathsResult, allVerifiedDeathsResults, viewCustomerDetailsSftp, allMatchResults = [], matchFileResult, viewCustomerDetails, viewMatchDetails, viewVfdDetails, viewAwsDetails, showError, isLoading, uniqueBucketRolePairs, uniqueSftpMatchLocations }) {
  const [sftpCount, setSftpCount] = useState(0);

  useEffect(() => {
    const sftpServers = new Set();

    allCustomerResults.forEach((customerResult) => {
      const sftpServer = customerResult?.transfer?.sftp?.sftpServer;

      if (sftpServer) {
        sftpServers.add(sftpServer);
      }
    });

    allMatchResults.forEach((matchResult) => {
      const sftpServer = matchResult?.transfer?.sftp?.host;
      const sftpPort = matchResult?.transfer?.sftp?.port;

      if (sftpServer && sftpPort) {
        sftpServers.add(sftpServer + ':' + sftpPort);
      }
    });

    allVerifiedDeathsResults?.forEach((vfdResult) => {
      const vdfSftpServer = vfdResult?.transfer?.sftp?.sftpServer;

      if (vdfSftpServer) {
        sftpServers.add(vdfSftpServer);
      }
    })

    setSftpCount(sftpServers.size);

  }, [allCustomerResults, allMatchResults, allVerifiedDeathsResults]);

  return (
    <>
      <PageHeader title="Transfer Locations & Credentials" subtitle="View locations used to transfer lists and retrieve matches." icon={<ReadMoreRounded />} />
      <Typography className={(showError || isLoading) ? 'hide' : 'show'} variant="h5">{customerResult?.transfer?.type === 'sftp' ? `SFTP Servers (${sftpCount})` : `S3 Buckets & IAM Roles (${uniqueBucketRolePairs?.length})`}</Typography>
      {customerResult?.transfer?.type === 'sftp' ?
        <SftpTransferTable allCustomerResults={allCustomerResults} customerResult={customerResult} allMatchResults={allMatchResults} matchFileResult={matchFileResult} verifiedDeathsResult={verifiedDeathsResult} allVerifiedDeathsResults={allVerifiedDeathsResults} viewCustomerDetailsSftp={viewCustomerDetailsSftp} viewMatchDetails={viewMatchDetails} uniqueSftpMatchLocations={uniqueSftpMatchLocations} />
        :
        <AwsS3TransferTable uniqueBucketRolePairs={uniqueBucketRolePairs} customerResult={customerResult} viewAwsDetails={viewAwsDetails} />
      }
    </>
  );
}

TransferTable.propTypes = {
  allCustomerResults: PropTypes.array,
  customerResult: PropTypes.object,
  verifiedDeathsResult: PropTypes.object,
  allMatchResults: PropTypes.array,
  matchFileResult: PropTypes.object,
  viewCustomerDetailsSftp: PropTypes.func,
  viewCustomerDetails: PropTypes.func,
  viewMatchDetails: PropTypes.func,
  viewVfdDetails: PropTypes.func,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
  allVerifiedDeathsResults: PropTypes.array, 
  viewAwsDetails: PropTypes.func,
  uniqueBucketRolePairs: PropTypes.array,
  uniqueSftpMatchLocations: PropTypes.array
};

export default TransferTable;
