const matchColumns = [
  { field: 'fileName', 
    headerName: 'File Name', 
    flex: 1.5,
    headerAlign: 'left',
    renderCell: (params) => {
      if(params.row.fileNameNickname != null){
        return <div className="wrap-cell"><p>{params.row.fileNameNickname}</p><p>{params.value}</p></div>;
      }
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  {
    field: 'id',
    headerName: 'Dataload ID',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  {
    field: 'completed',
    headerName: 'Results Sent',
    flex: 1.5,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  {
    field: 'totalMatches', 
    headerName: 'Match Records', 
    flex: 1, 
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'fileStatus',
    headerName: 'File Status',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'downloadFile',
    headerName: 'Download',
    headerAlign: 'left',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  }
];

export default matchColumns;