import { React, useEffect, useState } from 'react';
import { Grid, Typography, styled, Box, Button } from '@mui/material';
import CopyButton from 'src/components/CopyButton';
import FileDownloadCard from '../FileDownload';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PropTypes from 'prop-types';

const CloudTemplatesWrapper = styled(Box)(
  ({ theme }) => `
  text-align: left;
  border: 1px solid ${theme.palette.common.black}99;
  padding: 25px;
  background: ${theme.palette.background.default};
  `
);

function CloudTemplates({ data = {}, associatedData = [], evadataLambdaArn, evadataLambdaRoleArn, textAlign = 'center' }) {
  const [showCloudTemplates, setShowCloudTemplates] = useState(false);
  const [configTypes, setConfigTypes] = useState(null);
  const [evadataMatchLambdaRoleArn, setEvadataMatchLambdaRoleArn ] = useState(null);

  function toggleCloudTemplates() {
    setShowCloudTemplates(!showCloudTemplates);
  }

  useEffect(() => {
    const types = [];

    types.push(data?.configurationType);

    associatedData?.forEach((item) => {
      types.push(item?.configurationType);
      if (item?.transfer?.awsS3?.evadataMatchLambdaRoleArn) {
        setEvadataMatchLambdaRoleArn(item?.transfer?.awsS3?.evadataMatchLambdaRoleArn);
      }
    })

    setConfigTypes(types);
  }, [data?.configurationType, associatedData])

  return (
    <>
      <Grid item xs={12} sx={{ textAlign: textAlign }}>
        <Button className="cloud-template-button" variant="text" size="medium" sx={{ mb: 2 }} onClick={toggleCloudTemplates}> {showCloudTemplates ? 'Hide' : 'Show'} cloud templates &amp; lambdas {showCloudTemplates ? <ExpandLess /> : <ExpandMore />}</Button>
      </Grid>
      {showCloudTemplates &&
        <CloudTemplatesWrapper>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>Carrier AWS Cloud Formation Templates:</Typography>
          {configTypes?.includes('customer_records') &&
            <Grid item sx={{ ml: 1, mb: 2 }}>
              <FileDownloadCard variation="icon" fileName="carrier-aws-customer-files-option.yml" file='carrier-aws-customer-files-option.yml' fileType='yml' label="Customer List Template" />
            </Grid>
          }
          {configTypes?.includes('death_records') &&
            <Grid item sx={{ ml: 1, mb: 2 }}>
              <FileDownloadCard variation="icon" fileName="carrier-aws-vdf-option.yml" file='carrier-aws-vdf-option.yml' fileType='yml' label="Verified Deaths Template" />
            </Grid>
          }
          {configTypes?.includes('matches') &&
            <Grid item sx={{ ml: 1, mb: 2 }}>
              <FileDownloadCard variation="icon" fileName="carrier-aws-match-files-option.yml" file='carrier-aws-match-files-option.yml' fileType='yml' label="Matches Template" />
            </Grid>
          }
          <Grid item sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{}}>Downloading and deploying template(s) with the Lambdas in your AWS environment provides ARN output values.</Typography>
          </Grid>
          {evadataLambdaArn &&
            <Grid container>
              <Grid item sx={{ mb: 2}}>
                <Typography variant="subtitle1" display="inline">Lambda ARN:</Typography>
                <CopyButton toolTipText="Copy ARN" textToCopy={evadataLambdaArn} display="inline" />
                <Typography variant="body1" color="text.primary" display="inline">{evadataLambdaArn}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">Use Lambda ARN to set the event trigger for the S3 bucket.</Typography>
              </Grid>
            </Grid>
          }
          <Grid container>
            <Grid item sx={{ mb: 2 }}>
              <Typography variant="subtitle1" display="inline" >{(configTypes?.includes('matches') && data.configurationType === 'matches') && 'Matches '}Lambda Role ARN: </Typography>
              <CopyButton toolTipText="Copy ARN" textToCopy={evadataLambdaRoleArn} display="inline" />
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }} display="inline">{evadataLambdaRoleArn}</Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
                <Typography variant="body1">
                  {(configTypes?.includes('matches') && data.configurationType === 'matches') ? 
                  'Lambda Role ARN is used by your organization to set permissions for LENS to access your S3 bucket to send matches.' : 
                  'Use Lambda Role ARN to set permissions for LENS to access your S3 bucket to retrieve your list.'}
                </Typography>
            </Grid>
          </Grid>
          {evadataMatchLambdaRoleArn && (data.configurationType !== 'matches') &&
          <Grid container>
            <Grid item sx={{ mb: 2 }}>
              <Typography variant="subtitle1" display="inline">Matches Lambda Role ARN:</Typography>
              <CopyButton toolTipText="Copy ARN" textToCopy={evadataMatchLambdaRoleArn} display="inline"/>
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }} display="inline">{evadataMatchLambdaRoleArn}</Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <Typography variant="body1">Lambda Role ARN is used by your organization to set permissions for LENS to access your S3 bucket to send matches.</Typography>
            </Grid>
          </Grid>
          }
        </CloudTemplatesWrapper>
      }
    </>
  )
}

CloudTemplates.propTypes = {
  data: PropTypes.object, 
  associatedData: PropTypes.array, 
  evadataLambdaArn: PropTypes.string, 
  evadataLambdaRoleArn: PropTypes.string, 
  textAlign: PropTypes.string
};

export default CloudTemplates;