import React from 'react';

import MenuSection from '../MenuSection';
import DocumentationItems from './items';

function Documentation(roles, status) {
  return (
    <>
      {DocumentationItems(roles, status).map((section) => (
        <MenuSection heading={section.heading} items={section.items} key={section.heading} />
      ))}
    </>
  );
}

export default Documentation;