const jsonData = `[
  {
     "customer_id": "person-123",
     "ssn":"830701384",
     "dob":"19560424",
     "first_name":"FORREST",
     "middle_name":"SOLIS",
     "last_name":"NEHEMIAH",
     "address_line_1":"4708 PGIBFBPI KVVA",
     "address_line_2":"APT 231",
     "city":"UNION CITY",
     "state":"CA",
     "postal_code":"94587",
     "country": "US"
  },
  {
    "customer_id": "person-4356",
    ...
  }
]`;

export default jsonData;