import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, styled} from '@mui/material';
import AttributeCard from 'src/components/AttributeCard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionWrapper = styled(Accordion)(
  ({ theme }) => `
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      .MuiAccordionSummary-root {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      .MuiAccordionSummary-root.Mui-expanded {
        background-color: ${theme.palette.action.selected};
      }
      .MuiAccordionDetails-root {
        padding: 0px;
      }
      .child .MuiCard-root{
        box-shadow: 0 0 0 !important;
        border-radius: 0px;
      }
      .child .MuiCardContent-root {
        padding: 20px 30px;
      }
      .child .MuiAccordion-root {
        background-color: transparent;
        border-radius: 25px !important;
        border: 1px solid #aaa;
        margin-left: 30px;
        margin-right: 30px;
      }
      .child  .MuiAccordionSummary-root {
        background-color: transparent;
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
      }
      .child .MuiAccordionSummary-root, .child .MuiAccordionSummary-root:hover {
        min-height: 40px;
      }
      .child  .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
      }
     .MuiAccordionSummary-root[aria-expanded="true"] > .MuiAccordionSummary-content > .MuiTypography-root > .show-text {
        display: none;
      }
      .child .MuiAccordionSummary-root[aria-expanded="true"] {
        border-bottom: 2px solid #aaa;
        background-color: ${theme.palette.action.selected};
      }
     .MuiAccordionSummary-root[aria-expanded="false"] > .MuiAccordionSummary-content > .MuiTypography-root > .hide-text {
        display: none;
      }
      .child-accordion hr:nth-last-of-type(1) {
        display:none;
      }
      .accordion-title-text {
        color: ${theme.palette.text.secondary};
      }
      @media screen and (max-width: 600px) {
        .child .MuiAccordion-root {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
  `
);

function AttributeChild(props) {
    return (
      <Grid className="child-accordion" item xs>
        <AccordionWrapper>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1"><span className='show-text'>Show</span><span className='hide-text'>Hide</span> Child Attributes <Typography display="inline" color="text.secondary">"{props?.title}"</Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        {props.data.map((card) => (
          <>
          <AttributeCard key={card.title} title={card.title} required={card.required} dataType={card.dataType} length={card.length} format={card.format} note={card.note} url={card.url} urlText={card.urlText} childData={card.children} isChild={true}></AttributeCard>
            <hr/>
          </> 
          ))}
        </AccordionDetails>
      </AccordionWrapper>
        
    </Grid>
    );
}

AttributeChild.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};

export default AttributeChild;