import React from 'react';
import { Typography, Grid, Button, ThemeProvider } from '@mui/material';
import { ReactComponent as EvadataLogo } from 'src/images/EvadataLogo404.svg'
import CommGraphic from 'src/images/500ServerCommGraphic.png';
import { MailRounded, RefreshRounded } from '@mui/icons-material';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';
import { EvadataTheme } from 'src/theme/schemes/EvadataTheme';
import { mailError } from 'src/utilities/mailError';

function ErrorPage500() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const error = useRouteError();

  function refreshPage() {
    let page = searchParams?.get('page');
    if (page) {
      if (page === '500') {
        navigate('/');
      } else {
        navigate('/' + page);
      }
    } else {
      navigate('/');
    }
  }

  return (
    <ThemeProvider theme={EvadataTheme}>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '100px' }}>
          <EvadataLogo />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={CommGraphic} alt="Page error 500" />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
          <Typography variant="h2" sx={{ maxWidth: '700px', margin: '0 auto' }}>Something went wrong.</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography variant="h5" color="black" sx={{ maxWidth: '700px', margin: '0 auto' }}>The server encountered an internal error and was not able to complete your request. Please try refreshing your browser or contact LENS support if the issue persists.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { sm: 'right', xs: 'center' }, mt: 6 }}>
          <Button sx={{ mr: { sm: 3, xs: 0 }, minWidth: '192px' }} startIcon={<MailRounded />} onClick={() => mailError(error)} variant="outlined" color="primary">Email Support</Button>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { sm: 'left', xs: 'center' }, mt: { sm: 6, xs: 2 }, mb: 5 }}>
          <Button sx={{ ml: { sm: 3, xs: 0 }, minWidth: '192px' }} onClick={refreshPage} variant="contained" color="primary" startIcon={<RefreshRounded />}>Refresh</Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}


ErrorPage500.propTypes = {};

export default ErrorPage500;