import React from 'react';
import PropTypes from 'prop-types';

import { FormHelperText, Typography } from '@mui/material';
import EmailCheckboxEntry from '../EmailCheckboxEntry';
import EmailMultiEntry from '../EmailMultiEntry';

function NotificationPreferences({ formik, availableEmailAddresses, existingEmails, newEmails }) {
  return (
    <>
      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>NOTIFICATION PREFERENCES</Typography>
      <EmailCheckboxEntry availableEmailAddresses={availableEmailAddresses} formInputName="newCustExistingEmails" hasError={(formik?.errors?.[existingEmails] || formik?.errors?.newEmails)} />
      <EmailMultiEntry formik={formik} fieldName="newCustEmails" availableEmailAddresses={availableEmailAddresses} />
      {(formik?.errors?.[existingEmails] || formik?.errors?.[newEmails]) ? <FormHelperText sx={{ mb: 4, mt: 1, fontWeight: 400 }} error={true}>*One email selection or email entry is required.</FormHelperText> : ''}
    </>
  )
}

NotificationPreferences.propTypes = {
  formik: PropTypes.object, 
  availableEmailAddresses: PropTypes.array, existingEmails: PropTypes.string, 
  newEmails: PropTypes.string
};

export default NotificationPreferences;
