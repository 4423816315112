import { React } from 'react';
import { Typography, Card, Grid, styled} from '@mui/material';
import PropTypes from 'prop-types';

const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

function DisplayDateAdded({ data }) {

  return (
    <CardWrapper className="transfer-date-card" sx={{ mb: 3 }}>
      <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
        <Grid item xs={12}>
          <Typography className="transfer-date-added" variant="subtitle1">Date Added</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ p: 3, pl: 5, }}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {data?.auditDate}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: '11px' }}>
            By: {data?.auditUser}
          </Typography>
        </Grid>
      </Grid>
    </CardWrapper>
  )
}

DisplayDateAdded.propTypes = {
  data: PropTypes.object
};

export default DisplayDateAdded;