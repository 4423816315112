import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AttributeChip from './AttributeChip';
import AttributeNote from './AttributeNote';
import AttributeChild from './AttributeChild';

const CardWrapper = styled(Grid)(
  () => `
  .MuiCard-root {
    padding: 0px !important;
}
  `
);


function AttributeCard(props) {
  return (
    <CardWrapper className={props.isChild ? 'child' : 'parent'}>
      <Card sx={{ minWidth: 275, textAlign: 'left', mb: 3}}>
        <CardContent sx={{padding: '16px'}}>
          <Grid container spacing={0}>
            <Grid item xs={6} sm={6}>
              <Typography variant="subtitle1" sx={{ mb: 2 }} gutterBottom>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2, textAlign: 'right' }} color="text.primary" gutterBottom>
                <AttributeChip required={props.required} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Data Type:</strong> <span>{props.dataType}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography>
                <strong>Length:</strong> <span>{props.length}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ mb: 2 }}>
                <strong>Format:</strong> <span>{props.format}</span>
              </Typography>
            </Grid>
            {props.note && <AttributeNote note={props.note} url={props.url} urlText={props.urlText} />}
          </Grid>
        </CardContent>
        {(props?.childData?.length > 0) && <AttributeChild title={props.title} data={props.childData}></AttributeChild>}
      </Card>
    </CardWrapper>

  );
}

AttributeCard.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  dataType: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  note: PropTypes.string,
  childData: PropTypes.object,
  isChild: PropTypes.bool,
  url: PropTypes.string,
  urlText: PropTypes.string
};

export default AttributeCard;
