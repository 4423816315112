import { useContext } from 'react';
import Scrollbar from '../Scrollbar';
import { SidebarContext} from 'src/contexts/SidebarContext';

import { Box, Drawer, styled, useTheme, Tooltip, IconButton } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { MenuOpen } from '@mui/icons-material';

import SidebarMenu from './SidebarMenu';
import SidebarBottomButton from './BottomButton';

const ToggleButton = styled(Box)(
  () => `
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 9999999;

    @media screen and (max-width: 359px) {
      left: 3px;
    }
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 3;
        height: 100%;

        @media screen and (max-width: 1080px) {
          box-shadow: none;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
     <ToggleButton className="toggleSidebarButton" color="primary" onClick={toggleSidebar}>
     <Tooltip arrow title="Toggle Menu" sx={{
                            display: {
                                md: 'block',
                                lg: 'none'
                            }, 
                            float: 'left',
                        }}>
                <IconButton className="toggle-button" color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuOpen /> : <CloseTwoToneIcon />}
                </IconButton>
            </Tooltip>
     </ToggleButton>
    
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 60,
          height: 'calc(100% - 160px)'
        }}
      >
        
        
        <Scrollbar >
          <TopSection>
            <Box
              sx={{
                width: 52,
                mt: 2,
                mb: 3
              }}
            >
            </Box>
          </TopSection>
          <SidebarMenu />
        </Scrollbar>
        <SidebarBottomButton />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper  sx={{
          display: {
            xs: 'inline-block',
            lg: 'none'
          },
          left: 0,
          top: 80,
          height: 'calc(100% - 180px)'
        }}>
          <Scrollbar>
            <SidebarMenu />
          </Scrollbar>
          <SidebarBottomButton />
        </SidebarWrapper>
      </Drawer>
      
    </>
  );
}

export default Sidebar;
